import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import 'rxjs-compat/add/operator/map';
import {Location} from '../models/location.model';
import '../views/shared/utils/observable.extensions';
import {SessionContainer} from '../models/session-container';
import {map} from 'rxjs/operators';
import {CompanyType} from '../models/company.model';

@Injectable({
    providedIn: 'root'
})
export class SessionService {
    public sessionContainer = new BehaviorSubject<SessionContainer>(null);
    public currentLocation = new BehaviorSubject<Location>(null);
    public userLocations = new BehaviorSubject<Location[]>(null);

    public locationId$ = this.currentLocation.pipe(
        map(cl => cl?.id)
    ).notNull();
    public companyId$ = this.sessionContainer.pipe(
        map(sc => sc?.company?.id)
    ).notNull();
    public employeeId$ = this.sessionContainer.pipe(
        map(sc => sc?.employee?.id)
    ).notNull();

    public company$ = this.sessionContainer.pipe(map(sc => sc?.company));
    public isDentalLabCompany$ = this.company$.pipe(
        map(c => c?.companyTypeId === CompanyType.DentalLab)
    );

    constructor() {
        this.sessionContainer.notNull().subscribe(s => {
            this.setDefaultCurrentLocation();
            const validUserLocations: Location[] = [];
            (s.companyLocations ?? []).forEach((loc) => {
                if (s.employee.assignedLocations.map(l => l.locationId).includes(loc.id)) {
                    validUserLocations.push(loc);
                }
            });
            this.userLocations.next(validUserLocations);
        });
    }

    public getAuthToken(): string {
        return this.sessionContainer.value?.employee.userSession?.accessToken ?? null;
    }

    public getCompanyId(): number {
        return this.sessionContainer.value?.company.id ?? -1;
    }

    setDefaultCurrentLocation() {
        if (this.sessionContainer.value !== null && this.currentLocation.value === null) {
            const sessionContainer = this.sessionContainer.value;
            let defaultLocation, fallbackLocation: Location;
            sessionContainer.companyLocations?.forEach((loc) => {
                sessionContainer.employee.assignedLocations.map(l => l.locationId).forEach((uLocId) => {
                    if (sessionContainer.employee.defaultLocationId === loc.id) {
                        defaultLocation = loc;
                    }
                    if (uLocId === loc.id && !fallbackLocation) {
                        fallbackLocation = loc;
                    }
                });
            });

            if (sessionContainer.currentLocation) {
                // must find the location from the session array to ensure pointing at the same memory address for filter options in tables
                this.currentLocation.next(sessionContainer.companyLocations.find(l => l.id === sessionContainer.currentLocation.id));
            } else if (defaultLocation) {
                this.currentLocation.next(defaultLocation);
            } else if (fallbackLocation) {
                this.currentLocation.next(fallbackLocation);
            }
        }
    }
}
