import {Injectable} from '@angular/core';
import {FileUploadRequest, ImageUploadRequest} from '../models/file-upload.request';
import {ApiService} from './api.service';
import {Observable, ReplaySubject} from 'rxjs';
import {Referral} from '../models/referral.model';
import {environment} from '../../environments/environment';
import {Correspondence} from '../models/correspondence.model';
import {File} from '../models/file';
import * as buffer from 'buffer';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Attachment, AttachmentLink} from '../models/attachment.model';
import {EntityService} from './entity.service';
import {LabReferral} from '../models/lab-referral-preview';
import {Appointment} from "../models/enum/appointment.model";
import {map, switchMap} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})

export class FileService {

    constructor(private api: ApiService,
                private entityService: EntityService,
                private http: HttpClient) {
    }

    public getCorrespondenceAttachmentUploadUrl(correspondence: Correspondence, fileUploadRequest: FileUploadRequest): Observable<Attachment> {
        return this.api.post<Attachment>(`${environment.apiBaseUrl}/referrals/${correspondence.referralId}/correspondence/${correspondence.id}/attachments`, fileUploadRequest);
    }

    public getReferralAttachmentUploadUrl(referral: Referral, fileUploadRequest: FileUploadRequest): Observable<Attachment> {
            return this.api.post<Attachment>(`${environment.apiBaseUrl}/referrals/${referral.id}/attachments`, fileUploadRequest);
    }

    public getLabReferralAttachmentUploadUrl(referral: LabReferral, fileUploadRequest: FileUploadRequest): Observable<Attachment> {
        switch (referral.dentalLabsProductType) {
            case 'Implants':
                return this.api.post<Attachment>(`${environment.apiBaseUrl}/dentallabs/implantsreferrals/${referral.id}/attachments`, fileUploadRequest);
            case 'Orthodontics':
                return this.api.post<Attachment>(`${environment.apiBaseUrl}/dentallabs/orthodonticsreferrals/${referral.id}/attachments`, fileUploadRequest);
            case 'Removables':
                return this.api.post<Attachment>(`${environment.apiBaseUrl}/dentallabs/removablesreferrals/${referral.id}/attachments`, fileUploadRequest);
            case 'Crowns, Bridges & Veneers':
                return this.api.post<Attachment>(`${environment.apiBaseUrl}/dentallabs/crownsplusreferrals/${referral.id}/attachments`, fileUploadRequest);
        }
    }

    public getStageAppointmentAttachmentUploadUrl(appointment: Appointment, fileUploadRequest: FileUploadRequest): Observable<Attachment> {
        return this.api.post<Attachment>(`${environment.apiBaseUrl}/appointments/${appointment.id}/attachments`, fileUploadRequest);
    }


    public uploadReferralAttachment(file: File, referral, isLabReferral: boolean = false): Observable<boolean> {
        const uploadUrlRequest = new FileUploadRequest(this.entityService.getAttachmentTypeFromMediaType(file.type)?.id, file.name, file.type, file?.imageDateTaken);
        let url: Observable<Attachment>;
        return new Observable(s => {
            if (isLabReferral) {
                url = this.getLabReferralAttachmentUploadUrl(referral, uploadUrlRequest);
            } else {
                url = this.getReferralAttachmentUploadUrl(referral, uploadUrlRequest);
            }
            url.subscribe(attachment => {
                const attachmentLink = attachment.links.find(l => l.size === 'original');
                this.uploadFile(file.url as string, attachmentLink, file.type).subscribe(result => {
                    s.next(true);
                    s.complete();
                }, error => {
                    s.error(error);
                    s.complete();
                });
            }, error => {
                s.error(error);
                s.complete();
            });
        });
    }

    public uploadStageAppointmentAttachment(file: File, treatmentPlan: Appointment): Observable<boolean> {
        const uploadUrlRequest = new FileUploadRequest(this.entityService.getAttachmentTypeFromMediaType(file.type)?.id, file.name, file.type);
        return this.getStageAppointmentAttachmentUploadUrl(treatmentPlan, uploadUrlRequest).pipe(
            switchMap(attachment => {
                const attachmentLink = attachment.links.find(l => l.size === 'original');
                return this.uploadFile(file.url as string, attachmentLink, file.type);
            }),
            map(() => true),
        );
    }

    public uploadCorrespondenceAttachment(file: File, correspondence: Correspondence): Observable<boolean> {
        const uploadUrlRequest = new FileUploadRequest(this.entityService.getAttachmentTypeFromMediaType(file.type)?.id, file.name, file.type);
        return new Observable(s => {
            this.getCorrespondenceAttachmentUploadUrl(correspondence, uploadUrlRequest).subscribe(attachment => {
                const attachmentLink = attachment.links.find(l => l.size === 'original');
                this.uploadFile(file.url as string, attachmentLink, file.type).subscribe(result => {
                    s.next(true);
                    s.complete();
                }, error => {
                    s.error(error);
                    s.complete();
                });
            }, error => {
                console.log(error);
                s.error(error);
                s.complete();
            });
        });
    }

    public deleteStageAppointmentAttachment(appointmentId: string, attachment: Attachment): Observable<any> {
        return this.api.delete(`${environment.apiBaseUrl}/appointments/${appointmentId}/attachments/${attachment.id}`, null);
    }

    uploadFile(fileUrl: string, uploadUrl: AttachmentLink, mimeType: string): Observable<any> {
        const replacementString = 'data:' + mimeType + ';base64,';
        const newFileName = fileUrl.replace(replacementString, '');
        const buff = buffer.Buffer.from(newFileName, 'base64');
        const headers = new HttpHeaders({
            'Content-Type': mimeType,
            'Content-Encoding': 'base64'
        });

        const blob = new Blob([new Uint8Array(buff)]);
        return this.http.put<any>(uploadUrl.presignedUrl, blob, {headers});
    }
}

