import {Deserializable} from "../views/shared/utils/json-utils";

export class FeeGuideChangeLog implements Deserializable {

    createdDate: string;
    createdById: number;
    createdBy: string;
    id: string;
    feeGuideId: string;
    description: string;

    onDeserialize() {
    }
}
