<form [formGroup]="form">
    <div class="d-flex align-items-center" [style.gap.rem]="0.5">
        <div class="procedure-fee-title" [style.flex]="1">{{ procedureFee?.feeName }}</div>
        <div class="d-flex justify-content-end" [style.flex]="1" [style.gap.rem]="1.5">
            <div class="fee-guide-form-input">
                <label class="teth-textfield-label" for="code" i18n>Procedure Code</label>
                <input
                        [formControlName]="'feeCode'"
                        class="teth-textfield disabled"
                        type="text"
                        id="code"
                        name="feeCode"
                        [placeholder]="feeCodePlaceholder$ | async"
                        autocomplete="off">
            </div>
            <div class="fee-guide-form-input">
                <label class="teth-textfield-label" for="feeAmount" i18n>Fee Guide</label>
                <input
                        [formControlName]="'feeAmount'"
                        currencyMask
                        class="teth-textfield"
                        type="text"
                        id="feeAmount"
                        name="feeAmount"
                        placeholder="--"
                        autocomplete="off"
                        [class.error]="form.statusChanges | async | isInvalidAndTouched : form.controls.feeAmount"
                        [class.disabled]="disabled || locationFeeGuideMode">
                <span class="teth-textfield-error" [style.display]="(form.statusChanges | async | isInvalidAndTouched : form.controls.feeAmount) ? 'block' : 'none'" i18n>
                    Must be {{ 0 | currency }} or greater and less than {{ 1000000 | currency }}
                </span>
            </div>
            <div *ngIf="locationFeeGuideMode" class="fee-guide-form-input">
                <label class="teth-textfield-label" for="overrideFee" i18n>Override Fee</label>
                <input
                        [formControlName]="'overrideFeeAmount'"
                        currencyMask
                        class="teth-textfield"
                        type="text"
                        id="overrideFee"
                        name="overrideFee"
                        placeholder="--"
                        autocomplete="off"
                        [class.error]="form.statusChanges | async | isInvalidAndTouched : form.controls.overrideFeeAmount"
                        [class.disabled]="disabled"
                        i18n-placeholder>
                <span class="teth-textfield-error" [style.display]="(form.statusChanges | async | isInvalidAndTouched : form.controls.overrideFeeAmount) ? 'block' : 'none'" i18n>
                    Must be {{ 0 | currency }} or greater and less than {{ 1000000 | currency }}
                </span>
            </div>
        </div>
    </div>
</form>
