import {Injectable} from '@angular/core';
import {SessionService} from './session.service';
import * as moment from 'moment';
import DateUtils from '../views/shared/utils/date-utils';
import {DateFormatType} from '../models/date-format-type';
import {AbstractControl} from '@angular/forms';

@Injectable({
    providedIn: 'root'
})
export class DateService {

    constructor(private sessionService: SessionService) {
    }

    getSessionDateFormat(): string {
        const dateFormatType = this.sessionService.sessionContainer.value?.currentLocation?.dateFormat;
        if (dateFormatType == null) {
            console.warn('no date format');
            return DateFormatType.MMDDYYYYString;
        }
        return dateFormatType;
    }

    isDateStringValid(dateString: string): boolean {
        return moment(dateString, this.getSessionDateFormat(), true).isValid();
    }

    formatDateToString(date: Date): string {
        date = DateUtils.validateDate(date);
        return moment(date).format(this.getSessionDateFormat());
    }

    stringToDate(dateString: string): Date {
        return moment(dateString, this.getSessionDateFormat()).toDate();
    }

    dateStringIsBefore(one, two: string): number {
        const format = this.getSessionDateFormat();
        return moment(one, format, true).isBefore(moment(two, format, true)) ? -1 : moment(one, format, true).isAfter(moment(two, format, true)) ? 1 : 0;
    }

    getDateTextMask(): Array<string | RegExp> {
        const format = this.getSessionDateFormat();
        if (format === DateFormatType.DDMMYYYYString || format === DateFormatType.MMDDYYYYString) {
            return [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
        } else if (format === DateFormatType.YYYYMMDDString) {
            return [/\d/, /\d/, /\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/];
        }
        return [/.*/];
    }

    validateDateControl(c: AbstractControl) {
        if (c.value === null || c.value === '') {
            return null;
        }
        return this.isDateStringValid(c.value) ? null : {validDate: false};
    }
}
