export const environmentProd = {
    production: true,
    administratorAccess: false,
    apiBaseUrl: 'https://api.tetherport.ca',
    adminBaseUrl: 'https://admin.tetherport.ca',
    appBaseUrl: 'https://app.tetherport.ca',
    joinTetherportUrl: 'https://www.tetherport.ca',
    disableEncryptionKey: 'E73A871F-BF9A-4B02-9E0F-B47F9DC81F88',
    encryptionKey: '0342E48BAC58477093D73CCABCF4BF98',
    encryptionIV: '78_08!16!AK47!_?',
    encryptionBypass: false,
    useNewAuthInterceptor: true,
    webSocketUrl: 'wss://eqwrytswtc.execute-api.ca-central-1.amazonaws.com/prod',
};

export const environmentDemo = {
    production: true,
    administratorAccess: false,
    apiBaseUrl: 'https://demo.api.tetherport.ca',
    adminBaseUrl: 'https://demo.admin.tetherport.ca',
    appBaseUrl: 'https://demo.app.tetherport.ca',
    joinTetherportUrl: 'https://www.tetherport.ca',
    disableEncryptionKey: '15748384-F98A-4783-8CF0-E7176B1B0991',
    encryptionKey: '5BF8C39F425C4DB68A38413C40A18B60',
    encryptionIV: '78_08!16!AK47!_?',
    encryptionBypass: false,
    useNewAuthInterceptor: true,
    webSocketUrl: 'wss://eqwrytswtc.execute-api.ca-central-1.amazonaws.com/demo',
};

export const environmentDev = {
    production: false,
    administratorAccess: false,
    apiBaseUrl: 'https://dev.api.tetherport.ca',
    adminBaseUrl: 'https://dev.admin.tetherport.ca',
    appBaseUrl: 'https://dev.app.tetherport.ca',
    joinTetherportUrl: 'https://www.tetherport.ca',
    disableEncryptionKey: '82154F52-0BAF-42B6-9BF8-EA3E4126718B',
    encryptionKey: 'be3e89f7f6114c6e939a07c97804f43f',
    encryptionIV: '78_08!16!AK47!_?',
    encryptionBypass: true,
    useNewAuthInterceptor: true,
    webSocketUrl: 'wss://eqwrytswtc.execute-api.ca-central-1.amazonaws.com/dev',
};

