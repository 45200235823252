<div class="app-body mt-0 mb-0">
    <main class="main main-no-border d-flex align-items-center">
        <div class="container">
            <div class="row">
                <div class="col-md-11 col-sm-12 mx-auto">
                    <div class="card-group">
                        <div class="card splash-img-container">
                            <img [src]="'assets/img/brand/teth-sign-in-splash.png'"
                                 class="splash-img"
                                 alt="splash-img">
                            <img [src]="'assets/img/brand/tetherport-logo.png'"
                                 alt="splash-logo"
                                 class="splash-logo">
                        </div>
                        <div class="card py-4">
                            <div class="card-body card-body-npb">
                                <app-loading
                                        [hidden]="!signingIn"
                                        [loadingText]="null"
                                        [fullscreen]="false"
                                        [backgroundColor]="'#FDFDFD80'"
                                        [color]="'#388EFC80'">
                                </app-loading>
                                <!-- Sign In Flow -->
                                <form [formGroup]="signInForm"
                                      *ngIf="!pickLocation">
                                    <div class="mb-4 d-flex justify-content-between align-items-end">
                                        <div class="title-text">{{signInTitle}}</div>
                                        <a [routerLink]="" (click)="showHelpModal()" i18n>Need Help?</a>
                                    </div>
                                    <div class="row">
                                        <div class="col-12 mt-2">
                                            <div class="teth-textfield-form"
                                                 [class.error]="signInForm.controls.companyId.invalid && (signInForm.controls.companyId.dirty || signInForm.controls.companyId.touched)">
                                                <label class="teth-textfield-label" for="companyIdField" i18n>Company Identifier</label>
                                                <input class="teth-textfield" type="text" id="companyIdField"
                                                       (keyup)="handleInputKeyUp($event)"
                                                       name="companyIdField"
                                                       autocomplete="on"
                                                       [formControlName]="'companyId'"
                                                       placeholder="Enter Company Identifier"
                                                       i18n-placeholder>
                                                <span class="teth-textfield-error" i18n>Company Identifier Required</span>
                                            </div>
                                        </div>
                                        <div class="col-12 mt-2">
                                            <div class="teth-textfield-form"
                                                 [class.error]="signInForm.controls.username.invalid && (signInForm.controls.username.dirty || signInForm.controls.username.touched)">
                                                <label class="teth-textfield-label" for="usernameField" i18n>Username</label>
                                                <input class="teth-textfield" type="text" id="usernameField"
                                                       (keyup)="handleInputKeyUp($event)"
                                                       name="usernameField"
                                                       autocomplete="on"
                                                       [formControlName]="'username'"
                                                       placeholder="Enter Username"
                                                       i18n-placeholder>
                                                <span class="teth-textfield-error" i18n>Username Required</span>
                                            </div>
                                        </div>
                                        <div class="col-12 mt-2 mb-1">
                                            <div class="teth-textfield-form"
                                                 [class.error]="signInForm.controls.password.invalid && (signInForm.controls.password.dirty || signInForm.controls.password.touched)">
                                                <label class="teth-textfield-label" for="passwordField" i18n>Password</label>
                                                <input class="teth-textfield" type="password" id="passwordField"
                                                       (keyup)="handleInputKeyUp($event)"
                                                       name="passwordField"
                                                       [formControlName]="'password'"
                                                       placeholder="Enter Password"
                                                       i18n-placeholder>
                                                <span class="teth-textfield-error" i18n>Password Required</span>
                                            </div>
                                            <div class="teth-textfield-form" [class.error]="signInFailed">
                                                <span class="teth-textfield-error" i18n>Username, password, and company combination is incorrect.</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12">
                                            <button type="button"
                                                    (click)="signIn()"
                                                    class="teth-button blue-button w-100"
                                                    i18n>
                                                Sign In
                                            </button>

                                            <label class="teth-checkbox-container remember-me" for="rememberMe">
                                                <input type="checkbox"
                                                       class="teth-checkbox"
                                                       id="rememberMe"
                                                       [formControlName]="'rememberMe'"
                                                       name="rememberMe">
                                                <span class="teth-checkbox-label" i18n>Keep Me Signed In</span>
                                                <span class="teth-checkmark"></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12 d-flex justify-content-end">
                                            <a [routerLink]="" (click)="changeLanguage()" i18n>Français</a>
                                        </div>
                                    </div>
                                </form>
                                <!-- Pick Location Flow -->
                                <div class="pick-location-container"
                                     *ngIf="pickLocation">
                                    <div class="title-text mb-4" i18n>Choose Location</div>
                                    <ul class="teth-list location-picker-list">
                                        <li class="list-item"
                                            *ngFor="let loc of availableLocations"
                                            (click)="locationSelected(loc)">
                                            <img class="list-item-image"
                                                 src="../../../assets/img/placeholder/company-placeholder.jpg"
                                                 alt="result-image">
                                            <div class="list-item-text-container">
                                                <span class="list-item-text">
                                                    {{ loc.name }}
                                                </span>
                                                <span class="list-item-subtext">
                                                    {{ loc.address?.addressLine1 ? loc.address?.addressLine1 : '' }}
                                                </span>
                                            </div>
                                        </li>
                                    </ul>

                                    <form class="teth-textfield-form checkbox-container defaultLocationCheckbox">
                                        <label class="teth-checkbox-container">
                                            <input type="checkbox" class="teth-checkbox"
                                                   id="setDefaultLocation"
                                                   name="'Set as default location'"
                                                   [(ngModel)]="setDefaultLocation">
                                            <span class="checkBox-label" i18n>Set as default location</span>
                                            <span class="teth-checkmark"></span>
                                        </label>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>
