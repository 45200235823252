<div class="slideout-container">
    <div #header class="slideout-header">
        <div i18n>Filters</div>
        <img class="close-button" src="assets/img/icons/remove.svg" alt="close icon" (click)="closeSlideoutClicked.emit()">
    </div>
    <div class="filter-form-container" [style.height.px]="formContainerHeightInPx$ | async">
        <app-extra-filter-options-form
                *ngFor="let options of extraFilters$ | async; trackBy: extraFiltersTrackBy"
                [options]="options">
        </app-extra-filter-options-form>
    </div>
        <app-slideout-footer
                #footer
                (applyFiltersClicked)="applyFilters()"
                (clearFiltersClicked)="clearAllFiltersClicked.emit()">
        </app-slideout-footer>
</div>
