import {Deserializable} from '../views/shared/utils/json-utils';
import {ReferralStatusUtils} from "../views/shared/utils/referral-status-utils";

export enum ReferralStatusColorClass {
    Grey = 'greyStatus',
    Blue = 'blueStatus',
    Green = 'greenStatus',
    Orange = 'orangeStatus',
    Yellow = 'yellowStatus',
    Red = 'redStatus',
    Purple = 'purpleStatus'
}

export class ReferralStatus implements Deserializable {
    static CreatedId = 1;
    static InProgressId = 2;
    static WaitingId = 3;
    static CompletedId = 4;
    static DeclinedId = 5;
    static ConsultId = 6;
    static PendingId = 7;
    static TreatmentId = 8;

    public id: number;
    public name: string;

    public getStatusFontColor(): string {
        return ReferralStatusUtils.getStatusFontColor(this.id);
    }

    public getStatusIndicatorColor(): string {
        return ReferralStatusUtils.getStatusIndicatorColor(this.id);
    }

    public static getStatusColorClass(id: number): ReferralStatusColorClass {
        switch (id) {
            case 1:
                return ReferralStatusColorClass.Blue;
            case 4:
                return ReferralStatusColorClass.Green;
            case 5:
                return ReferralStatusColorClass.Red;
            case 6:
                return ReferralStatusColorClass.Orange;
            case 7:
                return ReferralStatusColorClass.Yellow;
            case 8:
                return ReferralStatusColorClass.Purple;
            default:
                return ReferralStatusColorClass.Grey;
        }
    }

    public static getStatusColorFromClass(colorClass: string, muted: boolean): string {
        let hexCode = '';
        switch (colorClass) {
            case 'blueStatus':
                hexCode = muted ? '#DEECFF' : '#388EFC';
                break;
            case 'greenStatus':
                hexCode = muted ? '#D0F2DD' : '#63D58F';
                break;
            case 'orangeStatus':
                hexCode = muted ? '#FDDCC3' : '#FC8B38';
                break;
            case 'yellowStatus':
                hexCode = muted ? '#FEF2C9' : '#FFD74B';
                break;
            case 'redStatus':
                hexCode = muted ? '#FDCCCC' : '#FA5555';
                break;
            case 'greyStatus':
                hexCode = muted ? '#9FA2AD' : '#747781';
                break;
        }
        return hexCode;
    }

    public static getStatusCountProperty(id: number): string {
        switch (id) {
            case 1:
                return 'newCount';
            case 2:
                return 'inProgressCount';
            case 3:
                return 'waitingCount';
            case 4:
                return 'completedCount';
            case 5:
                return 'declinedCount';
            default:
                return null;
        }
    }

    onDeserialize() {
    }
}
