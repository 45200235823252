import {Injectable} from '@angular/core';
import {Observable, of, ReplaySubject} from 'rxjs';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {ApiService} from './api.service';
import {EntityService} from './entity.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ImageUploadRequest} from '../models/file-upload.request';
import {Attachment, AttachmentLink} from '../models/attachment.model';
import {environment} from '../../environments/environment';
import {Location} from '../models/location.model';
import {Employee} from '../models/employee.model';
import {Company} from '../models/company.model';
import {DeserializeHelper} from '../views/shared/utils/json-utils';
import * as buffer from 'buffer';
import {tap} from 'rxjs/operators';

export type ImageServiceCacheType = 'employee' | 'company' | 'location' | 'help-video';

@Injectable({
    providedIn: 'root'
})
export class ImageService {
    cachedMedia = new Map<string, ReplaySubject<string | SafeResourceUrl>>();
    sizes = ['thumb', 'small', 'medium', 'large', 'original'];
    CacheErrorTimeOut = 5000;

    constructor(private api: ApiService,
                private entityService: EntityService,
                private sanitizer: DomSanitizer,
                private http: HttpClient) {
    }

    public getCompanyLogoUploadUrl(companyId: number, imageUploadRequest: ImageUploadRequest): Observable<Attachment> {
        return this.api.post<Attachment>(`${environment.apiBaseUrl}/companies/${companyId}/companylogos`, imageUploadRequest);
    }

    public getEmployeeImageUploadUrl(employee: Employee, imageUploadRequest: ImageUploadRequest): Observable<Attachment> {
        return this.api.post<Attachment>(`${environment.apiBaseUrl}/companies/${employee.companyId}/employees/${employee.id}/employeephotos`, imageUploadRequest);
    }

    public getLocationLogoUploadUrl(location: Location, imageUploadRequest: ImageUploadRequest): Observable<Attachment> {
        return this.api.post<Attachment>(`${environment.apiBaseUrl}/companies/${location.companyId}/locations/${location.id}/locationlogos`, imageUploadRequest);
    }

    public getEmployeePhotos(employee: Employee): Observable<Attachment[]> {
        return this.api.get<Attachment[]>(
            environment.apiBaseUrl + `/companies/${employee.companyId}/employees/${employee.id}/employeephotos`)
            .map(r => r.map(d => DeserializeHelper.deserializeToInstance(Attachment, d)));
    }

    public getLocationPhotos(location: Location): Observable<Attachment[]> {
        return this.api.get<Attachment[]>(
            environment.apiBaseUrl + `/companies/${location.companyId}/locations/${location.id}/locationlogos`)
            .map(r => r.map(d => DeserializeHelper.deserializeToInstance(Attachment, d)));
    }

    public getCompanyLogos(companyId: number): Observable<Attachment[]> {
        return this.api.get<Attachment[]>(
            environment.apiBaseUrl + `/Companies/${companyId}/companylogos`)
            .map(r => r.map(d => DeserializeHelper.deserializeToInstance(Attachment, d)));
    }

    public deleteEmployeePhoto(id: string, employeeId: number): Observable<string> {
        return this.api.delete(
            environment.apiBaseUrl + `/employeephotos/${id}`,
            null,
            null,
            'text').pipe(tap(() => this.clearCachedImages('employee', employeeId)));
    }

    public deleteLocationPhoto(id: string, locationId: number): Observable<string> {
        return this.api.delete(
            environment.apiBaseUrl + `/locationlogos/${id}`,
            null,
            null,
            'text').pipe(tap(() => this.clearCachedImages('location', locationId)));
    }

    public deleteCompanyLogo(id: string, companyId): Observable<string> {
        return this.api.delete(
            environment.apiBaseUrl + `/companylogos/${id}`,
            null,
            null,
            'text').pipe(tap(() => this.clearCachedImages('company', companyId)));
    }

    public uploadEmployeeImage(fileName: string, fileType: string, fileUrl: string, employee: Employee): Observable<boolean> {
        const imageUploadRequest = new ImageUploadRequest(fileName, fileType);
        return this.uploadEntityImage(this.getEmployeeImageUploadUrl(employee, imageUploadRequest), fileUrl, fileType)
            .pipe(tap(() => this.imageUploadSuccessful('employee', employee.id, fileUrl)));
    }

    public uploadLocationImage(fileName: string, fileType: string, fileUrl: string, location: Location): Observable<boolean> {
        const imageUploadRequest = new ImageUploadRequest(fileName, fileType);
        return this.uploadEntityImage(this.getLocationLogoUploadUrl(location, imageUploadRequest), fileUrl, fileType)
            .pipe(tap(() => this.imageUploadSuccessful('location', location.id, fileUrl)));
    }

    public uploadCompanyImage(fileName: string, fileType: string, fileUrl: string, company: Company): Observable<boolean> {
        const imageUploadRequest = new ImageUploadRequest(fileName, fileType);
        return this.uploadEntityImage(this.getCompanyLogoUploadUrl(company.id, imageUploadRequest), fileUrl, fileType)
            .pipe(tap(() => this.imageUploadSuccessful('company', company.id, fileUrl)));
    }

    public getEmployeeImage(employee: Employee, size: string): Observable<string | SafeResourceUrl> {
        const key = this.getCacheKey('employee', employee.id, size);
        return this.getCachedMedia(key, size, this.getEmployeePhotos(employee));
    }

    public getLocationImage(location: Location, size: string): Observable<string | SafeResourceUrl> {
        const key = this.getCacheKey('location', location.id, size);
        return this.getCachedMedia(key, size, this.getLocationPhotos(location));
    }

    public getCompanyImage(companyId: number, size: string): Observable<string | SafeResourceUrl> {
        const key = this.getCacheKey('company', companyId, size);
        return this.getCachedMedia(key, size, this.getCompanyLogos(companyId));
    }

    public getHelpVideo(videoId: number, attachment: Attachment): Observable<string | SafeResourceUrl> {
        const key = this.getCacheKey('help-video', videoId, AttachmentLink.OriginalSize);
        return this.getCachedMedia(key, AttachmentLink.OriginalSize, of([attachment]));
    }

    public getPresignedUrl(attachment: Attachment, size: string): string {
        return attachment?.links.find(l => l.size === size)?.presignedUrl;
    }

    private imageUploadSuccessful(cacheType: ImageServiceCacheType, id: number, fileUrl: string | SafeResourceUrl) {
        this.clearCachedImages(cacheType, id);
        this.sizes.forEach(s => {
            const r = new ReplaySubject<string | SafeResourceUrl>(1);
            this.cachedMedia.set(this.getCacheKey(cacheType, id, s), r);
            r.next(fileUrl);
        });
    }

    clearCachedImages(type: ImageServiceCacheType, id: number) {
        this.sizes.forEach(s => {
            const key = this.getCacheKey(type, id, s);
            this.cachedMedia.delete(key);
        });
    }

    private getCacheKey(type: ImageServiceCacheType, id: number, size: string): string {
        return type + id + size;
    }

    private uploadEntityImage(getUploadUrl: Observable<Attachment>, fileUrl: string, fileType: string): Observable<boolean> {
        return new Observable(s => {
            getUploadUrl.subscribe(attachment => {
                const uploadUrl = attachment.links.find(l => l.size === 'original');
                this.uploadFile(fileUrl, uploadUrl, fileType).subscribe(() => {
                    s.next(true);
                    s.complete();
                }, error => {
                    s.error(error);
                    s.complete();
                });
            }, error => {
                s.error(error);
                s.complete();
            });
        });
    }

    private uploadFile(fileUrl: string, uploadUrl: AttachmentLink, mimeType: string): Observable<any> {
        const replacementString = 'data:' + mimeType + ';base64,';
        const newFileName = fileUrl.replace(replacementString, '');
        const buff = buffer.Buffer.from(newFileName, 'base64');
        const headers = new HttpHeaders({
            'Content-Type': mimeType,
            'Content-Encoding': 'base64'
        });

        const blob = new Blob([new Uint8Array(buff)]);
        return this.http.put<any>(uploadUrl.presignedUrl, blob, {headers});
    }

    private getCachedMedia(key: string, size: string, imageResourceObservable: Observable<Attachment[]>): Observable<string | SafeResourceUrl> {
        if (this.cachedMedia.has(key)) {
            return this.cachedMedia.get(key);
        }

        const errorHandler = (error: Error) => {
            setTimeout(() => {
                this.cachedMedia.delete(key);
            }, this.CacheErrorTimeOut);
            r.error(error);
        };

        const r = new ReplaySubject<string | SafeResourceUrl>(1);
        imageResourceObservable.subscribe(photos => {
            const primaryPhoto = photos[0];
            const url = this.getPresignedUrl(primaryPhoto, size);
            if (url == null) {
                r.next(null);
                return;
            }
            this.http.get(url, {responseType: 'blob'}).subscribe(blob => {
                r.next(this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(blob)));
            }, errorHandler);
        }, errorHandler);
        this.cachedMedia.set(key, r);
        return r;
    }
}
