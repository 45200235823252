import {Deserializable} from '../views/shared/utils/json-utils';

export class ImplantType implements Deserializable {
    public id: number;
    public name: string;

    onDeserialize() {
    }
}

