import {Injectable} from "@angular/core";
import {DataTableExtraFilterOptions} from "../../../../models/data-table-options";
import {BehaviorSubject, Observable} from "rxjs";
import {DeserializeHelper} from "../../utils/json-utils";

@Injectable()
export class ExtraFiltersSlideoutService {

    private _extraFilters = new BehaviorSubject<DataTableExtraFilterOptions[]>(null);
    public extraFilters$ = this._extraFilters as Observable<DataTableExtraFilterOptions[]>;
    setExtraFilters = (extraFilters: DataTableExtraFilterOptions[]) => this._extraFilters.next(extraFilters);

    public setDataTableExtraFilterGroup(filterOptions: DataTableExtraFilterOptions): void {
        this.extraFilters$.once(extraFilters => {
            const extraFiltersCopy = DeserializeHelper.arrayOf(DataTableExtraFilterOptions, extraFilters);
            const index = extraFiltersCopy?.findIndex((filter: DataTableExtraFilterOptions) => {
                return filter?.filterId === filterOptions?.filterId;
            });
            extraFiltersCopy[index] = filterOptions;
            this._extraFilters.next(extraFiltersCopy);
        });
    }

}
