import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'chunk'
})
export class ChunkPipe implements PipeTransform {

  transform<T>(value: T[], size: number): T[][] {
    const chunkedArr = [];
    let index = 0;
    while (index < value?.length) {
      chunkedArr.push(value?.slice(index, size + index));
      index += size;
    }
    return chunkedArr;
  }

}
