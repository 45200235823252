import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {NavItem} from '../../models/nav-item.model';
import {SessionService} from '../../services/session.service';
import {ReferralService} from '../../services/referral.service';
import {Correspondence} from '../../models/correspondence.model';
import {InputModalComponent} from '../shared/input-modal/input-modal.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {filter} from 'rxjs/operators';
import {PermissionService, PermissionTypeIds} from '../../services/permission.service';
import {EmployeeService} from '../../services/employee.service';
import {NotificationService} from '../../services/notification.service';
import {ReferralNotification} from '../../models/referral-notification.model';
import {CompanyType} from '../../models/company.model';
import {environment} from '../../../environments/environment';
import {CorrespondenceStatus} from '../../models/correspondence-status.model';
import {HydratedCorrespondence} from '../../models/hydrated-correspondence';
import {EntityService} from '../../services/entity.service';
import {AccountService} from '../../services/account.service';
import {ChooseCompanyModalComponent} from '../shared/components/choose-company-modal/choose-company-modal.component';
import ModalUtils from '../shared/utils/modal-utils';
import LocalizationUtils from '../shared/utils/localization-utils';
import {Subscription} from 'rxjs';
import {Location} from '../../models/location.model';

@Component({
    selector: 'app-sidebar-navigation',
    templateUrl: './sidebar-nav.component.html',
    styleUrls: ['./sidebar-nav.component.scss', '../stylesheet/stylesheet.component.scss']
})
export class SidebarNavComponent implements OnInit {

    companyType = CompanyType;
    currentLocation: Location;
    currentLocationName: string = 'Location';
    currentLocationNotificationSubscription: Subscription;
    currentNavUrl: string;
    currentCompanyType: CompanyType = null;
    currentCompanyName: string;
    badgeContent = new Map<string, string>();
    navItems: NavItem[] = [];
    appVersionText: string = '';

    constructor(private router: Router,
                private referralService: ReferralService,
                private modalService: NgbModal,
                private permissionService: PermissionService,
                private employeeService: EmployeeService,
                private sessionService: SessionService,
                private entityService: EntityService,
                private accountService: AccountService,
                private notificationService: NotificationService) {
    }

    ngOnInit(): void {
        this.currentNavUrl = this.router.url;
        this.router.events.subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }
            this.urlChanged(evt);
        });

        if (environment.administratorAccess) {
            this.currentCompanyType = CompanyType.Administrator;
        } else {
            this.sessionService.sessionContainer.notNull().subscribe(s => {
                this.currentCompanyType = s.company?.companyTypeId;
            });
        }

        this.sessionService.sessionContainer.notNull().subscribe(s => {
            this.currentCompanyName = s.company?.name;
        });

        this.entityService.numberOfSpecialistNewReferrals$.subscribe(n => {
            this.updateLocationReferralBadgeContent(n);
        });

        this.referralService.locationIncomingReferralHydratedCorrespondences.subscribe(c => {
            this.updateLocationCorrespondenceBadgeContent(c, true);
        });

        this.referralService.locationOutgoingReferralHydratedCorrespondences.subscribe(c => {
            this.updateLocationCorrespondenceBadgeContent(c, false);
        });

        this.sessionService.currentLocation.pipe(filter(x => !!x)).subscribe((cl) => {
            this.currentLocation = cl;
            this.currentLocationName = cl.name;
            this.bindNotificationsForCurrentLocation();
            this.setNavItems();
        });

        this.permissionService.currentUserRole.notNull().subscribe(() => {
            this.setNavItems();
        });

        this.entityService.appVersion$.notNull().subscribe(v => {
            this.appVersionText = $localize`Version ${v}:appVersion:`;
        });
    }


    bindNotificationsForCurrentLocation() {
        if (!!this.currentLocationNotificationSubscription) {
            this.currentLocationNotificationSubscription.unsubscribe();
        }

        const location = this.currentLocation;
        this.currentLocationNotificationSubscription = this.notificationService
            .getNotificationSubject(location.id, location.companyId)
            .notNull()
            .subscribe(n => {
                this.updateActivityBadgeContent([...n.incoming.messagesUnread, ...n.incoming.referralsUnread], true);
                this.updateActivityBadgeContent([...n.outgoing.messagesUnread], false);
            });
    }

    setNavItems() {
        if (this.permissionService.currentUserRole.value === null) {
            return;
        }

        this.navItems = [
            this.generateAdminNavItem(),
            this.generateAdminReportsNavItem(),
            this.generateDashboardNavItem(),
            this.generateCreateReferralNavItem(),
            this.generateManageReferralsNavItem(),
            this.generateManageReferralsIncomingNavItem(),
            this.generateIncomingReferralsNavItem(),
            this.generateOutgoingReferralsNavItem(),
            this.generateActivityNavItem(),
            this.generateActivityIncomingNavItem(),
            this.generateCorrespondenceNavItem(),
            this.generateCorrespondenceIncomingNavItem(),
            this.generateEmployeesNavItem(),
            this.generateProceduresNavItem(),
            this.generateFeesNavItem(),
            this.generateSettingsNavItem(),
            this.generateProfileNavItem(),
            this.generateCurrentLocationNavItem(),
            this.generateHelpNavItem(),
            this.generateLogoutNavItem(),
            this.generateCurrentCompanyNavItem(),
        ];
    }

    updateLocationReferralBadgeContent(n: number) {
        const manageReferralsRouterLink = '/manage-referrals-incoming';
        if (this.currentCompanyType !== CompanyType.Specialist || !n || n === 0) {
            this.badgeContent.delete(manageReferralsRouterLink);
            return;
        }

        if (n > 0) {
            this.badgeContent.set(manageReferralsRouterLink, `${n.toString()}`);
        } else {
            this.badgeContent.delete(manageReferralsRouterLink);
        }
    }

    updateLocationCorrespondenceBadgeContent(correspondences: HydratedCorrespondence[], incoming) {
        const correspondenceRouterLink = incoming ? '/correspondence-incoming-referrals' : '/correspondence-outgoing-referrals';
        if (!correspondences || correspondences.length === 0) {
            this.badgeContent.delete(correspondenceRouterLink);
            return;
        }
        const currentEmployeeId = this.sessionService.sessionContainer.value?.employee?.id;

        let createdStatusCount: number;
        if (incoming) {
            createdStatusCount = correspondences.filter(c => c.referredCorrespondenceStatusTypeId === CorrespondenceStatus.PendingId && c.assignedSpecialistId === currentEmployeeId).length;
        } else {
            createdStatusCount = correspondences.filter(c => c.referredCorrespondenceStatusTypeId === CorrespondenceStatus.ApprovedId && c.referringCorrespondenceStatusTypeId === CorrespondenceStatus.PendingId && c.referringEmployeeId === currentEmployeeId).length;
        }

        if (createdStatusCount > 0) {
            this.badgeContent.set(correspondenceRouterLink, `${createdStatusCount}`);
        } else {
            this.badgeContent.delete(correspondenceRouterLink);
        }
    }

    updateActivityBadgeContent(notifications: ReferralNotification[], incoming: boolean) {
        const activityRouterLink = incoming ? '/activity-incoming-referrals' : '/activity-outgoing-referrals';
        if (!notifications || notifications.length === 0) {
            this.badgeContent.delete(activityRouterLink);
        }

        const notificationCount = notifications.length;

        if (notificationCount > 0) {
            this.badgeContent.set(activityRouterLink, `${notificationCount}`);
        } else {
            this.badgeContent.delete(activityRouterLink);
        }
    }

    private urlChanged(nav: NavigationEnd) {
        this.currentNavUrl = nav.url;
    }

    navItemSelected(navItem: NavItem) {
        if (navItem.childNavItems?.length > 0) {
            this.navItemSelected(navItem.childNavItems[0]);
            return;
        }

        if (navItem.routerPath === 'location-picker-modal') {
            this.showLocationPickerModal();
        } else if (navItem.routerPath === 'company-picker-modal') {
            this.showChooseCompanyModal();
        }  else if (navItem.routerPath === 'sign-out') {
            this.accountService.signOut();
        } else {
            this.router.navigate([navItem.routerPath]).then(() => {
            });
        }
    }

    getBadgeContentForNavItem(navItem: NavItem): string {
        return this.badgeContent.get(navItem.routerPath);
    }

    isNavItemActive(navItem: NavItem): boolean {
        if (navItem.childNavItems?.length > 0) {
            return navItem.childNavItems?.find(n => this.isNavItemActive(n)) != null;
        }
        return this.currentNavUrl.indexOf(navItem.routerPath) === 0;
    }

    isNavItemHidden(navItem: NavItem): boolean {
        return (navItem.hideForSpecialist && this.currentCompanyType === this.companyType.Specialist) ||
        (navItem.hideForGeneralist && this.currentCompanyType === this.companyType.Dentist) ||
        (navItem.hideForDentalLab && this.currentCompanyType === this.companyType.DentalLab) ||
        (navItem.hideForAdmin && this.currentCompanyType === this.companyType.Administrator);
    }

    showLocationPickerModal() {
        const modalRef = this.modalService.open(InputModalComponent, InputModalComponent.defaultModalOptions());
        const items = this.sessionService.userLocations.value;
        const selectedItem = this.sessionService.currentLocation.value;
        (modalRef.componentInstance as InputModalComponent)
            .initWithSelectionList($localize`Choose Location`, items, selectedItem, null, i => i.name);
        (modalRef.componentInstance as InputModalComponent).showCheckboxInput = true;
        (modalRef.componentInstance as InputModalComponent).checkboxItems = [{
            name: $localize`Set as default location`,
            id: 'setDefaultLocation'
        }];
        (modalRef.componentInstance as InputModalComponent).customCheckboxClass = 'defaultLocationCheckbox';
        modalRef.result.then(r => {
            if (r != null) {
                if (r.selectedCheckboxes?.length > 0 && r.selectedCheckboxes[0].id === 'setDefaultLocation') {
                    // set default location
                    this.employeeService.setEmployeeDefaultLocation(this.sessionService.sessionContainer.value?.employee,
                        r.selectedItem.id,
                        this.sessionService.getCompanyId(),
                        this.sessionService.sessionContainer.value?.employee.id).subscribe((updatedEmployee) => {
                        this.sessionService.sessionContainer.value.employee = updatedEmployee;
                    });
                }
                this.sessionService.currentLocation.next(r.selectedItem);
            }
        }, () => {
        });
    }

    changeLanguage() {
        LocalizationUtils.changeLanguage();
    }

    generateAdminNavItem(): NavItem {
        return new NavItem($localize`Companies`, '/admin-home', 'assets/img/icons/dashboard-white.svg', 'assets/img/icons/dashboard-filled.svg', true, true, false, true);
    }

    generateAdminReportsNavItem(): NavItem {
        return new NavItem($localize`Reports`, '/admin-reports', 'assets/img/icons/chart-bar-white.svg', 'assets/img/icons/chart-bar.svg', true, true, false, true);
    }

    generateDashboardNavItem(): NavItem {
        return new NavItem($localize`Dashboard`, '/dashboard', 'assets/img/icons/dashboard-white.svg', 'assets/img/icons/dashboard-filled.svg', !this.permissionService.permissionGranted(PermissionTypeIds.Dashboard), true, true, false);
    }

    generateCreateReferralNavItem(): NavItem {
        return new NavItem($localize`Create Referral`, '/create-referral', 'assets/img/icons/create-white.svg', 'assets/img/icons/create-filled.svg', true, !this.permissionService.permissionGranted(PermissionTypeIds.CreateReferrals), true, true);
    }

    generateManageReferralsNavItem(): NavItem {
        return new NavItem($localize`Manage Referrals`, '/manage-referrals-outgoing', 'assets/img/icons/manage-white.svg', 'assets/img/icons/manage-filled.svg', true, !this.permissionService.permissionGranted(PermissionTypeIds.ManageReferrals), true, true);
    }

    generateManageReferralsIncomingNavItem(): NavItem {
        return new NavItem($localize`Manage Referrals`, '/manage-referrals-incoming', 'assets/img/icons/manage-white.svg', 'assets/img/icons/manage-filled.svg', true, true, true, !this.permissionService.permissionGranted(PermissionTypeIds.ManageReferrals));
    }

    generateActivityNavItem(): NavItem {
        return new NavItem($localize`Activity`, '/activity-outgoing-referrals', 'assets/img/icons/status-white.svg', 'assets/img/icons/status-filled.svg', true, !this.permissionService.permissionGranted(PermissionTypeIds.ManageReferrals), true, true);
    }

    generateActivityIncomingNavItem(): NavItem {
        return new NavItem($localize`Activity`, '/activity-incoming-referrals', 'assets/img/icons/status-white.svg', 'assets/img/icons/status-filled.svg', true, true, true, false);
    }

    generateCorrespondenceNavItem(): NavItem {
        return new NavItem($localize`Correspondence`, '/correspondence-outgoing-referrals', 'assets/img/icons/correspondence-white.svg', 'assets/img/icons/correspondence-filled.svg', true, !this.permissionService.permissionGranted(PermissionTypeIds.Correspondence), true, true);
    }

    generateCorrespondenceIncomingNavItem(): NavItem {
        return new NavItem($localize`Correspondence`, '/correspondence-incoming-referrals', 'assets/img/icons/correspondence-white.svg', 'assets/img/icons/correspondence-filled.svg', true, true, true, true);
    }

    generateEmployeesNavItem(): NavItem {
        return new NavItem($localize`Employees`, '/employees', 'assets/img/icons/users-white.svg', 'assets/img/icons/users-filled.svg', !this.permissionService.permissionGranted(PermissionTypeIds.Employees), !this.permissionService.permissionGranted(PermissionTypeIds.Employees), false, false);
    }

    generateProceduresNavItem(): NavItem {
        return new NavItem($localize`Procedures`, '/procedures', 'assets/img/icons/needle-white.svg', 'assets/img/icons/needle-filled.svg', true, true, false, true);
    }

    generateFeesNavItem(): NavItem {
        return new NavItem($localize`Fees`, '/fees', 'assets/img/icons/dollar-white.svg', 'assets/img/icons/dollar-filled.svg', true, true, false, true);
    }

    generateSettingsNavItem(): NavItem {
        return new NavItem($localize`Settings`, '/settings', 'assets/img/icons/settings-white.svg', 'assets/img/icons/settings-filled.svg', !this.permissionService.permissionGranted(PermissionTypeIds.Settings), !this.permissionService.permissionGranted(PermissionTypeIds.Settings), false, false);
    }

    generateProfileNavItem(): NavItem {
        return new NavItem($localize`Profile`, '/profile', 'assets/img/icons/profile-white.svg', 'assets/img/icons/profile.svg', false, false, false, false);
    }

    generateCurrentLocationNavItem(): NavItem {
        return new NavItem(this.currentLocationName, 'location-picker-modal', 'assets/img/icons/location-white.svg', 'assets/img/icons/location-filled.svg', false, false, true, false);
    }

    generateCurrentCompanyNavItem(): NavItem {
        return new NavItem(this.currentCompanyName, 'company-picker-modal', null, null, true, true, false, true);
    }

    generateHelpNavItem(): NavItem {
        return new NavItem($localize`Help`, '/help', 'assets/img/icons/help-white.svg', 'assets/img/icons/help-filled.svg', false, false, false, false);
    }

    generateLogoutNavItem(): NavItem {
        return new NavItem($localize`Log Out`, 'sign-out', 'assets/img/icons/logout-white.svg', 'assets/img/icons/logout.svg', false, false, false, false);
    }

    generateOutgoingReferralsNavItem(): NavItem {
        return new NavItem($localize`Outgoing Referrals`, '', 'assets/img/icons/upload.svg', 'assets/img/icons/upload-filled.svg', false, true, true, true,
            [
                new NavItem($localize`Create`,
                    '/create-referral',
                    '',
                    '',
                    !this.permissionService.permissionGranted(PermissionTypeIds.CreateReferrals),
                    true, true, true),
                new NavItem($localize`Manage`,
                    '/manage-referrals-outgoing',
                    '',
                    '',
                    !this.permissionService.permissionGranted(PermissionTypeIds.ManageReferrals),
                    true, true, true),
                new NavItem($localize`Correspondence`,
                    '/correspondence-outgoing-referrals',
                    '',
                    '',
                    !this.permissionService.permissionGranted(PermissionTypeIds.Correspondence),
                    true, true, true),
                new NavItem($localize`Activity`,
                    '/activity-outgoing-referrals',
                    '',
                    '',
                    !this.permissionService.permissionGranted(PermissionTypeIds.ManageReferrals),
                    true, true, true)
            ]);
    }

    generateIncomingReferralsNavItem(): NavItem {
        return new NavItem($localize`Incoming Referrals`, '', 'assets/img/icons/save.svg', 'assets/img/icons/save-filled.svg', false, true, true, true,
            [
                new NavItem($localize`Manage`,
                    '/manage-referrals-incoming',
                    '',
                    '',
                    !this.permissionService.permissionGranted(PermissionTypeIds.ManageReferrals),
                    true, true, true),
                new NavItem($localize`Correspondence`,
                    '/correspondence-incoming-referrals',
                    '',
                    '',
                    !this.permissionService.permissionGranted(PermissionTypeIds.Correspondence),
                    true, true, true),
                new NavItem($localize`Activity`,
                    '/activity-incoming-referrals',
                    '',
                    '',
                    !this.permissionService.permissionGranted(PermissionTypeIds.ManageReferrals),
                    true, true, true)
            ]);
    }

    showChooseCompanyModal() {
        this.modalService.open(ChooseCompanyModalComponent, ModalUtils.defaultStaticModalOptions());
    }
}
