import {Directive} from '@angular/core';
import {FeeGuideSpecialty} from "../../../models/fee-guide-specialty.model";

export interface FeeGuideTabBarContext {
  $implicit: FeeGuideSpecialty;
}

/**
 * This directive exists as a typesafe way for FeeGuideTabBarComponent to get the contentChild reference
 * instead of using a magic string template variable. Also, if we ever turn on strictTemplates, this will strongly
 * type the template context.
**/

@Directive({
  selector: 'ng-template[appFeeGuideTabBarContent]'
})
export class FeeGuideTabBarContentDirective {

  static ngTemplateContextGuard(dir: FeeGuideTabBarContentDirective, ctx: unknown): ctx is FeeGuideTabBarContext {
    return true;
  }
}
