import {environment} from '../../../environments/environment';

export class OdataEndpoints {

    static getIncomingSpecialistReferrals(companyId: number, locationId: number): string {
        const cId = companyId.toString(10);
        const lId = locationId.toString(10);
        return environment.apiBaseUrl +
            `/odata/ReferralPreviews/IncomingByLocationByCompany(locationId=${lId},companyId=${cId})`;
    }

    static getIncomingDentalLabReferrals(companyId: number, locationId: number): string {
        const cId = companyId.toString(10);
        const lId = locationId.toString(10);
        return environment.apiBaseUrl +
            `/odata/DentalLabsReferralPreviews/IncomingDentalLabsReferralPreviews(locationId=${lId},companyId=${cId})`;
    }

    static getOutgoingSpecialistReferrals(companyId: number, locationId: number): string {
        const cId = companyId.toString(10);
        const lId = locationId.toString(10);
        return environment.apiBaseUrl +
            `/odata/ReferralPreviews/OutgoingByLocationByCompany(locationId=${lId},companyId=${cId})`;
    }

    static getOutgoingDentalLabReferrals(companyId: number, locationId: number): string {
        const cId = companyId.toString(10);
        const lId = locationId.toString(10);
        return environment.apiBaseUrl +
            `/odata/DentalLabsReferralPreviews/OutgoingDentalLabsReferralPreviews(locationId=${lId},companyId=${cId})`;
    }

}
