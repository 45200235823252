import {Deserializable} from '../views/shared/utils/json-utils';

export class Correspondence implements Deserializable {
    public id: string;
    public referralId: string;
    public content: string;
    public signeeId: number;
    public signee: string;
    public referringCorrespondenceStatusTypeId: number;
    public referringStatus: string;
    public referredCorrespondenceStatusTypeId: number;
    public referredStatus: string;
    public createdDate: Date;
    public createdById: number;
    public createdBy: string;
    public correspondenceTypeId: number;
    public correspondenceType: string;
    public updatedDate: Date;
    public updatedById: number;
    public updatedBy: string;
    public isSystemGenerated: boolean;

    onDeserialize() {
    }
}


