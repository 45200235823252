export default class PhoneUtils {
    public static phoneMask: Array<string | RegExp> = ['1', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    public static phoneRegex = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;

    static formatNumberAsE164(phoneNumber: string): string {
        if (!phoneNumber) {
            return null;
        }
        const digitOnly = phoneNumber.replace(/[^0-9.]/g, '');
        return `+${digitOnly}`;
    }
}
