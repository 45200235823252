import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule, FormsModule} from '@angular/forms';


import {ProfileRoutingModule} from './profile-routing.module';
import {ProfileComponent} from './profile.component';
import {SharedModule} from '../shared/shared.module';
import {CanDeactivateGuardService} from '../../services/can-deactivate-guard.service';


@NgModule({
    declarations: [ProfileComponent],
    imports: [
        CommonModule,
        ProfileRoutingModule,
        ReactiveFormsModule,
        FormsModule,
        SharedModule
    ],
    exports: [ProfileComponent],
    providers: [CanDeactivateGuardService]
})
export class ProfileModule {
}
