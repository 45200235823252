<div class="modal-body">
    <app-loading
            [determinateLoading]="false"
            [hidden]="!loading"
            [loadingText]=""
            [fullscreen]="false"
            [backgroundColor]="'#FDFDFD'"
            [color]="'#388EFC'">
    </app-loading>
    <div class="modal-title" i18n>Create Company</div>
    <div class="mt-3 pt-1">
        <div class="row px-1">
            <div class="col-12 px-0">
                <form class="teth-textfield-form" [formGroup]="companyInfoForm"
                      [class.error]="companyInfoForm.controls.organizationName.invalid && (companyInfoForm.controls.organizationName.dirty || companyInfoForm.controls.organizationName.touched)">
                    <label class="teth-textfield-label" for="orgName" i18n>Organization Name</label>
                    <input class="teth-textfield" type="text" id="orgName" name="orgName"
                           formControlName="organizationName">
                    <span class="teth-textfield-error" i18n>Organization name is required.</span>
                </form>
            </div>
            <div class="col-12 px-0">

                <div class="teth-textfield-form">
                    <app-auto-complete-address
                            i18n-label
                            label="Address Line 1"
                            (addressSelected)="autoCompleteAddressSelected($event)"
                            [control]="$any(companyInfoForm.controls.address1)">
                    </app-auto-complete-address>
                </div>
            </div>
            <div class="col-12 px-0">
                <form class="teth-textfield-form" [formGroup]="companyInfoForm"
                      [class.error]="companyInfoForm.controls.address2.invalid && (companyInfoForm.controls.address2.dirty || companyInfoForm.controls.address2.touched)">
                    <label class="teth-textfield-label" for="address2" i18n>Address Line 2</label>
                    <input class="teth-textfield" type="text" id="address2" name="address2"
                           formControlName="address2">
                    <span class="teth-textfield-error"></span>
                </form>
            </div>
            <div class="col-12 col-xl-6 px-0">
                <form class="teth-textfield-form" [formGroup]="companyInfoForm"
                      [class.error]="companyInfoForm.controls.postalCode.invalid && (companyInfoForm.controls.postalCode.dirty || companyInfoForm.controls.postalCode.touched)">
                    <label class="teth-textfield-label" for="postalCode" i18n>Postal Code/Zip Code</label>
                    <input class="teth-textfield" type="text" id="postalCode" name="postalCode"
                           formControlName="postalCode">
                    <span class="teth-textfield-error" i18n>Postal Code is required.</span>
                </form>
            </div>
            <div class="col-12 col-xl-6 px-0">
                <form class="teth-textfield-form" [formGroup]="companyInfoForm"
                      [class.error]="companyInfoForm.controls.city.invalid && (companyInfoForm.controls.city.dirty || companyInfoForm.controls.city.touched)">
                    <label class="teth-textfield-label" for="city" i18n>City</label>
                    <input class="teth-textfield" type="text" id="city" name="city"
                           formControlName="city">
                    <span class="teth-textfield-error" i18n>City is required.</span>
                </form>
            </div>
            <div class="col-12 col-xl-6 px-0">
                <form class="teth-textfield-form" [formGroup]="companyInfoForm"
                      [class.error]="companyInfoForm.controls.state.invalid && (companyInfoForm.controls.state.dirty || companyInfoForm.controls.state.touched)">
                    <label class="teth-textfield-label" for="state" i18n>Province/State</label>
                    <select class="teth-dropdown teth-textfield-dropdown"
                            name="state"
                            id="state"
                            formControlName="state">
                        <option value="-1" selected disabled hidden i18n>Select State/Province</option>
                        <option *ngFor="let state of states"
                                value="{{state.id}}">{{state.name}}</option>
                    </select>
                    <span class="teth-textfield-error" i18n>Select a province/state.</span>
                </form>
            </div>
            <div class="col-12 col-xl-6 px-0">
                <form class="teth-textfield-form" [formGroup]="companyInfoForm"
                      [class.error]="companyInfoForm.controls.country.invalid && (companyInfoForm.controls.country.dirty || companyInfoForm.controls.country.touched)">
                    <label class="teth-textfield-label" for="country" i18n>Country</label>
                    <select class="teth-dropdown teth-textfield-dropdown"
                            name="country"
                            id="country"
                            formControlName="country"
                            (change)="filterCountryStates($any($event.target).value*1)">
                        <option value="-1" selected disabled hidden i18n>Select Country</option>
                        <option *ngFor="let country of countries"
                                [value]="country.id">{{country.name}}</option>
                    </select>
                    <span class="teth-textfield-error" i18n>Country is required.</span>
                </form>
            </div>
            <div class="col-12 col-xl-6 px-0 mb-4">
                <form class="teth-textfield-form" [formGroup]="companyInfoForm"
                      [class.error]="companyInfoForm.controls.phoneNumber.invalid && (companyInfoForm.controls.phoneNumber.dirty || companyInfoForm.controls.phoneNumber.touched)">
                    <label class="teth-textfield-label" for="phoneNumber" i18n>Phone Number</label>
                    <input class="teth-textfield" type="text" id="phoneNumber" name="phoneNumber"
                           placeholder="1 (000) 000-0000"
                           [textMask]="{mask: phoneUtils.phoneMask}"
                           formControlName="phoneNumber">
                    <span class="teth-textfield-error" i18n>Valid phone number is required.</span>
                </form>
            </div>
            <div class="col-12 px-0">
                <form class="teth-textfield-form" [formGroup]="companyInfoForm"
                      [class.error]="companyInfoForm.controls.companyTypeId.invalid && (companyInfoForm.controls.companyTypeId.dirty || companyInfoForm.controls.companyTypeId.touched)">
                    <label class="teth-textfield-label" for="companyTypeId" i18n>Company Type</label>
                    <select class="teth-dropdown teth-textfield-dropdown"
                            name="companyTypeId"
                            id="companyTypeId"
                            formControlName="companyTypeId">
<!--                        <option [value]="companyType.Administrator" i18n>Administrator</option>-->
                        <option [value]="companyType.Specialist" i18n>Specialist</option>
                        <option [value]="companyType.Dentist" i18n>Dentist</option>
                        <option [value]="companyType.DentalLab" i18n>Dental Lab</option>
                    </select>
                    <span class="teth-textfield-error" i18n>Select a province/state.</span>
                </form>
            </div>
            <div class="col-12 col-xl-6 px-0 mb-4" *ngIf="companyInfoForm.errors?.createError">
                <form class="teth-textfield-form error">
                    <span class="teth-textfield-error">Verify all fields and try again.</span>
                </form>
            </div>
        </div>
    </div>
    <div class="footer-button">
        <button class="teth-button grey-button" (click)="closeModal()">Cancel</button>
        <button class="teth-button blue-button ml-3"
                [class.grey-button]="companyInfoForm.invalid && (companyInfoForm.dirty || companyInfoForm.touched)"
                [class.disabled]="companyInfoForm.invalid && (companyInfoForm.dirty || companyInfoForm.touched)"
                (click)="saveCompanyChanges()">
            Create Company
        </button>
    </div>
</div>
