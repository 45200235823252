import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {SignInRequest} from '../models/sign-in-request.model';
import {forkJoin, Observable, of} from 'rxjs';
import {environment} from '../../environments/environment';
import {SessionService} from './session.service';
import {CacheService} from './cache.service';
import {Employee} from '../models/employee.model';
import {Router} from '@angular/router';
import {EmployeeService} from './employee.service';
import '../views/shared/utils/observable.extensions';
import {Company} from '../models/company.model';
import {Location} from '../models/location.model';
import {SessionContainer} from '../models/session-container';
import {DeserializeHelper} from '../views/shared/utils/json-utils';
import {RefreshSessionRequest} from '../models/refresh-session-request.model';
import {ToastrService} from 'ngx-toastr';
import {Session} from '../models/session.model';
import {NotificationService} from './notification.service';

@Injectable({
    providedIn: 'root'
})
export class AccountService {
    public refreshing: boolean = false;
    userSessionCacheKey = 'userSessionCacheKey';

    constructor(private api: ApiService,
                private sessionService: SessionService,
                private employeeService: EmployeeService,
                private cacheService: CacheService,
                private notificationService: NotificationService,
                private router: Router,
                private toastr: ToastrService) {

        // update users locations
        this.sessionService.sessionContainer.notNull().subscribe((u) => {
            this.setActiveLocation();
        });

        this.sessionService.currentLocation.notNull().subscribe((loc) => {
            const existingSession = this.sessionService.sessionContainer.value;
            const session = new SessionContainer(existingSession.employee, existingSession.company, loc, existingSession.companyLocations, existingSession.loginTime);
            this.updateUserSession(session);
        });
    }

    updateUserSession(sessionContainer: SessionContainer, rememberUser: boolean = this.cacheService.isLocalStorageEnabled()) {
        this.cacheService.useLocalStorage(rememberUser);
        this.sessionService.sessionContainer.next(sessionContainer);
        this.cacheService.insertCachedObject(this.userSessionCacheKey, sessionContainer);
    }

    setActiveLocation() {
        const session = this.sessionService.sessionContainer.value;
        if (!session.currentLocation) {
            this.sessionService.setDefaultCurrentLocation();
        }
    }

    refreshSessionCompanyData(sessionContainer: SessionContainer): Observable<SessionContainer> {
        if (environment.administratorAccess) {
            // don't refresh company info for admins
            return of(sessionContainer);
        }

        const employee = sessionContainer.employee;
        return forkJoin([
            this.getCompanyForUser(employee.companyId, employee.userSession.accessToken).map(c => {
                sessionContainer.company = c;
            }),
            this.getLocationsForUser(employee.companyId, employee.userSession.accessToken).map(l => {
                sessionContainer.companyLocations = l;
            })
        ]).map(() => sessionContainer);
    }

    public signIn(request: SignInRequest, companyId: number, rememberUser: boolean): Observable<SessionContainer> {
        this.cacheService.removeCachedObject(this.userSessionCacheKey, true);
        return new Observable<SessionContainer>(o => {
            this.api.post<Employee>(environment.apiBaseUrl + `/companies/${companyId}/employees/${request.username}/login`, request)
                .subscribe(employee => {
                    employee = DeserializeHelper.deserializeToInstance(Employee, employee);
                    if (employee != null) {
                        const loginTime = (new Date().getTime() / 1000);
                        const sessionContainer = new SessionContainer(employee, null, null, null, loginTime);
                        this.refreshSessionCompanyData(sessionContainer).subscribe(updatedSessionContainer => {
                            this.updateUserSession(updatedSessionContainer, rememberUser);
                            o.next(updatedSessionContainer);
                        }, error => {
                            console.log(error);
                            o.error(error);
                        });
                    } else {
                        o.next(null);
                    }
                }, error => {
                    console.log(error);
                    o.error(error);
                });
        });
    }

    public async getCachableSrc(presignedUrl: string) {
        if (presignedUrl) {
            const blob = await fetch(presignedUrl).then(r => r.blob());
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onload = () => resolve(reader.result);
                reader.onerror = reject;
            });
        }
    }

    public getCompanyForUser(companyId: number, token: string): Observable<Company> {
        const additionalHeaders = {'Authorization': `Bearer ${token}`};
        return this.api.get<Company[]>(
            environment.apiBaseUrl + `/Companies`, null, additionalHeaders)
            .map(r => DeserializeHelper.deserializeToInstance(Company, r.find(c => c.id === companyId)));
    }

    public getLocationsForUser(companyId: number, token: string): Observable<Location[]> {
        const additionalHeaders = {'Authorization': `Bearer ${token}`};
        return this.api.get<Location[]>(
            environment.apiBaseUrl + `/Companies/${companyId}/Locations`, null, additionalHeaders)
            .map(r => r.map(d => DeserializeHelper.deserializeToInstance(Location, d)));
    }

    public refreshSession(refreshToken: RefreshSessionRequest, companyId: number, username: string): Observable<RefreshSessionRequest> {
        return this.api.post<RefreshSessionRequest>(
            environment.apiBaseUrl + `/Companies/${companyId}/employees/${username}/refreshsession`, refreshToken);
    }

    public isAuthenticated(forceRefresh: boolean = false): Observable<Session> {
        // Check whether the current time is past the
        // Access Token's expiry time
        this.refreshing = false;
        let currentUserSessionContainer = this.sessionService.sessionContainer.value;
        if (currentUserSessionContainer == null) {
            console.log('no sessionService user');
            currentUserSessionContainer = this.cacheService.getCachedObject<SessionContainer>(this.userSessionCacheKey);
            if (!currentUserSessionContainer) {
                console.log('no localStorage user, checking sessionStorage');
                this.cacheService.useLocalStorage(false);
                currentUserSessionContainer = this.cacheService.getCachedObject<SessionContainer>(this.userSessionCacheKey);
            } else {
                console.log(currentUserSessionContainer);
            }

            if (currentUserSessionContainer) {
                // since the session container company data is cached, get fresh data from the api
                this.refreshSessionCompanyData(currentUserSessionContainer).subscribe(updatedSessionContainer => {
                    this.updateUserSession(updatedSessionContainer);
                }, error => {
                    console.log(error);
                });
            } else {
                return of(null);
            }
        }

        const userSession = currentUserSessionContainer.employee.userSession;
        const userSessionExpiresAt = userSession?.expiresIn + currentUserSessionContainer.loginTime;
        const accessToken = userSession?.accessToken;
        const refreshToken = userSession?.refreshToken;
        const employeeCompanyId: number = currentUserSessionContainer.employee.companyId;
        const employeeUserName: string = currentUserSessionContainer.employee.userName;

        return new Observable(observer => {
                if (userSessionExpiresAt && accessToken) {
                    // adding a buffer to the expiration time to avoid API/auth issues
                    if (((new Date().getTime() / 1000) < userSessionExpiresAt - 250) && !this.refreshing && !forceRefresh) {
                        observer.next(userSession);
                    } else {
                        console.log('refreshing session');
                        this.refreshing = true;
                        const refreshSessionRequest = new RefreshSessionRequest();
                        refreshSessionRequest.refreshToken = refreshToken;
                        this.refreshSession(refreshSessionRequest, employeeCompanyId, employeeUserName).subscribe((res) => {
                            currentUserSessionContainer.employee.userSession.accessToken = res.userSession.accessToken;
                            currentUserSessionContainer.employee.userSession.expiresIn = res.userSession.expiresIn;
                            currentUserSessionContainer.employee.userSession.idToken = res.userSession.idToken;
                            currentUserSessionContainer.loginTime = (new Date().getTime() / 1000);
                            this.cacheService.useLocalStorage(this.cacheService.isLocalStorageEnabled());
                            this.sessionService.sessionContainer.next(currentUserSessionContainer);
                            this.cacheService.insertCachedObject(this.userSessionCacheKey, currentUserSessionContainer);
                            this.updateUserSession(currentUserSessionContainer);
                            observer.next(res.userSession);
                            this.refreshing = false;
                            observer.complete();
                        }, err => {
                            // error indicates that the refresh token has expired
                            console.log(err);
                            console.log('Session Expired, Logging out user');
                            this.toastr.error('Please log in again to continue your session', 'Refreshing Session');
                            observer.next(null);
                            this.signOut();
                        });
                    }
                } else {
                    observer.next(null);
                }
            }
        );
    }

    signOut(routeUserToSignIn: boolean = true) {
        this.notificationService.disposeBindings();
        this.sessionService.currentLocation.next(null);
        this.sessionService.sessionContainer.next(null);
        this.cacheService.removeCachedObject(this.userSessionCacheKey, true);
        if (routeUserToSignIn) {
            this.router.navigate(['/sign-in']);
        }
    }
}
