import { Component, OnInit } from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-user-agreement-modal',
  templateUrl: './user-agreement-modal.component.html',
  styleUrls: ['./user-agreement-modal.component.scss', '../../../stylesheet/stylesheet.component.scss']
})
export class UserAgreementModalComponent implements OnInit {

  expandAgreement: boolean = false;

  constructor(private activeModal: NgbActiveModal) {
  }

  ngOnInit() {

  }

  closeModal() {
    this.activeModal.close();
  }

  continue() {
    this.activeModal.close(true);
  }
}
