<div class="d-flex align-items-end">
    <div class="flex-grow-1 type-ahead-container" [ngClass]="customClass">
        <form class="teth-textfield-form" (submit)="search()">
            <label class="teth-textfield-label" for="typeAheadSearch">{{fieldLabel}}</label>
            <input class="teth-textfield search-textfield" type="text" id="typeAheadSearch" name="typeAheadSearch"
                   [placeholder]="fieldPlaceholder"
                   autocomplete="off"
                   [(ngModel)]="searchInput"
                   (ngModelChange)="searchInputChanged()">
            <button class="clear-search" type="reset" (click)="resetSearch()">CLEAR</button>
            <span class="teth-textfield-error"></span>
        </form>

        <div class="search-results type-ahead-search-results" *ngIf="!hideResults">
            <span *ngIf="showResultCount" class="search-results-header" i18n>
                Results ({{ searchResults.length }})
            </span>
            <div class="results-container">
                <ul class="teth-list">
                    <li class="list-item" *ngFor="let result of searchResults" (click)="rowClicked(result)">
                        <ng-container *ngTemplateOutlet="resultTemplate || defaultResultTemplate; context: {$implicit: result}">
                        </ng-container>
                        <ng-template #defaultResultTemplate>
                            <div class="list-item-text-container">
                        <span class="list-item-text">
                            <img class="result-img" *ngIf="resultIconPropertyName.length > 0"
                                 [src]="getItemIconSrc(result)" alt="">
                            {{ getSearchResultDisplayText(result) }}
                        </span>
                            </div>
                        </ng-template>
                    </li>
                    <li class="list-item inactive" *ngIf="searchResults.length === 0">
                        <div class="list-item-text-container">
                        <span class="list-item-text" i18n>
                            No Results Found for: {{ searchInput }}
                        </span>
                        </div>
                    </li>
                    <li class="list-item action" *ngIf="resultActionButtonText.length > 0"
                        (click)="resultsActionButtonClicked()">
                        <div class="list-item-text-container">
                        <span class="list-item-text" i18n>
                            {{resultActionButtonText}} "{{ searchInput }}"
                        </span>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <button *ngIf="showSearchButton" class="teth-button blue-button search-button" (click)="search()">Search</button>
</div>

