<div class="teth-upload-area" appDnd (fileDropped)="onFileDropped($event)">
    <form class="teth-upload-form">
        <img [src]="iconSrc" class="teth-upload-icon" alt="manage">
        <span class="teth-upload-text" i18n>
            <label for="fileDropRef{{id}}" class="teth-upload-label">Upload a File</label> or Drag and Drop
        </span>
        <span class="teth-upload-text secondary-upload-text" i18n>
            {{getLocalizedFileTypeText()}}
        </span>
        <input type="file" id="fileDropRef{{id}}" class="fileDropRef" #fileDropRef multiple accept="{{getAcceptValue()}}"
               (change)="fileBrowseHandler($event.target)">
    </form>
</div>

<div class="teth-upload-list-container" *ngIf="!hideFilesUploaded">
    <ul class="teth-upload-list">
        <li class="teth-upload-preview success" *ngFor="let file of files; let i = index"
            [ngClass]="{ 'success': file.success, 'error': file.failed }">
            <img class="teth-upload-preview-image" [src]="imagePreview(i)" alt="" [ngStyle]="{'padding':getPlaceholderImagePadding(i)}">
            <div class="teth-upload-preview-text-container">
                <span class="teth-upload-preview-text-name">
                    {{ file?.name }}
                </span>
                <span class="teth-upload-preview-text-size">
                    {{ formatBytes(file?.size) }}
                </span>
                <span class="teth-upload-preview-error">
                    {{ file?.failureError }}
                </span>
                <app-progress-bar
                        [progress]="file?.progress"
                        [errored]="file.failed"></app-progress-bar>
            </div>
            <button class="teth-upload-button teth-remove-upload-button">
                <img src="../../../assets/img/icons/remove.svg" class="teth-upload-button-icon" alt="remove"
                     (click)="deleteFile(i)">
            </button>
            <button class="teth-upload-button teth-refresh-upload-button">
                <img src="../../../assets/img/icons/retry.svg" class="teth-upload-button-icon" alt="retry"
                     (click)="retryUpload(i)">
            </button>
        </li>
    </ul>
</div>
