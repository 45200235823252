<app-loading
        [determinateLoading]="false"
        [hidden]="correspondence != null && awaitingRequests === 0"
        [loadingText]="loadingText"
        [fullscreen]="false"
        [backgroundColor]="'#FDFDFD'"
        [color]="'#388EFC'">
</app-loading>

<div class="container mb-4" *ngIf="correspondence != null">
    <div class="row back-button-row">
        <div class="col col-lg-12 d-flex">
            <button class="teth-button white-button back-button"
                    (click)="goBack()">
                <ng-container *ngIf="shownInModal">Close</ng-container>
                <ng-container *ngIf="!shownInModal">Back</ng-container>
            </button>
        </div>
    </div>
    <div class="row">
        <div class="col col-lg-12 d-flex">
            <div class="headline">{{getTitle()}}</div>
            <div class="status-pill ml-4" [hidden]="!correspondenceIsApproved()" i18n>Approved</div>
            <div class="status-pill unapproved ml-4" [hidden]="correspondenceIsApproved()" i18n>Pending Approval</div>
            <button class="teth-button blue-button ml-auto"
                    (click)="approveCorrespondence()"
                    [hidden]="!canApproveCorrespondence || correspondenceIsApproved()" i18n>
                Approve
            </button>
        </div>
        <div class="col col-lg-12 d-flex subheadline-title">
            <ng-container i18n>Created on: &nbsp;</ng-container>
            <span class="subheadline-content">{{shortDateFormat(correspondence?.createdDate)}}</span>
        </div>
        <div class="col col-lg-12 d-flex subheadline-title">
            <ng-container i18n>Signee: &nbsp;</ng-container>
            <span class="subheadline-content">{{correspondence?.signee}}</span></div>
    </div>
    <div class="row">
        <div class="col col-lg-12 d-flex">
            <div class="section-container mr-1">
                <div class="section-subtitle" i18n>Patient Information</div>
                <div class="row">
                    <div class="col col-sm-6">
                        <div class="info-title" i18n>First Name</div>
                        <div class="info-value">{{correspondence?.patientFirstName}}</div>
                    </div>
                    <div class="col col-sm-6">
                        <div class="info-title" i18n>Last Name</div>
                        <div class="info-value">{{correspondence?.patientLastName}}</div>
                    </div>
                    <div class="col col-sm-6">
                        <div class="info-title" i18n>Birthdate</div>
                        <div class="info-value">{{shortDateFormat(referralPatient?.birthdate)}}</div>
                    </div>
                    <div class="col col-sm-6">
                        <div class="info-title" i18n>Gender</div>
                        <div class="info-value">{{referralPatient?.gender}}</div>
                    </div>
                    <div class="col col-sm-6">
                        <div class="info-title" i18n>Procedures</div>
                        <div *ngFor="let p of correspondence.procedures" class="info-value">{{p.procedureName}}</div>
                    </div>
                </div>
            </div>
            <div class="section-container ml-4">
                <div class="section-subtitle" i18n>Referral Information</div>
                <div class="row">
                    <div class="col col-sm-6">
                        <div class="info-title" i18n>Referring Dentist</div>
                        <div class="info-value">{{referral?.referringEmployee}}</div>
                    </div>
                    <div class="col col-sm-6">
                        <div class="info-title" i18n>Assigned Specialist</div>
                        <div class="info-value">{{referral?.assignedSpecialist}}</div>
                    </div>
                    <div class="col col-sm-6">
                        <div class="info-title" i18n>Generalist Location</div>
                        <div class="info-value">{{referral?.referringLocation}}</div>
                    </div>
                    <div class="col col-sm-6">
                        <div class="info-title" i18n>Specialist Location</div>
                        <div class="info-value">{{referral?.referredLocation}}</div>
                    </div>
                    <div class="col col-sm-6 mt-3" *ngIf="!shownInModal">
                        <button class="teth-button blue-button" (click)="viewReferral()" i18n>View Referral</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col col-lg-12">
            <div class="row">
                <div class="col-md-12 col-xl-8 mt-4">
                    <div *ngIf="letterAttachmentSrc != null">
                        <div class="section-title" i18n>Letter</div>
                        <div class="section-container mb-4">
                            <div class="row">
                                <div class="col col-sm-12">
                                    <object class="attachment-viewer" [data]="letterAttachmentSrc" [type]="'application/pdf'"></object>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="section-title" i18n>Attachments</div>
                    <div class="section-container d-flex flex-row flex-wrap">
                        <div class="row w-100 mx-0">
                            <div class="no-items"
                                 [hidden]="correspondence?.attachments?.length > 0" i18n>No Attachments
                            </div>
                            <div class="col-sm-12 col-md-6 col-xl-4 attachment-img-container"
                                 *ngFor="let attachment of correspondence?.attachments">
                                <img class="attachment-img"
                                     [src]="attachmentUtils.attachmentThumbnailSrc(attachment)"
                                     (error)="$any($event.target).src='/assets/img/icons/manage.svg';$any($event.target).style.padding='10%';"
                                     [ngStyle]="{'padding':attachmentUtils.getPlaceholderImagePadding(attachment, '10%')}"
                                     (click)="attachmentClicked(attachment)"
                                     alt="attachment">
                                <div class="attachment-img-name">{{attachment.fileName}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 col-xl-4 mt-4">
                    <div class="section-title" i18n>Other Correspondences</div>
                    <div class="section-container">
                        <div class="no-items"
                             [hidden]="referralStageCorrespondenceDetails?.length > 0" i18n>No Other Correspondence
                        </div>
                        <div *ngFor="let stageDetail of referralStageCorrespondenceDetails; let i = index;">
                            <div class="row">
                                <div class="col col-12 correspondence-section-container"
                                     [ngClass]="{'active': stageDetail.active }">
                                    <div class="referral-stage-details-container">
                                        <div class="correspondence-section-title" *ngIf="stageDetail.title">
                                            <img [src]="'/assets/img/icons/'+stageDetail.iconName" alt="">
                                            {{ stageDetail.title }}
                                        </div>
                                        <div class="correspondence-section-description">
                                            {{ stageDetail.subtitle }}
                                            <div *ngIf="stageDetail.correspondenceId != null && !isCorrespondenceApprovedBySpecialist(stageDetail.correspondenceId)"
                                                 i18n>(Pending Approval)</div>
                                        </div>
                                    </div>
                                    <ng-container *ngIf="stageDetail?.correspondenceId === correspondence.id">
                                        <div class="correspondence-section-action-disabled" i18n>Currently Viewing</div>
                                    </ng-container>
                                    <ng-container *ngIf="stageDetail?.correspondenceId != correspondence?.id">
                                    <div class="correspondence-section-action"
                                         (click)="handleViewCorrespondenceClick(i)"
                                         [hidden]="shownInModal || !stageDetail.active || stageDetail.correspondenceId == null" i18n>
                                        View Correspondence Letter
                                    </div>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="row"
                                 [hidden]="i === referralStageCorrespondenceDetails.length - 1">
                                <div class="col col-12">
                                    <div class="divider"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

