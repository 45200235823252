import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {Employee} from '../../../../models/employee.model';
import {SessionService} from '../../../../services/session.service';
import {Location} from '../../../../models/location.model';
import {take} from 'rxjs/operators';
import AddressUtils from '../../utils/address-utils';

@Component({
    selector: 'app-employee-info',
    templateUrl: './employee-info.component.html',
    styleUrls: ['./employee-info.component.scss']
})
export class EmployeeInfoComponent implements OnInit, OnChanges {

    @Input() public employee: Employee = null;
    @Output() public locationSelected = new EventEmitter<Location>();
    
    addressUtils = AddressUtils;
    locations: Location[] = null;

    constructor(private sessionService: SessionService) {
    }

    ngOnInit(): void {
        this.loadLocations();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.loadLocations();
    }

    loadLocations() {
        this.sessionService.sessionContainer.firstNotNull().subscribe(s => {
            this.locations = s.companyLocations.filter(l => this.employee.assignedLocations.map(el => el.locationId).includes(l.id));
        });
    }
}
