import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbDatepickerI18n, NgbDateStruct, NgbDatepickerConfig} from '@ng-bootstrap/ng-bootstrap';
import {NgbDate} from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-date';
import {CustomDatepickerTetherport, I18n} from './custom-date-picker-language';
import DateUtils from '../../utils/date-utils';

export enum DatePickerOutput {
    SingleDate = 'SingleDate',
    DateRange = 'DateRange'
}

export enum DatePickerNavigationType {
    Select = 'select',
    Arrows = 'arrows',
    None = 'none'
}

@Component({
    selector: 'app-date-picker',
    templateUrl: './date-picker.component.html',
    styleUrls: ['./date-picker.component.scss'],
    providers: [I18n, {provide: NgbDatepickerI18n, useClass: CustomDatepickerTetherport}]
})

export class DatePickerComponent implements OnInit {

    @Input() public displayMonths: number;
    @Input() public navigationType: DatePickerNavigationType;
    @Input() public startDate: { year: number, month: number };
    @Input() public minDate: NgbDateStruct;
    @Input() public maxDate: NgbDateStruct;
    @Input() public disablePastDates: boolean = false;
    @Input() public disableWeekends: boolean = false;
    @Input() public hideBorder: boolean = false;
    @Input() public datePickerOutput: DatePickerOutput;
    @Input() public initialSelectedDates: NgbDate[] = [];
    @Input() public clearDateSelection: EventEmitter<any>;
    @Input() public thresholdDate: NgbDateStruct = null;

    @Output() public dateSelected = new EventEmitter<NgbDate[]>();

    public selectedDate: NgbDateStruct;
    public datePickerConfig: NgbDatepickerConfig = new NgbDatepickerConfig();
    public hoveredDate: NgbDate | null = null;
    public fromDate: NgbDate;
    public toDate: NgbDate | null = null;
    datePickerNavigationType = DatePickerNavigationType;

    constructor() {
    }

    ngOnInit(): void {
        if (!this.displayMonths) {
            this.displayMonths = 3;
        }
        if (!this.datePickerOutput) {
            this.datePickerOutput = DatePickerOutput.SingleDate;
        }
        if (!this.navigationType) {
            this.navigationType = DatePickerNavigationType.Select;
        }
        if (!this.startDate) {
            const currentDate = new Date();
            this.startDate = {
                year: currentDate.getFullYear(),
                month: currentDate.getMonth() + 1
            };
        }
        if (this.initialSelectedDates) {
            if (this.initialSelectedDates.length > 1) {
                this.fromDate = this.initialSelectedDates[0];
                this.toDate = this.initialSelectedDates[1];
            } else if (this.initialSelectedDates.length === 1) {
                this.selectedDate = this.initialSelectedDates[0];
            }
        }
        if (this.clearDateSelection) {
            this.clearDateSelection.subscribe(() => {
                this.toDate = null;
                this.selectedDate = null;
                this.fromDate = null;
            });
        }
        this.buildDatePickerConfig();
    }

    buildDatePickerConfig() {
        const config = new NgbDatepickerConfig();
        // default values for all date pickers
        config.firstDayOfWeek = 7; // first day of the week is Sunday

        // apply custom attributes
        config.displayMonths = this.displayMonths;
        config.navigation = this.navigationType;
        config.startDate = this.startDate;
        if (this.minDate) {
            config.minDate = this.minDate;
        }
        if (this.maxDate) {
            config.maxDate = this.maxDate;
        }
        if (this.disablePastDates) {
            config.markDisabled = function (date: NgbDate, _: { year: number, month: number }): boolean {
                return DateUtils.ngbDateIsBeforeToday(date.year, date.month, date.day);
            };
        }
        if (this.disableWeekends) {
            config.markDisabled = function (date: NgbDate): boolean {
                const checkDate = new Date(date.year, date.month - 1, date.day).getDay();
                return checkDate === 6 || checkDate === 0;
            };
        }
        this.datePickerConfig = config;
    }

    handleDateSelection(date: NgbDate) {
        if (this.datePickerOutput === DatePickerOutput.DateRange) {
            this.handleDateRangeSelection(date);
        } else {
            this.selectedDate = date;
            this.dateSelected.emit([date]);
        }
    }

    isPastMonth(date: NgbDate, currentMonth: number) {
        if (date.month === currentMonth) {
            return false;
        } else {
            return (date.month < new Date().getMonth() + 1) && (date.year === new Date().getFullYear());
        }
    }

    isOutOfMonth(date: NgbDate, currentMonth: number) {
        return date.month !== currentMonth;
    }

    isCurrentDate(date: NgbDate) {
        return date.year === new Date().getFullYear() && date.month === new Date().getMonth() + 1 && date.day === new Date().getDate();
    }

    isThresholdDate(date: NgbDate) {
        if (this.thresholdDate) {
            const currentDate = new Date();
            return date.before(this.thresholdDate) && date.after({year: currentDate.getFullYear(), month: currentDate.getMonth() + 1, day: currentDate.getDate()});
        } else {
            return null;
        }
    }

    isHovered(date: NgbDate) {
        return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
    }

    isInside(date: NgbDate) {
        return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
    }

    isRange(date: NgbDate) {
        return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
    }

    isFocused(date: NgbDate, focused: boolean) {
        return (date.equals(this.selectedDate)) || focused;
    }

    handleDateRangeSelection(date: NgbDate) {
        if (!this.fromDate && !this.toDate) {
            this.fromDate = date;
            this.dateSelected.emit([]);
        } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
            this.toDate = date;
            this.dateSelected.emit([this.fromDate, this.toDate]);
        } else {
            this.toDate = null;
            this.fromDate = date;
            this.dateSelected.emit([]);
        }
    }
}
