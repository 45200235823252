import { TimeRangeFilter } from '../../../models/time-range-filter';
import * as moment from 'moment';

export default class TimeRangeUtils {
    static generateDefaultTimeRanges(): TimeRangeFilter[] {
        const opts = [];

        const lastMonth = new TimeRangeFilter();
        lastMonth.name = $localize`Last 28 Days`;
        const lastMonthUnixSd = moment(new Date()).endOf('day').toDate();
        lastMonthUnixSd.setMonth(lastMonthUnixSd.getMonth() - 1);
        lastMonth.startDate = new Date(lastMonthUnixSd);
        lastMonth.endDate = moment(new Date()).endOf('day').toDate();
        opts.push(lastMonth);

        const lastYear = new TimeRangeFilter();
        lastYear.name = $localize`Last Year`;
        const lastYearUnixSd = moment(new Date()).endOf('day').toDate();
        lastYearUnixSd.setFullYear(lastYearUnixSd.getFullYear() - 1);
        lastYear.startDate = new Date(lastYearUnixSd);
        lastYear.endDate = moment(new Date()).endOf('day').toDate();
        opts.push(lastYear);

        const yearToDate = new TimeRangeFilter();
        yearToDate.name = $localize`Year to Date`;
        yearToDate.startDate = new Date(new Date().getFullYear(), 0, 1);
        yearToDate.endDate = moment(new Date()).endOf('day').toDate();
        opts.push(yearToDate);

        const customDateRange = new TimeRangeFilter();
        customDateRange.name = $localize`Custom`;
        customDateRange.customDateRange = true;
        opts.push(customDateRange);

        return opts;
    }
}
