import {Session} from './session.model';
import {Deserializable} from '../views/shared/utils/json-utils';
import {Procedure} from './procedure.model';
import {TopReferringDentist} from './top-referring-dentists';

export class Employee implements Deserializable {
    public id: number;
    public userName: string;
    public password: string;
    public primaryName: string;
    public familyName: string;
    public prefix: string;
    public email: string;
    public enabled: boolean;
    public isDentist: boolean;
    public specialtyType: string;
    public specialtyTypeId: number;
    public roleId: number;
    public role: string;
    public createdDate: Date;
    public createdById: number;
    public createdBy: string;
    public assignedLocations: AssignedLocation[];
    public assignedProcedures: AssignedProcedure[];
    public assignedPractitioners: AssignedPractitioner[];
    public companyId: number;
    public university: string;
    public degree: string;
    public graduationYear: string;
    public yearsPracticing: string;
    public biography: string;
    public defaultLocationId: number;
    public userSession: Session;
    public passwordChangedDate: Date;
    public eulaConfirmationDate: Date;
    public eulaConfirmation: boolean;
    public lastLoginDate: Date;

    onDeserialize() {
        this.lastLoginDate = this.lastLoginDate ? new Date(this.lastLoginDate) : null;
        this.passwordChangedDate = new Date(this.passwordChangedDate);
        this.eulaConfirmationDate = new Date(this.eulaConfirmationDate);
    }
}

export class AssignedLocation {
    locationId: number;
    location: string;
}

export class AssignedPractitioner {
    practitionerEmployeeId: number;
    practitionerName: string;
}

export class AssignedProcedure {
    procedureTypeId: number;
    procedure: string;

    constructor(procedure: Procedure) {
        this.procedureTypeId = procedure.id;
        this.procedure = procedure.name;
    }
}

export class ReferringEmployee {
    public id: number;
    public name: string;
    public referringLocationId: number;
    public referringLocationName: string;
    public numberOfReferrals: number;
    public numberOfCompletedReferrals: number;
    public numberOfCaseAcceptedReferrals: number;
    public numberOfLastReferrals: number;
    public numberOfLastCompletedReferrals: number;
    public numberOfLastCaseAcceptedReferrals: number;
    // Not From API, but used for Top Referring Dentists table
    public caseAcceptance: number = 0;
    public referralDifferencePercentage: number = 0;
    public caseAcceptanceDifferencePercentage: number = 0;

    constructor(id: number, name: string, referringLocationId: number, referringLocationName: string) {
        this.id = id;
        this.name = name;
        this.referringLocationId = referringLocationId;
        this.referringLocationName = referringLocationName;
        this.numberOfReferrals = 0;
        this.numberOfCompletedReferrals = 0;
        this.numberOfCaseAcceptedReferrals = 0;
        this.numberOfLastReferrals = 0;
        this.numberOfLastCompletedReferrals = 0;
        this.numberOfLastCaseAcceptedReferrals = 0;
    }

    public static fromTopReferringDentist(dentist: TopReferringDentist) {
        const referringEmployee = new ReferringEmployee(null, null, null, null);
        referringEmployee.name = dentist.dentistName;
        referringEmployee.referringLocationName = dentist.referringClinicName;
        referringEmployee.numberOfReferrals = dentist.referrals;
        referringEmployee.numberOfCaseAcceptedReferrals = dentist.caseAcceptance;
        referringEmployee.referralDifferencePercentage = dentist.referralDifferencePercentage;
        referringEmployee.caseAcceptanceDifferencePercentage = dentist.caseAcceptanceDifferencePercentage;
        referringEmployee.caseAcceptance = dentist.caseAcceptance;
        return referringEmployee;
    }

}
