import {ProcedureProductTrend} from '../../../models/procedure-product-trends';
import * as moment from 'moment';
import {ReferralOutcomeSummary} from '../../../models/referral-outcome-summaries';
import {DataTableColumn} from '../../../models/data-table-column';
import {ProcedureProductTrendChartDataSet} from '../../../models/procedure-product-trend-chart-object';
import {FeeGuide} from "../../../models/fee-guide.model";
import {FeeGuideProcedureFee} from "../../../models/fee-guide-procedure-fee.model";
import {StatusCard} from "../../../models/status-card";
import {ReferralStatus} from "../../../models/referral-status.model";
import {StageAppointmentFee} from "../../../models/stage-appointment-fee.model";


export class SortUtils {

    static sortProcedureProductTrendsByPeriod = (a: ProcedureProductTrend, b: ProcedureProductTrend) => {
        return a.period - b.period;
    }

    static sortProcedureProductTrendColumnsByDate = (a: ProcedureProductTrendChartDataSet, b: ProcedureProductTrendChartDataSet) => {
        const currentQuarter = moment().quarter();
        const currentMonth = moment().month() + 1; // Month is zero-based, so add 1 to get the actual month

        const aQuarter = a.quarter || 0; // Use 0 if quarter is not available
        const bQuarter = b.quarter || 0;
        const aMonth = a.month || 0; // Use 0 if month is not available
        const bMonth = b.month || 0;

        // Calculate the cyclical order value for aQuarter and bQuarter
        const cyclicalOrderA = (currentQuarter - aQuarter + 4) % 4; // 4 is the number of quarters
        const cyclicalOrderB = (currentQuarter - bQuarter + 4) % 4;

        // If the quarters are the same, compare the cyclical order value for aMonth and bMonth
        if (aQuarter === bQuarter) {
            const cyclicalOrderAMonth = (currentMonth - aMonth + 12) % 12; // 12 is the number of months
            const cyclicalOrderBMonth = (currentMonth - bMonth + 12) % 12;
            return cyclicalOrderAMonth - cyclicalOrderBMonth;
        }

        // Compare the cyclical order values
        return cyclicalOrderA - cyclicalOrderB;
    }

    static sortDataTableColumnsByCyclicalDate(sortedDataSets: ProcedureProductTrendChartDataSet[]): (a: [string, DataTableColumn], b: [string, DataTableColumn]) => number {
        return (a, b) => {
            const columnA = a[1];
            const columnB = b[1];
            const dataSetA = sortedDataSets.find((dataSet) => dataSet.getColumnName() === columnA.title);
            const dataSetB = sortedDataSets.find((dataSet) => dataSet.getColumnName() === columnB.title);
            if (dataSetA && dataSetB) {
                if (dataSetA.year !== dataSetB.year) {
                    return dataSetB.year - dataSetA.year; // Sort by year in descending order
                }
                if (dataSetA.quarter !== dataSetB.quarter) {
                    return dataSetA.quarter - dataSetB.quarter; // Sort by quarter in ascending order
                }
                return dataSetB.month - dataSetA.month; // Sort by month in descending order
            }
            return 0;
        };
    }

    static sortReferralSummariesByPeriod(isQuarter: boolean): (a: ReferralOutcomeSummary,  b: ReferralOutcomeSummary) => number {
        return (a, b) => {
            if (isQuarter) {
                if (a.year !== b.year) {
                    return a.year - b.year; // Sort by year in descending order
                }
                if (a.period !== b.period) {
                    return a.period - b.period; // Sort by quarter in ascending order
                }
            } else {
                if (a?.period === b?.period) {
                    return a.year - b.year;
                } else {
                    return a?.period < b?.period ? -1 : 1;
                }
            }
        };
    }

    static sortFeeGuidesByStartDate = (a: FeeGuide, b: FeeGuide) => {
        const aStartDate = new Date(a?.startDate);
        const bStartDate = new Date(b?.startDate);
        return bStartDate?.getTime() - aStartDate?.getTime();
    }

    static sortFeeGuideProcedureFeesByFeeCode = (a: FeeGuideProcedureFee, b: FeeGuideProcedureFee) => {
        return a?.feeCode?.localeCompare(b?.feeCode) ?? a?.feeName?.localeCompare(b?.feeName);
    }

    static sortAppointmentFeesByFeeCode = (a: StageAppointmentFee, b: StageAppointmentFee) => {
        // If the feeCode is a number, compare them as numbers
        if (!isNaN(parseFloat(a?.feeCode)) && !isNaN(parseFloat(b?.feeCode))) {
            return parseFloat(a?.feeCode) - parseFloat(b?.feeCode);
        } else {
            // Otherwise, compare them as strings
            return a.feeCode?.localeCompare(b?.feeCode);
        }
    }

    static sortReferralStatusCards = (a: StatusCard, b: StatusCard) => {
        const desiredOrder = [
            ReferralStatus.CreatedId,
            ReferralStatus.ConsultId,
            ReferralStatus.PendingId,
            ReferralStatus.TreatmentId,
            ReferralStatus.CompletedId,
            ReferralStatus.DeclinedId,
        ]

        const indexA = desiredOrder.indexOf(a.id);
        const indexB = desiredOrder.indexOf(b.id);
        return indexA - indexB;
    }

}
