import {Component, EventEmitter, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ToastrService} from 'ngx-toastr';
import {ReferralService} from '../../../../services/referral.service';
import {EntityService} from '../../../../services/entity.service';
import {DateService} from '../../../../services/date.service';
import {Router} from '@angular/router';
import {SessionService} from '../../../../services/session.service';
import {LocationAssignedSpecialty} from '../../../../models/location-assigned-specialty';
import {BehaviorSubject, combineLatest} from 'rxjs';
import {Employee} from '../../../../models/employee.model';
import {debounceTime, distinctUntilChanged, map, switchMap, tap} from 'rxjs/operators';
import {Location} from '../../../../models/location.model';
import {HydratedLocation} from '../../../../models/hydrated-location';
import {ExternalReferringDentist} from '../../../../models/external-referring-dentist.model';
import {indicate} from '../../utils/observable.extensions';
import {Procedure} from '../../../../models/procedure.model';
import {ManualReferralDetails} from '../../../../models/manual-referral-details';

@Component({
    selector: 'app-manual-referral-modal',
    templateUrl: './manual-referral-modal.component.html',
    styleUrls: ['./manual-referral-modal.component.scss', '../../../stylesheet/stylesheet.component.scss']
})
export class ManualReferralModalComponent implements OnInit {
    awaitingRequests = new BehaviorSubject<number>(0);
    loading$ = this.awaitingRequests.pipe(map(a => a > 0), distinctUntilChanged(), debounceTime(400));
    clearTypeaheadInput = new EventEmitter();
    createNewText = $localize`Create New Dentist`;
    locations: Location[];
    hydratedGeneralistLocations$ = this.entityService.getHydratedGeneralistLocations().pipe(indicate(this.awaitingRequests));
    externalReferringDentists$ = this.entityService.getExternalReferringDentists(this.sessionService.getCompanyId()).pipe(indicate(this.awaitingRequests));
    referringDentistSearchItems$ =
        combineLatest([this.hydratedGeneralistLocations$, this.externalReferringDentists$])
            .pipe(map(([hydratedGeneralistLocations, externalReferringDentists]) => {
                return this.mapDentistSearchItems(hydratedGeneralistLocations, externalReferringDentists);
            }));

    selectedSpecialistLocation = new BehaviorSubject<Location>(null);

    selectedHydratedLocation$ = this.selectedSpecialistLocation.notNull().pipe(
        switchMap(l => this.entityService.getHydratedLocation(l.id).pipe(indicate(this.awaitingRequests), tap(h => this.selectedHydratedLocation = h))),
    );
    selectedHydratedLocation: HydratedLocation;

    availableSpecialties$ = this.selectedSpecialistLocation.notNull().pipe(
        switchMap(l => this.entityService.getLocationAssignedSpecialties(l.companyId, l.id).pipe(indicate(this.awaitingRequests)))
    );

    selectedSpecialty = new BehaviorSubject<LocationAssignedSpecialty>(null);

    availableProcedures$ = this.selectedSpecialty.pipe(
        switchMap(selectedSpecialty => {
            return this.entityService.specialtyTypes$.pipe(map(specialtyType => {
                return specialtyType.find(st => st.id === selectedSpecialty?.specialtyTypeId)?.procedures;
            }), distinctUntilChanged(), tap(() => this.selectedProcedures.next(null)));
        }));

    selectedProcedures = new BehaviorSubject<Procedure[]>(null);

    availableSpecialists$ = combineLatest([this.selectedHydratedLocation$, this.selectedProcedures])
        .notNull()
        .pipe(
            debounceTime(400),
            map(([hydratedLocation, selectedProcedures]) => {
                return hydratedLocation.employees.filter(e => {
                    return e.isDentist && e.enabled && selectedProcedures
                        ?.every(p => e.assignedProcedures.find(ap => ap.procedureTypeId === p.id));
                });
            }),
            tap(() => this.selectedSpecialist.next(null))
        );
    selectedSpecialist = new BehaviorSubject<Employee>(null);

    selectedReferringDentist = new BehaviorSubject<ReferringDentistSearchItem>(null);

    constructor(private activeModal: NgbActiveModal,
                private toastr: ToastrService,
                private referralService: ReferralService,
                private entityService: EntityService,
                private sessionService: SessionService,
                public dateService: DateService,
                private router: Router) {
    }

    ngOnInit(): void {
        this.setupBindings();
    }

    initWithLocation(selectedLocation: Location) {
        this.selectedSpecialistLocation.next(selectedLocation);
    }

    setupBindings() {
        this.sessionService.sessionContainer.subscribe(s => {
            this.locations = s.companyLocations.filter(l => l.acceptingReferrals && l.enabled);
        });

        this.selectedSpecialistLocation.subscribe(() => {
            this.selectedSpecialty.next(null);
        });
    }

    createNewDentist(dentistName: string) {
        const newDentist = new ExternalReferringDentist();
        const newSearchItem = new ReferringDentistSearchItem();
        newDentist.name = dentistName;
        newSearchItem.externalDentist = newDentist;
        this.selectedReferringDentist.next(newSearchItem);
    }

    typeaheadItemSelected(item: ReferringDentistSearchItem) {
        this.selectedReferringDentist.next(item);
    }

    getSearchableTypeaheadProperties(): string[] {
        return ['displayValue'];
    }

    close() {
        this.activeModal.close();
    }

    private mapDentistSearchItems(hydratedGeneralistLocations: HydratedLocation[], externalReferringDentists: ExternalReferringDentist[]): ReferringDentistSearchItem[] {
        const searchItems: ReferringDentistSearchItem[] = [];
        hydratedGeneralistLocations?.forEach(l => {
            l.employees.filter(e => e.enabled).forEach(e => {
                const item = new ReferringDentistSearchItem();
                item.existingDentist = e;
                item.existingLocation = l;
                item.displayValue = `${e.prefix} ${e.primaryName} ${e.familyName} at ${l.name}`;
                item.typeaheadResultIconSrc = 'assets/img/icons/tp-icon-sm.svg';
                searchItems.push(item);
            });
        });

        externalReferringDentists?.forEach(e => {
            const item = new ReferringDentistSearchItem();
            item.externalDentist = e;
            item.displayValue = `${e.name}`;
            searchItems.push(item);
            item.typeaheadResultIconSrc = 'assets/img/icons/question-mark-circle.svg';
        });
        return searchItems;
    }

    backButtonClicked() {
        this.selectedReferringDentist.next(null);
    }

    clickedSpecialty(specialty: LocationAssignedSpecialty) {
        this.selectedSpecialty.next(specialty);
    }

    isProcedureSelected(procedure: Procedure): boolean {
        return this.selectedProcedures.value?.find(p => p.id === procedure.id) != null;
    }

    toggleProcedure(procedure: Procedure) {
        let selectedProcedures = this.selectedProcedures.value ?? [];
        if (selectedProcedures.find(p => p.id === procedure.id)) {
            selectedProcedures = selectedProcedures.filter(p => p.id !== procedure.id);
        } else {
            selectedProcedures.push(procedure);
        }
        this.selectedProcedures.next(selectedProcedures);
    }

    specialistLocationSelected(location: Location) {
        this.selectedSpecialistLocation.next(location);
    }
    specialistSelected(specialist: Employee) {
        this.selectedSpecialist.next(specialist);
    }

    continueButtonEnabled(): boolean {
        return this.selectedProcedures.value?.length > 0
            && !!this.selectedReferringDentist.value
            && !!this.selectedSpecialist.value
            && !!this.selectedSpecialistLocation.value
            && !!this.selectedSpecialty.value;
    }

    continueButtonClicked() {
        const r = this.generateManualReferralDetails();
        this.activeModal.close(r);
    }

    generateManualReferralDetails(): ManualReferralDetails {
        const r = new ManualReferralDetails();
        r.procedures = this.selectedProcedures.value;
        r.referringDentist = this.selectedReferringDentist.value;
        r.referredSpecialist = this.selectedSpecialist.value;
        r.referredLocation = this.selectedHydratedLocation;
        r.specialty = this.selectedSpecialty.value;
        return r;
    }
}

export class ReferringDentistSearchItem {
    public externalDentist: ExternalReferringDentist;
    public existingDentist: Employee;
    public existingLocation: HydratedLocation;
    public displayValue: string;
    public typeaheadResultIconSrc: string;
}


