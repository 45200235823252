import {Referral} from './referral.model';
import {Correspondence} from './correspondence.model';
import {Message} from './message.model';
import {Attachment} from './attachment.model';
import {ReferralProcedure} from './referral-procedure.model';
import {Deserializable, DeserializeHelper} from '../views/shared/utils/json-utils';

export class HydratedReferral extends Referral implements Deserializable {
    public attachments: Attachment[];
    public correspondence: Correspondence[];
    public messages: Message[];
    public procedures: ReferralProcedure[];
    public procedureIds: number[];

    onDeserialize() {
        super.onDeserialize();
        this.procedureIds = this.procedures.map((p) => p.procedureTypeId);
        this.attachments.forEach(a => DeserializeHelper.deserializeToInstance(Attachment, a));
    }
}

