export default class ArrayUtils {
    private static* range(x, y) {
        while (true) {
            if (x <= y) {
                yield x++;
            } else {
                return null;
            }
        }
    }

    static arrayFromRange(x: number, y: number): number[] {
        return Array.from(this.range(x, y));
    }
}

declare global {
    interface Array<T> {
        contains(v: T): boolean;
        first(): T;
        unique(): Array<T>;
        uniqueByMethod(method: string): Array<T>;
        uniqueByProperty(property: string, ignoreNull?: boolean): Array<T>;
    }
}

Array.prototype.contains = function(v) {
    for (let i = 0; i < this.length; i++) {
        if (this[i] === v) {
            return true;
        }
    }
    return false;
};

Array.prototype.unique = function () {
    const _self = this as Array<string>;
    return _self.filter((v, i, a) => a.indexOf(v) === i);
};

Array.prototype.uniqueByMethod = function(method: string) {
    const arr = [];
    const uniqueValues = new Set();
    for (const item of this) {
        if (!!item && typeof item[method] === 'function') {
            const value = item[method]();
            if (!uniqueValues.has(value)) {
                arr.push(item);
                uniqueValues.add(value);
            }
        }
    }
    return arr;
}

Array.prototype.uniqueByProperty = function(property: string, ignoreNull: boolean = true) {
    const uniqueProps = [];
    const arr = [];
    for (const item of this) {
        if (!!item && item.hasOwnProperty(property)) {
            const uniqueProp = item[property];
            if (!uniqueProps.contains(uniqueProp)) {
                if (ignoreNull) {
                    if (!!item) {
                        uniqueProps.push(uniqueProp);
                        arr.push(item);
                    }
                } else {
                    uniqueProps.push(uniqueProp);
                    arr.push(item);
                }
            }
        }
    }
    return arr;
};

Array.prototype.first = function () {
    const _self = this as Array<string>;
    return _self.find(x => x !== undefined);
};

