import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import ToothUtils from '../utils/tooth-utils';

@Component({
    selector: 'app-tooth-chart',
    templateUrl: './tooth-chart.component.html',
    styleUrls: [
        './tooth-chart.component.scss',
        '../../stylesheet/stylesheet.component.scss'
    ]

})

export class ToothChartComponent implements OnInit {
    id = Math.random().toString(36).substring(2);


    @Input() title: string;
    @Input() selectedTeeth: number[] = [];
    @Input() locale: ToothChartLocale = ToothChartLocale.CAN;
    @Input() editingDisabled: boolean = false;
    @Input() hidePermanent: boolean = false;
    @Input() hidePrimary: boolean = false;
    @Input() style: string;
    @Input() localeButtonsId: string = 'tooth-chart-local-toggle' + this.id;

    @Output() selectedTeethChanged = new EventEmitter<void>();

    public presentingPermanentChart: boolean = true;

    // default teeth quadrants
    public upperLeftTeeth = [11, 12, 13, 14, 15, 16, 17, 18];
    public upperRightTeeth = [21, 22, 23, 24, 25, 26, 27, 28];
    public lowerLeftTeeth = [41, 42, 43, 44, 45, 46, 47, 48];
    public lowerRightTeeth = [31, 32, 33, 34, 35, 36, 37, 38];
    public primaryUpperLeftTeeth = [51, 52, 53, 54, 55];
    public primaryUpperRightTeeth = [61, 62, 63, 64, 65];
    public primaryLowerLeftTeeth = [81, 82, 83, 84, 85];
    public primaryLowerRightTeeth = [71, 72, 73, 74, 75];

    constructor() {
    }

    ngOnInit(): void {
        if (this.hidePermanent && !this.hidePrimary) {
            this.presentingPermanentChart = false;
        }
    }

    localeSelected(l: string) {
        this.locale = (l === 'US') ? ToothChartLocale.US : ToothChartLocale.CAN;
    }

    isToothActive(num: number, loc: string) {
        if (loc === 'CAN') {
            return (this.selectedTeeth?.indexOf(num) ?? -1) > -1;
        } else {
            // convert US to CAN and check selectedTeeth
            return (this.selectedTeeth?.indexOf(ToothUtils.usToCanPermanent(num)) ?? -1) > -1;
        }
    }

    isPrimaryToothActive(num: string, loc: string) {
        if (loc === 'CAN') {
            return (this.selectedTeeth?.indexOf(parseInt(num, null)) ?? -1) > -1;
        } else {
            // convert US to CAN and check selectedTeeth
            return (this.selectedTeeth?.indexOf(ToothUtils.usToCanPrimary(num)) ?? -1) > -1;
        }
    }

    selectTooth(num: number, loc: string) {
        if (!this.editingDisabled) {
            const toothIndex = (loc === 'CAN') ? this.selectedTeeth.indexOf(num) : this.selectedTeeth.indexOf(ToothUtils.usToCanPermanent(num));
            if (toothIndex > -1) {
                // remove the tooth
                this.selectedTeeth.splice(toothIndex, 1);
            } else {
                this.selectedTeeth.push((loc === 'CAN') ? num : ToothUtils.usToCanPermanent(num));
            }
            this.selectedTeeth.sort();
            this.selectedTeethChanged.emit();
        }
    }

    selectPrimaryTooth(num: string, loc: string) {
        if (!this.editingDisabled) {
            const toothIndex = (loc === 'CAN') ? this.selectedTeeth.indexOf(parseInt(num, null)) : this.selectedTeeth.indexOf(ToothUtils.usToCanPrimary(num));
            if (toothIndex > -1) {
                // remove the tooth
                this.selectedTeeth.splice(toothIndex, 1);
            } else {
                this.selectedTeeth.push((loc === 'CAN') ? parseInt(num, null) : ToothUtils.usToCanPrimary(num));
            }
            this.selectedTeeth.sort();
            this.selectedTeethChanged.emit();
        }
    }

    selectAllTeeth(teethInSection: number[]) {
        // check if all teeth are already selected, and unselect
        const overlappingTeeth = this.selectedTeeth.filter(value => teethInSection.includes(value));
        if (teethInSection.length === overlappingTeeth.length) {
            // all teeth are already selected, unselect them
            teethInSection.forEach((t) => {
                const i = this.selectedTeeth.indexOf(t);
                this.selectedTeeth.splice(i, 1);
            });
        } else {
            // select any unselected teeth
            teethInSection.forEach((t) => {
                if (this.selectedTeeth.indexOf(t) === -1) {
                    this.selectedTeeth.push(t);
                }
            });
        }
        this.selectedTeeth.sort();
        this.selectedTeethChanged.emit();
    }
}

enum ToothChartLocale {
    US = 'US',
    CAN = 'CAN'
}
