import {Deserializable, DeserializeHelper} from "../views/shared/utils/json-utils";

export class DataTableColumn implements Deserializable {
    public id: string;
    public title: string;
    public template?: string;
    public type: DatatableColumnType;
    public method: any;
    public secondaryDataMethod: any;
    public disableSorting: boolean;
    public className: string;
    public classNameMethod: any;
    public customSorting: any;
    public tooltip: any;
    public subtext: DataTableColumnSubtext[];
    public differentialMethod: any;

    public onDeserialize() {
        this.subtext = DeserializeHelper.arrayOf(DataTableColumnSubtext, this.subtext);
    }
}

export class DataTableColumnSubtext implements Deserializable {

    public name: string;
    public method: any;

    public onDeserialize() {
    }
}

export enum DatatableColumnType {
    Button = 'Button',
    Text = 'Text',
    IconSrc = 'IconSrc',
    TextWithSubtext = 'TextWithSubtext',
    Checkbox = 'Checkbox',
    Status = 'Status',
    NumberWithDifferential = 'NumberWithDifferential',
    TextWithSpecialist = 'TextWithSpecialist'
}
