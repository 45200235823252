import {FeeGuide} from "./fee-guide.model";
import {FeeGuideProcedure} from "./fee-guide-procedure.model";

export class FeeGuideSpecialty {

    specialtyId: number;
    specialty: string;
    procedures: FeeGuideProcedure[] = [];

    public static createSpecialtiesFromFeeGuide(feeGuide: FeeGuide): FeeGuideSpecialty[] {
        const specialties = feeGuide?.procedures
            ?.map(procedure => {
                return Object.assign(new FeeGuideSpecialty, {
                    specialtyId: procedure?.specialtyId,
                    specialty: procedure?.specialty
                });
            })
            ?.uniqueByProperty('specialtyId')
            ?.sort((a, b) => {
                return a?.specialty?.localeCompare(b?.specialty);
            });

        specialties?.forEach(specialty => {
            specialty.procedures = feeGuide?.procedures?.filter(procedure => {
                return procedure?.specialtyId === specialty?.specialtyId;
            });
        });
        return specialties;
    }

}
