<ng-container *ngIf="!changingPassword">
    <div class="modal-body"
         [class.loading]="loading">
        <app-loading
                [determinateLoading]="false"
                [hidden]="!loading"
                [loadingText]="'Password reset in progress'"
                [fullscreen]="false"
                [backgroundColor]="'#FDFDFD'"
                [color]="'#388EFC'">
        </app-loading>
        <div [hidden]="loading || generatedPassword">
            <div class="modal-title" i18n>Reset Password</div>
            <div class="reset-description">
                <ng-container i18n>A new password will be generated for this user.</ng-container>
                <br>
                <ng-container i18n>Are sure you want to reset this users password?</ng-container>
            </div>
            <div class="footer-button mt-3">
                <button class="teth-button grey-button" (click)="closeModal()" i18n>Cancel</button>
                <button class="teth-button destructive-button ml-3" (click)="resetPassword()" i18n>Reset</button>
            </div>
        </div>
        <div [hidden]="loading || !generatedPassword">
            <div class="modal-title" i18n>New Password Created</div>
            <div class="reset-description">{{getLocalizedPasswordCreatedText()}}</div>
            <div class="reset-field-value mt-3">{{employee?.userName}}</div>
            <div class="reset-field-title" i18n>Username</div>
            <div class="reset-field-value mt-2">{{generatedPassword}}</div>
            <div class="reset-field-title mb-4" i18n>Temporary Password</div>
            <div class="footer-button">
                <button class="teth-button blue-button" (click)="closeModal(true)" i18n>Okay</button>
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="changingPassword">
    <div class="modal-body" [class.loading]="loading">
        <app-loading
                [determinateLoading]="false"
                [hidden]="!loading"
                [loadingText]="loadingText"
                [fullscreen]="false"
                [backgroundColor]="'#FDFDFD'"
                [color]="'#388EFC'">
        </app-loading>
        <div [hidden]="loading">
            <div class="modal-title">{{getLocalizedTitle()}}</div>
            <div class="reset-description mb-3" *ngIf="requireChange" i18n>
                You must update your password in order to sign in.
            </div>
            <div class="reset-description mb-3" i18n>
                Passwords must contain 8 characters, a number, a special character, one lowercase, and one uppercase letter
            </div>
            <form class="teth-textfield-form mb-3"
                  [formGroup]="changePasswordForm"
                  [class.error]="changePasswordForm.controls.currentPassword.invalid && (changePasswordForm.controls.currentPassword.dirty || changePasswordForm.controls.currentPassword.touched)">
                <label class="teth-textfield-label" for="currentPassword">Current Password</label>
                <input class="teth-textfield" type="password" id="currentPassword" name="currentPassword"
                       placeholder="Enter current password"
                       [formControlName]="'currentPassword'"
                       i18n-placeholder>
                <span *ngIf="changePasswordForm.controls.currentPassword.errors?.pattern" class="teth-textfield-error" i18n>Current password does not meet the requirements</span>
                <span *ngIf="changePasswordForm.controls.currentPassword.errors?.required" class="teth-textfield-error" i18n>Current password is required</span>
            </form>
            <form class="teth-textfield-form mb-3"
                  [formGroup]="changePasswordForm"
                  [class.error]="changePasswordForm.controls.newPassword.invalid && (changePasswordForm.controls.newPassword.dirty || changePasswordForm.controls.newPassword.touched)">
                <label class="teth-textfield-label" for="newPassword" i18n>New Password</label>
                <input class="teth-textfield" type="password" id="newPassword" name="newPassword"
                       placeholder="Enter new password"
                       i18n-placeholder
                       [formControlName]="'newPassword'">
                <span *ngIf="changePasswordForm.controls.newPassword.errors?.pattern" class="teth-textfield-error" i18n>New password does not meet the requirements</span>
                <span *ngIf="changePasswordForm.controls.newPassword.errors?.required" class="teth-textfield-error" i18n>New password is required</span>
            </form>
            <form class="teth-textfield-form mb-3"
                  [formGroup]="changePasswordForm"
                  [class.error]="changePasswordForm.controls.confirmPassword.invalid && (changePasswordForm.controls.confirmPassword.dirty || changePasswordForm.controls.confirmPassword.touched)">
                <label class="teth-textfield-label" for="confirmPassword" i18n>Confirm Password</label>
                <input class="teth-textfield" type="password" id="confirmPassword" name="confirmPassword"
                       placeholder="Confirm new password"
                       i18n-placeholder
                       [formControlName]="'confirmPassword'">
                <span *ngIf="changePasswordForm.controls.confirmPassword.value != changePasswordForm.controls.newPassword.value" class="teth-textfield-error" i18n>Does not match new password</span>
                <span *ngIf="changePasswordForm.controls.confirmPassword.errors?.required" class="teth-textfield-error" i18n>Please confirm new password</span>
            </form>
            <div class="footer-button mt-4">
                <button class="teth-button grey-button" (click)="closeModal()" i18n>Close</button>
                <button class="teth-button blue-button ml-3"
                        [class.grey-button]="!this.changePasswordForm.valid"
                        [disabled]="!this.changePasswordForm.valid"
                        (click)="changePassword()" i18n>Save
                </button>
            </div>
        </div>
    </div>
</ng-container>
