import {Injectable} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {BehaviorSubject, combineLatest, EMPTY, forkJoin, Observable} from "rxjs";
import {FeeGuide} from "../models/fee-guide.model";
import {FeeGuideService} from "./fee-guide.service";
import {map, shareReplay, switchMap, take, tap, withLatestFrom} from "rxjs/operators";
import {DeserializeHelper} from "../views/shared/utils/json-utils";
import {FeeGuideProcedure} from "../models/fee-guide-procedure.model";
import {FeeGuideProcedureFee} from "../models/fee-guide-procedure-fee.model";

@Injectable()
export class EditFeeGuideFormService {

    constructor(private feeGuideService: FeeGuideService) {
    }

    private _forSelectedLocationId = new BehaviorSubject<number>(null);
    private forSelectedLocationId$ = this._forSelectedLocationId as Observable<number>;
    setForSelectedLocationId = (id: number) => this._forSelectedLocationId.next(id);

    private _procedureFeeForms = new BehaviorSubject(new Map<string, FormGroup>());
    private procedureFeeForms$ = this._procedureFeeForms as Observable<Map<string, FormGroup>>;

    private _defaultAverageFeeAmountForms = new BehaviorSubject(new Map<string, FormGroup>());
    private defaultAverageFeeAmountForms$ = this._defaultAverageFeeAmountForms as Observable<Map<string, FormGroup>>;

    private _dateRangeForm = new BehaviorSubject<FormGroup>(null);
    private dateRangeForm$ = this._dateRangeForm as Observable<FormGroup>;
    setDateRangeForm = (form: FormGroup) => this._dateRangeForm.next(form);

    private _descriptionForm = new BehaviorSubject<FormGroup>(null);
    public descriptionForm$ = this._descriptionForm as Observable<FormGroup>;
    setDescriptionForm = (form: FormGroup) => this._descriptionForm.next(form);

    private _feeGuide = new BehaviorSubject<FeeGuide>(null);
    public feeGuide$ = this._feeGuide as Observable<FeeGuide>;
    setFeeGuide(feeGuide: FeeGuide): void {
        this.clearAllForms();
        this._feeGuide.next(feeGuide);
    }

    private procedureFormsHaveChanges$ = combineLatest([
        this.procedureFeeForms$,
        this.defaultAverageFeeAmountForms$
    ]).pipe(
        map(([procedureFeeFormsMap, defaultAverageFeeForms]) => {
            return !!procedureFeeFormsMap?.size || !!defaultAverageFeeForms?.size;
        })
    );

    public formsHaveChanges$ = combineLatest([
        this.procedureFormsHaveChanges$,
        this.dateRangeForm$
    ]).pipe(
        map(([procedureFormChanges, dateRangeForm ]) => {
            return procedureFormChanges || !!dateRangeForm;
        })
    );

    private _invalidForms = new BehaviorSubject(new Set<FormGroup>());
    public allFormsAreValid$ = this._invalidForms.pipe(
        map(set => set?.size === 0)
    );

    public queuedFeeGuideForReview$ = combineLatest([
        this.feeGuide$,
        this.dateRangeForm$,
        this.procedureFeeForms$,
        this.defaultAverageFeeAmountForms$
    ]).pipe(
        map(([feeGuide, dateRangeForm, procedureFeeFormsMap, defaultAverageFeeForms]) => {
            return this.extractChangesFromFormsAsFeeGuide(
                dateRangeForm,
                feeGuide,
                procedureFeeFormsMap,
                defaultAverageFeeForms
            );
        }),
        shareReplay({ refCount: true, bufferSize: 1 })
    );

    private extractChangesFromFormsAsFeeGuide(
        dateRangeForm: FormGroup,
        feeGuide: FeeGuide,
        procedureFeeForms: Map<string, FormGroup>,
        defaultAverageForms: Map<string, FormGroup>
    ) {
        const feeGuideWithOnlyChanges = new FeeGuide();
        this.setChangesDateRange(dateRangeForm, feeGuide, feeGuideWithOnlyChanges);

        procedureFeeForms?.forEach((form, id) => {
            this.setChangesProcedureFees(
                form,
                id,
                feeGuide,
                feeGuideWithOnlyChanges
            );
        });

        defaultAverageForms?.forEach((form, id) => {
            this.setChangesDefaultAverageFeeAmounts(
                form,
                id,
                feeGuide,
                feeGuideWithOnlyChanges
            );
        });

        return feeGuideWithOnlyChanges;
    }

    private setChangesDateRange(
        dateRangeForm: FormGroup,
        feeGuide: FeeGuide,
        feeGuideWithOnlyChanges: FeeGuide
    ): void {
        if (dateRangeForm) {
            Object.assign(feeGuideWithOnlyChanges, dateRangeForm.value);
        } else {
            feeGuideWithOnlyChanges.startDate = feeGuide?.startDate;
            feeGuideWithOnlyChanges.endDate = feeGuide?.endDate;
        }
    }

    private setChangesProcedureFees(
        form: FormGroup,
        id: string,
        feeGuide: FeeGuide,
        feeGuideWithOnlyChanges: FeeGuide
    ): void {
        const feeGuideWithOnlyChangesContainsProcedure = feeGuideWithOnlyChanges?.procedures?.some(proc => {
            return proc?.id === form?.get('feeGuideProcedureId').value;
        });
        if (!feeGuideWithOnlyChangesContainsProcedure) {
            const procedure = feeGuide?.procedures?.find(proc => proc?.fees.some(fee => fee?.id === id));
            const procedureCopy = DeserializeHelper.deserializeToInstance(FeeGuideProcedure, procedure);
            procedureCopy.defaultAverageFeeAmount = null;
            procedureCopy.fees = [];
            const fee = procedure?.fees?.find(fee => fee?.id === id);
            const feeCopy = DeserializeHelper.deserializeToInstance(FeeGuideProcedureFee, fee);
            Object.assign(feeCopy, form.value);
            procedureCopy.fees.push(feeCopy);
            feeGuideWithOnlyChanges?.procedures?.push(procedureCopy);
        } else {
            const procedure = feeGuideWithOnlyChanges?.procedures?.find(proc => {
                return proc?.id === form.get('feeGuideProcedureId').value;
            });
            const fee = procedure?.fees?.find(fee => fee?.id === id);
            const feeCopy = DeserializeHelper.deserializeToInstance(FeeGuideProcedureFee, fee);
            Object.assign(feeCopy, form.value);
            procedure?.fees?.push(feeCopy);
        }
    }

    private setChangesDefaultAverageFeeAmounts(
        form: FormGroup,
        id: string,
        feeGuide: FeeGuide,
        feeGuideWithOnlyChanges: FeeGuide
    ): void {
        const changedFeeGuideContainsProcedure = feeGuideWithOnlyChanges?.procedures?.some(proc => {
            return proc?.id === id;
        });
        if (changedFeeGuideContainsProcedure) {
            const procedure = feeGuideWithOnlyChanges?.procedures?.find(proc => proc?.id === id);
            procedure.defaultAverageFeeAmount = form.value.defaultAverageFeeAmount;
        } else {
            const procedure = feeGuide?.procedures?.find(proc => proc?.id === id);
            const procedureCopy = DeserializeHelper.deserializeToInstance(FeeGuideProcedure, procedure);
            if (procedureCopy) {
                procedureCopy.fees = [];
                procedureCopy.defaultAverageFeeAmount = form.value.defaultAverageFeeAmount;
                feeGuideWithOnlyChanges?.procedures?.push(procedureCopy);
            }
        }
    }

    public addDefaultAverageFeeAmountFormToMap(form: FormGroup): void {
        this.defaultAverageFeeAmountForms$.once((map) => {
            const mapCopy = new Map(map);
            mapCopy.set(form?.value?.id, form);
            this._defaultAverageFeeAmountForms.next(mapCopy);
        });
    }

    public addProcedureFeeFormToMap(form: FormGroup): void {
        this.procedureFeeForms$.once((map) => {
            const mapCopy = new Map(map);
            mapCopy.set(form?.value?.id, form);
            this._procedureFeeForms.next(mapCopy);
        });
    }

    public addFormToInvalidForms(form: FormGroup): void {
        this._invalidForms.once((set) => {
            const setCopy = new Set(set);
            setCopy.add(form);
            this._invalidForms.next(setCopy);
        });
    }

    public removeFormFromInvalidForms(form: FormGroup): void {
        this._invalidForms.once((set) => {
            const setCopy = new Set(set);
            setCopy.delete(form);
            this._invalidForms.next(setCopy);
        });
    }

    public publishChanges(): Observable<any>{
        return combineLatest([
            this.feeGuide$,
            this.dateRangeForm$,
            this.defaultAverageFeeAmountForms$,
            this.procedureFeeForms$,
            this.descriptionForm$,
        ]).pipe(
            take(1),
            withLatestFrom(this.forSelectedLocationId$),
            switchMap(([
                           [
                               feeGuide,
                               dateRangeForm,
                               averageFeeAmountFormsMap,
                               procedureFeeFormsMap,
                               descriptionForm,
                           ],
                            selectedLocationId
                       ]) => {
                const apiCalls: Observable<any>[] = [];
                if (procedureFeeFormsMap?.size || averageFeeAmountFormsMap?.size) {
                    const constructedProcedures = this.constructUpdatedFeeGuideProcedures(
                        averageFeeAmountFormsMap,
                        procedureFeeFormsMap,
                        feeGuide
                    ) ?? [];
                    if (!!selectedLocationId) {
                        apiCalls.push(
                            ...constructedProcedures?.map(proc => {
                                return this.feeGuideService.updateLocationProcedure(proc, selectedLocationId)
                            })
                        );
                    } else {
                        apiCalls.push(
                            ...constructedProcedures?.map(proc => {
                                return this.feeGuideService.updateProcedure(proc);
                            })
                        );
                    }
                }
                if (descriptionForm?.valid && !descriptionForm?.disabled) {
                    apiCalls.push(this.feeGuideService.createChangeLog(feeGuide?.id, descriptionForm.value));
                }
                if (dateRangeForm) {
                    const feeGuideCopy = DeserializeHelper.deserializeToInstance(FeeGuide, feeGuide);
                    Object.assign(feeGuideCopy, dateRangeForm.value);
                    apiCalls.push(this.feeGuideService.updateFeeGuide(feeGuideCopy));
                }
                return apiCalls.length ? forkJoin(apiCalls) : EMPTY;
            }),
            tap(() => this.clearAllForms()),
        );
    }

    private constructUpdatedFeeGuideProcedures(
        averageAmountForms: Map<string, FormGroup>,
        feeCodeForms : Map<string, FormGroup>,
        feeGuide: FeeGuide
    ): FeeGuideProcedure[] {
        const updatedProcedures = [];
        feeCodeForms?.forEach((form) => {
            if (updatedProcedures?.some(proc => proc?.id === form?.value?.feeGuideProcedureId)) {
                const procedure = updatedProcedures?.find(proc => proc?.id === form?.value?.feeGuideProcedureId);
                const fee = procedure?.fees?.find(fee => fee?.id === form?.value?.id);
                Object.assign(fee, form?.value);
            } else {
                const procedure = feeGuide?.procedures?.find(proc => {
                    return proc?.id === form?.value?.feeGuideProcedureId;
                });
                const procedureCopy = DeserializeHelper.deserializeToInstance(FeeGuideProcedure, procedure);
                const fee = procedureCopy?.fees?.find(fee => fee?.id === form?.value?.id);
                Object.assign(fee, form?.value);
                updatedProcedures.push(procedureCopy);
            }
        });
        averageAmountForms?.forEach((form) => {
            if (updatedProcedures?.some(proc => proc?.id === form?.value?.id)) {
                const procedure = updatedProcedures?.find(proc => proc?.id === form?.value?.id);
                Object.assign(procedure, form?.value);
            } else {
                const procedure = feeGuide?.procedures?.find(proc => proc?.id === form?.value?.id);
                const procedureCopy = DeserializeHelper.deserializeToInstance(FeeGuideProcedure, procedure);
                Object.assign(procedureCopy, form?.value);
                updatedProcedures.push(procedureCopy);
            }
        });
        return updatedProcedures;
    }

    private clearAllForms(): void {
        this._dateRangeForm.next(null);
        this._invalidForms.next(new Set<FormGroup>());
        this._procedureFeeForms.next(new Map<string, FormGroup>());
        this._defaultAverageFeeAmountForms.next(new Map<string, FormGroup>());
        this._descriptionForm.next(null);
    }

}
