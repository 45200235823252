import {AfterViewInit, ChangeDetectorRef, Component, EventEmitter, OnInit} from '@angular/core';
import {TimeRangeFilter} from '../../../../models/time-range-filter';
import {DatePickerNavigationType, DatePickerOutput} from '../date-picker/date-picker.component';
import {NgbActiveModal, NgbDate} from '@ng-bootstrap/ng-bootstrap';
import DateUtils from '../../utils/date-utils';

@Component({
    selector: 'app-date-range-picker-modal',
    templateUrl: './date-range-picker-modal.component.html',
    styleUrls: ['./date-range-picker-modal.component.scss', '../../../stylesheet/stylesheet.component.scss']
})
export class DateRangePickerModalComponent implements OnInit, AfterViewInit {

    tempCustomTimeRange: TimeRangeFilter = new TimeRangeFilter();
    enableDatePickerContinueButton: boolean = false;
    datePickerNavTypes = DatePickerNavigationType;
    datePickerOutputs = DatePickerOutput;
    clearDateSelection = new EventEmitter();

    static defaultModalOptions(): any {
        return {backdrop: 'static', centered: true, windowClass: 'date-picker-modal'};
    }

    constructor(private activeModal: NgbActiveModal,
                private cd: ChangeDetectorRef) {
    }

    ngOnInit(): void {
    }

    ngAfterViewInit() {
        this.cd.detectChanges();
    }

    public handleCustomDateRangeSelection(dates: NgbDate[]) {
        this.enableDatePickerContinueButton = dates.length === 2;
        if (dates.length === 2) {
            this.tempCustomTimeRange.startDate = DateUtils.startOfDay(DateUtils.getDateFromNgbDate(dates[0]));
            this.tempCustomTimeRange.endDate = DateUtils.endOfDay(DateUtils.getDateFromNgbDate(dates[1]));
        }
    }

    public cancelCustomDateRangePressed() {
        this.activeModal.dismiss();
    }

    public continueCustomDateRangePressed() {
        this.activeModal.close(this.tempCustomTimeRange);
    }
}
