import {Deserializable} from "../views/shared/utils/json-utils";

export class ProcedureVariant implements Deserializable {

    public id: number;
    public name: string;
    public procedureTypeId: number;
    public procedureType: string;
    public updatedDate: string;
    public updatedById: number;
    public updatedBy: string;
    public createdDate: string;
    public createdById: number;
    public createdBy: string;

    onDeserialize() {
    }

}
