import {Component, OnInit} from '@angular/core';
import {ReferralService} from '../../../services/referral.service';
import {Location as _Location} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {SessionService} from '../../../services/session.service';
import {EntityService} from '../../../services/entity.service';
import {CorrespondenceType} from '../../../models/correspondence-type.model';
import {HydratedCorrespondence} from '../../../models/hydrated-correspondence';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {ImagePreviewModalComponent} from '../../shared/image-preview-modal/image-preview-modal.component';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ToastrService} from 'ngx-toastr';
import {FileService} from '../../../services/file.service';
import AttachmentUtils from '../../shared/utils/attachment-utils';
import {Attachment} from '../../../models/attachment.model';
import {Correspondence} from '../../../models/correspondence.model';
import {ReferralStage} from '../../../models/referral-stage';
import DateUtils from '../../shared/utils/date-utils';
import {Observable} from 'rxjs';
import {HttpHeaders, HttpClient} from '@angular/common/http';
import {PermissionService} from '../../../services/permission.service';
import {CorrespondenceStatus} from '../../../models/correspondence-status.model';
import {Referral} from '../../../models/referral.model';
import '../../shared/utils/observable.extensions';
import {DateService} from '../../../services/date.service';
import {Patient} from '../../../models/patient.model';


@Component({
    selector: 'app-individual-correspondence',
    templateUrl: './individual-correspondence.component.html',
    styleUrls: ['./individual-correspondence.component.scss', '../../stylesheet/stylesheet.component.scss']
})
export class IndividualCorrespondenceComponent implements OnInit {

    correspondenceId: string = null;
    correspondence: HydratedCorrespondence = null;
    correspondenceTypes: CorrespondenceType[] = [];
    letterAttachment: Attachment;
    letterAttachmentSrc: string | SafeResourceUrl;
    attachmentUtils = AttachmentUtils;
    otherCorrespondence: Correspondence[];
    referralStageCorrespondenceDetails: ReferralStage[];
    canApproveCorrespondence = false;
    shownInModal = false;
    iFrameLoaded: boolean = false;
    referral: Referral;
    referralPatient: Patient;
    dateUtils = DateUtils;
    awaitingRequests = 0;
    loadingText = $localize`Loading Correspondence`;
    incomingReferral: boolean;

    constructor(private referralService: ReferralService,
                private _location: _Location,
                private router: Router,
                private activatedRoute: ActivatedRoute,
                private sessionService: SessionService,
                private entityService: EntityService,
                private sanitizer: DomSanitizer,
                private fileService: FileService,
                private modalService: NgbModal,
                private activeModal: NgbActiveModal,
                private toastr: ToastrService,
                private permissionService: PermissionService,
                private dateService: DateService,
                private http: HttpClient) {
    }

    ngOnInit(): void {
        this.setupBindings();
    }

    getTitle(): string {
        return $localize`${this.correspondence?.correspondenceType}:correspondenceType: Correspondence`;
    }

    setupBindings() {
        this.activatedRoute.parent?.data.subscribe(d => {
            this.incomingReferral = d.incomingReferrals;
            this.activatedRoute.params.subscribe(p => {
                this.correspondenceId = p['correspondenceId'];
                if (this.correspondenceId) {
                    this.loadCorrespondence();
                }
            });
        });

        this.entityService.correspondenceTypes$.subscribe(c => {
            this.correspondenceTypes = c ?? [];
        });
    }

    initWithCorrespondenceId(id: string, incomingReferral: boolean) {
        this.incomingReferral = incomingReferral;
        this.shownInModal = true;
        this.correspondenceId = id;
        this.loadCorrespondence();
    }

    loadCorrespondence() {
        this.awaitingRequests++;
        this.referralService.getHydratedCorrespondence(this.correspondenceId).subscribe(c => {
            this.awaitingRequests--;
            this.correspondence = c;
            this.chooseLetterFromAttachments();
            this.loadReferral(c.referralId);
            this.loadOtherCorrespondence();
            this.checkIfUserCanApprove();
        }, error => {
            console.log(error);
            this.awaitingRequests--;
        });
    }

    checkIfUserCanApprove() {
        this.sessionService.sessionContainer.firstNotNull().subscribe(s => {
            this.canApproveCorrespondence = this.incomingReferral ? s.employee.id === this.correspondence.assignedSpecialistId : true;
        });
    }

    chooseLetterFromAttachments() {
        this.letterAttachment = null;
        this.letterAttachmentSrc = null;
        const letterIndex = this.correspondence.attachments.findIndex(a => a.mediaType === 'application/pdf'
            || a.mediaType === 'binary/octet-stream');
        if (letterIndex > -1) {
            this.letterAttachment = this.correspondence.attachments[letterIndex];
            this.letterAttachmentSrc = this.sanitizer.bypassSecurityTrustResourceUrl
            (this.letterAttachment.links.find(l => l.size === 'original').presignedUrl);
            this.correspondence.attachments.splice(letterIndex, 1);
        } else {
            this.letterAttachmentSrc = null;
        }
    }

    loadOtherCorrespondence() {
        if (this.correspondence) {
            this.awaitingRequests++;
            this.referralService.getReferralCorrespondence(this.correspondence.referralId).subscribe(oc => {
                this.awaitingRequests--;
                this.otherCorrespondence = oc.filter(c => c.id !== this.correspondence.id);
                this.otherCorrespondence.push(this.correspondence);
                this.setupReferralStageDetails();
            }, error => {
                console.log(error);
                this.awaitingRequests--;
            });
        }
    }

    loadReferral(id: string) {
        this.awaitingRequests++;
        this.referralService.getHydratedReferral(id).subscribe(r => {
            this.referral = r;
            this.referralPatient = this.incomingReferral ? (r.referredPatient ?? r.referringPatient) : r.referringPatient;
            this.awaitingRequests--;
        }, error => {
            console.log(error);
            this.awaitingRequests--;
        });
    }

    isCorrespondenceApprovedBySpecialist(correspondenceId: string): boolean {
        const correspondence = this.otherCorrespondence.find(c => c.id === correspondenceId);
        if (correspondence == null) {
            return false;
        }
        return correspondence.referredCorrespondenceStatusTypeId === CorrespondenceStatus.ApprovedId;
    }

    handleViewCorrespondenceClick(i: number) {
        const stageClicked = this.referralStageCorrespondenceDetails[i];
        this.router.navigate([stageClicked.correspondenceId], {relativeTo: this.activatedRoute.parent});
    }

    setupReferralStageDetails() {
        this.awaitingRequests++;
        this.referralStageCorrespondenceDetails = [];
        const correspondenceIds = [];
        this.otherCorrespondence.sort((a, b) => a.createdDate.toString().localeCompare(b.createdDate.toString()));

        this.otherCorrespondence.forEach(correspondence => {
            const stage = new ReferralStage();
            stage.active = true;
            if (!correspondenceIds.includes(correspondence.correspondenceTypeId)) {
                stage.title = correspondence.correspondenceType;
                stage.iconName = stage.active ? 'check-in-circle.svg' : 'urgent.svg';
                correspondenceIds.push(correspondence.correspondenceTypeId);
            }
            stage.correspondenceId = correspondence.id;
            stage.subtitle = this.getCorrespondenceSubtitle(correspondence.id);
            stage.dateCreated = correspondence.createdDate;
            stage.correspondenceType = this.correspondenceTypes.find(ct => ct.id === correspondence.correspondenceTypeId);
            if (this.incomingReferral || correspondence.referredCorrespondenceStatusTypeId === CorrespondenceStatus.ApprovedId) {
                this.referralStageCorrespondenceDetails.push(stage);
            }
        });
        // sorting by correspondence type id first, and then by date
        this.referralStageCorrespondenceDetails.sort((a, b) => a.correspondenceType.id - b.correspondenceType.id);
        this.referralStageCorrespondenceDetails.sort((a, b) => a.dateCreated.toString().localeCompare(b.dateCreated.toString()));
        this.awaitingRequests--;
    }

    getCorrespondenceSubtitle(correspondenceId: String) {
        const correspondence = this.otherCorrespondence.find(c => c.id === correspondenceId);
        return $localize`${correspondence.correspondenceType}:correspondenceType: correspondence sent on ${DateUtils.formatDateTimeToReadableString(correspondence.createdDate)}:date:.`;
    }

    shortDateFormat(date: Date): string {
        return this.dateService.formatDateToString(date);
    }

    goBack() {
        if (this.shownInModal) {
            this.activeModal.close();
        } else {
            this._location.back();
        }
    }

    attachmentClicked(attachment: Attachment) {
        if (AttachmentUtils.canPreview(attachment)) {
            this.showImagePreview(attachment);
        } else {
            this.downloadAttachment(attachment);
        }
    }

    showImagePreview(attachment: Attachment) {
        const attachmentUrl = attachment.links.find(l => l.size === 'original').presignedUrl;
        this.generateBlobURL(attachmentUrl, attachment.mediaType).subscribe(data => {
            const blob = new Blob([data], {type: attachment.mediaType});
            const blobUrl = window.URL.createObjectURL(blob);
            const modalRef = this.modalService.open(ImagePreviewModalComponent, {size: 'lg', backdrop: 'static'});
            const isPdf = attachment.mediaType === 'application/pdf';
            const isTiff = attachment.mediaType === 'image/tiff';
            (modalRef.componentInstance as ImagePreviewModalComponent).initWithUrl(attachmentUrl, attachment.fileName, isPdf, isTiff, blobUrl, AttachmentUtils.canPrint(attachment));
            modalRef.result.then(r => {
            }, () => {
            });
        });
    }

    downloadAttachment(attachment: Attachment) {
        const url = attachment.links.find(l => l.size === 'original').presignedUrl;
        this.generateBlobURL(url, attachment.mediaType).subscribe(data => {
            const blob = new Blob([data], {type: attachment.mediaType});
            const blobUrl = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = blobUrl;
            a.download = attachment.fileName;
            a.setAttribute('target', '_blank');
            a.click();
        });
    }

    correspondenceIsApproved(): boolean {
        if (this.incomingReferral) {
            return this.correspondence.referredCorrespondenceStatusTypeId === CorrespondenceStatus.ApprovedId;
        } else {
            return this.correspondence.referringCorrespondenceStatusTypeId === CorrespondenceStatus.ApprovedId;
        }
    }

    approveCorrespondence() {
        const updatedCorrespondence = Object.assign(new HydratedCorrespondence(), this.correspondence);
        if (this.incomingReferral) {
            updatedCorrespondence.referredCorrespondenceStatusTypeId = CorrespondenceStatus.ApprovedId;
        } else {
            updatedCorrespondence.referringCorrespondenceStatusTypeId = CorrespondenceStatus.ApprovedId;
        }
        this.awaitingRequests++;
        this.referralService.updateReferralCorrespondence(updatedCorrespondence).subscribe(res => {
            this.toastr.success($localize`Approved Successfully`);
            this.loadCorrespondence();
            this.awaitingRequests--;
        }, error => {
            this.toastr.error(error.message, $localize`Approve Correspondence Failed`);
            this.loadCorrespondence();
            this.awaitingRequests--;
        });
    }

    generateBlobURL(presignedUrl: string, mediaType: string): Observable<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Accept': mediaType,
        });
        return this.http.get(presignedUrl, {headers: headers, responseType: 'blob'});
    }

    doneLoading() {
        this.iFrameLoaded = true;
    }

    viewReferral() {
        const path = (this.activatedRoute.parent.url as any).value[0]?.path;
        let referralPath = 'manage-referrals-incoming';
        if (path === 'correspondence-outgoing-referrals') {
            referralPath = 'manage-referrals-outgoing';
        }
        this.router.navigate([`${referralPath}/${this.referral.id}`]);
    }

}
