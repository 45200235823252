import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {AccountService} from './account.service';

@Injectable({
    providedIn: 'root'
})

export class AuthGuardService implements CanActivate {

    constructor(public accountService: AccountService, public _router: Router) {
    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        const observer = this.accountService.isAuthenticated().map( r => r != null);
        observer.subscribe(res => {
            if (!res) {
                console.log('redirect to sign-in');
                this._router.navigate(['/sign-in']);
            }
        });
        if (this.accountService.refreshing) {
            console.log('refreshing the session not hitting isAuthenticated()');
        }

        return observer;
    }
}
