import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {LanguageCode} from '../models/languageCode';

@Injectable({
    providedIn: 'root'
})
export class SendGridService {

    constructor(private http: HttpClient) {
    }

    getTemplate(): Observable<any> {
        let headers = new HttpHeaders();
        const templateId = $localize.locale === 'fr' ? 'd-af8518e9cd584dc5b5d8dd967677c7bd' : 'd-37cd5b71a31747fbb4e6074c37841bd5';
        const token = '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';
        headers = headers.set('authorization', `Bearer ${token}`);
        return this.http.get<any>(`https://api.sendgrid.com/v3/templates/${templateId}`, {headers: headers});
    }
}
