<div class="mt-4">
    <mat-tab-group
            dynamicHeight
            mat-align-tabs="start"
            [selectedIndex]="selectedIndex$ | async"
            [animationDuration]="'0ms'"
            [disableRipple]="true"
            (selectedIndexChange)="handleSelectedIndexChange($event)">
        <mat-tab *ngFor="let specialtyObj of specialties$ | async" [label]="specialtyObj?.specialty">
            <div [hidden]="!loadingOptions.isLoading" class="white-background"></div>
            <app-loading
                    [hidden]="!loadingOptions.isLoading"
                    [backgroundColor]="loadingOptions.backgroundColor"
                    [color]="loadingOptions.color"
                    [spinnerColor]="loadingOptions.spinnerColor">
            </app-loading>
            <ng-container *ngTemplateOutlet="tabContent; context: { $implicit: specialtyObj }"></ng-container>
        </mat-tab>
    </mat-tab-group>
</div>
