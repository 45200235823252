<div class="info-card-wrapper">
    <div class="row" *ngIf="title && title !== ''">
        <div class="col col-lg-12">
            <div class="info-card-section-title">
                {{ title }}
            </div>
        </div>
    </div>

    <div class="row info-card-container">
        <div class="col" *ngFor="let infoCard of infoCards; let i = index;"
             [hidden]="infoCard.hidden"
             [ngClass]="infoCard.classNames">
            <div class="info-card"
                 (mouseover)="infoCard.hovered = true"
                 (mouseout)="infoCard.hovered = false"
                 (click)="cardClicked.emit(infoCard)"
                 [ngStyle]="{ 'background-color': getBackgroundColor(i) }">
                <div class="info-card-icon-container"
                     *ngIf="infoCard.type === cardTypes.InfoWithIcon">
                    <div class="info-card-icon-box"
                         [ngStyle]="{ 'background-color': getIconBackgroundColor(i) }">
                        <img src="assets/img/icons/{{ infoCard.iconName }}" alt="" class="info-card-icon">
                    </div>
                </div>
                <div class="info-card-content-container">
                    <div class="info-card-title"
                         [ngStyle]="{ 'color': getTextColor(i), 'opacity': 0.75 }">
                        {{ infoCard.title }}
                    </div>
                    <div class="info-card-content">
                        <div class="info-card-value"
                             [ngStyle]="{ 'color': getTextColor(i) }">
                            {{ infoCard.value }}
                        </div>
                        <div class="info-card-percent-change"
                             *ngIf="infoCard.type === cardTypes.InfoWithPercentChange"
                             [ngStyle]="{ 'color': getPercentChangeTextColor(i), 'background-color': getPercentageChangeBoxColor(i) }">
                            <img [hidden]="getPercentChangeHidden(i)" [src]="'assets/img/icons/' + getPercentChangeIconName(i)" alt="trend-icon">
                            {{ formatPercentText(i) }}
                        </div>
                    </div>

                    <div class="info-card-cta"
                         *ngIf="infoCard.type === cardTypes.InfoWithCTA && infoCard.ctaTitle !== ''">
                        <div class="info-card-cta-text">
                            {{ infoCard.ctaTitle }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

