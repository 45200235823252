import {Directive, ElementRef, Input, OnChanges, OnDestroy, SimpleChange, SimpleChanges} from '@angular/core';
import {ContentScrollService} from "../../../services/content-scroll.service";

@Directive({
  selector: '[appScrollToTarget]'
})
export class ScrollToTargetDirective implements OnChanges, OnDestroy {

  @Input() scrollToKey: string;

  constructor(private contentScrollService: ContentScrollService, private elementRef: ElementRef) {
  }


  private handleKeyChange(key: SimpleChange): void {
    const prevKey = key.previousValue;
    const currKey = key.currentValue;
    if (!prevKey && !!currKey) {
      this.contentScrollService.addScrollTargetToMap(this.scrollToKey, this.elementRef);
    } else if (!!prevKey && !currKey) {
      this.contentScrollService.removeScrollTargetFromMap(prevKey);
    } else if (!!prevKey && !!currKey) {
      this.contentScrollService.removeScrollTargetFromMap(prevKey);
      this.contentScrollService.addScrollTargetToMap(this.scrollToKey, this.elementRef);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.scrollToKey) {
      this.handleKeyChange(changes.scrollToKey);
    }
  }

  ngOnDestroy() {
    this.contentScrollService.removeScrollTargetFromMap(this.scrollToKey);
  }

}
