import {Deserializable, Logable} from '../views/shared/utils/json-utils';

export class Message implements Deserializable, Logable {
    public id: string;
    public referralId: string;
    public content: string;
    public createdDate: Date;
    public createdById: number;
    public createdBy: string;
    public isLogItem: boolean = false;

    onDeserialize() {
    }

}

export class NewMessage implements Deserializable {
    public content: string;
    public createdById: number;

    onDeserialize() {
    }
}
