import {Deserializable} from '../views/shared/utils/json-utils';

export class LocationIntegration implements Deserializable {
    public id: number;
    public integrationTypeId: number;
    public clientId: string;
    public clientSecret: string;
    public practiceManagementIdentifier: string;
    public enabled: boolean;

    onDeserialize() {
    }
}
