import {Deserializable} from '../views/shared/utils/json-utils';
import {Patient} from './patient.model';
import * as moment from 'moment';
import {Attachment} from './attachment.model';
import {Message} from './message.model';
import {
    LocationCrownsPlusSurfaceTextureType,
    LocationImplantsSurfaceTextureType,
    SurfaceTextureTypes
} from './surface-texture-types.model';
import {ReferralStatus} from './referral-status.model';

export enum LabReferralType {
    CrownsPlus = 'crownsplusreferrals',
    Implants = 'implantsreferrals',
    Removables = 'removablesreferrals',
    Orthodontics = 'orthodonticsreferrals'
}

export class LabReferralPreview implements Deserializable {
    public id: string;
    public patientId: string;
    public patientName: string;
    public patientGender: string;
    public patientGenderId: number;
    public referringCompanyId: number;
    public referringLocationId: number;
    public referringLocation: string;
    public referringEmployeeId: number;
    public referringEmployee: string;
    public dentalLabCompanyId: number;
    public dentalLabLocationId: number;
    public dentalLabLocation: string;
    public locationDentalLabsProductTypeId: number;
    public dentalLabsReferralFileType: string;
    public dentalLabsProductTypeId: number;
    public dentalLabsProductType: string;
    public dentalLabsReferralFileTypeId: number;
    public statusTypeId: number;
    public status: string;
    public deliveryDate: Date;
    public lastReferralToday: boolean;
    public createdDate: Date;
    public createdById: number;
    public createdBy: string;
    public isUrgent: boolean;
    public highSortPriority: boolean = false;

    // Not From API
    public patient: Patient;


    onDeserialize() {
        this.createdDate = moment.utc(this.createdDate.toString()).toDate();
        this.deliveryDate = moment.utc(this.deliveryDate.toString()).toDate();
        this.highSortPriority = this.isUrgent && this.statusTypeId < 4;
    }
}

export class LabReferral extends LabReferralPreview {

    public referringCompany: string;
    public dentalLabCompany: string;
    public deliveryAcceptedDate: Date;
    public deliveryAcceptedById: number;
    public deliveryAcceptedBy: string;
    public teeth: string;
    public otherTeeth: string;
    public comments: string;
    public attachments: Attachment[];
    public messages: Message[];

    onDeserialize() {
        super.onDeserialize();
        if (this.deliveryAcceptedById) {
            this.deliveryAcceptedDate = moment.utc(this?.deliveryAcceptedDate?.toString()).toDate();
        }
    }
}

export class OrthodonticsLabReferral extends LabReferral {

    public applianceTypeId: number;
    public applianceType: string;

    onDeserialize() {
        super.onDeserialize();
    }
}

export class ImplantsLabReferral extends LabReferral {

    public abutmentLevelTypeId: number;
    public abutmentLevelType: string;
    public abutmentOptionTypeId: number;
    public abutmentOptionType: string;
    public angulatedCorrectionTypeId: number;
    public angulatedCorrectionType: string;
    public attachmentTypeId: number;
    public attachmentType: string;
    public barOptionTypeId: number;
    public barOptionType: string;
    public deliveryTypeId: number;
    public deliveryType: string;
    public designOptionTypeId: number;
    public designOptionType: string;
    public incisalTranslucencyTypeId: number;
    public incisalTranslucencyType: string;
    public interproximalContactTypeId: number;
    public interproximalContactType: string;
    public materialOptionTypeId: number;
    public materialOptionType: string;
    public occlusalContactTypeId: number;
    public occlusalContactType: string;
    public ponticDesignTypeId: number;
    public ponticDesignType: string;
    public removableTypeId: number;
    public removableType: string;
    public restorationTypeId: number;
    public restorationType: string;
    public restorativeOptionTypeId: number;
    public restorativeOptionType: string;
    public surfaceTextureTypes: LocationImplantsSurfaceTextureType[];
    public surfaceTextureType: string;
    public toothMorphologyTypeId: number;
    public toothMorphologyType: string;
    public customShade: boolean;
    public shade: string;

    onDeserialize() {
        super.onDeserialize();
    }
}

export class CrownsPlusLabReferral extends LabReferral {

    public restorationTypeId: number;
    public restorationType: string;
    public productTypeId: number;
    public productType: string;
    public ceramicsTypeId: number;
    public ceramicsType: string;
    public deliveryTypeId: number;
    public deliveryType: string;
    public shade: string;
    public stumpShade: string;
    public customShade: boolean;
    public marginTypeId: number;
    public marginType: string;
    public metalTypeId: number;
    public metalType: string;
    public ponticDesignTypeId: number;
    public ponticDesignType: string;
    public occlusalContactTypeId: number;
    public occlusalContactType: string;
    public toothMorphologyTypeId: number;
    public toothMorphologyType: string;
    public surfaceTextureTypes: LocationCrownsPlusSurfaceTextureType[];
    public surfaceTextureType: string;
    public interproximalContactTypeId: number;
    public interproximalContactType: string;
    public incisalTranslucencyTypeId: number;
    public incisalTranslucencyType: string;

    onDeserialize() {
        super.onDeserialize();
    }
}

export class RemovablesLabReferral extends LabReferral {

    public completeDenturesTypeId: number;
    public completeDenturesType: string;
    public deliveryTypeId: number;
    public deliveryType: string;
    public majorConnectorTypeId: number;
    public majorConnectorType: string;
    public materialTypeId: number;
    public materialType: string;
    public partialDentureTypeId: number;
    public partialDentureType: string;
    public restorationTypeId: number;
    public restorationType: string;
    public shade: string;


    onDeserialize() {
        super.onDeserialize();
    }
}

