import {ReferralNotification} from './referral-notification.model';

export class LocationAllNotifications {
    public incoming: LocationNotificationGroup = new LocationNotificationGroup();
    public outgoing: LocationNotificationGroup = new LocationNotificationGroup();
}

export class LocationNotificationGroup {
    public referralsCompleted: ReferralNotification[] = [];
    public referralsUnread: ReferralNotification[] = [];
    public messagesCompleted: ReferralNotification[] = [];
    public messagesUnread: ReferralNotification[] = [];
}

export enum ReferralNotificationType {
    ReferralCreated,
    ReferralUpdated,
    Message,
    All,
}
