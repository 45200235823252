import {Deserializable} from '../views/shared/utils/json-utils';
import {Attachment} from './attachment.model';
import * as moment from 'moment';
import {LanguageCode} from './languageCode';
import {CompanyType} from './company.model';

export class HelpVideo implements Deserializable {
    public id: number;
    public permissionId: number;
    public permission: string;
    public companyTypeId: CompanyType;
    public name: string;
    public helpVideos: LocalizedHelpVideo[];

    public createdDate: Date;
    public createdById: number;
    public createdBy: string;
    public updatedDate: Date;
    public updatedById: number;
    public updatedBy: string;

    onDeserialize() {
        this.createdDate = moment.utc(this.createdDate.toString()).toDate();
        this.updatedDate = moment.utc(this.updatedDate.toString()).toDate();
    }
}

export class LocalizedHelpVideo implements Deserializable {
    public id: number;
    public helpVideoId: number;
    public languageId: LanguageCode;
    public attachmentTypeId: number;
    public description: string;
    public attachment: Attachment;

    public createdDate: Date;
    public createdById: number;
    public createdBy: string;
    public updatedDate: Date;
    public updatedById: number;
    public updatedBy: string;

    onDeserialize() {
        this.createdDate = moment.utc(this.createdDate.toString()).toDate();
        this.updatedDate = moment.utc(this.updatedDate.toString()).toDate();
    }
}

