import {Injectable} from '@angular/core';
import {NgbDatepickerI18n, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';

const I18N_VALUES = {
    'teth': {
        weekdays: [
            $localize`:monday|:M`,
            $localize`:tuesday|:T`,
            $localize`:wednesday|:W`,
            $localize`:thursday|:T`,
            $localize`:friday|:F`,
            $localize`:saturday|:S`,
            $localize`:sunday|:S`],
        months: [
            $localize`January`,
            $localize`February`,
            $localize`March`,
            $localize`April`,
            $localize`May`,
            $localize`June`,
            $localize`July`,
            $localize`August`,
            $localize`September`,
            $localize`October`,
            $localize`November`,
            $localize`December`
        ],
        monthShortNames: [
            $localize`Jan`,
            $localize`Feb`,
            $localize`Mar`,
            $localize`Apr`,
            $localize`May`,
            $localize`Jun`,
            $localize`Jul`,
            $localize`Aug`,
            $localize`Sep`,
            $localize`Oct`,
            $localize`Nov`,
            $localize`Dec`
        ]
    }
};

@Injectable()
export class I18n {
    language = 'teth';
}

@Injectable()
export class CustomDatepickerTetherport extends NgbDatepickerI18n {

    constructor(private _i18n: I18n) {
        super();
    }

    getWeekdayShortName(weekday: number): string {
        return I18N_VALUES[this._i18n.language].weekdays[weekday - 1];
    }
    getMonthShortName(month: number): string {
        return I18N_VALUES[this._i18n.language].monthShortNames[month - 1];
    }
    getMonthFullName(month: number): string {
        return this.getMonthShortName(month);
    }
    getDayAriaLabel(date: NgbDateStruct): string {
        return `${date.day}-${date.month}-${date.year}`;
    }
}

