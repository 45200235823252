<app-loading
        [determinateLoading]="false"
        [hidden]="!loading"
        [loadingText]=""
        [fullscreen]="false"
        [backgroundColor]="'#FDFDFD'"
        [color]="'#388EFC'">
</app-loading>
<div class="modal-body" [hidden]="showPreview">
    <div class="modal-title" i18n>Add Clinics to your Network</div>
    <div class="modal-subtitle" i18n>
        Invite dental clinics in your area to join Tetherport for free and start receiving secure digital referrals.
    </div>
    <div class="preview-button">
        <a [routerLink]="" (click)="showPreview = true" i18n>Preview Email</a>
    </div>
    <div>
        <div class="row">
            <div class="col">
                <form class="px-0 teth-textfield-form mt-3">
                    <textarea class="teth-text-area w-100" id="customMessage" name="customMessage" rows="3"
                              placeholder="Enter custom message to email"
                              i18n-placeholder
                              [(ngModel)]="customTextString"
                              (ngModelChange)="customTextSubject.next($event)"></textarea>
                </form>
            </div>
        </div>
        <div class="divider-line mb-2"></div>
        <div class="row">
            <div class="col">
                <form class="teth-textfield-form px-0"
                      [class.error]="emailControl.invalid && (emailControl.dirty || emailControl.touched)">
                    <label class="teth-textfield-label" for="lastName" i18n>Add individual email addresses</label>
                    <div class="d-flex align-items-center">
                        <input class="teth-textfield flex-grow-1"
                               type="text"
                               [formControl]="emailControl"
                               id="lastName" name="lastName"
                               i18n-placeholder
                               placeholder="Email Address"
                               (keyup.enter)="addEmail()">
                        <img class="add-email-button"
                             alt="plus-xicon"
                             (click)="addEmail()"
                             [src]="'assets/img/icons/plus-circle.svg'">
                    </div>
                    <span class="teth-textfield-error" i18n>Enter a valid email address.</span>
                </form>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col">
                <label class="teth-textfield-label" i18n>
                    Or upload a file containing multiple email addresses
                </label>
                <app-file-upload
                        [files]="files"
                        [fileTypeOverride]="'CSV'"
                        [mimeTypeOverride]="'text/csv'"
                        [hideFilesUploaded]="true"
                        [handleFileAdded]="handleFileAdded.bind(this)">
                </app-file-upload>
            </div>
        </div>
    </div>
    <div class="divider-line mb-3"></div>
    <div class="emails-container">
        <div class="email-pill" *ngFor="let email of emails">
            {{email}}
            <img [src]="'assets/img/icons/remove.svg'" alt="remove" (click)="removeEmail(email)">
        </div>
    </div>
    <div class="divider-line mb-3 mt-auto"></div>
    <div class="footer-button">
        <button class="teth-button grey-button" (click)="closeModal()" i18n>Cancel</button>
        <button class="teth-button blue-button d-flex align-items-center ml-3"
                (click)="sendInvites()"
                [class.send-disabled]="emails.length == 0">
            <img class="send-icon" [src]="'assets/img/icons/paper-airplane.svg'" alt="send">
            <ng-container i18n>Send Invites</ng-container>
        </button>
    </div>
</div>
<div class="modal-body" [hidden]="!showPreview">
    <button class="teth-button white-button back-button"
            (click)="showPreview = false">
        <ng-container i18n>Back</ng-container>
    </button>
    <div class="modal-title" i18n>Email Preview</div>
    <div class="modal-subtitle" i18n>
        This is a preview of the email that will be sent to all of the addresses that you have added. This message
        cannot be edited.
    </div>
    <div class="email-preview mb-3">
        <div [innerHTML]="emailPreviewHtml"></div>
    </div>
    <div class="divider-line mb-3 mt-auto"></div>
    <div class="d-flex justify-content-start">
        <button class="teth-button grey-button" (click)="showPreview = false" i18n>Back</button>
    </div>
</div>
