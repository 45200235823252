import {Component} from '@angular/core';
import {NgbActiveModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {Stage} from '../../../models/stage.model';
import {NgbDate} from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-date';
import DateUtils from '../utils/date-utils';
import * as moment from 'moment';
import {FormArray, FormBuilder, FormGroup} from "@angular/forms";
import {Appointment} from "../../../models/enum/appointment.model";

@Component({
    selector: 'app-status-modal',
    templateUrl: './status-modal.component.html',
    styleUrls: ['../../stylesheet/stylesheet.component.scss', './status-modal.component.scss']
})
export class StatusModalComponent {

    constructor(private activeModal: NgbActiveModal, private fb: FormBuilder) {
    }

    public title: string;
    public stageIndex: number;
    public selectAppointmentDate = false;
    public stage: Stage;
    public appointmentDate: Date;

    public scheduledStatusId = Stage.ScheduledStatusId;

    public form = this.fb.group({
        formArray: this.fb.array([])
    });

    public stageOptions = [
        [
            {
                src: 'assets/img/icons/calendar.svg',
                title: $localize`Consultation Scheduled`,
                id: Stage.ScheduledStatusId
            },
            {
                src: 'assets/img/icons/check-circle.svg',
                title: $localize`Consultation Completed`,
                id: Stage.CompletedStatusId
            },
            {
                src: 'assets/img/icons/check-circle.svg',
                title: $localize`No Consultation Required`,
                id: Stage.NotRequiredStatusId
            },
            {
                src: 'assets/img/icons/stopped.svg',
                title: $localize`Patient Declined Consultation`,
                id: Stage.DeclinedStatusId
            }
        ],
        [
            {
                src: 'assets/img/icons/calendar.svg',
                title: $localize`Treatment Scheduled`,
                id: Stage.ScheduledStatusId
            },
            {
                src: 'assets/img/icons/check-circle.svg',
                title: $localize`Treatment Completed`,
                id: Stage.CompletedStatusId
            },
            {
                src: 'assets/img/icons/check-circle.svg',
                title: $localize`No Treatment Required`,
                id: Stage.NotRequiredStatusId
            },
            {
                src: 'assets/img/icons/stopped.svg',
                title: $localize`Patient Declined Treatment`,
                id: Stage.DeclinedStatusId
            }
        ],
        [
            {
                src: 'assets/img/icons/calendar.svg',
                title: $localize`Follow-Up Scheduled`,
                id: Stage.ScheduledStatusId
            },
            {
                src: 'assets/img/icons/check-circle.svg',
                title: $localize`Follow-Up Completed`,
                id: Stage.CompletedStatusId
            },
            {
                src: 'assets/img/icons/check-circle.svg',
                title: $localize`No Follow-Up Required`,
                id: Stage.NotRequiredStatusId
            },
            {
                src: 'assets/img/icons/stopped.svg',
                title: $localize`Patient Declined Follow-Up`,
                id: Stage.DeclinedStatusId
            }
        ]
    ];

    public static defaultModalOptions(): NgbModalOptions {
        return {size: 'md', backdrop: 'static', centered: true, scrollable: true};
    }

    public closeModal(): void {
        this.activeModal.close();
    }

    public updateClicked(): void {
        const appointments: Appointment[] = [];
        this.formArray.controls.forEach((c) => {
            const appointmentDate = c.get('appointmentDate').value;
            const appointmentTime = c.get('appointmentTime').value;
            const appointment = new Appointment();
            if (appointmentDate && appointmentTime) {
                Object.assign(appointment, c.value)
                const convertedDate = DateUtils.getDateFromNgbDate(appointmentDate);
                const dateString = moment(convertedDate).format('yyyy-MM-DD');
                const dateTimeString = dateString + ' ' + appointmentTime;
                appointment.appointmentDate = moment(dateTimeString)?.toDate();
            } else {
                appointment.referralStageId = this.stage?.id;
            }
            appointments.push(appointment);
        });
        this.activeModal.close(appointments);
    }

    public statusButtonClicked(stageStatusId: number): void {
        if (stageStatusId === Stage.ScheduledStatusId) {
            this.selectAppointmentDate = !this.selectAppointmentDate;
        } else {
            this.selectAppointmentDate = false;
            this.activeModal.close(stageStatusId);
        }
    }

    public initWith(title: string, index: number, stage: Stage): void {
        this.title = title;
        this.stageIndex = index;
        this.stage = stage;
        if (!stage?.appointments?.length) {
            this.addFormControl();
        } else {
            stage?.appointments?.forEach((appt, i) => {
                this.addFormControl();
                if (appt?.appointmentDate) {
                    const utcMoment = moment.utc(appt?.appointmentDate);
                    const timeString = moment(utcMoment).local().format('HH:mm');
                    const ngbDateString = DateUtils.getNgbDateFromDate(appt?.appointmentDate);
                    this.formArray.controls[i].patchValue(appt);
                    this.formArray.controls[i].get('appointmentDate').setValue(ngbDateString);
                    this.formArray.controls[i].get('appointmentTime').setValue(timeString);
                }
            })
        }
    }

    public dateSelected(ngbDate: NgbDate, index: number): void {
        this.formArray.controls[index].get('appointmentDate').setValue(ngbDate);
        const appointmentTimeControl = this.formArray.controls[index].get('appointmentTime');
        if (!appointmentTimeControl.value) {
            appointmentTimeControl.setValue('12:00');
        }
    }

    public dateTimeFormsHaveErrors(): boolean {
        return this.formArray.controls.some((c, i) => {
            return this.dateInputHasError(i);
        });
    }

    private createInputGroup(): FormGroup {
        const formInputs = {
            appointmentDate: [null],
            appointmentTime: [null],
            appointmentDuration: [null],
            comments: [null],
            teeth: [null],
            completed: [null],
        }
        return this.stage
            ? this.fb.group({
                id: [null],
                referralStageId: [this.stage.id],
                ...formInputs
        })
            : this.fb.group({
                ...formInputs
            })
    }

    public get formArray(): FormArray {
        return this.form.get('formArray') as FormArray;
    }

    public addFormControl(): void {
        this.formArray.push(this.createInputGroup());
    }

    public dateInputHasError(index: number): boolean {
        const appointmentDate = this.formArray.controls[index].get('appointmentDate').value;
        const appointmentTime = this.formArray.controls[index].get('appointmentTime').value;
        return (!appointmentDate && appointmentTime);
    }

    public getDateInputRequirementText(index: number): string {
        const appointmentDate = this.formArray.controls[index].get('appointmentDate').value;
        const appointmentTime = this.formArray.controls[index].get('appointmentTime').value;
        if (appointmentTime && !appointmentDate) {
            return $localize`Required`;
        }
        return $localize`Optional`;
    }

    public removeFormControls(i: number): void {
        this.formArray.removeAt(i);
    }

    public resetFormControl(): void {
        const formGroup = this.formArray.controls.first() as FormGroup;
        formGroup.controls.appointmentTime.setValue(null);
        formGroup.controls.appointmentDate.setValue(null);
    }
}
