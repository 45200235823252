import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SharedModule} from '../shared/shared.module';
import {RouterModule} from '@angular/router';
import {SignUpComponent} from './sign-up.component';
import {TextMaskModule} from 'angular2-text-mask';
import { SignUpAgreementModalComponent } from './sign-up-agreement-modal/sign-up-agreement-modal.component';
import {NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    declarations: [SignUpComponent, SignUpAgreementModalComponent],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        RouterModule,
        TextMaskModule,
        NgbTooltipModule,
    ]
})
export class SignUpModule {
}
