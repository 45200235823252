<div class="average-value-container">
    <form [formGroup]="form" class="w-100">
        <div class="d-flex align-items-center" [style.gap.rem]="1.5">
            <div class="average-value-title" i18n>Default Average Value</div>
            <div class="d-flex justify-content-end" [style.flex]="1" [style.gap.rem]="1.5">
                <div class="average-value-input">
                    <label class="teth-textfield-label" for="avgFeeAmt" i18n>Fee Guide</label>
                    <input
                            [formControlName]="'defaultAverageFeeAmount'"
                            currencyMask
                            class="teth-textfield"
                            type="text"
                            id="avgFeeAmt"
                            name="avgFeeAmt"
                            placeholder="--"
                            autocomplete="off"
                            [class.error]="form.statusChanges | async | isInvalidAndTouched : form.controls.defaultAverageFeeAmount"
                            [class.disabled]="disabled"
                            (click)="$event.stopPropagation()">
                    <span class="teth-textfield-error" [style.display]="(form.statusChanges | async | isInvalidAndTouched : form.controls.defaultAverageFeeAmount) ? 'block' : 'none'" i18n>
                    Must be {{ 0 | currency }} or greater and less than {{ 1000000 | currency }}
                </span>
                </div>
                <div *ngIf="locationFeeGuideMode" class="average-value-input">
                    <label class="teth-textfield-label" for="overrideAverage" i18n>Override Fee</label>
                    <input
                            [formControlName]="'overrideDefaultAverageFeeAmount'"
                            currencyMask
                            class="teth-textfield"
                            type="text"
                            id="overrideAverage"
                            name="overrideAverage"
                            placeholder="--"
                            autocomplete="off"
                            [class.error]="form.statusChanges | async | isInvalidAndTouched : form.controls.overrideDefaultAverageFeeAmount"
                            [class.disabled]="disabled"
                            (click)="$event.stopPropagation()">
                    <span class="teth-textfield-error" [style.display]="(form.statusChanges | async | isInvalidAndTouched : form.controls.overrideDefaultAverageFeeAmount) ? 'block' : 'none'" i18n>
                    Must be {{ 0 | currency }} or greater and less than {{ 1000000 | currency }}
                </span>
                </div>
            </div>
        </div>
    </form>
</div>
