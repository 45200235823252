import {environment} from '../../environments/environment';

export const DEFAULT_PAGINATION_TAKE = 50;

export class UrlBuilder {
    static build(route: string, params: Map<string, string> = null): string {
        let url = environment.apiBaseUrl + route;
        if (params) {
            url += this.buildQueryParamsString(params);
        }
        return url;
    }

    static buildQueryParamsString(params: Map<string, string>): string {
        let paramsString = '';
        if (params) {
            paramsString += '?';
            Object.keys(params).forEach(k => {
                paramsString += k + '=' + params[k] + '&';
            });
            // remove the trailing ampersand
            paramsString = paramsString.slice(0, -1);
        }
        return paramsString;
    }
}
