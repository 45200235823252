import { Pipe, PipeTransform } from '@angular/core';
import {GroupOption} from "../../../../../../models/group-option";
import {DataTableExtraFilterGroup} from "../../../../../../models/data-table-options";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";

@Pipe({
    name: 'optionIsSelected'
})
export class OptionIsSelectedPipe implements PipeTransform {

    transform(option: GroupOption, filterGroup$: Observable<DataTableExtraFilterGroup>): Observable<boolean> {
        return filterGroup$.pipe(
            map(filterGroup => {
                return filterGroup?.selectedOptions?.some((selectedOption: GroupOption) => {
                    return selectedOption?.getUniqueIdentifier() === option?.getUniqueIdentifier();
                });
            })
        );
    }

}
