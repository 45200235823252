import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {CorrespondenceComponent} from './correspondence.component';
import {IndividualCorrespondenceComponent} from './individual-correspondence/individual-correspondence.component';

const localizedTitle = () => {
    return $localize`Correspondence`;
};
const routes: Routes = [
    {
        path: '',
        component: CorrespondenceComponent,
        data: {
            title: localizedTitle(),
            cacheRoute: true,
        }
    },
    {
        path: ':correspondenceId',
        component: IndividualCorrespondenceComponent,
        data: {
            title: localizedTitle()
        }
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class CorrespondenceRoutingModule {
}
