import {Procedure} from './procedure.model';
import {Deserializable, DeserializeHelper} from '../views/shared/utils/json-utils';

export class SpecialtyType implements Deserializable {
    public id: number;
    public name: string;
    public procedures: Procedure[];

    onDeserialize() {
        this.procedures = DeserializeHelper.arrayOf(Procedure, this.procedures);
        this.procedures?.sort((a, b) => a?.name?.localeCompare(b?.name));
    }
}
