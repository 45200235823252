import {Deserializable, DeserializeHelper} from "../views/shared/utils/json-utils";
import {FeeGuide} from "./fee-guide.model";
import DateUtils from "../views/shared/utils/date-utils";
import {SortUtils} from "../views/shared/utils/sort-utils";

export class FeeGuideRegion implements Deserializable {

    id: string;
    stateId: number;
    state: string;
    feeGuides: FeeGuide[] = [];
    updatedDate: string;
    updatedById: number;
    updatedBy: string;
    createdDate: string;
    createdById: number;
    createdBy: string;

    onDeserialize() {
        this.feeGuides = DeserializeHelper.arrayOf(FeeGuide, this.feeGuides) ?? [];
        this.feeGuides?.sort(SortUtils.sortFeeGuidesByStartDate);
    }

    currentActiveFeeGuide(): FeeGuide {
        return FeeGuide.feeGuideForDate( this.feeGuides, new Date());
    }

    mostRecentFeeGuide(): FeeGuide {
        const feeGuidesCopy = DeserializeHelper.arrayOf(FeeGuide, this.feeGuides) ?? [];
        feeGuidesCopy.sort((a, b) => {
            const aStartDate = new Date(a?.startDate);
            const bStartDate = new Date(b?.startDate);
            return bStartDate?.getTime() - aStartDate?.getTime();
        });
        return feeGuidesCopy.length > 0 ? feeGuidesCopy[0] : null;
    }

    currentOrMostRecentFeeGuide(): FeeGuide {
        return this.currentActiveFeeGuide() ?? this.mostRecentFeeGuide();
    }

    nextAvailableFeeGuideStartDate(): Date {
        const mostRecentFeeGuide = this.mostRecentFeeGuide();
        const mostRecentFeeGuideEndDate = mostRecentFeeGuide?.endDate;
        const nextAvailableStartDate = mostRecentFeeGuideEndDate
            ? DateUtils.addNDays(new Date(mostRecentFeeGuideEndDate), 1)
            : null;
        nextAvailableStartDate?.setHours(0, 0, 0, 0);
        return nextAvailableStartDate;
    }

}

