import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {SessionService} from '../../../../services/session.service';
import {CompanyType} from '../../../../models/company.model';

@Component({
    selector: 'app-new-clinic-checklist',
    templateUrl: './new-clinic-checklist.component.html',
    styleUrls: ['./new-clinic-checklist.component.scss', '../../../stylesheet/stylesheet.component.scss']
})
export class NewClinicChecklistComponent implements OnInit {

    dismissNewClinicChecklistForeverKey = 'dismissNewClinicChecklistForeverKey';
    hidden = true;
    enabledForCurrentUser = false;

    checklistItems = [
        {
            title: $localize`Add Dentists`,
            description: $localize`Create an account for each dentist in the practice as well as front-desk and admin staff.`,
            action: $localize`Add Employees`,
            routerLink: '/employees/add'
        },
        {
            title: $localize`Complete Company Settings`,
            description: $localize`Confirm clinic locations and user roles for your company.`,
            action: $localize`Review Company Settings`,
            routerLink: '/settings'
        },
        {
            title: $localize`Learn More`,
            description: $localize`Review FAQ’s and tutorial videos to get started.`,
            action: $localize`Visit Help Centre`,
            routerLink: '/help'
        }
    ];

    constructor(private router: Router, private sessionService: SessionService) {
    }

    ngOnInit(): void {
        const hiddenForever = localStorage.getItem('dismissNewClinicChecklistForeverKey') != null;
        if (!hiddenForever) {
            this.hidden = false;
        }

        this.sessionService.sessionContainer.notNull().subscribe(s => {
            if (s.company.companyTypeId === CompanyType.Dentist && s.company.administratorId === s.employee.id) {
                this.enabledForCurrentUser = true;
            }
        });
    }

    hide() {
        this.hidden = true;
    }

    hideForever() {
        this.hide();
        localStorage.setItem('dismissNewClinicChecklistForeverKey', 'true');
    }

    actionClicked(routerLink: string) {
        this.router.navigate([routerLink]);
    }
}
