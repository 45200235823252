<div class="d-flex flex-column" [style.gap.rem]="1.5">
    <div class="filter-name">{{(options$ | async)?.filterName}}</div>
    <div *ngIf="(options$ | async)?.searchable" class="search-bar-container">
        <label class="search-label" for="searchBar" i18n>Search</label>
        <input
                class="text-field search-text-field"
                id="searchBar"
                name="searchBar"
                [ngModel]="searchText$ | async"
                [placeholder]="searchPlaceholder$ | async"
                autocomplete="off"
                type="text"
                (input)="handleSearchInput($event)"/>
    </div>

    <div class="options-container">
        <ng-container *ngIf="!(options$ | async)?.useSwitchInput else switchInput">
            <ng-container *ngIf="!(options$ | async)?.isDateFilter else datePicker"></ng-container>
            <ng-container *ngFor="let searchedSelectionOptions of (searchedFilterGroups$ | async); let index = index; trackBy: selectionOptionsTrackBy">
                <ng-container [ngSwitch]="!!searchedSelectionOptions?.groupTitle">
                    <app-nested-filter-options-form
                        #nestedFilterForm
                        *ngSwitchCase="true"
                        [hidden]="index >= 5 && !(showAllItems$ | async)"
                        [searchedFilterGroup]="searchedSelectionOptions"
                        [originalFilterGroup]="searchedSelectionOptions | getOriginalSelectionOptions : options$ | async "
                        (optionClicked)="handleSelectionChange($event)"
                        (selectAllClicked)="handleSelectAllClicked(searchedSelectionOptions)">
                    </app-nested-filter-options-form>

                    <ng-container *ngFor="let option of searchedGroupOptions$ | async; trackBy: groupOptionTrackBy">
                        <div *ngSwitchCase="false" class="d-flex" [style.gap.rem]="0.5">
                            <label class="teth-checkbox-container" [for]="option?.getUniqueIdentifier()">
                                <input
                                        type="checkbox"
                                        class="teth-checkbox"
                                        [id]="option?.getUniqueIdentifier()"
                                        [checked]="option | optionIsSelectedFromAllFilterGroups : options$ | async"
                                        (click)="handleSelectionChange(option)">
                                <span class="teth-checkbox-label"> {{ option?.displayableName }} </span>
                                <span class="teth-checkmark"></span>
                            </label>
                        </div>
                    </ng-container>
                </ng-container>
            </ng-container>
        </ng-container>

        <div *ngIf="showVisibilityToggle$ | async" class="text-link" (click)="toggleShowAllItems()">
            {{ visibilityToggleText$ | async }}
        </div>
        <div [hidden]="!(showNoResultsText$ | async)" i18n>
            No filters match the search criteria
        </div>
    </div>
    <div class="divider my-0"></div>
</div>

<ng-template #switchInput>
    <ng-container *ngFor="let selectionOption of (options$ | async)?.selectionOptions; trackBy: selectionOptionsTrackBy">
        <ng-container *ngFor="let option of selectionOption?.groupOptions; trackBy: groupOptionTrackBy">
            <div class="switch-container">
                <label class="switch">
                    <input
                            type="checkbox"
                            [checked]="option | optionIsSelectedFromAllFilterGroups : options$ | async"
                            (click)="handleSelectionChange(option)">
                    <span class="slider"></span>
                </label>
                {{ option?.displayableName }}
            </div>
        </ng-container>
    </ng-container>
</ng-template>

<ng-template #datePicker>
    <app-date-filter-options-form
            [options]="options$ | async"
            (dateRangeSelected)="handleDateRangeSelected($event)"
            (dateRangeCleared)="handleDateRangeCleared()">
    </app-date-filter-options-form>
</ng-template>
