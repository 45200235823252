export class File {
    public name: string;
    public type: string;
    public url: string | ArrayBuffer;
    public size: number;
    public progress: number;
    public success: boolean;
    public failed: boolean;
    public failureError: string;
    public hasPreview: boolean;
    public imageDateTaken: Date;
}
