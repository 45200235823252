import {Attachment} from "../attachment.model";
import {Deserializable, DeserializeHelper} from "../../views/shared/utils/json-utils";
import * as moment from "moment";
import {StageAppointmentFee} from "../stage-appointment-fee.model";
import {SortUtils} from "../../views/shared/utils/sort-utils";

export class Appointment implements Deserializable {
    public id: string;
    public referralStageId: string;
    public procedureTypeId: number;
    public procedureType: string;
    public appointmentDate: Date;
    public appointmentDuration: number;
    public comments: string;
    public attachments: Attachment[];
    public fees: StageAppointmentFee[];
    public teeth: string;
    public updatedDate: Date;
    public updatedById: number;
    public updatedBy: string;
    public createdDate: Date;
    public createdById: number;

    public onDeserialize(): void {
        this.attachments = DeserializeHelper.arrayOf(Attachment, this.attachments);
        this.attachments?.sort((a, b) => a.fileName.localeCompare(b.fileName));
        this.fees = DeserializeHelper.arrayOf(StageAppointmentFee, this.fees);
        this.fees?.sort(SortUtils.sortAppointmentFeesByFeeCode);
        if (this.appointmentDate) {
             this.appointmentDate = moment.utc(this.appointmentDate).toDate();
        }
    }

}
