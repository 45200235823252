import {Deserializable} from '../views/shared/utils/json-utils';

export class AttachmentType implements Deserializable {
    static VideoId = 4;

    public id: number;
    public name: string;

    onDeserialize() {
    }
}


