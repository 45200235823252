import {DataTableColumn} from './data-table-column';
import {DataTableAction} from './data-table-action';
import {NgbDate} from '@ng-bootstrap/ng-bootstrap';
import {DatePickerOutput} from '../views/shared/components/date-picker/date-picker.component';
import DateUtils from '../views/shared/utils/date-utils';
import {GroupOption} from './group-option';
import {DatatableExtraFilterId} from './enum/datatable-extra-filter-id.enum';
import {Deserializable, DeserializeHelper} from "../views/shared/utils/json-utils";

export class DataTableSegmentOptions {
    public options: DataTableOptions;
    public title: string;
    public showCountBadge: boolean;
    public selected: boolean;
}

export class DataTableOptions implements Deserializable {
    public columns: DataTableColumn[];
    public actions: DataTableAction[];
    public data: any[];
    public tableName: string;
    public noResultsText: string;
    public noResultsTitle: string = 'No Results';
    public bulkEditText: string;
    public statuses: any[] = [];
    public primaryFilterOptions: DataTablePrimaryFilterOptions = new DataTablePrimaryFilterOptions();
    public extraFilterOptions: DataTableExtraFilterOptions[] = [];
    public moreFilterOptions: MoreFilterOption[] = [];
    public externalFiltersAreActiveMethod: () => boolean;
    public clearExternalFiltersMethod: () => void;
    public statusColorBandedRows: boolean = false;
    public showClearFiltersButton: boolean = false;
    public hideFilteringOptions: boolean = false;
    public showDateFilter: boolean = false;
    public hideFooter: boolean;
    public hideColumnBorders: boolean = false;
    public centeredTextInCell: boolean = false;
    public defaultTableSorting: any;
    public defaultTableSortColumn: DataTableColumn;
    public useSlideoutFiltering: boolean = false;

    public onDeserialize() {
        this.columns = DeserializeHelper.arrayOf(DataTableColumn, this.columns);
        this.actions = DeserializeHelper.arrayOf(DataTableAction, this.actions);
        this.primaryFilterOptions = DeserializeHelper.deserializeToInstance(DataTablePrimaryFilterOptions, this.primaryFilterOptions);
        this.extraFilterOptions = DeserializeHelper.arrayOf(DataTableExtraFilterOptions, this.extraFilterOptions);
        this.moreFilterOptions = DeserializeHelper.arrayOf(MoreFilterOption, this.moreFilterOptions);
    }

    public addMoreFilterOption(opt: MoreFilterOption) {
        const existingFilter = this.moreFilterOptions.find((o) => o.filterPropertyName === opt.filterPropertyName);
        if (existingFilter) {
            // remove the existing filter
            this.moreFilterOptions.splice(this.moreFilterOptions.indexOf(existingFilter), 1);
        }
        // add the filter
        this.moreFilterOptions.push(opt);
        this.sortMoreFilterOptions();
    }

    public getStatusFromId(id: number, statusIdProperty: string): any {
        let status: any = null;
        (this.statuses || []).forEach((s) => {
            if (s.hasOwnProperty(statusIdProperty) && s[statusIdProperty] === id) {
                status = s;
            }
        });
        return status;
    }

    public extraFilterOptionsAreActive(): boolean {
        let active = false;
        this.extraFilterOptions.forEach((opt) => {
            if (opt.isActive()) {
                active = true;
            }
        });
        return active;
    }

    public moreFiltersAreActive(): boolean {
        let active = false;
        let totalSelectionCount = 0;
        let totalInitialCount = 0;
        this.moreFilterOptions.forEach((opt) => {
            totalSelectionCount += opt.group.selectedOptions.length;
            totalInitialCount += opt.group.initialSelectionCount;
            if (opt.group.selectedOptions.length > 0 || (opt.group.selectedOptions.length !== opt.group.initialSelectionCount)) {
                active = true;
            }
        });
        return active && (totalSelectionCount > 0 || totalSelectionCount < totalInitialCount);
    }

    public externalFiltersAreActive(): boolean {
        if (this.externalFiltersAreActiveMethod == null) {
            return false;
        }
        return this.externalFiltersAreActiveMethod();
    }

    public getMoreFiltersName(): string {
        let totalSelectionCount = 0;
        this.moreFilterOptions.forEach((opt) => {
            totalSelectionCount += opt.group.selectedOptions.length;
        });
        if (totalSelectionCount > 0) {
            return totalSelectionCount + (totalSelectionCount === 1 ? ' Filter' : ' Filters');
        } else {
            return 'More Filters';
        }
    }

    private sortMoreFilterOptions() {
        this.moreFilterOptions.sort((a, b) => {
            return a.filterPropertyName.localeCompare(b.filterPropertyName);
        });
    }

}

export class DataTablePrimaryFilterOptions implements Deserializable {
    public primaryFilterData: any[];
    public activePrimaryFilter: any;
    public primaryFilterLabel: string;
    public primaryFilterDisplayProperty: string;

    public onDeserialize() {
    }
}

export class DataTableExtraFilterOptions implements Deserializable {
    public filterName: string;
    public filterPropertyName: string;
    // Date specific filter options
    public isDateFilter: boolean = false;
    public dateOutput: DatePickerOutput;
    public selectedDates: NgbDate[];
    // Selection based filter options
    public selectionOptions: DataTableExtraFilterGroup[] = [];
    public filterId: DatatableExtraFilterId;
    public count: number = null;
    public searchable: boolean = true;
    public useSwitchInput: boolean = false;

    getFilterName(): string {
        if (this.isActive()) {
            if (this.isDateFilter) {
                // get formatted dates
                return DateUtils.formatNgbDateRangeToString(this.selectedDates);
            } else {
                if (this.selectionOptions.length === 0) {
                    return this.filterName;
                } else if (this.selectionOptions.length === 1) {
                    const fn = this.selectionOptions[0].getFilterName();
                    return fn ? fn : this.filterName;
                } else {
                    let totalSelectionCount = 0;
                    let activeSelectionOption = '';
                    this.selectionOptions.forEach((selOpt) => {
                        totalSelectionCount += selOpt.selectedOptions.length;
                        if (selOpt.selectedOptions.length === 1) {
                            activeSelectionOption = selOpt.selectedOptions[0].displayableName;
                        }
                    });
                    if (totalSelectionCount === 1) {
                        return activeSelectionOption;
                    } else {
                        return totalSelectionCount + ' ' + this.selectionOptions[0].pluralGroupName;
                    }
                }
            }
        } else {
            return this.filterName;
        }
    }

    clearFilter() {
        if (this.isDateFilter) {
            this.selectedDates = [];
        } else {
            this.selectionOptions.forEach((opt) => {
                opt.clear();
            });
        }
    }

    isActive(): boolean {
        if (this.isDateFilter) {
            const requiredDates = (this.dateOutput === DatePickerOutput.DateRange) ? 2 : 1;
            return this.selectedDates && this.selectedDates.length === requiredDates;
        } else {
            let active = false;
            let totalSelectionCount = 0;
            let totalInitialCount = 0;
            this.selectionOptions.forEach((opt) => {
                totalSelectionCount += opt.selectedOptions.length;
                totalInitialCount += opt.initialSelectionCount;
                if (opt.selectedOptions.length > 0 || (opt.selectedOptions.length !== opt.initialSelectionCount)) {
                    active = true;
                }
            });
            return active && (totalSelectionCount > 0 || totalSelectionCount < totalInitialCount);
        }
    }

    valueSatisfiesFilter(v: any): boolean {
        if (this.isDateFilter) {
            const valueDate = new Date(v);
            const ngbVal = DateUtils.getNgbDateFromDate(valueDate);
            if (this.selectedDates.length === 1) {
                // if only one date, then must be an exact match
                return this.selectedDates[0].equals(ngbVal);
            } else if (this.selectedDates.length === 2) {
                // if two dates, then must fall within range
                return (this.selectedDates[0].before(ngbVal) || this.selectedDates[0].equals(ngbVal)) &&
                    (this.selectedDates[1].after(ngbVal) || this.selectedDates[1].equals(ngbVal));
            } else {
                return false;
            }
        } else {
            // v is the full object and we must apply comparisonPropertyName for each option
            let valid = false;
            this.selectionOptions.forEach((opt) => {
                opt.selectedOptions.forEach((selOpt) => {
                    if (selOpt?.comparisonPropertyName === opt?.comparisonPropertyName) {
                        if (v instanceof Array && v.indexOf(selOpt?.value) > -1) {
                            valid = true;
                            // tslint:disable-next-line:triple-equals
                        } else if (v == selOpt?.value) {
                            // filter criteria is satisfied - only require one filter option to be valid to data to
                            valid = true;
                        }
                    }
                });
            });
            return valid;
        }
    }

    setInitialValues() {
        this.selectionOptions.forEach((sel) => {
            sel.setInitialSelectionCount();
        });
    }

    onDeserialize() {
        this.selectionOptions = DeserializeHelper.arrayOf(DataTableExtraFilterGroup, this.selectionOptions)
    }

}

export class MoreFilterOption implements Deserializable {
    public filterPropertyName: string;
    public group: DataTableExtraFilterGroup;

    public onDeserialize() {
        this.group = DeserializeHelper.deserializeToInstance(DataTableExtraFilterGroup, this.group);
    }
}

export class DataTableExtraFilterGroup implements Deserializable {
    public groupTitle: string;
    public groupOptionsTitle: string;
    public groupOptions: GroupOption[] = [];
    public displayPropertyName: string;
    public comparisonPropertyName: string;
    public pluralGroupName: string;
    public selectedOptions: GroupOption[] = [];
    public initialSelectionCount: number;

    onDeserialize() {
        this.groupOptions = DeserializeHelper.arrayOf(GroupOption, this.groupOptions);
        this.selectedOptions = DeserializeHelper.arrayOf(GroupOption, this.selectedOptions);
    }


    sortOptions() {
        this.groupOptions.sort((a, b) => {
            if (a.hasOwnProperty(this.displayPropertyName) && a[this.displayPropertyName] && b.hasOwnProperty(this.displayPropertyName) && b[this.displayPropertyName]) {
                return a[this.displayPropertyName].localeCompare(b[this.displayPropertyName]);
            } else {
                return false;
            }
        });
    }

    getFilterName(): string {
        if (this.selectedOptions.length === 0) {
            return null;
        } else if (this.selectedOptions.length === 1) {
            return this.selectedOptions[0].displayableName;
        } else {
            return this.selectedOptions.length + ' ' + this.pluralGroupName;
        }
    }

    setInitialSelectionCount() {
        this.initialSelectionCount = this.selectedOptions.length;
    }

    clear() {
        this.selectedOptions = [];
        this.initialSelectionCount = 0;
    }

    valueSatisfiesFilter(v: any): boolean {
        let valid = false;
        this.selectedOptions.forEach((selOpt) => {
            if (selOpt?.comparisonPropertyName === this.comparisonPropertyName) {
                if (v instanceof Array && v.indexOf(selOpt?.value) > -1) {
                    valid = true;
                } else if (v === selOpt?.value) {
                    // filter criteria is satisfied - only require one filter option to be valid to data to
                    valid = true;
                }
            }
        });
        return valid;
    }

    getUniqueIdentifier(): string {
        return `${this.groupTitle}
      -${this.groupOptionsTitle}
      -${this.displayPropertyName}
      -${this.comparisonPropertyName}
      -${this.pluralGroupName}`;
    }

}
