import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {PermissionCategory} from '../../../../models/permission-category.model';
import {ToastrService} from 'ngx-toastr';
import {Permission} from '../../../../models/permission.model';
import {Role, RolePermission} from '../../../../models/role.model';
import {EmployeeService} from '../../../../services/employee.service';
import {SessionService} from '../../../../services/session.service';

@Component({
    selector: 'app-role-modal',
    templateUrl: './role-modal.component.html',
    styleUrls: ['./role-modal.component.scss', '../../../stylesheet/stylesheet.component.scss']
})
export class RoleModalComponent implements OnInit {
    titleText: string = '';
    roleName: string = '';
    permissionCategories: PermissionCategory[] = [];
    addingNewRole: boolean;
    newPermissions: Permission[];

    roleForm = new FormGroup({
        roleName: new FormControl('', Validators.required),
    });

    static defaultModalOptions(): any {
        return {size: 'lg', backdrop: 'static', centered: true};
    }

    constructor(private activeModal: NgbActiveModal,
                private toastr: ToastrService,
                private employeeService: EmployeeService,
                private sessionService: SessionService) {
    }

    ngOnInit(): void {
        if (this.addingNewRole === false) {
            this.populateModal();
        }
    }

    initWith(titleText: string, roleName: string, permissions: PermissionCategory[], isAddingNewRole: boolean) {
        this.titleText = titleText;
        this.roleName = roleName;
        this.permissionCategories = permissions;
        this.addingNewRole = isAddingNewRole;

        if (!this.addingNewRole) {
            this.newPermissions = this.permissionCategories.flatMap(c => {
                const p: Permission[] = [];
                if (c.primaryPermission) {
                    p.push(c.primaryPermission);
                }
                if (c.childPermissions?.length > 0) {
                    p.push(...c.childPermissions);
                }
                return p.filter(x => !!x);
            });
        }
    }

    closeModal() {
        this.activeModal.close();
    }

    saveNewRole() {
        if (!this.roleForm.valid) {
            this.toastr.error($localize`Missing required fields`);
            return;
        } else {
            const newRole = new Role();
            newRole.name = this.roleForm.value.roleName;
            newRole.permissions = this.newPermissions.map(p => {
                const rolePermission = new RolePermission();
                rolePermission.permissionId = p.id;
                return rolePermission;
            });
            this.employeeService.createRole(newRole, this.sessionService.getCompanyId()).subscribe(createdRole => {
                this.toastr.success($localize`${newRole.name}:roleName: Role Created Successfully`);
                this.activeModal.close(createdRole);
            }, err => {
                console.log(err);
                this.toastr.error($localize`Creating Role Failed`);
            });
        }
    }

    isPermissionChecked(permission: Permission): boolean {
        if (permission == null) {
            return false;
        }
        return (this.newPermissions?.findIndex(p => p.id === permission.id) ?? -1) > -1;
    }

    permissionCheckboxClicked(permission: Permission) {
        if (!this.addingNewRole) {
            return;
        }

        if (this.newPermissions == null) {
            this.newPermissions = [];
        }
        if (!this.isPermissionChecked(permission)) {
            this.newPermissions.push(permission);
        } else {
            const existingPermissionIndex = this.newPermissions.findIndex(p => p.id === permission.id);
            this.newPermissions.splice(existingPermissionIndex, 1);
        }
    }

    populateModal() {
        this.roleForm.patchValue({
            roleName: this.roleName
        });
        this.roleForm.controls.roleName.disable();
    }

}
