import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormGroup} from '@angular/forms';
@Component({
  selector: 'app-implants-form',
  templateUrl: './implants-form.component.html',
  styleUrls: ['./implants-form.component.scss', '../../../stylesheet/stylesheet.component.scss']
})
export class ImplantsFormComponent implements OnInit {

  constructor() { }

  @Input() parentForm: FormGroup;
  @Input() radiobuttons = [];

  @Output() public textfieldChangedEmitter = new EventEmitter();
  @Output() public checkboxChangedEmitter = new EventEmitter();

  showAdvancedOptions = false;

  ngOnInit(): void {
  }

  toggleOptions() {
    this.showAdvancedOptions = !this.showAdvancedOptions;
  }

  getLocalizedRestorationTypeTitle(): string {
    return $localize`Restoration Type`;
  }

  getLocalizedRemovableTitle(): string {
    return $localize`Removable`;
  }

  getLocalizedAttachmentTypeTitle(): string {
    return $localize`Attachment Type`;
  }

  getLocalizedDesignOptionTitle(): string {
    return $localize`Design Option`;
  }

  getLocalizedBarOptionTitle(): string {
    return $localize`Bar Option`;
  }

  getLocalizedRestorativeOptionTitle(): string {
    return $localize`Restorative Option`;
  }

  getLocalizedAbutmentLevelTitle(): string {
    return $localize`Abutment Level`;
  }

  getLocalizedMaterialOptionTitle(): string {
    return $localize`Material Option`;
  }

  getLocalizedAngulatedCorrectionTitle(): string {
    return $localize`Angulated Correction`;
  }

  getLocalizedAbutmentOptionTitle(): string {
    return $localize`Abutment Option`;
  }

  getLocalizedShadePlaceholder(): string {
    return $localize`Enter Shade...`;
  }

  getLocalizedShadeTitle(): string {
    return $localize`Shade`;
  }

  getLocalizedPonticDesignTitle(): string {
    return $localize`Pontic Design`;
  }

  getLocalizedOcclusalContactTitle(): string {
    return $localize`Occlusal Contact`;
  }

  getLocalizedToothMorphTitle(): string {
    return $localize`Tooth Morphology`;
  }

  getLocalizedSurfaceTextureTitle(): string {
    return $localize`Surface Texture`;
  }

  getLocalizedInterproximalContactTitle(): string {
    return $localize`Interproximal Contact`;
  }

  getLocalizedIncisalTranslucencyTitle(): string {
    return $localize`Incisal Translucency`;
  }

  getLocalizedCustomShadeTitle(): string {
    return $localize`Custom Shade`;
  }

  getLocalizedDeliveryTitle(): string {
    return $localize`Delivery`;
  }

  getLocalizedShowOptionsTitle(): string {
    return $localize`Show Advanced Options`;
  }

  getLocalizedHideOptionsTitle(): string {
    return $localize`Hide Advanced Options`;
  }

  emitTextFieldValue(value: string) {
    this.textfieldChangedEmitter.emit(value);
  }

  emitCheckboxValue(value: boolean) {
    this.checkboxChangedEmitter.emit(value);
  }

}
