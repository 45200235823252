import {Deserializable} from "../views/shared/utils/json-utils";

export class GroupOption implements Deserializable {
    public displayableName: string;
    public value: any;
    public comparisonPropertyName: string;

    constructor(displayableName: string, value: any, comparisonName: string) {
        this.displayableName = displayableName;
        this.value = value;
        this.comparisonPropertyName = comparisonName;
    }

    onDeserialize() {
    }

    getUniqueIdentifier(): string {
        return `${this.value}
      -${this.displayableName}
      -${this.comparisonPropertyName}`;
    }
}
