export class ReferralStatusUtils {

    static getStatusFontColor(id: number): string {
        switch (id) {
            case 1:
                return '#001E72';
            case 4:
                return '#085E28';
            case 5:
                return '#750202';
            case 6:
                return '#702100';
            case 7:
                return '#784700';
            case 8:
                return '#380068';
            default:
                return '#000000';
        }
    }

    static getStatusIndicatorColor(id: number): string {
        switch (id) {
            case 1:
                return '#BCCEFF';
            case 4:
                return '#D2FFE3';
            case 5:
                return '#FFC3C3';
            case 6:
                return '#FFD4C2';
            case 7:
                return '#FFE8C8';
            case 8:
                return '#E1BFFF';
            default:
                return '#A89292';
        }
    }

}
