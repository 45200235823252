import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {navItems} from '../../_nav';
import {SessionService} from '../../services/session.service';
import {ReferralService} from '../../services/referral.service';
import {environment} from '../../../environments/environment';
import {CompanyType} from '../../models/company.model';
import {ContentScrollService} from '../../services/content-scroll.service';
import {NavigationStart, Router} from "@angular/router";
import {filter} from "rxjs/operators";


@Component({
    selector: 'app-dashboard',
    templateUrl: './default-layout.component.html',
    styleUrls: ['./default-layout.component.scss']
})
export class DefaultLayoutComponent implements OnInit, AfterViewInit {

    public sidebarMinimized = false;
    public navItems = navItems;
    @ViewChild('mainContent') mainContent: ElementRef;

    constructor(private sessionService: SessionService,
                private router: Router,
                private referralService: ReferralService,
                private scrollService: ContentScrollService) {
    }

    ngOnInit(): void {
        this.sessionService.sessionContainer.subscribe(s => {
            if (environment.administratorAccess) {
                const bodyStyles = window.getComputedStyle(document.body);
                const adminColor = bodyStyles.getPropertyValue('--nav-sidebar-background-admin');
                document.body.style.setProperty('--nav-sidebar-background', adminColor);
                const rgb = bodyStyles.getPropertyValue('--nav-sidebar-background-admin-rgb');
                document.body.style.setProperty('--nav-sidebar-background-rgb', rgb);
            } else if (s && s.company.companyTypeId === CompanyType.Specialist) {
                const bodyStyles = window.getComputedStyle(document.body);
                const sppColor = bodyStyles.getPropertyValue('--nav-sidebar-background-spp');
                document.body.style.setProperty('--nav-sidebar-background', sppColor);
                const rgb = bodyStyles.getPropertyValue('--nav-sidebar-background-spp-rgb');
                document.body.style.setProperty('--nav-sidebar-background-rgb', rgb);
            } else if (s && s.company.companyTypeId === CompanyType.DentalLab) {
                const bodyStyles = window.getComputedStyle(document.body);
                const labColor = bodyStyles.getPropertyValue('--nav-sidebar-background-lab');
                document.body.style.setProperty('--nav-sidebar-background', labColor);
                const rgb = bodyStyles.getPropertyValue('--nav-sidebar-background-lab-rgb');
                document.body.style.setProperty('--nav-sidebar-background-rgb', rgb);
            } else {
                const bodyStyles = window.getComputedStyle(document.body);
                const gppColor = bodyStyles.getPropertyValue('--nav-sidebar-background-gpp');
                document.body.style.setProperty('--nav-sidebar-background', gppColor);
                const rgb = bodyStyles.getPropertyValue('--nav-sidebar-background-gpp-rgb');
                document.body.style.setProperty('--nav-sidebar-background-rgb', rgb);
            }
        });
    }

    ngAfterViewInit(): void {
        this.router.events.pipe(filter(e => e instanceof NavigationStart)).subscribe(() => {
            // legacy typescript type defs do not include the behavior: 'instant' value
            // @ts-ignore
            this.mainContent.nativeElement.scrollTo({top: 0, left: 0, behavior: 'instant'});
        });
        this.scrollService.scrollToTopSubject.subscribe(() => {
            this.scrollToTop();
        });
    }

    scrollToTop() {
        this.mainContent.nativeElement.scrollTop = 0;
    }
}
