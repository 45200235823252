import {Pipe, PipeTransform} from '@angular/core';
import {DataTableExtraFilterGroup, DataTableExtraFilterOptions} from "../../../../../models/data-table-options";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";

@Pipe({
  name: 'getOriginalSelectionOptions'
})
export class GetOriginalSelectionOptionsPipe implements PipeTransform {

  transform(searchedFilterGroup: DataTableExtraFilterGroup, options$: Observable<DataTableExtraFilterOptions>): Observable<DataTableExtraFilterGroup> {
    return options$.pipe(
        map(options => {
          return options?.selectionOptions?.find(g => {
            return g?.getUniqueIdentifier() === searchedFilterGroup?.getUniqueIdentifier();
          });
        })
    );
  }

}
