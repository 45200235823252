<div class="row">
    <div class="col-12">
        <form class="teth-radio-form" [formGroup]="parentForm"
              [class.error]="parentForm.get(control.toString()).invalid && parentForm.get(control.toString()).touched">
            <label [ngClass]="{'required': required}" class="checkbox-group-label">{{textLabel}}</label>
            <div *ngFor="let rb of radioButtons" class="col-md-3 inline-col">
                <label class="teth-checkbox-container" for="{{textLabel + rb.id}}">
                    <input type="checkbox" class="teth-checkbox" id="{{textLabel + rb.id}}" name="{{textLabel + rb.id}}"
                           (change)="checkboxSelected($event, rb.id)"
                           [checked]="selectedValues.includes(rb.id)">
                    <span class="teth-checkbox-label">{{rb.label}}</span>
                    <span class="teth-checkmark"></span>
                </label>
            </div>
            <span class="teth-textfield-error" i18n>Field must be completed</span>
        </form>
    </div>
</div>
