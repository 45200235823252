import {Deserializable, DeserializeHelper} from '../views/shared/utils/json-utils';
import {Appointment} from "./enum/appointment.model";

export class Stage implements Deserializable {
    static ScheduledStatusId = 1;
    static CompletedStatusId = 2;
    static DeclinedStatusId = 3;
    static NotRequiredStatusId = 4;

    static ConsultationStageId = 1;
    static TreatmentStageId = 2;
    static FollowUpStageId = 3;

    id: string;
    referralId: string;
    stageId: number;
    stage: string;
    isCurrentStage: boolean;
    statusId: number;
    status: string;
    appointments: Appointment[];
    declinedStatusReasonId: number;
    declinedStatusReason: string;

    public static getStageName(id: number): string {
        switch (id) {
            case this.ConsultationStageId: return 'Consultation';
            case this.TreatmentStageId: return 'Treatment';
            case this.FollowUpStageId: return 'Follow Up';
        }
    }

    onDeserialize() {
        this.appointments = DeserializeHelper.arrayOf(Appointment, this.appointments);
        const sortAppointmentsNullsLast = (a: Appointment, b: Appointment) => {
            if (a?.appointmentDate && b?.appointmentDate) {
                return a.appointmentDate?.getTime() - b.appointmentDate?.getTime();
            } else if (a?.appointmentDate) {
                return -1;
            } else if (b?.appointmentDate) {
                return 1;
            }
        }
        this.appointments?.sort(sortAppointmentsNullsLast);
    }
}
