import {Pipe, PipeTransform} from '@angular/core';
import {FeeGuide} from "../../../models/fee-guide.model";

@Pipe({
  name: 'feeGuideCoverageString'
})
export class FeeGuideCoverageStringPipe implements PipeTransform {

  transform(feeGuide: FeeGuide, separator?: string): string {
    return separator ? feeGuide?.getCoverageString(separator) : feeGuide?.getCoverageString();
  }

}
