<div class="modal-body">
    <div class="modal-title" i18n>
        {{title}}
    </div>
    <div class="date-picker-container">
        <app-date-picker [displayMonths]="1"
                         [navigationType]="datePickerNavTypes.Select"
                         [hideBorder]="true"
                         [datePickerOutput]="datePickerOutputs.SingleDate"
                         [clearDateSelection]="clearDateSelection"
                         (dateSelected)="handleCustomDateRangeSelection($event)">
        </app-date-picker>
    </div>

    <div class="modal-button-footer">
        <button class="teth-button modal-footer-button grey-button"
                (click)="cancelClicked()" i18n>
            Cancel
        </button>
        <button class="teth-button modal-footer-button blue-button"
                [ngClass]="{ 'disabled': !selectedDate }"
                [disabled]="!selectedDate"
                (click)="continueClicked()" i18n>
            Continue
        </button>
    </div>
</div>
