import {Injectable} from '@angular/core';
import {SessionService} from './session.service';
import {Permission} from '../models/permission.model';
import {EntityService} from './entity.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {DeserializeHelper} from '../views/shared/utils/json-utils';
import {ApiService} from './api.service';
import {Employee} from '../models/employee.model';
import {Role, RolePermission} from '../models/role.model';
import {PermissionCategory} from '../models/permission-category.model';
import {CompanyType} from '../models/company.model';
import {debounceTime} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class PermissionService {
    currentUserRole: BehaviorSubject<Role> = new BehaviorSubject<Role>(null);
    companyRoles: BehaviorSubject<Role[]> = new BehaviorSubject<Role[]>(null);

    constructor(private sessionService: SessionService,
                private entityService: EntityService,
                private api: ApiService) {
        this.sessionService.sessionContainer.pipe(debounceTime(100)).subscribe(s => {
            this.currentUserRole.next(null);
            if (s != null && s.employee.roleId != null) {
                this.getRole(s.employee).subscribe(role => {
                    console.log('role loaded');
                    this.currentUserRole.next(role);
                });

                if (s.company?.id) {
                    this.getCompanyRoles(s.company.id).subscribe(roles => {
                        if (roles.every(role => role.companyId === s.company?.id)) {
                            this.companyRoles.next(roles);
                        }
                    }, error => {
                        console.log(error);
                    });
                }
            }
        });
    }

    public getRole(employee: Employee): Observable<Role> {
        return this.api.get<Role>(
            environment.apiBaseUrl + `/companies/${employee.companyId}/roles/${employee.roleId}`)
            .map(r => DeserializeHelper.deserializeToInstance(Role, r));
    }

    public getCompanyRoles(companyId: number): Observable<Role[]> {
        return this.api.get<Role[]>(
            environment.apiBaseUrl + `/companies/${companyId}/roles`)
            .map(r => r?.map(d => DeserializeHelper.deserializeToInstance(Role, d)));
    }

    public permissionGranted(permissionId: number): boolean {
        if (environment.administratorAccess) {
            return true;
        }

        if (this.currentUserRole.value == null) {
            console.warn('Permission requested when no role is set: ' + permissionId);
            return false;
        }
        return this.currentUserRole.value?.permissions.find(p => p.permissionId === permissionId) != null;
    }

    public rolePermissionToPermission(rolePermissions: RolePermission[]): Permission[] {
        return rolePermissions.map(rolePerm => {
            return this.entityService.permissionTypes$.value?.find(p => p.id === rolePerm.permissionId);
        });
    }

    public getCategorizedPermissions(permissions: Permission[], returnAllCategories: boolean = false): PermissionCategory[] {
        const createReferral = new PermissionCategory($localize`Create Referrals`, permissions.find(p => p.id === PermissionTypeIds.CreateReferrals), null);
        const dashboard = new PermissionCategory($localize`Dashboard`, permissions.find(p => p.id === PermissionTypeIds.Dashboard), [permissions.find(p => p.id === PermissionTypeIds.DashboardViewAllData)]);
        const manageReferral = new PermissionCategory($localize`Manage Referrals`, permissions.find(p => p.id === PermissionTypeIds.ManageReferrals),
            [
                permissions.find(p => p.id === PermissionTypeIds.ManageReferralsViewAllReferrals),
                permissions.find(p => p.id === PermissionTypeIds.ManageReferralsTransfer),
                permissions.find(p => p.id === PermissionTypeIds.ManageReferralsDelete),
            ]);
        const clinicalCorrespondence = new PermissionCategory($localize`Clinical Correspondence`, permissions.find(p => p.id === PermissionTypeIds.Correspondence), null);
        const employees = new PermissionCategory($localize`Employees`, permissions.find(p => p.id === PermissionTypeIds.Employees), null);
        const systemSettings = new PermissionCategory($localize`System Settings`, permissions.find(p => p.id === PermissionTypeIds.Settings), null);
        const permissionCategories: PermissionCategory[] = [];
        const companyId = this.sessionService.sessionContainer.value?.company.companyTypeId;

        if (returnAllCategories || companyId === CompanyType.Specialist) {
            permissionCategories.push(createReferral, dashboard, manageReferral, clinicalCorrespondence, employees, systemSettings);
        } else if (companyId === CompanyType.DentalLab) {
            permissionCategories.push(dashboard, manageReferral, clinicalCorrespondence, employees, systemSettings);
        } else {
            permissionCategories.push(createReferral, manageReferral, clinicalCorrespondence, employees, systemSettings);
        }
        return permissionCategories.filter(c => c.primaryPermission !== null || c.childPermissions?.length > 0);
    }
}

export class PermissionTypeIds {
    static CreateReferrals = 1;
    static Dashboard = 2;
    static DashboardViewAllData = 3;
    static ManageReferrals = 4;
    static ManageReferralsViewAllReferrals = 5;
    static Correspondence = 6;
    static Employees = 7;
    static Settings = 8;
    static ManageReferralsTransfer = 9;
    static ManageReferralsDelete = 10;
}
