import {Component, HostBinding, OnInit} from '@angular/core';
import {File as _File} from '../../../../models/file';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {EntityService} from '../../../../services/entity.service';
import {FileService} from '../../../../services/file.service';
import {SessionService} from '../../../../services/session.service';
import {ReferralService} from '../../../../services/referral.service';
import {FormControl, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {NetworkContact} from '../../../../models/network-contact.model';
import {BehaviorSubject, forkJoin, Observable, of} from 'rxjs';
import {SendGridService} from '../../../../services/send-grid.service';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {environment} from '../../../../../environments/environment';
import {NetworkInvitationCustomText} from '../../../../models/network-invitation-custom-text';
import {debounce, debounceTime, filter} from 'rxjs/operators';

@Component({
    selector: 'app-invite-clinics-modal',
    templateUrl: './invite-clinics-modal.component.html',
    styleUrls: ['./invite-clinics-modal.component.scss', '../../../stylesheet/stylesheet.component.scss'],
})
export class InviteClinicsModalComponent implements OnInit {
    @HostBinding('class') hostClass = 'flex-grow-1';
    emailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
    files: _File[] = [];
    loading = false;
    showPreview = false;
    customTextString = '';
    updatingCustomText = false;
    customTextSubject = new BehaviorSubject<string>(null);
    customText: NetworkInvitationCustomText = null;
    emailControl = new FormControl(null, [Validators.pattern(this.emailRegex)]);
    emails: string[] = [];
    networkContacts: NetworkContact[];
    companyId: number;
    locationId: number;
    locationName: string;
    emailPreviewHtmlUnsanitized: string;
    emailPreviewHtml: SafeHtml;

    constructor(private activeModal: NgbActiveModal,
                private entityService: EntityService,
                private fileService: FileService,
                private sessionService: SessionService,
                private toastr: ToastrService,
                private sendGridService: SendGridService,
                private sanitizer: DomSanitizer,
                private referralService: ReferralService) {
    }

    ngOnInit() {
        this.loading = true;
        this.sessionService.sessionContainer.subscribe(s => {
            this.companyId = s.company.id;
            this.locationId = s.currentLocation.id;
            this.locationName = s.currentLocation.name;
            this.getExistingContacts();
            this.getSendGridPreview();
        });

        this.customTextSubject
            .pipe(debounceTime(500), filter(x => x != null))
            .subscribe(customText => {
                this.updateCustomText(customText);
            });
    }

    getExistingContacts() {
        const getCustomText = this.entityService.getLocationNetworkInvitationCustomTexts(this.companyId, this.locationId);
        const getContacts = this.entityService.getLocationNetworkContacts(this.companyId, this.locationId);
        forkJoin([getContacts, getCustomText]).subscribe(([contacts, customText]) => {
            this.loading = false;
            this.networkContacts = contacts;
            this.customText = customText[0];
            this.customTextString = this.customText.text;
            this.emails = contacts.map(c => c.email)?.unique();
        }, () => {
            this.loading = false;
        });
    }

    createContactsForEmails(contactEmails: string[]) {
        let newContacts = contactEmails.map(e => {
            const newContact = new NetworkContact();
            newContact.email = e.trim();
            return newContact;
        });
        // filter out emails that already exist as contacts
        newContacts = newContacts.filter(c => !this.networkContacts.find(n => n.email === c.email));
        const obs: Observable<NetworkContact>[] = [];
        newContacts.forEach(c => {
            obs.push(this.entityService.createLocationNetworkContact(this.companyId, this.locationId, c));
        });

        forkJoin(obs).subscribe(result => {
            this.getExistingContacts();
        }, error => {
            console.log(error);
            this.getExistingContacts();
        });
    }

    getSendGridPreview() {
        this.sendGridService.getTemplate().subscribe(result => {
            const html = result?.versions[0]?.html_content as string;
            if (html) {
                this.emailPreviewHtmlUnsanitized = html;
                this.updateSendgridPreview();
            }
        });
    }

    updateSendgridPreview() {
        let html = this.emailPreviewHtmlUnsanitized;
        html = html.replace('{{sppclinicname}}', this.locationName);
        html = html.replace('{{jointetherportlink}}', environment.joinTetherportUrl);
        html = html.replace('{{customtext}}', this.customTextString);
        this.emailPreviewHtml = this.sanitizer.bypassSecurityTrustHtml(html);
    }

    closeModal() {
        this.activeModal.close();
    }

    handleFileAdded(_file: _File) {
        this.convertFile();
    }

    addEmail() {
        if (this.emailControl.valid && this.emailControl.value?.length > 0) {
            this.emails.push(this.emailControl.value);
            this.createContactsForEmails([this.emailControl.value]);
            this.emailControl.reset();
            this.emails = this.emails.unique();
        }
    }

    removeEmail(emailToRemove: string) {
        this.emails.splice(this.emails.findIndex(e => e === emailToRemove), 1);
        const removedContacts = this.networkContacts.splice(this.networkContacts.findIndex(c => c.email === emailToRemove), 1);
        if (removedContacts && removedContacts[0]?.id) {
            const removedContact = removedContacts[0];
            this.entityService.deleteLocationNetworkContact(removedContact).subscribe(() => {
            }, error => {
                this.toastr.error($localize`There was a problem removing the email address. Please Try again.`);
                console.log(error);
            });
        }
    }

    sendInvites() {
        this.loading = true;
        this.entityService.sendLocationNetworkContactInvitations(this.locationId, this.companyId).subscribe(result => {
            this.loading = false;
            this.toastr.success($localize`Invitations sent successfully.`);
            this.closeModal();
        }, error => {
            this.loading = false;
            this.toastr.error($localize`There was a problem sending the invitations. Please try again.`);
            console.log(error);
        });
    }

    processCsv(csvContent: string) {
        const csvEmails = csvContent.split('\n');
        let importedCount = 0;
        let failedCount = 0;
        csvEmails.forEach(email => {
            if (email.match(`[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}`)) {
                importedCount++;
                this.emails.push(email.trim());
            } else {
                failedCount++;
            }
        });
        this.emails = this.emails.unique();
        this.createContactsForEmails(this.emails);
        if (failedCount > 0) {
            this.toastr.warning($localize`${failedCount}:emailCount: email(s) could not be imported.`);
        }
        this.toastr.success($localize`Imported ${importedCount}:emailCount: email(s).`);
    }

    convertFile() {
        const file = this.b64toBlob(this.files[0].url);
        this.readFileContent(file).then(content => {
            this.processCsv(content);
        }).catch(error => console.log(error));
    }

    b64toBlob(dataURI) {
        const byteString = atob(dataURI.split(',')[1]);
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: 'text/csv' });
    }

    readFileContent(file): Promise<any> {
        const reader = new FileReader();
        return new Promise((resolve, reject) => {
            reader.onload = event => resolve(event.target.result);
            reader.onerror = error => reject(error);
            reader.readAsText(file);
        });
    }

    updateCustomText(newCustomText: string) {
        if (this.updatingCustomText || newCustomText === this.customText?.text) {
            return;
        }
        this.updateSendgridPreview();
        const c = new NetworkInvitationCustomText();
        c.text = newCustomText;
        const deleteOldText = this.customText ? this.entityService.deleteLocationNetworkInvitationCustomText(this.customText) : of('');
        const createNewText = newCustomText?.trim()?.length > 0 ? this.entityService.createLocationNetworkInvitationCustomText(this.companyId, this.locationId, c) : of(null);
        this.updatingCustomText = true;
        forkJoin([deleteOldText, createNewText]).subscribe(([, updatedCustomText]) => {
            this.customText = updatedCustomText;
            this.updatingCustomText = false;
        }, error => {
            this.customText = null;
            this.updatingCustomText = false;
            this.customTextString = '';
            console.log(error);
        });
    }
}
