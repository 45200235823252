<div class="modal-body">
    <app-loading
            [determinateLoading]="false"
            [hidden]="!loading"
            [loadingText]="loadingText"
            [fullscreen]="false"
            [backgroundColor]="'#FDFDFD'"
            [color]="'#388EFC'">
    </app-loading>
    <div class="modal-title" i18n>Add Correspondence</div>
    <div class="mt-3 pt-1">
        <div class="row">
            <div class="col">
                <form class="teth-textfield-form px-0">
                    <label class="teth-textfield-label" for="correspondenceType" i18n>Correspondence Type</label>
                    <select class="teth-dropdown teth-textfield-dropdown"
                            name="correspondenceType"
                            id="correspondenceType"
                            [(ngModel)]="selectedCorrespondenceType">
                        <option [ngValue]="null" [selected]="selectedCorrespondenceType == null" disabled hidden i18n>
                            Select Correspondence Type
                        </option>
                        <option *ngFor="let c of correspondenceTypes"
                                [ngValue]="c">{{c.name}}</option>
                    </select>
                </form>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col">
                <label class="teth-textfield-label" i18n>Correspondence Attachments</label>
                <app-file-upload
                        [files]="files"
                        [handleFileAdded]="handleFileAdded">
                </app-file-upload>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col">
                <label class="teth-checkbox-container" for="approve">
                    <input type="checkbox" class="teth-checkbox" id="approve"
                           name="approve"
                           [(ngModel)]="approve">
                    <span class="teth-checkbox-label" i18n>Correspondence is approved</span>
                    <span class="teth-checkmark"></span>
                </label>
            </div>
        </div>
    </div>
    <div class="footer-button">
        <button class="teth-button grey-button" (click)="closeModal()" i18n>Cancel</button>
        <button class="teth-button blue-button ml-3"
                [class.grey-button]="this.selectedCorrespondenceType == null || this.files?.length === 0"
                [class.disabled]="this.selectedCorrespondenceType == null || this.files?.length === 0"
                (click)="createCorrespondence()" i18n>
            Create Correspondence
        </button>
    </div>
</div>
