import {Injectable} from '@angular/core';
import {Referral} from '../models/referral.model';
import {environment} from '../../environments/environment';
import {ApiService} from './api.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {Attachment} from '../models/attachment.model';
import {Correspondence} from '../models/correspondence.model';
import {IntegrationLog} from '../models/integration-log.model';
import {Message, NewMessage} from '../models/message.model';
import {ReferralProcedure} from '../models/referral-procedure.model';
import {HydratedReferral} from '../models/hydrated-referral';
import {DeserializeHelper} from '../views/shared/utils/json-utils';
import {HydratedCorrespondence} from '../models/hydrated-correspondence';
import {SessionService} from './session.service';
import DateUtils from '../views/shared/utils/date-utils';
import {Patient} from '../models/patient.model';
import {Stage} from '../models/stage.model';
import {ReferralPreview} from '../models/referral-preview';
import {PermissionService} from './permission.service';
import {ReferralDuplicateCheck} from '../models/referral-duplicate-check.model';
import {TransferReferralRequestModel} from '../models/transferReferralRequest.model';
import {ManualReferralDetails} from '../models/manual-referral-details';
import {PreferredClinicLocation} from '../models/preferred-clinic-location';
import {OdataEndpoints} from './odata/odata-endpoints';
import {ODataQueryOptions} from '../models/odata-query-options';
import {map, tap} from 'rxjs/operators';
import {LabReferralPreview} from '../models/lab-referral-preview';
import {Appointment} from "../models/enum/appointment.model";
import {StageAppointmentFee} from "../models/stage-appointment-fee.model";

@Injectable({
    providedIn: 'root'
})

export class ReferralService {

    constructor(
        private api: ApiService,
        private sessionService: SessionService,
        private permissionService: PermissionService
    ) {
    }

    locationIncomingReferralPreviews = new BehaviorSubject<ReferralPreview[]>([]);
    locationOutgoingReferralPreviews = new BehaviorSubject<ReferralPreview[]>([]);
    locationIncomingReferralHydratedCorrespondences = new BehaviorSubject<HydratedCorrespondence[]>([]);
    locationOutgoingReferralHydratedCorrespondences = new BehaviorSubject<HydratedCorrespondence[]>([]);
    manualReferralDetails: ManualReferralDetails;

    private _numberOfLocationReferrals = new BehaviorSubject<Number>(null);
    public numberOfLocationReferrals$ = this._numberOfLocationReferrals as Observable<Number>;


    public getHydratedReferralsForLocation(locationId: number, startDate: Date = null, endDate: Date = null): Observable<HydratedReferral[]> {
        const route = `/Companies/${this.sessionService.getCompanyId()}/Locations/${locationId}/HydratedReferrals`;
        const params = new Map<string, string>();
        if (startDate != null) {
            params['CreatedDate[gte]'] = DateUtils.formatDateToQueryParamString(startDate);
        }
        if (endDate != null) {
            params['CreatedDate[lte]'] = DateUtils.formatDateToQueryParamString(endDate);
        }
        return this.api.recursiveGet<HydratedReferral[]>(route, params)
            .map(r => r.map(g => DeserializeHelper.deserializeToInstance(HydratedReferral, g)));
    }

    public getReferralPreviewsForLocation(incoming: boolean, locationId: number, filterPermissionId: number, startDate: Date = null, endDate: Date = null): Observable<ReferralPreview[]> {
        const currentSessionLocationId = this.sessionService.currentLocation.value?.id;
        const route = `/Companies/${this.sessionService.getCompanyId()}/Locations/${locationId}/referralpreviews`;
        const params = new Map<string, string>();
        params['incoming'] = incoming;
        if (startDate != null) {
            params['CreatedDate[gte]'] = DateUtils.formatDateToQueryParamString(startDate);
        }
        if (endDate != null) {
            params['CreatedDate[lte]'] = DateUtils.formatDateToQueryParamString(endDate);
        }
        return this.api.recursiveGet<ReferralPreview[]>(route, params)
            .map(r => {
                const hr = r.map(g => DeserializeHelper.deserializeToInstance(ReferralPreview, g));
                const referrals = this.filterReferralsByAssigned(hr, filterPermissionId, incoming);
                if (locationId === currentSessionLocationId) {
                    incoming ? this.locationIncomingReferralPreviews.next(referrals) : this.locationOutgoingReferralPreviews.next(referrals);
                }
                return referrals;
            });
    }

    public getHydratedReferral(referralId: string): Observable<HydratedReferral> {
        return this.api.get<HydratedReferral>(
            environment.apiBaseUrl + `/HydratedReferrals/${referralId}`)
            .map(r => DeserializeHelper.deserializeToInstance(HydratedReferral, r));
    }

    public getHydratedCorrespondenceForLocation(incoming: boolean, locationId: number, startDate, endDate: Date): Observable<HydratedCorrespondence[]> {
        const currentSessionLocationId = this.sessionService.currentLocation.value?.id;
        const route = `/Companies/${this.sessionService.getCompanyId()}/Locations/${locationId}/HydratedCorrespondence`;
        const params = new Map<string, string>();
        params['incoming'] = incoming;
        params['CreatedDate[gte]'] = DateUtils.formatDateToQueryParamString(startDate);
        params['CreatedDate[lte]'] = DateUtils.formatDateToQueryParamString(endDate);
        return this.api.recursiveGet<HydratedCorrespondence[]>(route, params)
            .map(c => {
                const hc = c.map(g => DeserializeHelper.deserializeToInstance(HydratedCorrespondence, g));
                if (locationId === currentSessionLocationId) {
                    incoming ? this.locationIncomingReferralHydratedCorrespondences.next(hc) : this.locationOutgoingReferralHydratedCorrespondences.next(hc);
                }
                return hc;
            });
    }

    public getHydratedCorrespondence(correspondenceId: string): Observable<HydratedCorrespondence> {
        return this.api.get<HydratedCorrespondence>(
            environment.apiBaseUrl + `/HydratedCorrespondence/${correspondenceId}`)
            .map(g => DeserializeHelper.deserializeToInstance(HydratedCorrespondence, g));
    }

    public createReferral(referral: Referral): Observable<Referral> {
        return this.api.post<Referral>(
            environment.apiBaseUrl + `/Referrals`, referral);
    }

    public updateReferral(referral: Referral): Observable<Referral> {
        return this.api.put<Referral>(
            environment.apiBaseUrl + `/Referrals/${referral.id}`, referral);
    }

    public getReferralAttachments(referralId: string): Observable<Attachment[]> {
        return this.api.get<Attachment[]>(
            environment.apiBaseUrl + `/Referrals/${referralId}/Attachments`)
            .map(r => r.map(d => DeserializeHelper.deserializeToInstance(Attachment, d)));
    }

    public deleteReferralAttachment(companyId: string, referralId, attachmentId: string): Observable<string> {
        return this.api.delete(
            environment.apiBaseUrl + `/Companies/${companyId}/Referrals/${referralId}/Attachments/${attachmentId}`,
            null,
            null,
            'text');
    }

    public getReferralCorrespondence(referralId: string): Observable<Correspondence[]> {
        const route = `/Referrals/${referralId}/Correspondence`;
        const params = new Map<string, string>();
        return this.api.recursiveGet<Correspondence[]>(route, params)
            .map(r => r.map(d => DeserializeHelper.deserializeToInstance(Correspondence, d)));
    }

    public createReferralCorrespondence(correspondence: Correspondence): Observable<Correspondence> {
        return this.api.post<Correspondence>(
            environment.apiBaseUrl + `/Referrals/${correspondence.referralId}/Correspondence`,
            correspondence);
    }

    public updateReferralCorrespondence(correspondence: Correspondence): Observable<Correspondence> {
        return this.api.put<Correspondence>(
            environment.apiBaseUrl + `/Referrals/${correspondence.referralId}/Correspondence/${correspondence.id}`,
            correspondence);
    }

    public createStage(stage: Stage, referralId: string): Observable<Stage> {
        return this.api.post<Stage>(environment.apiBaseUrl + `/referrals/${referralId}/stages`, stage);
    }

    public updateStage(stage: Stage, referralId: string): Observable<Stage> {
        return this.api.put<Stage>(environment.apiBaseUrl + `/referrals/${referralId}/stages/${stage.id}`, stage);
    }

    public getStage(referral: Referral): Observable<Stage> {
        return this.api.get<Stage>(environment.apiBaseUrl + `/referrals/${referral.id}/stages/${referral.stages[0].id}`);
    }

    public createStageAppointment(appointment: Appointment, referralId: string, stageId: string): Observable<Appointment> {
        return this.api.post<Appointment>(environment.apiBaseUrl + `/referrals/${referralId}/stages/${stageId}/appointments`, appointment);
    }

    public updateStageAppointment(appointment: Appointment, referralId: string): Observable<Appointment> {
        return this.api.put<Appointment>(environment.apiBaseUrl + `/referrals/${referralId}/stages/${appointment.referralStageId}/appointments/${appointment.id}`, appointment);
    }

    public deleteStageAppointment(appointment: Appointment, referralId: string): Observable<string> {
        return this.api.delete(
            environment.apiBaseUrl + `/referrals/${referralId}/stages/${appointment.referralStageId}/appointments/${appointment.id}`,
            null
        );
    }

    public getStageAppointmentFees(appointmentId: string): Observable<StageAppointmentFee[]> {
        return this.api.get<any>(environment.apiBaseUrl + `/appointments/${appointmentId}/fees`)
    }

    public createStageAppointmentFee(appointmentId: string, fee: StageAppointmentFee): Observable<StageAppointmentFee> {
        return this.api.post<StageAppointmentFee>(
            environment.apiBaseUrl + `/appointments/${appointmentId}/fees`,
            fee
        ).pipe(
            map(r => DeserializeHelper.deserializeToInstance(StageAppointmentFee, r)),
        );
    }

    public deleteStageAppointmentFee(appointmentId: string, feeId: string): Observable<string> {
        return this.api.delete(
            environment.apiBaseUrl + `/appointments/${appointmentId}/fees/${feeId}`,
            null
        );
    }

    public getReferralCorrespondenceAttachments(referralId: string, correspondenceId: string): Observable<Attachment[]> {
        return this.api.get<Attachment[]>(
            environment.apiBaseUrl + `/Referrals/${referralId}/Correspondence/${correspondenceId}/Attachments`)
            .map(r => r.map(d => DeserializeHelper.deserializeToInstance(Attachment, d)));
    }

    public getReferralIntegrationLogs(referralId: string): Observable<IntegrationLog[]> {
        const route = `/Referrals/${referralId}/IntegrationLogs`;
        return this.api.recursiveGet<IntegrationLog[]>(route)
            .map(r => r.map(d => DeserializeHelper.deserializeToInstance(IntegrationLog, d)));
    }

    public getReferralMessages(referralId: string): Observable<Message[]> {
        const route = `/Referrals/${referralId}/Messages`;
        return this.api.recursiveGet<Message[]>(route)
            .map(r => r.map(d => DeserializeHelper.deserializeToInstance(Message, d)));
    }

    public getReferralMessage(referralId, messageId: string): Observable<Message> {
        return this.api.get<Message>(
            environment.apiBaseUrl + `/Referrals/${referralId}/Messages/${messageId}`);
    }

    public createReferralMessage(referralId: string, message: NewMessage): Observable<Message> {
        return this.api.post<Message>(
            environment.apiBaseUrl + `/Referrals/${referralId}/Messages`, message);
    }

    public getReferralProcedures(referralId: string): Observable<ReferralProcedure[]> {
        const route = `/Referrals/${referralId}/Procedures`;
        return this.api.recursiveGet<ReferralProcedure[]>(route)
            .map(r => r.map(d => DeserializeHelper.deserializeToInstance(ReferralProcedure, d)));
    }

    public createReferralProcedure(referralId: string, procedure: ReferralProcedure): Observable<ReferralProcedure> {
        return this.api.post<ReferralProcedure>(
            environment.apiBaseUrl + `/referrals/${referralId}/procedures`,
            procedure);
    }

    public searchPatientsWithName(companyId: number, locationId: number, patientName: string): Observable<Patient[]> {
        const params = new Map<string, string>();
        params['patientName'] = patientName;
        const route = `/Companies/${companyId}/Locations/${locationId}/Patients`;
        return this.api.recursiveGet<Patient[]>(route, params)
            .map(r => r.map(d => DeserializeHelper.deserializeToInstance(Patient, d)));
    }

    public createPatient(companyId: number, locationId: number, patient: Patient): Observable<Patient> {
        const route = `/Companies/${companyId}/Locations/${locationId}/Patients`;
        return this.api.post<Patient>(
            environment.apiBaseUrl + route, patient)
            .map(r => DeserializeHelper.deserializeToInstance(Patient, r));
    }

    public updatePatient(companyId: number, locationId: number, patient: Patient): Observable<Patient> {
        const route = `/Companies/${companyId}/Locations/${locationId}/Patients/${patient.id}`;
        return this.api.put<Patient>(
            environment.apiBaseUrl + route, patient)
            .map(r => DeserializeHelper.deserializeToInstance(Patient, r));
    }

    public checkForDuplicateReferral(referralDuplicateCheck: ReferralDuplicateCheck): Observable<ReferralDuplicateCheck> {
        return this.api.post<ReferralDuplicateCheck>(
            environment.apiBaseUrl + `/referrals/checkforduplicatereferral`, referralDuplicateCheck)
            .map(r => DeserializeHelper.deserializeToInstance(ReferralDuplicateCheck, r));
    }

    public deleteReferral(referralId): Observable<string> {
        return this.api.delete(
            environment.apiBaseUrl + `/referrals/${referralId}`,
            null,
            null,
            'text');
    }

    public transferReferral(referralId: string, req: TransferReferralRequestModel): Observable<TransferReferralRequestModel> {
        return this.api.post<any>(
            environment.apiBaseUrl + `/referrals/${referralId}/transfer`, req);
    }

    filterReferralsByAssigned(referrals: ReferralPreview[], filterPermissionId: number, incomingReferrals: boolean): ReferralPreview[] {
        if (this.permissionService.permissionGranted(filterPermissionId)) {
            return referrals;
        }
        return referrals.filter(r => this.userCanAccessReferral(r, filterPermissionId, incomingReferrals));
    }

    userCanAccessReferral(hydratedReferral: HydratedReferral | ReferralPreview, filterPermissionId: number, incoming: boolean): boolean {
        if (this.permissionService.permissionGranted(filterPermissionId)) {
            return true;
        }
        const currentUser = this.sessionService.sessionContainer.value.employee;

        let referralIsUnassigned: boolean;
        let userIsAssignedToReferral: boolean;
        let userIsAssignedToPractitionerOnReferral: boolean;

        if (!incoming) {
            referralIsUnassigned = false;
            userIsAssignedToReferral = hydratedReferral.referringEmployeeId === currentUser.id;
            userIsAssignedToPractitionerOnReferral = currentUser.assignedPractitioners.map(ap => ap.practitionerEmployeeId).includes(hydratedReferral.referringEmployeeId);
        } else {
            referralIsUnassigned = hydratedReferral.assignedSpecialistId == null;
            userIsAssignedToReferral = hydratedReferral.assignedSpecialistId === currentUser.id;
            userIsAssignedToPractitionerOnReferral = currentUser.assignedPractitioners.map(ap => ap.practitionerEmployeeId).includes(hydratedReferral.assignedSpecialistId);
        }

        return referralIsUnassigned || userIsAssignedToReferral || userIsAssignedToPractitionerOnReferral;
    }

    public getPreferredClinicLocations(companyId: number, locationId: number): Observable<PreferredClinicLocation[]> {
        return this.api.get<PreferredClinicLocation[]>(
            environment.apiBaseUrl + `/companies/${companyId}/locations/${locationId}/preferredClinics`)
            .map(r => r.map(rr => DeserializeHelper.deserializeToInstance(PreferredClinicLocation, rr)));
    }

    public createPreferredClinicLocation(companyId: number, locationId: number, preferredClinic: PreferredClinicLocation): Observable<PreferredClinicLocation> {
        return this.api.post<PreferredClinicLocation>(
            environment.apiBaseUrl + `/companies/${companyId}/locations/${locationId}/preferredClinics`,
            preferredClinic)
            .map(r => DeserializeHelper.deserializeToInstance(PreferredClinicLocation, r));
    }

    public deletePreferredClinicLocation(companyId: number, locationId: number, preferredClinicId: number): Observable<string> {
        return this.api.delete(
            environment.apiBaseUrl + `/companies/${companyId}/locations/${locationId}/preferredClinics/${preferredClinicId}`,
            null,
            null,
            'text');
    }

    public getOldestSpecialistIncomingReferral(companyId: number, locationId: number): Observable<ReferralPreview[]> {
        const url = OdataEndpoints.getIncomingSpecialistReferrals(companyId, locationId);
        const oDataQuery = new ODataQueryOptions();
        oDataQuery.setOrderBy('createdDate asc');
        oDataQuery.setTop(1);
        oDataQuery.setCount(true);
        return this.api.getOdata<ReferralPreview>(url, oDataQuery).pipe(
            tap(response => this._numberOfLocationReferrals.next(response['@odata.count'])),
            map(response => DeserializeHelper.arrayOf(ReferralPreview, response.value))
        );
    }

    public getOldestDentalLabIncomingReferral(companyId: number, locationId: number): Observable<LabReferralPreview[]> {
        const url = OdataEndpoints.getIncomingDentalLabReferrals(companyId, locationId);
        const oDataQuery = new ODataQueryOptions();
        oDataQuery.setOrderBy('createdDate asc');
        oDataQuery.setTop(1);
        oDataQuery.setCount(true);
        return this.api.getOdata<LabReferralPreview>(url, oDataQuery).pipe(
            tap(response => this._numberOfLocationReferrals.next(response['@odata.count'])),
            map(response => DeserializeHelper.arrayOf(LabReferralPreview, response.value))
        );
    }
}
