<div class="modal-body">
    <app-loading
            [determinateLoading]="false"
            [hidden]="!loading"
            [loadingText]=""
            [fullscreen]="false"
            [backgroundColor]="'#FDFDFD'"
            [color]="'#388EFC'">
    </app-loading>
    <div class="modal-title" i18n>Transfer Referral</div>
    <form class="teth-textfield-form px-0" [formGroup]="transferInfoForm">
        <div class="row">
            <div class="col-12"
                [class.error]="transferInfoForm.controls.locationId.invalid
                && (transferInfoForm.controls.locationId.dirty || transferInfoForm.controls.locationId.touched)">
                <label class="teth-textfield-label" for="locationId" i18n>Referred Location</label>
                <select class="teth-dropdown teth-textfield-dropdown"
                        name="locationId"
                        id="locationId"
                        formControlName="locationId">
                    <option [ngValue]="null" selected disabled hidden i18n>Select a Location</option>
                    <option *ngFor="let location of locations"
                            [ngValue]="location.id">{{location.name}}</option>
                </select>
                <span class="teth-textfield-error" i18n>Select a Specialty.</span>
            </div>
            <div class="col-12 mt-3"
                [class.error]="transferInfoForm.controls.specialtyId.invalid
                && (transferInfoForm.controls.specialtyId.dirty || transferInfoForm.controls.specialtyId.touched)">
                <label class="teth-textfield-label" for="specialtyId" i18n>Specialty</label>
                <select class="teth-dropdown teth-textfield-dropdown"
                        name="specialtyId"
                        id="specialtyId"
                        formControlName="specialtyId">
                    <option [ngValue]="null" selected disabled hidden i18n>Select a Specialty</option>
                    <option *ngFor="let specialty of specialties"
                            [ngValue]="specialty.id">{{specialty.name}}</option>
                </select>
                <span class="teth-textfield-error" i18n>Select a Specialty.</span>
            </div>
            <div class="col-12 mt-3" formGroupName="procedureIds">
                <div class="teth-textfield-label mb-3"
                     *ngIf="filteredProcedures?.length > 0"
                     i18n>Procedures</div>
                <ng-container *ngFor="let p of filteredProcedures">
                    <label class="teth-checkbox-container" for="procedure{{p.id}}">
                        <input type="checkbox"
                               class="teth-checkbox"
                               id="procedure{{p.id}}"
                               formControlName="{{p.id}}"
                               name="procedure{{p.id}}">
                        <span class="check-box-label">{{p.name}}</span>
                        <span class="teth-checkmark"></span>
                    </label>
                </ng-container>
            </div>
        </div>
    </form>
    <div class="footer-button">
        <button class="teth-button grey-button" (click)="closeModal()">Cancel</button>
        <button class="teth-button blue-button ml-3"
                [class.disabled]="transferInfoForm.invalid && (transferInfoForm.dirty || transferInfoForm.touched)"
                (click)="transferReferral()">
            Transfer Referral
        </button>
    </div>
</div>
