import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-chevron-list-item',
  templateUrl: './chevron-list-item.component.html',
  styleUrls: ['./chevron-list-item.component.scss', '../../../stylesheet/stylesheet.component.scss']
})
export class ChevronListItemComponent {

  @Input() label: string;

}
