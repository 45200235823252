import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {File} from '../../../../models/file';
import {ImageCroppedEvent} from 'ngx-image-cropper';

@Component({
    selector: 'app-upload-image-modal',
    templateUrl: './upload-image-modal.component.html',
    styleUrls: ['./upload-image-modal.component.scss', '../../../stylesheet/stylesheet.component.scss']
})
export class UploadImageModalComponent implements OnInit {
    titleText = '';
    editTitleText = '';
    files: File[] = [];
    croppedImage: any = '';
    roundedOverlay = true;
    loadingImage = false;

    constructor(private activeModal: NgbActiveModal) {
    }

    ngOnInit() {

    }

    initWith(title: string, editTitle: string) {
        this.titleText = title;
        this.editTitleText = editTitle;
    }

    closeModal() {
        this.activeModal.close();
    }

    apply() {
        this.activeModal.close(this.croppedImage);
    }

    handleFileAdded(file: File) {
        if (file.type.indexOf('image') === -1) {
            this.files.length = 0;
        }
    }

    imageCropped(event: ImageCroppedEvent) {
        this.croppedImage = event.base64;
    }

    imageLoaded() {
        // show cropper
    }

    cropperReady() {
        // cropper ready
        this.loadingImage = false;
    }

    loadImageFailed() {
        // show message
    }
}
