export class FileUploadRequest {
    attachmentTypeId: number;
    fileName: string;
    mediaType: string;
    imageTakenDate: Date;

    constructor(attachmentTypeId: number, fileName, mediaType: string, imageTakenDate: Date = null) {
        this.attachmentTypeId = attachmentTypeId;
        this.fileName = fileName;
        this.mediaType = mediaType;
        this.imageTakenDate = imageTakenDate;
    }
}

export class ImageUploadRequest {
    fileName: string;
    mediaType: string;

    constructor(fileName, mediaType: string) {
        this.fileName = fileName;
        this.mediaType = mediaType;
    }
}

