import {Deserializable} from '../views/shared/utils/json-utils';
import * as moment from 'moment';

export class ReferralNotification implements Deserializable {
    public id: string;
    public referralId: string;
    public referringClinic: string;
    public referringDentist: string;
    public patientName: string;
    public productType: string;
    public isUrgent: boolean;
    public procedure: string;
    public completed: boolean;
    public completedDate: Date;
    public completedById: number;
    public completedBy: string;
    public createdBy: string;
    public createdByCompany: string;
    public createdById: number;
    public createdDate: Date;

    onDeserialize() {
        this.createdDate = moment.utc(this.createdDate?.toString()).toDate();
        this.completedDate = moment.utc(this.completedDate?.toString()).toDate();
    }
}

export class LabReferralNotification extends ReferralNotification {
    public productType: string;

    onDeserialize() {
    }
}
