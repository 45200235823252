import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {DataTableExtraFilterGroup} from "../../../../../../models/data-table-options";
import {BehaviorSubject, Observable} from "rxjs";
import {map} from "rxjs/operators";
import {GroupOption} from "../../../../../../models/group-option";

@Component({
  selector: 'app-nested-filter-options-form',
  templateUrl: './nested-filter-options-form.component.html',
  styleUrls: ['../../../../../stylesheet/stylesheet.component.scss', './nested-filter-options-form.component.scss']
})
export class NestedFilterOptionsFormComponent implements OnChanges {

  @Input() searchedFilterGroup: DataTableExtraFilterGroup;
  @Input() originalFilterGroup: DataTableExtraFilterGroup;
  @Output() selectAllClicked = new EventEmitter<void>()
  @Output() optionClicked = new EventEmitter<GroupOption>()

  private _searchedFilterGroup = new BehaviorSubject<DataTableExtraFilterGroup>(null);
  public searchedFilterGroup$ = this._searchedFilterGroup as Observable<DataTableExtraFilterGroup>;

  private _originalFilterGroup = new BehaviorSubject<DataTableExtraFilterGroup>(null);
  public originalFilterGroup$ = this._originalFilterGroup as Observable<DataTableExtraFilterGroup>;

  private _optionsAreVisible = new BehaviorSubject(false);
  public optionsAreVisible$ = this._optionsAreVisible as Observable<boolean>;
  setOptionsAreVisible = (visible: boolean) => this._optionsAreVisible.next(visible);

  public percentSelected$ = this.originalFilterGroup$.notNull().pipe(
      map(filterGroup => {
        const nOptions = filterGroup?.groupOptions?.length;
        const nChecked = filterGroup?.selectedOptions?.length;
        return (nChecked / nOptions) * 100;
      })
  );

  public displayIndeterminate$ = this.percentSelected$.pipe(
      map(percentSelected => percentSelected > 0 && percentSelected < 100)
  );

  public toggleOptionVisibilty(): void {
    this.optionsAreVisible$.once(areVisible => {
      this._optionsAreVisible.next(!areVisible);
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.searchedFilterGroup) {
        this._searchedFilterGroup.next(this.searchedFilterGroup);
    }
    if (changes.originalFilterGroup) {
        this._originalFilterGroup.next(this.originalFilterGroup);
    }
  }
}
