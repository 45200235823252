<div class="row">
    <div class="col" [hidden]="hidePositionText">
        <div class="position-text">
            <ng-container i18n>Showing</ng-container>
            <ng-container *ngIf="dataLength > 0">
                <span class="position-text-bold">{{beginAmount() + 1}}</span>
                <ng-container i18n>to</ng-container>
                <span class="position-text-bold">{{ endAmount() < dataLength ? endAmount() : dataLength}}</span>
                <ng-container i18n>of</ng-container>
            </ng-container>
            <span class="position-text-bold">{{dataLength}}</span>
            <ng-container i18n>results</ng-container>
        </div>
    </div>
    <ng-container *ngIf="useDayButtons">
        <div class="col-auto ml-auto">
            <ng-container *ngIf="dataLength > 0">
                <ul class="dt_pagination">
                    <li class="hidden-bullet mr-3" [class.disabled]="previousDisabled()">
                        <a (click)="previousClicked()" tabindex="0">
                            <button [disabled]="previousDisabled()" [class.grey-button]="previousDisabled()" class="teth-button blue-button day-button" i18n>Next Day</button>
                        </a>
                    </li>
                    <li class="hidden-bullet" [class.disabled]="nextDisabled()">
                        <a (click)="nextClicked()" aria-controls="DataTables_Table_0" data-dt-idx="5" tabindex="0">
                            <button [disabled]="nextDisabled()" [class.grey-button]="nextDisabled()" class="teth-button blue-button day-button" i18n>Previous Day</button>
                        </a>
                    </li>
                </ul>
            </ng-container>
        </div>
    </ng-container>
    <ng-container *ngIf="!useDayButtons">
        <div class="col-auto ml-auto">
            <div *ngIf="dataLength > 0">
                <ul class="pagination dt_pagination">
                    <li class="paginate_button page-item previous" [class.disabled]="previousDisabled()">
                        <a (click)="previousClicked()" tabindex="0" class="page-link">
                            <img class="pagination-arrow" [src]="'/assets/img/icons/chevron-left.svg'" alt=""/>
                        </a>
                    </li>
                    <ng-container *ngFor="let page of this.pages()">
                        <li *ngIf="isPageNumber(page)" class="paginate_button page-item page-item-number"
                            [class.active]="isActivePage(page)">
                            <a class="page-link" (click)="pageClicked(page)">
                                {{ page }}
                            </a>
                        </li>

                        <li *ngIf="page === '...'" class="paginate_button page-item page-item-ellipses">
                            <a aria-controls="DataTables_Table_0" data-dt-idx="5" tabindex="0"
                               class="page-link">
                                ...
                            </a>
                        </li>
                    </ng-container>
                    <li class="paginate_button page-item next" [class.disabled]="nextDisabled()">
                        <a (click)="nextClicked()" aria-controls="DataTables_Table_0" data-dt-idx="5" tabindex="0"
                           class="page-link">
                            <img class="pagination-arrow" [src]="'/assets/img/icons/chevron-right.svg'" alt=""/>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </ng-container>
</div>
