import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {
    DataTableExtraFilterGroup,
    DataTableExtraFilterOptions,
    DataTableOptions,
    DataTableSegmentOptions
} from '../../../models/data-table-options';
import {DataTableColumn, DatatableColumnType} from '../../../models/data-table-column';
import {DatePickerNavigationType, DatePickerOutput} from '../components/date-picker/date-picker.component';
import {NgbDate} from '@ng-bootstrap/ng-bootstrap';
import {LoadingOptions} from '../../../models/loading-options';
import {ReferralStatus} from '../../../models/referral-status.model';
import {LabsReferralStatusType} from '../../../models/labs-referral-status-type';
import {TimeRangeFilter} from '../../../models/time-range-filter';
import {BehaviorSubject, combineLatest, Observable, Subject} from 'rxjs';
import timeRangeUtils from '../utils/timeRange-utils';
import DateUtils from '../utils/date-utils';
import {DateService} from '../../../services/date.service';
import {debounceTime, distinctUntilChanged, map, take} from 'rxjs/operators';
import {GroupOption} from '../../../models/group-option';
import FuzzySearch from 'fuzzy-search';
import {DatatableOdataParam} from '../../../models/datatable-odata-param';
import {MatSidenav} from "@angular/material/sidenav";
import {DeserializeHelper} from "../utils/json-utils";

declare var $;


@Component({
    selector: 'app-datatable',
    templateUrl: './datatable.component.html',
    styleUrls: [
        './datatable.component.scss',
        '../../stylesheet/stylesheet.component.scss'
    ]
})

export class DatatableComponent implements OnInit, OnChanges {
    @Input() public options: DataTableOptions = new DataTableOptions();
    @Input() public dataSegments: DataTableSegmentOptions[] = [];
    @Input() public data: any[] = [];
    @Input() public loadingOptions: LoadingOptions = new LoadingOptions();
    @Input() public clearFilters: EventEmitter<any>;
    @Input() public applyFilters: EventEmitter<any>;
    @Input() public resetTable: EventEmitter<any>;
    @Input() public resetBulkEdit: EventEmitter<any>;
    @Input() public customTimeRangeOptions: TimeRangeFilter[] = [];
    @Input() public customTimeRangeFilterClass: string = null;
    @Input() public customTableClass: string = null;
    // To be used with Odata, will not apply filters to data in table, as Odata already filtered the data
    @Input() public bypassFiltering: boolean = false;

    @Output() public rowClickedEmitter = new EventEmitter();
    @Output() public primaryFilterChangedEmitter = new EventEmitter();
    @Output() public timeRangeChangedEmitter = new EventEmitter<TimeRangeFilter>();
    @Output() public bulkEditPressed = new EventEmitter<any[]>();
    @Output() public dataSegmentSelected = new EventEmitter<DataTableSegmentOptions>();
    @Output() public numberOfResultsChanged = new EventEmitter<number>();
    @Output() public resetFiltersButtonPressed = new EventEmitter<boolean>();
    @Output() public selectedOdataFilters = new EventEmitter<DatatableOdataParam[]>();
    @Output() public odataSearchValue = new EventEmitter<string>();

    @ViewChild('extraFilterModal') extraFilterModal: ElementRef;
    @ViewChild('slideout') slideout: MatSidenav;

    constructor(private dateService: DateService) {
    }

    public columnTypes = DatatableColumnType;
    public filteredData: any[] = [];
    public displayedData: any[] = [];
    public page: number = 0;
    public searchFilter: string = '';
    public searchFilterSubject: Subject<string> = new Subject<string>();
    public sortedColumn: any = null;
    public ascending: boolean;
    public numberOfEntries: string = '10';
    public numberOfEntriesInt: number = 10;
    public maxNumberOfPages: number = 9;
    public presentingExtraFilter$ = new BehaviorSubject<DataTableExtraFilterOptions>(new DataTableExtraFilterOptions());
    public presentingExtraFilterIsValid: boolean = false;
    public presentingMoreFilters: boolean = false;
    public datePickerNavTypes = DatePickerNavigationType;
    public datePickerOutputs = DatePickerOutput;
    public enableDatePickerContinueButton: boolean = false;
    public bulkEditPercentageChecked: number = 0;
    public bulkEditIndexes: number[] = [];
    // time range filter
    public timeRangeOptions: TimeRangeFilter[] = [];
    public selectedTimeRange = new BehaviorSubject<TimeRangeFilter>(null);
    public clearDateSelection = new EventEmitter();
    // slideout
    private _slideoutDatatableOptions = new BehaviorSubject<DataTableOptions>(null);
    public slideoutDatatableOptions$ = this._slideoutDatatableOptions as Observable<DataTableOptions>;
    public resetSlideout = new Subject<boolean>();
    resetSlideoutIfNeeded = (opened: boolean) => {
        if (!opened) {
            this.resetSlideout.next(true)
            setTimeout(
                () => this.resetSlideout.next(false),
                50
            );
        }
    }

    @ViewChild('dateRangeFilterModal') dateRangeFilterModal: ElementRef;

    public tempCustomTimeRange: TimeRangeFilter;

    // Fuzzy searching of filters
    public searchInput$ = new BehaviorSubject<string>(null);
    private _searchableItems = this.presentingExtraFilter$.pipe(
        map(f => f?.selectionOptions)
    );
    public filteredSearchableItems$ = combineLatest(
        [
            this.searchInput$,
            this._searchableItems
        ]
    ).pipe(
        debounceTime(50),
        map(([search, filterGroups]) => {
            const itemMap = new Map<string, GroupOption[]>();
            filterGroups.forEach(item => {
                const groupOptions = item.groupOptions;
                if (!search || search === ' ') {
                    itemMap.set(item.groupTitle, groupOptions);
                } else {
                    const searcher = new FuzzySearch(groupOptions, ['displayableName'], {caseSensitive: false});
                    itemMap.set(item.groupTitle, searcher.search(search) as GroupOption[]);
                }
            });
            return itemMap;
        })
    );

    // Localized extra filter modal search placeholder
    public localizedModalSearchPlaceholder$ = this.presentingExtraFilter$.pipe(
        map(f => {
            return $localize`Search for ${f?.filterName}`;
        })
    );

    public ngOnInit(): void {
        this.tableReset();
        if (this.clearFilters) {
            this.clearFilters.subscribe((_) => {
                this.clearAllFilters();
            });
        }
        if (this.applyFilters) {
            this.applyFilters.subscribe((_) => {
                this.applyExtraFilters();
            });
        }
        if (this.resetTable) {
            this.resetTable.subscribe((_) => {
                this.tableReset();
            });
        }
        if (this.resetBulkEdit) {
            this.resetBulkEdit.subscribe((_) => {
                this.bulkEditIndexes = [];
                this.bulkEditPercentageChecked = 0;
            });
        }
        this.applyDefaultTableSort();

        if (this.customTimeRangeOptions.length > 0) {
            this.timeRangeOptions = this.customTimeRangeOptions;
        } else {
            this.timeRangeOptions = timeRangeUtils.generateDefaultTimeRanges();
        }
        // setting the default timeRange to the first in the array of ranges
        this.selectedTimeRange.next(this.timeRangeOptions[0]);
        // emit the default value of the time range filter
        this.timeRangeChangedEmitter.emit(this.selectedTimeRange.value);

        this.searchFilterSubject
            .pipe(
                distinctUntilChanged(),
                debounceTime(500)
            )
            .subscribe(s => {
                this.searchFilter = s;
                this.odataSearchValue.emit(s);
                if (!this.bypassFiltering) {
                    this.applySearchFilter();
                }
            });
    }

    public ngOnChanges(changes: SimpleChanges): void {
        this.tableReset();
    }

    public entriesChanged(_): void {
        this.numberOfEntriesInt = parseInt(this.numberOfEntries, 10);
        this.numberOfResultsChanged.emit(this.numberOfEntriesInt);
        this.tableReset();
        this.pageClicked(0);
    }

    public applySearchFilter() {
        const value = this.searchFilter?.toLowerCase() ?? '';
        const columns = this.options.columns;
        this.resetPagination();

        const activeFilters = this.filtersAreActive();
        if (activeFilters) {
            // must apply the extra filters before the search text to properly set this.filteredData
            this.applyExtraFilters();
        } else {
            // clear bulk selection
            this.clearBulkSelection();
        }
        // if extra filters are applied we want to filter down this.filteredData not this.data
        this.filteredData = ((activeFilters ? this.filteredData : this.data) ?? []).filter((d) => {
            return (columns || [])
                .map((col) => {
                    const cellValue = this.getCellContent(d, col) + this.getAllCellSubtextContent(d, col);
                    return (
                        cellValue.toString()
                            .toLowerCase()
                            .indexOf(value) !== -1
                    );
                })
                .reduceRight((accu, curr) => {
                    return accu || curr;
                });
        });

        this.paginate();
    }

    public searchFilterChanged(event): void {
        this.searchFilterSubject.next(event);
    }

    public headerClicked(event: any, column: any): void {
        this.resetPagination();

        if (this.sortedColumn === column && this.ascending === true) {
            this.ascending = false;
        } else if (this.sortedColumn === null) {
            this.sortedColumn = column;
            this.ascending = true;
        } else if (this.sortedColumn !== column) {
            this.sortedColumn = column;
            this.ascending = true;
        } else {
            this.sortedColumn = null;
            this.ascending = false;
        }

        if (this.sortedColumn !== null) {
            this.sortByColumn();
        } else if (this.filtersAreActive()) {
            this.applyDefaultTableSort();
            this.paginate();
        } else {
            this.tableReset();
        }
    }

    public isAscending(column: any): boolean {
        return this.sortedColumn === column && this.ascending;
    }

    public isDescending(column: any): boolean {
        return this.sortedColumn === column && !this.ascending;
    }

    public pageClicked(pageNumber: number): void {
        this.page = pageNumber;
        this.paginate();
    }

    public beginAmount(): number {
        return this.page * this.numberOfEntriesInt;
    }

    public endAmount(): number {
        return this.page * this.numberOfEntriesInt + this.numberOfEntriesInt;
    }

    public getCellContent(row: any, column: any): string {
        if (column.type === this.columnTypes.Checkbox) {
            return '';
        } else if (column.method) {
            return column.method(row);
        } else if (column.id === null) {
            console.log('Column id not set! Unable to get cell content.');
            return row;
        } else {
            let result = this.getPropertyValue(row, column.id);
            if (!result) {
                result = '';
            }
            if (column.template !== undefined) {
                return column.template.replace('$0', result);
            } else {
                return result;
            }
        }
    }

    public getCellSecondaryContent(row: any, column: any): string {
        if ((column as DataTableColumn).secondaryDataMethod) {
            return (column as DataTableColumn).secondaryDataMethod(row);
        } else {
            return '';
        }
    }

    public getCellSubtextHeader(row: any, column: any, index: number): string {
        if (column.subtext) {
            return column.subtext[index].name;
        } else {
            return '';
        }
    }

    public getCellDifferential(row: any, column: any): number {
        if (column.differentialMethod) {
            return column.differentialMethod(row);
        } else {
            return 0;
        }
    }

    public getPercentChangeIconName(row: any, column: any): string {
        const differential = this.getCellDifferential(row, column);
        if (differential === 0) {
            return 'minus.svg';
        }
        return differential >= 0 ? 'trending-up.svg' : 'trending-down.svg';
    }

    public getCellSubtextContent(row: any, column: any, index: number): string {
        if (column.subtext) {
            return column.subtext[index].method(row);
        } else {
            return '';
        }
    }

    public getAllCellSubtextContent(row: any, column: any): string {
        let result = '';
        column?.subtext?.forEach(s => {
            result += s.method(row);
        });
        return result;
    }

    getCellToolTip(row: any, column: any) {
        if (column.tooltip) {
            return column.tooltip(row);
        }
    }

    public buttonSelected(row: any, section: any) {
        this.rowClickedEmitter.emit([row, section]);
    }

    public rowClicked(row: any, _: number): void {
        this.rowClickedEmitter.emit(row);
    }

    public getBackgroundColor(index: number): string {
        // Urgent referrals that are not completed or declined
        if (this.displayedData[index]?.isUrgent &&
            (![ReferralStatus.CompletedId, ReferralStatus.DeclinedId]?.includes(this.displayedData[index]?.statusType?.id))) {
            return '#ffeded';
        } else {
            return index % 2 ? '#F2F2F2' : '#FDFDFD';
        }
    }

    public getDifferentialClass(row: any, column: any): string {
        const differential = this.getCellDifferential(row, column);
        if (differential === 0) {
            return 'trend-flat';
        } else {
            return differential > 0 ? 'trend-up' : 'trend-down';
        }
    }

    public getBackgroundColorClass(index: number): string {
        if (this.options.statusColorBandedRows) {
            if (this.data[0].hasOwnProperty('dentalLabsProductTypeId')) {
                return LabsReferralStatusType.getStatusColorClass(this.displayedData[index]?.statusTypeId);
            } else {
                return ReferralStatus.getStatusColorClass(this.displayedData[index]?.statusType?.id);
            }
        } else {
            return '';
        }
    }

    public getCellClassName(row: any, column: any): string {
        let classNames = '';
        if ((column.className && column.className !== '') || column.classNameMethod !== null) {
            const cellContent = this.getCellContent(row, column);
            if (cellContent && cellContent !== '' && cellContent !== '--') {
                // append status class name if applicable
                if (column.classNameMethod) {
                    const computedClassName = column.classNameMethod(row);
                    if (column.className) {
                        classNames += column.className + ' ' + computedClassName;
                    } else {
                        classNames += computedClassName;
                    }
                } else {
                    classNames += column.className;
                }
            }
        }
        return classNames;
    }

    public resetSearch() {
        this.searchFilter = '';
        this.applySearchFilter();
    }

    public clearAllFilters() {
        // clear all filters
        this.options.extraFilterOptions.forEach((extraFilter) => {
            extraFilter.clearFilter();
        });
        this.options.moreFilterOptions.forEach((opt) => {
            opt.group.clear();
        });
        // clear search criteria
        this.resetSearch();

        // clear external filters
        if (this.options.clearExternalFiltersMethod) {
            this.options.clearExternalFiltersMethod();
        }
        this.applyDefaultTableSort();
        this.paginate();
        this.selectedOdataFilters.emit([]);
        this.odataSearchValue.emit(null);
        this.resetFiltersButtonPressed.emit(true);
    }

    public handleClearAllFiltersClicked() {
        this.slideout.close().then(() => this.clearAllFilters());
    }

    public primaryFilterIsSelected(filterOption: any): boolean {
        return this.options.primaryFilterOptions.activePrimaryFilter === filterOption;
    }

    public getPrimaryFilterValue(filterOption: any): string {
        if (filterOption.hasOwnProperty(this.options.primaryFilterOptions.primaryFilterDisplayProperty)) {
            return filterOption[this.options.primaryFilterOptions.primaryFilterDisplayProperty];
        } else {
            return '';
        }
    }

    public primaryFilterChanged() {
        this.clearAllFilters();
        this.primaryFilterChangedEmitter.emit(this.options.primaryFilterOptions.activePrimaryFilter);
    }

    public dataSegmentPressed(segment: DataTableSegmentOptions) {
        this.clearAllFilters();
        this.dataSegmentSelected.emit(segment);
    }

    public bulkEditButtonPressed() {
        const bulkEditData: any[] = [];
        this.bulkEditIndexes.forEach((dataIndex) => {
            bulkEditData.push(this.displayedData[dataIndex]);
        });
        this.bulkEditPressed.emit(bulkEditData);
    }

    public showExtraFilterModal() {
        $(this.extraFilterModal.nativeElement).modal('show');
    }

    public openExtraFilterOptionsModal(filterOptions: DataTableExtraFilterOptions) {
        filterOptions.setInitialValues();
        this.presentingExtraFilter$.next(filterOptions);
        this.showExtraFilterModal();
    }

    public clearExtraFilterPressed() {
        this.presentingExtraFilter$.pipe(take(1)).subscribe(filterOps => {
            // set validation to false for the cleared out filter
            this.presentingExtraFilterIsValid = false;
            // handle the presenting filter accordingly
            if (this.presentingMoreFilters) {
                this.options.moreFilterOptions.forEach((opt) => {
                    opt.group.clear();
                });
                // clear the active more filter
                this.presentingMoreFilters = false;
            } else {
                // clear the data for the extra filter
                const copy = Object.assign(new DataTableExtraFilterOptions(), filterOps);
                copy.clearFilter();
                this.presentingExtraFilter$.next(copy);
                // clear the active extra filter
                this.clearPresentingExtraFilter();
            }
            // close the modal
            $(this.extraFilterModal.nativeElement).modal('hide');
            this.searchInput$.next(null);
            // apply the current extra filters
            this.applyExtraFilters();
        });
    }

    public cancelExtraFilterPressed() {
        if (this.presentingMoreFilters) {
            // clear the active more filter
            this.presentingMoreFilters = false;
        } else {
            // clear the active extra filter
            this.clearPresentingExtraFilter();
        }
        // close the modal
        $(this.extraFilterModal.nativeElement).modal('hide');
        this.searchInput$.next(null);
    }

    public continueExtraFilterPressed() {
        this.presentingExtraFilter$.pipe(take(1)).subscribe(filterOps => {
            if (this.presentingMoreFilters) {
                this.setMoreFilterInitialValues();
                // clear the active more filter
                this.presentingMoreFilters = false;
            } else {
                const copy = Object.assign(new DataTableExtraFilterOptions(), filterOps);
                copy.setInitialValues();
                this.presentingExtraFilter$.next(copy);
                // clear the active extra filter
                this.clearPresentingExtraFilter();
            }
            // close the modal
            $(this.extraFilterModal.nativeElement).modal('hide');
            // clear the search bar before applying extra filters
            this.searchFilter = '';
            this.searchInput$.next(null);
            // apply the current extra filters
            if (filterOps.isDateFilter) {
                const i = this.options.extraFilterOptions.findIndex(opt => opt.isDateFilter);
                if (i !== -1) {
                    this.options.extraFilterOptions[i].selectedDates = filterOps.selectedDates;
                }
            }
            this.applyExtraFilters();
        });
    }

    public clearPresentingExtraFilter() {
        this.presentingExtraFilter$.next(new DataTableExtraFilterOptions());
    }

    public handleFilterDateSelected(dates: NgbDate[]) {
        this.presentingExtraFilter$.pipe(take(1)).subscribe(filterOps => {
            filterOps.selectedDates = dates;
            this.presentingExtraFilterIsValid = filterOps.isActive();
            const copy = Object.assign(new DataTableExtraFilterOptions(), filterOps);
            copy.selectedDates = dates;
            this.presentingExtraFilter$.next(copy);
        });
    }

    public removeFilter(filter: DataTableExtraFilterOptions) {
        filter?.selectionOptions?.forEach((opt) => {
            opt.selectedOptions = [];
        });
        this.applyExtraFilters();
    }

    public applyExtraFilters() {
        // apply all extra filters to the data set
        let extraFilteredData: any[] = this.data ?? [];
        const activeOdataFilters: DatatableOdataParam[] = [];
        // clear any bulk edit selection
        this.clearBulkSelection();
        // iterate over extra filters
        this.options.extraFilterOptions.forEach((extraFilter) => {
            if (extraFilter.isActive()) {
                let selectedValues = extraFilter.selectionOptions
                    .flatMap(opt => opt.selectedOptions)
                    .map(opt => opt.value);
                if (extraFilter.isDateFilter) {
                    selectedValues = extraFilter.selectedDates;
                }
                activeOdataFilters.push(
                    new DatatableOdataParam(extraFilter.filterPropertyName, selectedValues, extraFilter.filterId)
                );
            }
            extraFilteredData = extraFilteredData.filter((d) => {
                if (extraFilter.isActive()) {
                    // only apply the filter if its active
                    const propertyValue = this.getPropertyValue(d, extraFilter.filterPropertyName);
                    return extraFilter.valueSatisfiesFilter(propertyValue);
                } else {
                    return true;
                }
            });
        });
        // iterate over more filters
        this.options.moreFilterOptions.forEach((filterOption) => {
            extraFilteredData = extraFilteredData.filter((d) => {
                if (filterOption.group.selectedOptions.length > 0) {
                    // only apply the filter if its active
                    const propertyValue = this.getPropertyValue(d, filterOption.filterPropertyName);
                    if (propertyValue !== null) {
                        return filterOption.group.valueSatisfiesFilter(propertyValue);
                    } else {
                        return false;
                    }
                } else {
                    return true;
                }
            });
        });

        this.selectedOdataFilters.emit(activeOdataFilters);

        if (this.bypassFiltering) {
            this.filteredData = this.data ?? [];
        } else {
            this.filteredData = extraFilteredData;
        }

        if (this.sortedColumn !== null) {
            this.sortByColumn();
        } else {
            this.applyDefaultTableSort();
        }
        this.paginate();
    }

    public applySlideoutFilters(extraFilters: DataTableExtraFilterOptions[]) {
        this.slideout.close().then(() => {
            this.options.extraFilterOptions = extraFilters;
            this.applyExtraFilters();
        });
    }

    public getPropertyValue(obj: any, propertyName: string): any {
        const objectPath = propertyName.split('.');
        let result = obj;
        for (const i of objectPath) {
            if (result && result.hasOwnProperty(i)) {
                result = result[i];
            } else {
                result = null;
                break;
            }
        }
        return result;
    }

    public filtersAreActive(): boolean {
        let active = false;
        this.options.extraFilterOptions.forEach((extraFilter) => {
            if (extraFilter.isActive()) {
                active = true;
            }
        });
        if (this.options.moreFiltersAreActive()) {
            active = true;
        }
        return active;
    }

    public extraFilterSelectionMade(option: GroupOption, optionGroup: DataTableExtraFilterGroup) {
        this.presentingExtraFilter$.pipe(take(1)).subscribe(filterOps => {
            const selectedIndex = optionGroup.selectedOptions.indexOf(option);
            if (selectedIndex >= 0) {
                optionGroup.selectedOptions.splice(selectedIndex, 1);
            } else {
                optionGroup.selectedOptions.push(option);
            }
            // check for cta validation
            if (this.presentingMoreFilters) {
                this.presentingExtraFilterIsValid = this.options.moreFiltersAreActive();
            } else {
                this.presentingExtraFilterIsValid = filterOps?.isActive();
            }
        });
    }

    // Bulk Edit Logic

    public selectAllClicked(event) {
        const checked = event.target.checked;
        this.bulkEditPercentageChecked = (this.displayedData.length === 0) ? 0 : checked ? 1 : 0;
        if (checked) {
            // select all displayed indexes
            let i = 0;
            this.displayedData.forEach((_) => {
                this.bulkEditIndexes.push(i);
                i++;
            });
        } else {
            this.bulkEditIndexes = [];
        }
    }

    public selectSingleOptionClicked(event, index) {
        const checked = event.target.checked;
        const indexPosition = this.bulkEditIndexes.indexOf(index);
        if (checked && indexPosition === -1) {
            // add index
            this.bulkEditIndexes.push(index);
        } else if (!checked && indexPosition > -1) {
            // remove index
            this.bulkEditIndexes.splice(indexPosition, 1);
        }
        this.bulkEditIndexes.sort();
        this.setCheckAllState();
    }

    public setCheckAllState() {
        this.bulkEditPercentageChecked = (this.displayedData.length === 0) ? 0 : this.bulkEditIndexes.length / this.displayedData.length;
    }

    public clearBulkSelection() {
        this.bulkEditIndexes = [];
        this.bulkEditPercentageChecked = 0;
    }

    // More Filters Logic

    public openMoreFilterOptionsModal() {
        this.setMoreFilterInitialValues();
        this.presentingMoreFilters = true;
        this.showExtraFilterModal();
    }

    public setMoreFilterInitialValues() {
        this.options.moreFilterOptions.forEach((opt) => {
            opt.group.setInitialSelectionCount();
        });
    }

    // Private Methods

    private paginate(): void {
        const begin = this.beginAmount();
        const end = this.endAmount();
        this.displayedData = this.filteredData.slice(begin, end);
    }

    private tableReset(): void {
        if (this.data) {
            this.filteredData = JSON.parse(JSON.stringify(this.data));

            if (this.searchFilter) {
                this.applySearchFilter();
            }
            this.applyDefaultTableSort();
            this.applyExtraFilters();
        }
    }

    private applyDefaultTableSort() {
        if (this.filteredData.length > 0 && this.options.defaultTableSorting) {
            this.filteredData = this.filteredData.sort((one, two) => {
                return (two?.highSortPriority - one?.highSortPriority) || this.options.defaultTableSorting(this.getCellContent(one, this.options.defaultTableSortColumn), this.getCellContent(two, this.options.defaultTableSortColumn));
            });
        } else if (this.options.defaultTableSorting) {
            this.data = this.data?.sort((one, two) => {
                return (two?.highSortPriority - one?.highSortPriority) || this.options.defaultTableSorting(this.getCellContent(one, this.options.defaultTableSortColumn), this.getCellContent(two, this.options.defaultTableSortColumn));
            });
        }
    }

    private resetPagination(): void {
        this.page = 0;
    }

    private sortByColumn(): void {
        this.filteredData = this.filteredData.sort((one, two) => {
            let sort;
            if (this.sortedColumn.customSorting) {
                sort = this.sortedColumn.customSorting(this.getCellContent(one, this.sortedColumn), this.getCellContent(two, this.sortedColumn));
            } else {
                // default sort for standard text
                sort = ('' + this.getCellContent(one, this.sortedColumn)).localeCompare(
                    this.getCellContent(two, this.sortedColumn),
                    'en',
                    { numeric: true }
                );
            }
            if (this.ascending) {
                return (two?.highSortPriority - one?.highSortPriority) || sort;
            } else {
                return (two?.highSortPriority - one?.highSortPriority) || (sort * -1);
            }
        });
        this.paginate();
    }

    public formatPercentText(differential: number): string {
        if (differential === 0) {
            return '0%';
        }
        const pc = differential;
        if (pc) {
            return pc.toFixed(0) + '%';
        }
        return '';
    }

    public newTimeRangeSelected(ev) {
        // clear out extra custom date ranges
        this.selectedTimeRange.next(ev);
        this.timeRangeOptions = this.timeRangeOptions.filter((opt) => !opt.customName);
        if (this.selectedTimeRange.value.customDateRange) {
            this.tempCustomTimeRange = new TimeRangeFilter();
            // open date picker modal and set the time range start/end dates
            this.clearDateSelection.emit();
            this.enableDatePickerContinueButton = false;
            $(this.dateRangeFilterModal.nativeElement).modal('show');
        } else {
            this.timeRangeOptions.map((tr) => tr.clearCustomRange());
            // emit the value selected
            this.timeRangeChangedEmitter.emit(this.selectedTimeRange.value);
        }
    }

    public handleCustomDateRangeSelection(dates: NgbDate[]) {
        this.enableDatePickerContinueButton = dates.length === 2;
        if (dates.length === 2) {
            this.tempCustomTimeRange.startDate = DateUtils.startOfDay(DateUtils.getDateFromNgbDate(dates[0]));
            this.tempCustomTimeRange.endDate = DateUtils.endOfDay(DateUtils.getDateFromNgbDate(dates[1]));
        }
    }

    public cancelCustomDateRangePressed() {
        // reset the temp custom time range
        this.tempCustomTimeRange = null;
        this.selectedTimeRange.next(this.timeRangeOptions[0]);
        // emit the value selected
        this.timeRangeChangedEmitter.emit(this.selectedTimeRange.value);
        // close the modal
        $(this.dateRangeFilterModal.nativeElement).modal('hide');
    }

    public continueCustomDateRangePressed() {
        this.setCustomTimeRange(this.tempCustomTimeRange);
        // close the modal
        $(this.dateRangeFilterModal.nativeElement).modal('hide');
    }

    public setCustomTimeRange(customTimeRange: TimeRangeFilter) {
        customTimeRange.setCustomName(this.dateService.getSessionDateFormat());
        this.timeRangeOptions.push(customTimeRange);
        this.selectedTimeRange.next(customTimeRange);
        // emit the value selected
        this.timeRangeChangedEmitter.emit(this.selectedTimeRange.value);
    }

    // Extra Filtering Modal Search

    public searchChanged(e: Event) {
        const target = e?.target as HTMLInputElement;
        this.searchInput$.next(target?.value);
    }

    public toggleSlideout(): void {
        if (!this.slideout.opened) {
            const slideoutOptsCopy = DeserializeHelper.deserializeToInstance(DataTableOptions, this.options);
            this._slideoutDatatableOptions.next(slideoutOptsCopy);
        }
        this.slideout.toggle().then();
    }
}
