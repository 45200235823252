<div class="datatable-wrapper">
    <div class="row" *ngIf="options.tableName && options.tableName !== ''">
        <div class="col col-lg-12">
            <div class="table-name">
                {{ options.tableName }}
            </div>
        </div>
    </div>

    <div class="datatable-loading-wrapper">
        <!-- Loading Logic -->
        <app-loading [hidden]="!loadingOptions.isLoading" [loadingText]="loadingOptions.loadingText"
                     [fullscreen]="loadingOptions.fullscreen" [backgroundColor]="loadingOptions.backgroundColor"
                     [color]="loadingOptions.color" [topMarginRem]="loadingOptions.topMarginRem"
                     [spinnerColor]="loadingOptions.spinnerColor">
        </app-loading>

        <div class="row filtering-options mx-0">
            <div class="col-sm-12 col-md-9 datatable-segmented-controls" *ngIf="dataSegments.length > 1">
                <div class="segment-control-container" *ngFor="let segment of dataSegments"
                     [ngClass]="{ 'active': segment.selected }" (click)="dataSegmentPressed(segment)">
                    <div class="segment-control-title">
                        {{ segment.title }}
                        <span class="nav-item-notification-pill ml-2" *ngIf="segment.showCountBadge">
                                {{ segment.options?.data?.length }}
                            </span>
                    </div>
                    <div class="segment-control-indicator"></div>
                </div>
            </div>
            <!-- Time Range Filter -->
            <div class="col d-flex justify-content-end pr-0" [ngClass]="customTimeRangeFilterClass" *ngIf="options.showDateFilter">
                <form class="teth-textfield-form form-noPadding">
                    <select class="teth-dropdown teth-textfield-dropdown" name="timeRangeFilter" id="timeRangeFilter"
                            [ngModel]="selectedTimeRange.value" (ngModelChange)="newTimeRangeSelected($event)">
                        <option *ngFor="let tr of timeRangeOptions" [ngValue]="tr">
                            {{ tr.customName && tr.customName !== '' ? tr.customName : tr.name }}
                        </option>
                    </select>
                </form>
            </div>
        </div>

        <div class="d-flex filtering-options flex-wrap" *ngIf="!options.hideFilteringOptions">
            <div class="d-flex align-items-end search-input-container" [style.gap.rem]="1.5">
                <!-- Search Input -->
                <div class="search-input">
                    <form class="teth-textfield-form form-noPadding mb-0">
                        <label class="teth-textfield-label" for="textfield6" i18n>Search to filter results</label>
                        <input class="teth-textfield search-textfield" type="text" id="textfield6" name="textfield6"
                               placeholder="Enter name, procedure, etc." i18n-placeholder
                               [(ngModel)]="searchFilter"
                               (ngModelChange)="searchFilterChanged($event)">
                        <button class="clear-search" type="reset" (click)="resetSearch()" i18n>
                            CLEAR
                        </button>
                        <span class="teth-textfield-error"></span>
                    </form>
                </div>
            </div>
            <div class="d-flex align-items-end" [style.gap.rem]="1.5">
                <!-- Primary Filter -->
                <div class="primary-filter">
                    <form class="teth-textfield-form form-noPadding mb-0">
                        <label class="teth-textfield-label" for="primaryFilter">
                            {{ options.primaryFilterOptions?.primaryFilterLabel }}
                        </label>
                        <select class="teth-dropdown teth-textfield-dropdown" name="primaryFilter" id="primaryFilter"
                                [(ngModel)]="options.primaryFilterOptions.activePrimaryFilter"
                                (ngModelChange)="primaryFilterChanged()"
                                [disabled]="options.primaryFilterOptions.primaryFilterData?.length === 1"
                                [ngClass]="{ 'disabled': options.primaryFilterOptions.primaryFilterData?.length === 1 }">
                            <option *ngFor="let filterOption of options.primaryFilterOptions.primaryFilterData"
                                    [selected]="primaryFilterIsSelected(filterOption)" [ngValue]="filterOption">
                                {{ getPrimaryFilterValue(filterOption) }}
                            </option>
                        </select>
                    </form>
                </div>
                <!-- Pagination Result Count -->
                <div class="pagination-dropdown">
                    <form class="teth-textfield-form form-noPadding mb-0">
                        <label class="teth-textfield-label" for="numberOfResults" i18n>
                            Number of Results
                        </label>
                        <select class="teth-dropdown teth-textfield-dropdown" name="numberOfResults" id="numberOfResults"
                                [(ngModel)]="numberOfEntries" (ngModelChange)="entriesChanged($event)">
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                    </form>
                </div>
                <!-- Filters Button -->
                <div *ngIf="options?.extraFilterOptions?.length > 0 && options.useSlideoutFiltering" class="filters-button">
                    <button
                            class="teth-button grey-button d-flex align-items-center justify-content-center"
                            [style.height.rem]="2.5"
                            [style.gap.rem]="0.5"
                            (click)="toggleSlideout()">
                        <span i18n>Filters</span><img class="ml-1" src="assets/img/icons/filter.svg" alt="Filter icon" [style.width.rem]="1">
                    </button>
                </div>
            </div>
        </div>

        <div class="row extra-filtering-options" *ngIf="!options.hideFilteringOptions && options?.extraFilterOptions?.length > 0"
             [ngStyle]="{ 'margin-bottom': bulkEditPercentageChecked === 0 ? '3rem' : '1.5rem' }">
            <div class="col extra-filtering-pills">
                <ng-container *ngFor="let extraFilter of options.extraFilterOptions">
                    <ng-container *ngIf="options.useSlideoutFiltering else modalOpenButton">
                        <button *ngIf="extraFilter.isActive()" class="teth-pill active extra-filtering-pill"
                                (click)="removeFilter(extraFilter)">
                            {{ extraFilter.getFilterName() }}
                            <span class="nav-item-notification-pill ml-1" *ngIf="!!extraFilter.count">{{extraFilter.count}}</span>
                        </button>
                    </ng-container>
                    <ng-template #modalOpenButton>
                        <button class="teth-pill hide-x extra-filtering-pill"
                                [ngClass]="{ 'active': extraFilter.isActive() }" (click)="openExtraFilterOptionsModal(extraFilter)">
                            {{ extraFilter.getFilterName() }}
                            <span class="nav-item-notification-pill ml-1" *ngIf="!!extraFilter.count">{{extraFilter.count}}</span>
                        </button>
                    </ng-template>
                </ng-container>
                <!-- Add a pill for 'More' filtering options -->
                <button class="teth-pill hide-x extra-filtering-pill" *ngIf="options.moreFilterOptions.length > 0"
                        [ngClass]="{ 'active': options.moreFiltersAreActive() }" (click)="openMoreFilterOptionsModal()">
                    {{ options.getMoreFiltersName() }}
                </button>
            </div>
            <!-- Add a pill for 'Clear Filters' if enabled -->
            <div class="col-auto pl-0" *ngIf="options.showClearFiltersButton && (options.moreFiltersAreActive() || options.extraFilterOptionsAreActive() || options.externalFiltersAreActive())">
                <button class="teth-button white-button bordered-button" (click)="clearAllFilters()" i18n>
                    Clear Filters
                </button>
            </div>
        </div>

        <div class="row bulk-edit-button" *ngIf="bulkEditPercentageChecked > 0">
            <div class="col-sm-12">
                <button class="teth-button blue-button" (click)="bulkEditButtonPressed()">
                    {{ options.bulkEditText }}
                </button>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-12">
                <table class="table teth-datatable table-hover" [ngClass]="customTableClass">
                    <thead>
                    <tr *ngIf="options && options.columns">
                        <th *ngFor="let column of options.columns" (click)="headerClicked($event, column)"
                            [class.sorting_asc]="isAscending(column)" [class.sorting_desc]="isDescending(column)"
                            [ngClass]="{ 'disable-sorting': column.disableSorting }" class="table-header sorting">
                            <!-- Bulk Edit Checkbox -->
                            <ng-container *ngIf="column.type === columnTypes.Checkbox">
                                <label class="teth-checkbox-container" for="checkbox-bulk-all">
                                    <input type="checkbox" class="teth-checkbox" id="checkbox-bulk-all"
                                           name="checkbox-bulk-all"
                                           [ngClass]="{ 'indeterminate': bulkEditPercentageChecked > 0 && bulkEditPercentageChecked < 1 }"
                                           [checked]="bulkEditPercentageChecked === 1"
                                           (change)="selectAllClicked($event)">
                                    <span class="teth-checkbox-label"></span>
                                    <span class="teth-checkmark"></span>
                                </label>
                            </ng-container>
                            <!-- Column Title -->
                            <ng-container *ngIf="column.type !== columnTypes.Checkbox">
                                {{ column.title }}
                            </ng-container>
                            <!-- Sorting Icon -->
                            <span class="sorting-icon"></span>
                        </th>
                    </tr>
                    </thead>
                    <tbody [hidden]="displayedData.length === 0">
                    <tr *ngFor="let row of displayedData; let i = index;"
                        [ngStyle]="{ 'background-color': getBackgroundColor(i)}" class="datatable-row"
                        [ngClass]="getBackgroundColorClass(i)">

                        <ng-template ngFor let-column [ngForOf]="options.columns" let-columnIndex="index">

                            <!-- Handle Button Type Layout -->
                            <td *ngIf="column.type === columnTypes.Button"
                                [ngbTooltip]="getCellToolTip(row, column)"
                                [ngStyle]="{'padding': columnIndex === 0 ? '1rem 0 1rem 1rem' : '1rem 0 1rem 0'}"
                                container="body">
                                <div *ngIf="options.actions.length > 0" class="btn-group" dropdown>
                                    <button dropdownToggle class="btn btn-primary dropdown-toggle" i18n>
                                        Add<span class="caret"></span>
                                    </button>
                                    <ul *dropdownMenu class="dropdown-menu" role="menu">
                                        <li *ngFor="let section of options.actions" role="menuitem">
                                            <a class="dropdown-item" (click)="buttonSelected(row, section)">
                                                {{ section.title }}
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </td>

                            <!-- Handle Text Type Layout -->
                            <td *ngIf="column.type === columnTypes.Text"
                                class="table-cell {{ options.centeredTextInCell && columnIndex > 0 ? 'centered-text' : 'v-centered-text'}}"
                                container="body"
                                [ngStyle]="{'padding': columnIndex === 0 ? '1rem 0 1rem 1rem' : '1rem 0 1rem 0'}"
                                [ngbTooltip]="getCellToolTip(row, column)" [ngClass]="getCellClassName(row,column)"
                                (click)="rowClicked(row, i)">
                                <div class="table-cell-content-container"
                                     [ngClass]=" {'no-right-border': options.hideColumnBorders || columnIndex === options.columns.length - 1}">
                                    <div class="cell-content">
                                        {{ getCellContent(row, column) }}
                                    </div>
                                </div>
                            </td>

                            <!-- Handle Icon Src Layout -->
                            <td *ngIf="column.type === columnTypes.IconSrc"
                                class="table-cell {{ options.centeredTextInCell && columnIndex > 0 ? 'centered-text' : 'v-centered-text'}}"
                                container="body"
                                [ngStyle]="{'padding': columnIndex === 0 ? '1rem 0 1rem 1rem' : '1rem 0 1rem 0'}"
                                [ngbTooltip]="getCellToolTip(row, column)" [ngClass]="getCellClassName(row,column)"
                                (click)="rowClicked(row, i)">
                                <div class="table-cell-content-container"
                                     [ngClass]=" {'no-right-border': options.hideColumnBorders || columnIndex === options.columns.length - 1}">
                                    <div class="cell-content">
                                        <img class="cell-icon"
                                             *ngIf="getCellContent(row, column)?.length > 0"
                                             [src]="getCellContent(row, column)" alt="">
                                    </div>
                                </div>
                            </td>

                            <!-- Handle NumberWithDifferential Layout -->
                            <td *ngIf="column.type === columnTypes.NumberWithDifferential"
                                class="table-cell {{ options.centeredTextInCell && columnIndex > 0 ? 'centered-text' : ''}}"
                                container="body"
                                [ngStyle]="{'padding': columnIndex === 0 ? '1rem 0 1rem 1rem' : '1rem 0 1rem 0'}"
                                [ngbTooltip]="getCellToolTip(row, column)" [ngClass]="getCellClassName(row,column)"
                                (click)="rowClicked(row, i)">
                                <div class="table-cell-content-container"
                                     [ngClass]=" {'no-right-border': options.hideColumnBorders || columnIndex === options.columns.length - 1}">
                                    <div class="cell-content d-flex">
                                        {{ getCellContent(row, column) }}
                                        <div class="percent-change"
                                             *ngIf="getCellDifferential(row, column) !== null"
                                             [ngClass]="getDifferentialClass(row, column)">
                                            <img [src]="'assets/img/icons/' + getPercentChangeIconName(row, column)" alt="trend-icon">
                                            {{ formatPercentText(getCellDifferential(row, column)) }}
                                        </div>
                                    </div>
                                </div>
                            </td>

                            <!-- Handle Text Subtext Type Layout -->
                            <td *ngIf="column.type === columnTypes.TextWithSubtext" class="table-cell"
                                container="body"
                                [ngStyle]="{'padding': columnIndex === 0 ? '1rem 0 1rem 1rem' : '1rem 0 1rem 0'}"
                                [ngbTooltip]="getCellToolTip(row, column)" [ngClass]="getCellClassName(row,column)"
                                (click)="rowClicked(row, i)">

                                <div class="table-cell-content-container"
                                     [ngClass]=" {'no-right-border': options.hideColumnBorders  || columnIndex === options.columns.length - 1}">
                                    <div class="cell-content cell-content-primary-subtext">
                                        {{ getCellContent(row, column) }}
                                    </div>
                                    <div class="cell-content-subtext-container">
                                        <div class="cell-content-subtext"
                                             *ngFor="let subtext of column.subtext; let subtextIndex = index; ">
                                            <ng-container *ngIf="getCellSubtextHeader(row, column, subtextIndex)?.length > 0">
                                                <span class="cell-content-subtext-header">
                                                    {{ getCellSubtextHeader(row, column, subtextIndex) }}:
                                                </span>
                                            </ng-container>
                                            <ng-container *ngIf="getCellSubtextContent(row, column, subtextIndex)?.length > 0">
                                                <span class="cell-content-subtext-content">
                                                    {{ getCellSubtextContent(row, column, subtextIndex) }}
                                                </span>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                            </td>

                            <!-- Handle Text Specialist Type Layout -->
                            <td *ngIf="column.type === columnTypes.TextWithSpecialist" class="table-cell"
                                container="body"
                                [ngStyle]="{'padding': '1rem 0 1rem 1rem', 'text-align' : 'center'}"
                                [ngbTooltip]="getCellToolTip(row, column)" [ngClass]="getCellClassName(row,column)"
                                (click)="rowClicked(row, i)">

                                <div class="table-cell-content-container"
                                     [ngClass]=" {'no-right-border': options.hideColumnBorders  || columnIndex === options.columns.length - 1}">
                                    <div class="cell-content cell-content-primary-subtext">
                                        {{ getCellContent(row, column) }}
                                    </div>
                                    <div class="cell-content-subtext-container">
                                        <div class="cell-content-subtext"
                                             *ngFor="let subtext of column.subtext; let subtextIndex = index; ">
                                                <span class="cell-content-subtext-header">
                                                    {{ getCellSubtextHeader(row, column, subtextIndex) }}:
                                                </span>
                                            <span class="cell-content-specialist-name">
                                                    {{ getCellSubtextContent(row, column, subtextIndex) }}
                                                </span>
                                        </div>
                                    </div>
                                </div>
                            </td>

                            <!-- Handle Status Type Layout -->
                            <td *ngIf="column.type === columnTypes.Status"
                                class="table-cell {{ options.centeredTextInCell ? 'centered-text' : ''}}"
                                container="body"
                                [ngStyle]="{'padding': columnIndex === 0 ? '1rem 0 1rem 1rem' : '1rem 0 1rem 0'}"
                                [ngbTooltip]="getCellToolTip(row, column)" [ngClass]="getCellClassName(row,column)"
                                (click)="rowClicked(row, i)">

                                <div class="table-cell-content-container flex-column"
                                     [ngClass]=" {'no-right-border': options.hideColumnBorders  || columnIndex === options.columns.length - 1}">
                                    <div class="cell-content d-flex w-100 align-items-center">
                                        <div class="status-pill">
                                            {{ getCellContent(row, column) }}
                                        </div>
                                        <div class="nav-item-notification-pill ml-2"
                                             *ngIf="getCellSecondaryContent(row, column)?.length > 0">
                                            {{ getCellSecondaryContent(row, column) }}
                                        </div>
                                    </div>
                                    <div class="cell-content-subtext-container">
                                        <div class="cell-content-subtext"
                                             *ngFor="let subtext of column.subtext; let subtextIndex = index; ">
                                            <ng-container *ngIf="getCellSubtextHeader(row, column, subtextIndex)?.length > 0">
                                                <span class="cell-content-subtext-header">
                                                    {{ getCellSubtextHeader(row, column, subtextIndex) }}:
                                                </span>
                                            </ng-container>
                                            <ng-container *ngIf="getCellSubtextContent(row, column, subtextIndex)?.length > 0">
                                                <span class="cell-content-subtext-content">
                                                    {{ getCellSubtextContent(row, column, subtextIndex) }}
                                                </span>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                            </td>

                            <!-- Handle Checkbox Type Layout -->
                            <td *ngIf="column.type === columnTypes.Checkbox"
                                class="table-cell {{ options.centeredTextInCell ? 'centered-text' : ''}}"
                                container="body"
                                [ngStyle]="{'padding': columnIndex === 0 ? '1rem 0 1rem 1rem' : '1rem 0 1rem 0'}"
                                [ngbTooltip]="getCellToolTip(row, column)" [ngClass]="getCellClassName(row,column)">

                                <div class="table-cell-content-container table-cell-checkbox-container"
                                     [ngClass]=" {'no-right-border': options.hideColumnBorders || columnIndex === options.columns.length - 1}"
                                     [ngStyle]="{'margin-left':columnIndex === 0 ? '-1.6rem' : '0' }">
                                    <div class="cell-content col-sm-12">
                                        <label class="teth-checkbox-container"
                                               for="{{ 'datatable-checkbox-' + i }}">
                                            <input type="checkbox" class="teth-checkbox"
                                                   id="{{ 'datatable-checkbox-' + i }}"
                                                   name="{{ 'datatable-checkbox-' + i }}"
                                                   [checked]="bulkEditIndexes.indexOf(i) > -1"
                                                   (change)="selectSingleOptionClicked($event, i)">
                                            <span class="teth-checkbox-label"></span>
                                            <span class="teth-checkmark"></span>
                                        </label>
                                    </div>
                                </div>

                            </td>

                        </ng-template>

                    </tr>
                    </tbody>
                </table>

                <div class="no-results-container" [hidden]="displayedData.length > 0">
                    <span class="no-results-title">
                        {{ options.noResultsTitle }}
                    </span>
                    <span class="no-results-body">
                        {{ options.noResultsText }}
                    </span>
                    <button class="teth-button white-button bordered-button no-results-reset"
                            (click)="clearAllFilters()" i18n>
                        Remove All Filters
                    </button>
                </div>
            </div>
        </div>
        <div class="mt-3">
            <app-pagination [hidden]="options.hideFooter"
                            [currentPage]="page"
                            [dataLength]="filteredData?.length"
                            [maxNumberOfPages]="maxNumberOfPages"
                            [numberOfEntries]="numberOfEntriesInt"
                            (pageClickedEvent)="pageClicked($event)">
            </app-pagination>
        </div>
    </div>

    <!-- Extra Filter Modal -->
    <div #extraFilterModal class="modal fade" id="extraFilterModal" tabindex="-1" role="dialog" data-backdrop="static"
         data-keyboard="false" aria-labelledby="extraFilterModalLabel" aria-hidden="true">

        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-body">
                    <div class="extra-filter-modal-title">
                        {{ presentingMoreFilters ? 'More Filters' : 'Filter Referrals by ' + (presentingExtraFilter$ | async).filterName }}
                    </div>
                    <form *ngIf="!presentingMoreFilters && !(presentingExtraFilter$ | async).isDateFilter" class="search-bar-container">
                        <label class="search-label" for="searchBar">Search</label>
                        <input
                            #searchText
                            (input)="searchChanged($event)"
                            [ngModel]="searchInput$ | async"
                            class="text-field search-text-field"
                            id="searchBar"
                            name="searchBar"
                            placeholder="{{ localizedModalSearchPlaceholder$ | async }}"
                            i18n-placeholder
                            autocomplete="off"
                            type="text" />
                    </form>
                    <!-- Present Date Picker Modal -->
                    <div class="date-picker-modal-container"
                         *ngIf="(presentingExtraFilter$ | async).isDateFilter && !presentingMoreFilters">
                        <app-date-picker [displayMonths]="2" [navigationType]="datePickerNavTypes.Arrows"
                                         [disablePastDates]="false" [hideBorder]="true"
                                         [datePickerOutput]="datePickerOutputs.DateRange"
                                         [initialSelectedDates]="(presentingExtraFilter$ | async).selectedDates"
                                         (dateSelected)="handleFilterDateSelected($event)">
                        </app-date-picker>
                    </div>
                    <!-- Present Selection Filter Modal -->
                    <div *ngIf="!presentingMoreFilters">
                        <div class="row extra-filter-checkbox-row"
                             *ngFor="let optionGroup of (presentingExtraFilter$ | async).selectionOptions; let i = index;"
                             [ngClass]="{ 'hide-divider': (i === (presentingExtraFilter$ | async).selectionOptions.length - 1 || (presentingExtraFilter$ | async).selectionOptions.length === 1) }">
                            <span class="extra-filter-checkbox-row-title"
                                  *ngIf="optionGroup.groupTitle && optionGroup.groupTitle != ''">
                                {{ optionGroup.groupTitle }}
                            </span>
                            <div class="col-md-6 extra-filter-checkbox"
                                 *ngFor="let option of (filteredSearchableItems$ | async)?.get(optionGroup.groupTitle)">
                                <label class="teth-checkbox-container"
                                       for="{{ option.getUniqueIdentifier() }}">
                                    <input type="checkbox" class="teth-checkbox"
                                           id="{{ option.getUniqueIdentifier() }}"
                                           name="{{ option.getUniqueIdentifier() }}"
                                           [ngModel]="optionGroup.selectedOptions.indexOf(option) >= 0"
                                           (click)="extraFilterSelectionMade(option, optionGroup)">
                                    <span class="teth-checkbox-label"> {{ option.displayableName }}
                                    </span>
                                    <span class="teth-checkmark"></span>
                                </label>
                            </div>
                            <div *ngIf="!(filteredSearchableItems$ | async)?.get(optionGroup.groupTitle)?.length && (searchInput$|async)?.length"
                                 class="col-md-6 extra-filter-checkbox"
                                 i18n>
                                No filters match the search criteria
                            </div>
                            <div class="extra-filter-checkbox-row-divider"></div>
                        </div>
                    </div>
                    <!-- Present More Filters Modal -->
                    <div *ngIf="presentingMoreFilters">
                        <div class="row extra-filter-checkbox-row"
                             *ngFor="let opt of options.moreFilterOptions; let i = index;"
                             [ngClass]="{ 'hide-divider': (i === options.moreFilterOptions.length - 1 || options.moreFilterOptions.length === 1) }">
                            <span class="extra-filter-checkbox-row-title"
                                  *ngIf="opt.group.groupTitle && opt.group.groupTitle != ''">
                                {{ opt.group.groupTitle }}
                            </span>
                            <div class="col-md-6 extra-filter-checkbox"
                                 *ngFor="let option of opt.group.groupOptions;">
                                <label class="teth-checkbox-container"
                                       for="{{option.getUniqueIdentifier()}}">
                                    <input type="checkbox" class="teth-checkbox"
                                           id="{{ option.getUniqueIdentifier() }}"
                                           name="{{ option.getUniqueIdentifier() }}"
                                           [ngModel]="opt.group.selectedOptions.indexOf(option) >= 0"
                                           (click)="extraFilterSelectionMade(option, opt.group)">
                                    <span class="teth-checkbox-label"> {{ option.displayableName }}
                                    </span>
                                    <span class="teth-checkmark"></span>
                                </label>
                            </div>
                            <div class="extra-filter-checkbox-row-divider"></div>
                        </div>
                    </div>

                    <div class="modal-button-footer">
                        <button i18n class="teth-button bordered-button white-button mr-3"
                                (click)="clearExtraFilterPressed()">
                            Clear
                        </button>
                        <button i18n class="teth-button grey-button" style="min-width: 6.5rem"
                                [hidden]="presentingExtraFilterIsValid" (click)="cancelExtraFilterPressed()">
                            Cancel
                        </button>
                        <button i18n class="teth-button blue-button" style="min-width: 6.5rem"
                                [hidden]="!presentingExtraFilterIsValid" (click)="continueExtraFilterPressed()">
                            Continue
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Date Range Filter -->
    <div #dateRangeFilterModal class="modal fade" id="dateRangeFilterModal" tabindex="-1" role="dialog"
         data-backdrop="static" data-keyboard="false" aria-labelledby="dateRangeFilterModalLabel" aria-hidden="true">

        <div class="modal-dialog modal-dialog-centered date-picker-modal" role="document">
            <div class="modal-content">
                <div class="modal-body">
                    <div i18n class="modal-title">
                        Choose a Date Range
                    </div>
                    <!-- Present Date Picker Modal -->
                    <div class="date-picker-modal-container">
                        <app-date-picker [displayMonths]="2"
                                         [navigationType]="datePickerNavTypes.Arrows"
                                         [hideBorder]="true"
                                         [datePickerOutput]="datePickerOutputs.DateRange"
                                         [clearDateSelection]="clearDateSelection"
                                         (dateSelected)="handleCustomDateRangeSelection($event)">
                        </app-date-picker>
                    </div>

                    <div class="modal-button-footer">
                        <button i18n class="teth-button modal-footer-button grey-button" style="min-width: 6.5rem"
                                (click)="cancelCustomDateRangePressed()">
                            Cancel
                        </button>
                        <button i18n class="teth-button modal-footer-button blue-button" style="min-width: 6.5rem"
                                [ngClass]="{ 'disabled': !enableDatePickerContinueButton }"
                                [disabled]="!enableDatePickerContinueButton"
                                (click)="continueCustomDateRangePressed()">
                            Continue
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<mat-sidenav-container>
    <mat-sidenav
        #slideout
        position="end"
        [mode]="'over'"
        [fixedInViewport]="true"
        (openedChange)="resetSlideoutIfNeeded($event)">
        <app-datatable-extra-filters-slideout
                *ngIf="!(resetSlideout | async)"
                [extraFilters]="(slideoutDatatableOptions$ | async)?.extraFilterOptions"
                (applyFiltersClicked)="applySlideoutFilters($event)"
                (clearAllFiltersClicked)="handleClearAllFiltersClicked()"
                (closeSlideoutClicked)="slideout.close()">
        </app-datatable-extra-filters-slideout>
    </mat-sidenav>
</mat-sidenav-container>
