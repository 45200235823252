<div class="modal-content modal-lg">
    <div class="modal-header">
        <h3 class="float-left preview-title">{{titleText}}</h3>
        <div class="float-right">
            <button class="teth-button bordered-button"
                    [style]="{'margin-right': '0.5rem'}"
                    (click)="download()" i18n>
                Download
            </button>
            <button class="teth-button bordered-button"
                    [hidden]="!canPrint"
                    [style]="{'margin-right': '0.5rem'}"
                    (click)="print()" i18n>
                Print
            </button>
            <button class="teth-button blue-button"
                    (click)="closeModal()" i18n>
                Close
            </button>
        </div>
    </div>
    <div class="modal-body">
        <div class="container">
            <app-loading
                    [determinateLoading]="false"
                    [hidden]="url && iFrameLoaded"
                    [loadingText]="'Loading Attachment'"
                    [fullscreen]="false"
                    [backgroundColor]="'#FDFDFD'"
                    [color]="'#388EFC'"
                    *ngIf="pdf">
            </app-loading>
            <img (error)="errorOnImageLoad = true" *ngIf="!tiff && !pdf && !errorOnImageLoad" [src]="url" alt="document">
            <ngx-doc-viewer *ngIf="pdf" [viewerUrl]="'https://docs.google.com/gview?url=%URL%&embedded=true'" [url]="printUrl" viewer="url" (loaded)="doneLoading()" style="width:100%;height:45rem;"></ngx-doc-viewer>
            <canvas [hidden]="!tiff" class="w-100 mt-4" #rgbaCanvas></canvas>
            <div *ngIf="errorOnImageLoad" class="error-text" i18n>
                There was a problem loading the requested content. Please try again.
            </div>
        </div>
    </div>
</div>
