<div [class.error]="control.invalid && (control.dirty || control.touched)">
    <label class="teth-textfield-label" for="field{{id}}">{{label}}</label>
    <input #autoCompleteInput
           ngDefaultControl
           class="teth-textfield"
           type="text"
           id="field{{id}}"
           placeholder="Address"
           i18n-placeholder
           autocomplete="new-password"
           onfocus="this.setAttribute('autocomplete', 'new-password');"
           [formControl]="control">
    <span class="teth-textfield-error" i18n>Address is required</span>
</div>
