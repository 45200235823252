import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {ApiService} from './api.service';
import {Employee} from '../models/employee.model';
import {Role} from '../models/role.model';
import {PasswordChange} from '../models/passwordChange.model';
import {Permission} from '../models/permission.model';
import {DeserializeHelper} from '../views/shared/utils/json-utils';
import * as buffer from 'buffer';

@Injectable({
    providedIn: 'root'
})
export class EmployeeService {
    constructor(private http: HttpClient, private api: ApiService) {
    }

    public getEmployee(companyId: number, employeeId: number): Observable<Employee> {
        return this.api.get<Employee>(environment.apiBaseUrl + `/Companies/${companyId}/Employees/${employeeId}`)
            .map(r => DeserializeHelper.deserializeToInstance(Employee, r));
    }

    public getEmployeesForCompany(companyId: number): Observable<Employee[]> {
        return this.api.get<Employee[]>(environment.apiBaseUrl + `/Companies/${companyId}/Employees`)
            .map(r => r?.map(d => DeserializeHelper.deserializeToInstance(Employee, d)));
    }

    public createEmployee(employee: Employee, companyId: number): Observable<Employee> {
        return this.api.post<Employee>(environment.apiBaseUrl + `/Companies/${companyId}/Employees`, employee);
    }

    public createCompanyAdministrator(employee: Employee, companyId: number): Observable<Employee> {
        return this.api.post<Employee>(environment.apiBaseUrl + `/Companies/${companyId}/CompanyAdministrator`, employee);
    }

    public updateEmployee(employee: Employee, companyId: number, employeeId: number): Observable<Employee> {
        return this.api.put<Employee>(environment.apiBaseUrl + `/Companies/${companyId}/Employees/${employeeId}`, employee);
    }

    public setEmployeeDefaultLocation(employee: Employee, locationId: number, companyId: number, employeeId: number): Observable<Employee> {
        employee.defaultLocationId = locationId;
        return this.updateEmployee(employee, companyId, employeeId);
    }

    public getPermissions(): Observable<Permission[]> {
        return this.api.get<Permission[]>(environment.apiBaseUrl + `/permissiontypes`)
            .map(r => r.map(d => DeserializeHelper.deserializeToInstance(Permission, d)));
    }

    public getRolesForCompany(companyId: string): Observable<Role[]> {
        return this.api.get<Role[]>(environment.apiBaseUrl + `/Companies/${companyId}/Roles`)
            .map(r => r?.map(d => DeserializeHelper.deserializeToInstance(Role, d)));
    }

    public createRole(role: Role, companyId: number): Observable<Role> {
        return this.api.post<Role>(environment.apiBaseUrl + `/Companies/${companyId}/Roles`, role);
    }

    public updateRole(role: Role, companyId: number): Observable<Role> {
        return this.api.put<Role>(environment.apiBaseUrl + `/Companies/${companyId}/Roles/${role.id}`, role);
    }

    public getRolePermissions(companyId: string, roleId: string): Observable<Permission[]> {
        return this.api.get<Permission[]>(
            environment.apiBaseUrl + `/Companies/${companyId}/Roles/${roleId}/Permissions`)
            .map(r => r.map(d => DeserializeHelper.deserializeToInstance(Permission, d)));
    }

    public getRoleById(companyId: string, roleId: string): Observable<Role> {
        return this.api.get<Role>(
            environment.apiBaseUrl + `/companies/${companyId}/roles/${roleId}`)
            .map(r => DeserializeHelper.deserializeToInstance(Role, r));
    }

    public createRolePermission(roleId: string, companyId: string, permission: Permission): Observable<Permission> {
        return this.api.post<Permission>(
            environment.apiBaseUrl + `/Companies/${companyId}/Roles/${roleId}/Permissions`, permission);
    }
    public changePassword(companyId: string, username: string, password: PasswordChange): Observable<PasswordChange> {
        return this.api.post<PasswordChange>(environment.apiBaseUrl + `/companies/${companyId}/employees/${username}/changepassword`, password);
    }

    public uploadProfilePicture(file: string, presignedUrl: string): Observable<any> {
        let newFileName = file.replace(/^data:image\/\w+;base64,/, '');
        newFileName = newFileName.replace(/^data:video\/\w+;base64,/, '');
        newFileName = newFileName.replace(/^data:application\/\w+;base64,/, '');

        // image cropper only returns .png images so type can be hardcoded
        const buff = buffer.Buffer.from(newFileName, 'base64');
        const headers = new HttpHeaders({
            'Content-Type': 'image/png',
            'Content-Encoding': 'base64'
        });

        const blob = new Blob([new Uint8Array(buff)]);
        return this.http.put<any>(presignedUrl, blob, {headers});
    }

    public resetPassword(companyId: number, username: string, newPassword: string): Observable<any> {
        return this.api.post<any>(
            environment.apiBaseUrl + `/companies/${companyId}/employees/${username}/resetpassword`,
            { NewPassword: newPassword});
    }
}

