import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {SessionService} from './session.service';
import {Observable} from 'rxjs';
import {PermissionService, PermissionTypeIds} from './permission.service';
import {environment} from '../../environments/environment';
import {CompanyType} from '../models/company.model';

@Injectable({
    providedIn: 'root'
})

export class RoleGuardService implements CanActivate {

    constructor(public sessionService: SessionService,
                public _router: Router,
                public permissionService: PermissionService) {
    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return new Observable<boolean>(subscriber => {
            this.permissionService.currentUserRole.firstNotNull().subscribe(() => {
                subscriber.next(this.routeIsPermitted(state));
                subscriber.complete();
            });
        });
    }

    routeIsPermitted(state: RouterStateSnapshot): boolean {
        const user = this.sessionService.sessionContainer.value?.employee;
        const company = this.sessionService.sessionContainer.value?.company;
        if (user == null && company == null) {
            return false;
        }

        let isPermitted = true;
        switch (state.url) {
            // Add routes that need special permissions based on role
            case '/admin-home':
                isPermitted = environment.administratorAccess;
                break;
            case '/directory':
                isPermitted = user.isDentist;
                break;
            case '/correspondence-incoming-referrals':
            case '/correspondence-outgoing-referrals':
                isPermitted = this.permissionService.permissionGranted(PermissionTypeIds.Correspondence);
                break;
            case '/settings':
                isPermitted = this.permissionService.permissionGranted(PermissionTypeIds.Settings);
                break;
            case '/dashboard':
                if (environment.administratorAccess) {
                    this._router.navigate(['admin-home']);
                    return false;
                } else if (this.permissionService.permissionGranted(PermissionTypeIds.Dashboard) && (company.companyTypeId === CompanyType.Specialist || company.companyTypeId === CompanyType.DentalLab)) {
                    return true;
                } else if (this.permissionService.permissionGranted(PermissionTypeIds.CreateReferrals) && company.companyTypeId === CompanyType.Dentist) {
                    this._router.navigate(['create-referral']);
                    return false;
                } else if (this.permissionService.permissionGranted(PermissionTypeIds.ManageReferrals)) {
                    this._router.navigate([company.companyTypeId === CompanyType.Dentist ? 'manage-referrals-outgoing' : 'manage-referrals-incoming']);
                    return false;
                } else {
                    this._router.navigate(['profile']);
                    return false;
                }
        }

        if (!isPermitted) {
            this._router.navigate(['']);
        }
        return isPermitted;
    }

}
