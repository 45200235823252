import {Deserializable, DeserializeHelper} from '../views/shared/utils/json-utils';
import {Patient} from './patient.model';
import {ReferralProcedure} from './referral-procedure.model';
import * as moment from 'moment';
import {ReferralStatus} from './referral-status.model';

export class ReferralPreview implements Deserializable {
    public id: string;
    public referringPatient: Patient;
    public referredPatient: Patient;
    public caseAccepted: boolean;
    public isUrgent: boolean;
    public statusTypeId: number;
    public status: string;
    public referringEmployeeId: number;
    public referringEmployee: string;
    public preferredSpecialistId: number;
    public preferredSpecialist: string;
    public assignedSpecialistId: number;
    public assignedSpecialist: string;
    public procedures: ReferralProcedure[];
    public createdDate: Date;
    public createdById: number;
    public createdBy: string;
    public highSortPriority: boolean = false;
    public procedureIds: number[];
    public revenueValue: number;
    public referringCompanyId: number;
    public referringLocationId: number;
    public referringLocation: string;
    public referredCompanyId: number;
    public referredLocationId: number;
    public referredLocation: string;
    public waitingStatusReasonId: number;
    public waitingStatusReason: string;
    public declinedStatusReasonId: number;
    public declinedStatusReason: string;
    public externalReferringDentist: string;
    public externalReferral: boolean;

    onDeserialize() {
        if (this.createdDate instanceof Date) {
            this.createdDate = moment.utc(this.createdDate.toString()).toDate();
        } else {
            this.createdDate = moment.utc(this.createdDate).toDate();
        }
        this.highSortPriority = this.isUrgent && this.statusTypeId === ReferralStatus.CreatedId;
        this.procedureIds = this.procedures?.map(p => p.procedureTypeId);
        if (this.referringPatient) {
            this.referringPatient = DeserializeHelper.deserializeToInstance(Patient, this.referringPatient);
        }
        if (this.referredPatient) {
            this.referredPatient = DeserializeHelper.deserializeToInstance(Patient, this.referredPatient);
        }

        if (this.externalReferringDentist?.length > 0 && this.externalReferral) {
            this.referringEmployee = this.externalReferringDentist;
        }
    }
}
