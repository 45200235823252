import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BreadcrumbsComponent} from './breadcrumbs/breadcrumbs.component';
import {FileUploadComponent} from './file-upload/file-upload.component';
import {DndDirective} from './directives/dnd.directive';
import {ProgressBarComponent} from './components/progress-bar/progress-bar.component';
import {TypeAheadSearchComponent} from './type-ahead-search/type-ahead-search.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {LoadingComponent} from './loading/loading.component';
import {NgxSpinnerModule} from 'ngx-spinner';
import {ColorPickerModule} from 'ngx-color-picker';
import {DatatableComponent} from './datatable/datatable.component';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {DatePickerComponent} from './components/date-picker/date-picker.component';
import {NgbModule, NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {InfoCardsComponent} from './info-cards/info-cards.component';
import {ToothChartComponent} from './tooth-chart/tooth-chart.component';
import {ImagePreviewModalComponent} from './image-preview-modal/image-preview-modal.component';
import {InputModalComponent} from './input-modal/input-modal.component';
import {MessagesComponent} from './messages/messages.component';
import {EmployeeGridComponent} from './components/employee-grid/employee-grid.component';
import {EmployeeInfoComponent} from './components/employee-info/employee-info.component';
import {UploadImageModalComponent} from './components/upload-image-modal/upload-image-modal.component';
import {ImageCropperModule} from 'ngx-image-cropper';
import {PasswordResetModalComponent} from './components/password-reset-modal/password-reset-modal.component';
import {ConfirmationModalComponent} from './components/confirmation-modal/confirmation-modal.component';
import {RoleModalComponent} from './components/role-modal/role-modal.component';
import {
    CreateCorrespondenceModalComponent
} from './components/create-correspondence-modal/create-correspondence-modal.component';
import {NgxDocViewerModule} from 'ngx-doc-viewer';
import {StatusModalComponent} from './status-modal/status-modal.component';
import {GenericModalComponent} from './components/generic-modal/generic-modal.component';
import {CreateCompanyModalComponent} from './components/create-company-modal/create-company-modal.component';
import {TextMaskModule} from 'angular2-text-mask';
import {RadioGroupComponent} from './components/lab-type-form-group/radio-group/radio-group.component';
import {TextGroupComponent} from './components/lab-type-form-group/text-group/text-group.component';
import {DateRangePickerModalComponent} from './components/date-range-picker-modal/date-range-picker-modal.component';
import {PaginationComponent} from './components/pagination/pagination.component';
import {UserAgreementModalComponent} from './components/user-agreement-modal/user-agreement-modal.component';
import {RouterModule} from '@angular/router';
import {RemovablesFormComponent} from './components/removables-form/removables-form.component';
import {CrownsplusFormComponent} from './components/crownsplus-form/crownsplus-form.component';
import {ImplantsFormComponent} from './components/implants-form/implants-form.component';
import {InviteClinicsModalComponent} from './components/invite-clinics-modal/invite-clinics-modal.component';
import {AutoCompleteAddressComponent} from './components/auto-complete-address/auto-complete-address.component';
import {NewClinicChecklistComponent} from './components/new-clinic-checklist/new-clinic-checklist.component';
import {CheckboxGroupComponent} from './components/lab-type-form-group/checkbox-group/checkbox-group.component';
import {UploadFileModalComponent} from './components/upload-file-modal/upload-file-modal.component';
import {TextFieldModule} from '@angular/cdk/text-field';
import {ChooseCompanyModalComponent} from './components/choose-company-modal/choose-company-modal.component';
import {VersionInfoModalComponent} from './components/version-info-modal/version-info-modal.component';
import {ReleaseNotesComponent} from './components/release-notes/release-notes.component';
import {LinkPatientModalComponent} from './components/link-patient-modal/link-patient-modal.component';
import {DatePickerModalComponent} from './components/date-picker-modal/date-picker-modal.component';
import {CurrencyMaskModule} from 'ng2-currency-mask';
import {TransferReferralModalComponent} from './components/transfer-referral-modal/transfer-referral-modal.component';
import {ManualReferralModalComponent} from './components/manual-referral-modal/manual-referral-modal.component';
import {ScrollToTargetDirective} from './directives/scroll-to-target.directive';
import {ScrollToTriggerDirective} from './directives/scroll-to-trigger.directive';
import {IsInvalidAndTouchedPipe} from "./pipes/is-invalid-and-touched.pipe";
import {ModalFooterComponent} from "./components/modal-footer/modal-footer.component";
import {ChevronListItemComponent} from "./components/chevron-list-item/chevron-list-item.component";
import {StickyFixedHeightCardComponent} from './components/sticky-fixed-height-card/sticky-fixed-height-card.component';
import {ChunkPipe} from "./pipes/chunk.pipe";
import {MatSidenavModule} from "@angular/material/sidenav";
import {
    DatatableExtraFiltersSlideoutComponent
} from './datatable/datatable-extra-filters-slideout/datatable-extra-filters-slideout.component';
import {
    ExtraFilterOptionsFormComponent
} from './datatable/datatable-extra-filters-slideout/extra-filter-options-form/extra-filter-options-form.component';
import {
    NestedFilterOptionsFormComponent
} from './datatable/datatable-extra-filters-slideout/extra-filter-options-form/nested-filter-options-form/nested-filter-options-form.component';
import { OptionIsSelectedPipe } from './datatable/datatable-extra-filters-slideout/extra-filter-options-form/nested-filter-options-form/option-is-selected.pipe';
import { DateFilterOptionsFormComponent } from './datatable/datatable-extra-filters-slideout/date-filter-options-form/date-filter-options-form.component';
import { SlideoutFooterComponent } from './datatable/datatable-extra-filters-slideout/slideout-footer/slideout-footer.component';
import { GetOriginalSelectionOptionsPipe } from './datatable/datatable-extra-filters-slideout/extra-filter-options-form/get-original-selection-options.pipe';
import { OptionIsSelectedFromAllFilterGroupsPipe } from './datatable/datatable-extra-filters-slideout/extra-filter-options-form/option-is-selected-from-all-filter-groups.pipe';
import {FeeGuideCoverageStringPipe} from "./pipes/fee-guide-coverage-string.pipe";
import {FeeGuideTabBarComponent} from "./components/fee-guide-tab-bar/fee-guide-tab-bar.component";
import {MatTabsModule} from "@angular/material/tabs";
import { FeeGuideProceduresContainerComponent } from './components/fee-guide-procedures-container/fee-guide-procedures-container.component';
import {
    FeeGuideProcedureFeesListComponent
} from "./components/fee-guide-procedure-fees-list/fee-guide-procedure-fees-list.component";
import {MatExpansionModule} from "@angular/material/expansion";
import {
    FeeGuideDefaultAverageValueFormComponent
} from "./components/fee-guide-default-average-value-form/fee-guide-default-average-value-form.component";
import {
    FeeGuideProcedureFeeFormComponent
} from "./components/edit-fee-guide-procedure-fee-form/fee-guide-procedure-fee-form.component";
import { FeeGuideTabBarContentDirective } from './directives/fee-guide-tab-bar-content.directive';
import { FirstOrNullPipe } from './pipes/first-or-null.pipe';
import { GetEmployeePhotoSrcPipe } from './components/employee-grid/get-employee-photo-src.pipe';

@NgModule({
    declarations: [
        BreadcrumbsComponent,
        FileUploadComponent,
        DndDirective,
        ProgressBarComponent,
        TypeAheadSearchComponent,
        LoadingComponent,
        DatatableComponent,
        DatePickerComponent,
        InfoCardsComponent,
        ToothChartComponent,
        ImagePreviewModalComponent,
        InputModalComponent,
        MessagesComponent,
        EmployeeGridComponent,
        EmployeeInfoComponent,
        UploadImageModalComponent,
        PasswordResetModalComponent,
        ConfirmationModalComponent,
        GenericModalComponent,
        RoleModalComponent,
        CreateCorrespondenceModalComponent,
        StatusModalComponent,
        CreateCompanyModalComponent,
        RadioGroupComponent,
        TextGroupComponent,
        DateRangePickerModalComponent,
        PaginationComponent,
        UserAgreementModalComponent,
        RemovablesFormComponent,
        CrownsplusFormComponent,
        ImplantsFormComponent,
        InviteClinicsModalComponent,
        AutoCompleteAddressComponent,
        NewClinicChecklistComponent,
        CheckboxGroupComponent,
        UploadFileModalComponent,
        ChooseCompanyModalComponent,
        VersionInfoModalComponent,
        ReleaseNotesComponent,
        LinkPatientModalComponent,
        DatePickerModalComponent,
        TransferReferralModalComponent,
        ManualReferralModalComponent,
        ScrollToTargetDirective,
        ScrollToTriggerDirective,
        IsInvalidAndTouchedPipe,
        ModalFooterComponent,
        ChevronListItemComponent,
        StickyFixedHeightCardComponent,
        ChunkPipe,
        DatatableExtraFiltersSlideoutComponent,
        ExtraFilterOptionsFormComponent,
        NestedFilterOptionsFormComponent,
        OptionIsSelectedPipe,
        DateFilterOptionsFormComponent,
        SlideoutFooterComponent,
        GetOriginalSelectionOptionsPipe,
        OptionIsSelectedFromAllFilterGroupsPipe,
        FeeGuideCoverageStringPipe,
        FeeGuideTabBarComponent,
        FeeGuideProceduresContainerComponent,
        FeeGuideProcedureFeesListComponent,
        FeeGuideDefaultAverageValueFormComponent,
        FeeGuideProcedureFeeFormComponent,
        FeeGuideTabBarContentDirective,
        FirstOrNullPipe,
        GetEmployeePhotoSrcPipe,
    ],
    exports: [
        BreadcrumbsComponent,
        FileUploadComponent,
        TypeAheadSearchComponent,
        LoadingComponent,
        DatatableComponent,
        DatePickerComponent,
        InfoCardsComponent,
        ToothChartComponent,
        ImagePreviewModalComponent,
        MessagesComponent,
        EmployeeGridComponent,
        EmployeeInfoComponent,
        UploadImageModalComponent,
        TextGroupComponent,
        RadioGroupComponent,
        PaginationComponent,
        RemovablesFormComponent,
        CrownsplusFormComponent,
        ImplantsFormComponent,
        AutoCompleteAddressComponent,
        NewClinicChecklistComponent,
        ReleaseNotesComponent,
        TransferReferralModalComponent,
        ScrollToTriggerDirective,
        ScrollToTargetDirective,
        IsInvalidAndTouchedPipe,
        ModalFooterComponent,
        ChevronListItemComponent,
        StickyFixedHeightCardComponent,
        DateRangePickerModalComponent,
        ChunkPipe,
        FeeGuideCoverageStringPipe,
        DatatableExtraFiltersSlideoutComponent,
        FeeGuideTabBarComponent,
        FeeGuideProceduresContainerComponent,
        FeeGuideProcedureFeesListComponent,
        FeeGuideDefaultAverageValueFormComponent,
        FeeGuideProcedureFeeFormComponent,
        FeeGuideTabBarContentDirective,
        FirstOrNullPipe,
        GetEmployeePhotoSrcPipe,
    ],
    imports: [
        CommonModule,
        FormsModule,
        NgxSpinnerModule,
        ColorPickerModule,
        BsDropdownModule,
        NgbModule,
        NgbTooltipModule,
        ImageCropperModule,
        ReactiveFormsModule,
        NgxDocViewerModule,
        TextMaskModule,
        RouterModule,
        TextFieldModule,
        CurrencyMaskModule,
        MatSidenavModule,
        MatTabsModule,
        MatExpansionModule,
    ]
})
export class SharedModule {
}
