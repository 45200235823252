import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {Employee} from '../../../../models/employee.model';
import {LoadingOptions} from '../../../../models/loading-options';
import {EmployeeService} from '../../../../services/employee.service';
import {ImageService} from '../../../../services/image.service';

@Component({
    selector: 'app-employee-grid',
    templateUrl: './employee-grid.component.html',
    styleUrls: ['./employee-grid.component.scss', '../../../stylesheet/stylesheet.component.scss']
})
export class EmployeeGridComponent implements OnInit, OnChanges {

    @Input() public employees: Employee[] = null;
    @Input() public loadingOptions: LoadingOptions;
    @Output() public employeeSelected = new EventEmitter<Employee>();
    @Input() public numberOfEntries: string = '12';
    @Input() public ctaText: string = 'View';
    public displayedEmployees: Employee[] = null;

    public page: number = 0;
    public numberOfEntriesInt: number = 12;
    public maxNumberOfPages: number = 9;

    constructor(private employeeService: EmployeeService, public imageService: ImageService) {
    }

    ngOnInit(): void {
    }

    public ngOnChanges(changes: SimpleChanges): void {
        this.numberOfEntriesInt = parseInt(this.numberOfEntries, 10);
        if (this.employees == null) {
            this.employees = [];
        }
        this.paginate();
    }

    public pageClicked(pageNumber: number): void {
        this.page = pageNumber;
        this.paginate();
    }

    public beginAmount(): number {
        return this.page * this.numberOfEntriesInt;
    }

    public endAmount(): number {
        return this.page * this.numberOfEntriesInt + this.numberOfEntriesInt;
    }

    private numberOfPages(): number {
        if (this.employees && this.numberOfEntries) {
            return Math.ceil(
                this.employees.length / this.numberOfEntriesInt,
            );
        } else {
            return 0;
        }
    }

    private paginate(): void {
        if ((this.page + 1) > this.numberOfPages()) {
            this.page = 0;
        }

        const begin = this.beginAmount();
        const end = this.endAmount();

        this.displayedEmployees = this.employees.slice(begin, end);
    }

}
