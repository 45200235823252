import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-sign-up-agreement-modal',
    templateUrl: './sign-up-agreement-modal.component.html',
    styleUrls: ['./sign-up-agreement-modal.component.scss', '../../stylesheet/stylesheet.component.scss']
})
export class SignUpAgreementModalComponent implements OnInit {

    eulaAgreement = false;
    privacyPolicyAgreement = false;

    constructor(private activeModal: NgbActiveModal) {

    }

    initWith(eulaAgreement: boolean) {
        this.eulaAgreement = eulaAgreement;
        this.privacyPolicyAgreement = !eulaAgreement;
    }

    ngOnInit() {

    }

    closeModal() {
        this.activeModal.close();
    }

    agree() {
        this.activeModal.close(true);
    }

}
