import {Correspondence} from './correspondence.model';
import {Attachment} from './attachment.model';
import {Deserializable} from '../views/shared/utils/json-utils';
import {ReferralProcedure} from './referral-procedure.model';

export class HydratedCorrespondence extends Correspondence implements Deserializable {
    public patientFirstName: string;
    public patientLastName: string;
    public referringEmployeeId: number;
    public referringEmployee: string;
    public assignedSpecialistId: number;
    public assignedSpecialist: string;
    public procedures: ReferralProcedure[];
    public procedureIds: number[];
    public attachments: Attachment[];
    public id: string;
    public referralId: string;
    public content: string;
    public correspondenceTypeId: number;
    public correspondenceType: string;
    public signeeId: number;
    public referringCorrespondenceStatusTypeId: number;
    public referringStatus: string;
    public referredCorrespondenceStatusTypeId: number;
    public referredStatus: string;
    public updatedDate: Date;
    public updatedById: number;
    public updatedBy: string;
    public createdDate: Date;
    public createdById: number;
    public createdBy: string;

    onDeserialize() {
        this.procedureIds = this.procedures.map((p) => p.procedureTypeId);
    }
}
