import {Deserializable} from '../views/shared/utils/json-utils';
import {CorrespondenceType} from './correspondence-type.model';
import {Appointment} from "./enum/appointment.model";

export class ReferralStage implements Deserializable {
    public active: boolean;
    public iconName: string;
    public title: string;
    public subtitle: string;
    public correspondenceType: CorrespondenceType;
    public correspondenceId: string;
    public waiting: boolean;
    public declined: boolean;
    public completed: boolean;
    public appointments: Appointment[];
    public showAppointmentsAsSubtitle: boolean;
    public dateCreated: Date;
    public statusId: number;

    onDeserialize() {
    }

    getBackgroundColor(): string {
        if (this.declined) {
            return '#FA5555';
        } else if (this.active) {
            return '#023474';
        } else if (this.waiting) {
            return '#FC8B38';
        } else {
            return '#FDFDFD';
        }
    }

    getBackgroundBorder(): string {
        if (this.declined) {
            return '2px solid #FA5555';
        } else if (this.active) {
            return '2px solid #023474';
        } else if (this.waiting) {
            return '2px solid #FC8B38';
        } else {
            return '2px solid #222222';
        }
    }

}
