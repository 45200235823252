import {Component, EventEmitter, Output} from '@angular/core';

@Component({
  selector: 'app-slideout-footer',
  templateUrl: './slideout-footer.component.html',
  styleUrls: ['../../../../stylesheet/stylesheet.component.scss', './slideout-footer.component.scss']
})
export class SlideoutFooterComponent {

  @Output() applyFiltersClicked = new EventEmitter<void>();
  @Output() clearFiltersClicked = new EventEmitter<void>();

}
