import {Deserializable} from "../views/shared/utils/json-utils";

export class DataTableAction implements Deserializable {
    public id: string;
    public title: string;
    public priority: number;
    public type: DatatableActionType;

    public onDeserialize() {
    }
}

export enum DatatableActionType {
    None = 'None'
}

