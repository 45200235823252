import { Component, OnInit } from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Location as _Location} from '@angular/common';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss', '../../../stylesheet/stylesheet.component.scss']
})
export class ConfirmationModalComponent implements OnInit {

  static defaultModalOptions(): any {
    return {size: 'md', backdrop: 'static', centered: true};
  }

  constructor(private activeModal: NgbActiveModal,
              private _location: _Location) {
  }

  public whatChanged = $localize`page`;

  ngOnInit(): void {
  }

  public goBack() {
    this._location.back();
  }

  keepEditing() {
    this.activeModal.close(false);
  }

  abandonChanges() {
    this.activeModal.close(true);
  }

}
