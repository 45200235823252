import {Deserializable} from '../views/shared/utils/json-utils';

export class ReferralProcedure implements Deserializable {
    public id: string;
    public referralId: string;
    public procedureTypeId: number;
    public procedureName: string;
    public comment: string;
    public createdDate: Date;
    public createdById: number;
    public createdBy: string;
    public updatedDate: Date;
    public updatedById: number;
    public updatedBy: string;

    onDeserialize() {
    }
}
