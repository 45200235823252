import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'firstOrNull'
})
export class FirstOrNullPipe implements PipeTransform {

  transform<T>(arr: T[]): T | null {
    return arr?.length ? arr[0] : null;
  }

}
