<div class="employee-container">
    <app-loading
            [determinateLoading]="loadingOptions.determinateLoading"
            [hidden]="!loadingOptions.isLoading"
            [loadingText]="loadingOptions.loadingText"
            [fullscreen]="loadingOptions.fullscreen"
            [backgroundColor]="loadingOptions.backgroundColor"
            [spinnerColor]="loadingOptions.spinnerColor"
            [color]="loadingOptions.color">
    </app-loading>
    <div class="row flex-grow-1">
        <div class="col-xl-3 col-lg-4 col-sm-6 col-12 d-flex flex-column"
             [class.disabled-card]="!employee.enabled"
             *ngFor="let employee of displayedEmployees">
            <div class="employee-card" (click)="employeeSelected.emit(employee)">
                <div class="employee-initials">
                    <div *ngIf="((employee | getEmployeePhotoSrc : imageService) | async) == null">
                        {{employee.primaryName[0]}}{{employee.familyName[0]}}
                    </div>
                    <div *ngIf="((employee | getEmployeePhotoSrc : imageService) | async) as photoSrc">
                        <img class="profile-picture" [src]="photoSrc">
                    </div>
                </div>
                <div class="employee-name">
                    {{employee.primaryName}} {{employee.familyName}}
                </div>
                <div class="employee-role" *ngIf="employee.enabled">
                    {{employee.role}}
                </div>
                <div class="employee-role" *ngIf="!employee.enabled" i18n="Account is disabled">
                    Disabled
                </div>
                <div class="divider-line"></div>
                <div class="employ-card-cta">
                    {{ctaText}}
                </div>
            </div>
        </div>
    </div>
    <div class="mt-3 w-100">
        <app-pagination [numberOfEntries]="numberOfEntriesInt"
                        [maxNumberOfPages]="maxNumberOfPages"
                        (pageClickedEvent)="pageClicked($event)"
                        [currentPage]="page"
                        [dataLength]="employees.length">
        </app-pagination>
    </div>
</div>
