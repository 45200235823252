import {Deserializable} from '../views/shared/utils/json-utils';
import * as moment from 'moment';
import {LocalizedQuestion} from './localizedQuestion';
import {CompanyType} from './company.model';

export class FrequentlyAskedQuestion implements Deserializable {
    public id: number;
    public permissionId: number;
    public permission: string;
    public companyTypeId: CompanyType;
    public name: string;
    public questions: LocalizedQuestion[];

    public createdDate: Date;
    public createdById: number;
    public createdBy: string;
    public updatedDate: Date;
    public updatedById: number;
    public updatedBy: string;

    onDeserialize() {
        this.createdDate = moment.utc(this.createdDate.toString()).toDate();
        this.updatedDate = moment.utc(this.updatedDate.toString()).toDate();
    }
}

