<div class="messages-container">

    <div class="messages-section-title">Messages</div>
    <div class="messages-section-container pb-3">

        <app-loading
                [determinateLoading]="loadingOptions.determinateLoading"
                [hidden]="!loadingOptions.isLoading"
                [loadingText]="loadingOptions.loadingText"
                [fullscreen]="loadingOptions.fullscreen"
                [backgroundColor]="loadingOptions.backgroundColor"
                [spinnerColor]="loadingOptions.spinnerColor"
                [color]="loadingOptions.color">
        </app-loading>

        <!-- No Messages Placeholder -->
        <div class="no-message-placeholder" *ngIf="messages.length === 0">
            <div class="no-message-text" i18n>
                No messages have been sent for this referral yet.
            </div>
        </div>
        <div #messageScrollContainer class="scrollable-message-wrapper">
            <div *ngFor="let message of messages; trackBy: trackById">
                <!-- Received Messages -->
                <div class="message-received-container"
                     *ngIf="!isSentMessage(message) && !message.isLogItem">
                    <div class="message-user-icon">
                        <div class="sender-initials">
                            {{ getSenderInitials(message) }}
                        </div>
                    </div>
                    <div class="message-content-container">
                        <div class="message-content">
                            {{ message.content }}
                        </div>
                        <div class="message-content-footer">
                            {{ getFormattedMessageSender(message) }}
                        </div>
                    </div>
                </div>
                <!-- Sent Messages -->
                <div class="message-sent-container"
                     *ngIf="isSentMessage(message)  && !message.isLogItem">
                    <div class="message-content-container">
                        <div class="message-content">
                            {{ message.content }}
                        </div>
                        <div class="message-content-footer">
                            {{ getFormattedMessageSender(message) }}
                        </div>
                    </div>
                </div>
                <!-- Integration Log Messages -->
                <div class="integration-log-container"
                     *ngIf="message.isLogItem">
                    <div class="integration-log-content">
                        {{ message.content }}
                    </div>
                    <div class="integration-log-footer">
                        {{ getFormattedMessageDate(message) }}
                    </div>
                </div>

            </div>
        </div>

        <div class="preset-messages" *ngIf="presetMessages?.length > 0">
            <button class="teth-button" (click)="showPresetMessages()" i18n>
                Preset Messages
            </button>
        </div>
        <div class="type-message-container">
            <div class="message-refresh-button"
                 (click)="reloadMessages.emit(true)"
                 (mouseenter)="reloadMessagesHover = true"
                 (mouseleave)="reloadMessagesHover = false">
                <img [src]="'/assets/img/icons/retry-blue.svg'" alt="add" [hidden]="reloadMessagesHover">
                <img [src]="'/assets/img/icons/retry-white.svg'" alt="add" [hidden]="!reloadMessagesHover">
            </div>
            <form class="teth-textfield-form message-form">
                <label for="message-input" hidden></label>
                <textarea #messageTextArea="cdkTextareaAutosize"
                          class="message-textfield"
                          type="text"
                          id="message-input"
                          name="message-input"
                          placeholder="Start a new message"
                          cdkTextareaAutosize
                          cdkAutosizeMinRows="1"
                          cdkAutosizeMaxRows="2"
                          (keyup.enter)="sendNewMessage(true)"
                          [(ngModel)]="newMessageText"
                          (ngModelChange)="newMessageTextUpdated()"></textarea>
            </form>
            <button class="teth-button blue-button send-button"
                    [ngClass]="{'disabled': !sendButtonEnabled}"
                    (click)="sendNewMessage()" i18n>
                SEND
            </button>
        </div>

    </div>
</div>
