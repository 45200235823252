<div class="teth-breadcrumb-container mt-3">
    <ul class="teth-breadcrumb-list">
        <div class="teth-breadcrumb-title">
            {{title}}
        </div>
        <li class="teth-breadcrumb-item"
            *ngFor="let breadcrumb of breadcrumbs; let i = index;"
            [ngClass]="{ 'active' : breadcrumb.active, 'disabled' : breadcrumb.disabled }"
            [ngStyle]="{ 'height': breadcrumb.iconName !== '' ? '2rem' : '3rem' }"
            [hidden]="breadcrumb.hidden">
            <div class="teth-breadcrumb-text-container"
                 (click)="breadcrumbClicked((breadcrumb.active || breadcrumb.disabled), i)">
                <div class="teth-breadcrumb-image-container"
                     *ngIf="breadcrumb.iconName && breadcrumb.iconName !== ''">
                    <img class="teth-breadcrumb-image" src="../../../assets/img/icons/{{ breadcrumb.iconName }}"
                         alt=""/>
                </div>
                <div class="teth-breadcrumb-details-container">
                    <div class="teth-breadcrumb-label">
                        {{ breadcrumb.label }}
                    </div>
                    <div class="teth-breadcrumb-state" [hidden]="breadcrumb.completedState">
                        {{ breadcrumb.state }}
                    </div>
                    <div class="teth-breadcrumb-state" [hidden]="!breadcrumb.completedState">
                        {{ breadcrumb.completedState }}
                    </div>
                </div>
            </div>
        </li>
    </ul>
</div>
