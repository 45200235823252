<div class="row">
    <div class="col-12">
        <form class="padding-x-0 teth-textfield-form" [formGroup]="parentForm"
              [class.error]="parentForm.controls[textId].invalid && (parentForm.controls[textId].dirty || parentForm.controls[textId].touched)">
            <label [ngClass]="{'required': required}" class="text-group-label" for="{{textId}}">{{textLabel}}</label>
            <textarea class="teth-text-area w-50" id="{{textId}}"  rows="1"
                      cols="5" placeholder="{{placeholderText}}"
                      formControlName="{{textId}}"
                      (input)="textfieldChanged()"></textarea>
            <ng-container *ngIf="showCheckbox">
                <div class="checkbox-container">
                    <label class="teth-checkbox-container" for="{{checkId}}">
                        <input type="checkbox" class="teth-checkbox" id="{{checkId}}"
                               formControlName="{{checkId}}"
                               (change)="checkboxChanged()">
                        <span class="teth-checkbox-label">{{checkboxLabel}}</span>
                        <span class="teth-checkmark"></span>
                    </label>
                </div>
            </ng-container>
            <span class="teth-textfield-error" i18n>Field must be completed</span>
        </form>
    </div>
</div>
