<app-radio-group
        [textLabel]="getLocalizedRestorationTypeTitle()"
        [control]="'restorationTypeId'"
        [parentForm]="parentForm"
        [radioButtons]="radiobuttons[0]"
        [required]="true">
</app-radio-group>
<hr>
<app-radio-group
        [textLabel]="getLocalizedProductTypeTitle()"
        [control]="'productTypeId'"
        [parentForm]="parentForm"
        [radioButtons]="radiobuttons[1]"
        [required]="true">
</app-radio-group>
<hr>
<ng-container *ngIf="parentForm.controls.productTypeId?.value == 1">
    <app-radio-group
            [textLabel]="getLocalizedMetalTypeTitle()"
            [control]="'metalTypeId'"
            [parentForm]="parentForm"
            [radioButtons]="radiobuttons[2]"
            [required]="true">
    </app-radio-group>
    <hr>
</ng-container>
<ng-container *ngIf="parentForm.controls.productTypeId?.value == 2">
    <app-radio-group
            [textLabel]="getLocalizedCeramicsTypeTitle()"
            [control]="'ceramicsTypeId'"
            [parentForm]="parentForm"
            [radioButtons]="radiobuttons[3]"
            [required]="true">
    </app-radio-group>
    <hr>
</ng-container>
<app-text-group
        [placeholderText]="getLocalizedShadePlaceholder()"
        [textLabel]="getLocalizedShadeTitle()"
        [parentForm]="parentForm"
        [textId]="'shade'"
        [showCheckbox]="true"
        [checkboxLabel]="getLocalizedCustomShadeTitle()"
        [checkId]="'customShade'"
        [required]="true"
        (checkboxChangedEmitter)="emitCheckboxValue($event)"
        (textfieldChangedEmitter)="emitTextFieldValue($event)">
</app-text-group>
<hr>
<app-text-group
        [placeholderText]="getLocalizedStumpShadePlaceholderTitle()"
        [textLabel]="getLocalizedStumpShadeTitle()"
        [parentForm]="parentForm"
        [textId]="'stumpShade'"
        [required]="false">
</app-text-group>
<hr>
<app-radio-group
        [textLabel]="getLocalizedMarginTitle()"
        [control]="'marginTypeId'"
        [parentForm]="parentForm"
        [radioButtons]="radiobuttons[4]"
        [required]="parentForm.controls.productTypeId?.value !== 2">
</app-radio-group>
<hr>
<app-radio-group
        [textLabel]="getLocalizedDeliveryTitle()"
        [control]="'deliveryTypeId'"
        [parentForm]="parentForm"
        [radioButtons]="radiobuttons[11]"
        [required]="true">
</app-radio-group>
<hr>
<ng-container *ngIf="parentForm.controls.restorationTypeId?.value == 2">
    <app-radio-group
            [textLabel]="getLocalizedPonticDesignTitle()"
            [control]="'ponticDesignTypeId'"
            [parentForm]="parentForm"
            [radioButtons]="radiobuttons[5]"
            [required]="true">
    </app-radio-group>
    <hr>
</ng-container>
<ng-container *ngIf="showAdvancedOptions">
    <app-radio-group
            [textLabel]="getLocalizedOcclusalContactTitle()"
            [control]="'occlusalContactTypeId'"
            [parentForm]="parentForm"
            [radioButtons]="radiobuttons[6]">
    </app-radio-group>
    <hr>
    <app-radio-group
            [textLabel]="getLocalizedToothMorphTitle()"
            [control]="'toothMorphologyTypeId'"
            [parentForm]="parentForm"
            [radioButtons]="radiobuttons[7]">
    </app-radio-group>
    <hr>
    <app-checkbox-group
            [textLabel]="getLocalizedSurfaceTextureTitle()"
            [control]="'surfaceTextureTypeId'"
            [parentForm]="parentForm"
            [radioButtons]="radiobuttons[8]">
    </app-checkbox-group>
    <hr>
    <app-radio-group
            [textLabel]="getLocalizedInterproximalContactTitle()"
            [control]="'interproximalContactTypeId'"
            [parentForm]="parentForm"
            [radioButtons]="radiobuttons[9]">
    </app-radio-group>
    <hr>
    <app-radio-group
            [textLabel]="getLocalizedIncisalTranslucencyTitle()"
            [control]="'incisalTranslucencyTypeId'"
            [parentForm]="parentForm"
            [radioButtons]="radiobuttons[10]">
    </app-radio-group>
</ng-container>
<div class="mt-3 mb-3">
    <button class="teth-button" (click)="toggleOptions()">
        <span>{{showAdvancedOptions ? getLocalizedHideOptionsTitle() : getLocalizedShowOptionsTitle()}}</span></button>
</div>
<hr>
