<div class="d-flex justify-content-end" [class.modal-footer]="sticky" [style.padding-top.rem]="paddingTopInRem" [style.gap.rem]="0.75">
    <button
            class="teth-button white-button bordered-button procedures-modal-button"
            (click)="secondaryButtonClicked.emit()">
        {{secondaryButtonText}}
    </button>
    <button
            class="teth-button {{primaryButtonClass}} procedures-modal-button"
            [class.disabled]="primaryButtonDisabled"
            [disabled]="primaryButtonDisabled"
            (click)="primaryButtonClicked.emit()">
        {{primaryButtonText}}
    </button>
</div>
