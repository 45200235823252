import {Address} from './address.model';
import {Deserializable} from '../views/shared/utils/json-utils';
import {CompanyReferralSource} from './company-referral-source.model';

export class Company implements Deserializable {
    public id: number;
    public name: string;
    public address: Address;
    public phoneNumber: string;
    public administratorId: number;
    public administratorPhoneNumber: string;
    public administrator: string;
    public companyTypeId: CompanyType;
    public referralSourceId: number;
    public referralSource: CompanyReferralSource;
    public createdDate: Date;
    public createdById: number;
    public createdBy: string;


    onDeserialize() {
    }
}

export enum CompanyType {
    Administrator = 1,
    Specialist = 2,
    Dentist = 3,
    DentalLab = 4,
}
