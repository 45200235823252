import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Location as _Location} from '@angular/common';

@Component({
    selector: 'app-generic-modal',
    templateUrl: './generic-modal.component.html',
    styleUrls: ['./generic-modal.component.scss', '../../../stylesheet/stylesheet.component.scss']
})
export class GenericModalComponent implements OnInit {
    title: string;
    message: string;
    primaryButtonText: string;
    primaryButtonClass: string;
    secondaryButtonText: string;
    secondaryButtonClass: string;

    static defaultModalOptions(): any {
        return {size: 'md', backdrop: 'static', centered: true};
    }

    constructor(private activeModal: NgbActiveModal) {
    }

    ngOnInit(): void {
    }

    initWith(title: string, message: string, primaryButtonText: string, secondaryButtonText: string, primaryButtonClass: string = 'blue-button', secondaryButtonClass: string = 'grey-button') {
        this.title = title;
        this.message = message;
        this.primaryButtonText = primaryButtonText;
        this.primaryButtonClass = primaryButtonClass;
        this.secondaryButtonText = secondaryButtonText;
        this.secondaryButtonClass = secondaryButtonClass;
    }

    buttonClicked(primary: boolean) {
        this.activeModal.close(primary);
    }
}
