import {Type} from '@angular/core';

export interface Deserializable {
    onDeserialize();
}

export class DeserializeHelper {
    static deserializeToInstance<T extends Deserializable>(object: new () => T, data: any): T {
        const instance = Object.assign(Object.create(object.prototype), data);
        instance.onDeserialize();
        return instance as T;
    }

    static shallowCopyOf<T>(data: T): T {
        if (!data) {
            return null;
        } else {
            const shallowCopy = Object.create(Object.getPrototypeOf(data));
            Object.keys(data).forEach(key => shallowCopy[key] = data[key]);
            return shallowCopy;
        }
    }

    static arrayOf<T extends Deserializable>(ObjectType: Type<T>, data: any[]): T[] {
        if (!data) {
            return null;
        } else {
            const arr: T[] = [];
            data.forEach(d => {
                const inst: T = this.deserializeToInstance(ObjectType, d);
                if (!!inst) { arr.push(inst); }
            });
            return arr;
        }
    }
}

// Used to represent Message and IntegrationLog
export interface Logable {
    id: string;
    referralId: string;
    createdDate: Date;
    createdById: number;
    createdBy: string;
    content: string;
    isLogItem: boolean;
}
