<div class="new-clinic-checklist" [class.d-none]="hidden || !enabledForCurrentUser">
    <div class="checklist-container">
        <div class="checklist-title" i18n>New Clinic Checklist</div>
        <div class="checklist-items">
            <div class="checklist-item" *ngFor="let item of checklistItems">
                <div class="item-title">
                    {{item.title}}
                </div>
                <div class="item-description">
                    {{item.description}}
                </div>
                <div class="item-action" (click)="actionClicked(item.routerLink)">
                    {{item.action}}
                    <img [src]="'assets/img/icons/arrow-right.svg'" alt="action">
                </div>
            </div>
        </div>
        <div class="dismiss"
             (click)="hideForever()"
             i18n>Dismiss and Never Show Again</div>
    </div>
    <div class="checklist-image">
        <img [src]="'assets/img/images/checkmark-graphic-purple.svg'" alt="graphic">
    </div>
    <div class="close-button" (click)="hide()">
        <img [src]="'assets/img/icons/remove-white.svg'" alt="close">
    </div>
</div>
