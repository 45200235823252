import {Deserializable} from '../views/shared/utils/json-utils';

export class NetworkInvitationCustomText implements Deserializable {
    public id: number;
    public companyId: number;
    public locationId: number;
    public text: string;
    public createdDate: string;
    public createdById: number;
    public createdBy: string;

    onDeserialize() {
    }
}
