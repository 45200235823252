<app-loading
        [determinateLoading]="false"
        [hidden]="!isLoading"
        [loadingText]="'Loading Profile'"
        [fullscreen]="false"
        [backgroundColor]="'#FDFDFD'"
        [color]="'#388EFC'">
</app-loading>

<div class="container mt-5" *ngIf="!isLoading">
    <div *ngIf="addingEmployee" class="row">
        <div class="col-md-12 d-flex">
            <div class="page-title flex-grow-1 pb-0" i18n>Add Employee</div>
            <button class="teth-button white-button bordered-button" (click)="goBack()" i18n>Cancel</button>
        </div>
    </div>
    <div *ngIf="!addingEmployee" class="row">
        <div class="no-margin-top">
            <button class="teth-button white-button back-button" (click)="goBack()" i18n>Back</button>
        </div>
        <div *ngIf="(!addingEmployee && !userId)" class="ml-auto">
            <button class="teth-button grey-button"
                    (click)="logOut()">
                <ng-container i18n>Logout</ng-container>
                <i class="logout-icon"></i>
            </button>
        </div>
    </div>
    <div class="row">
        <div *ngIf="!addingEmployee">
            <div class="mr-auto">
                <div class="profilePicture">
                    <div class="placeholder-profilePic"
                         [hidden]="(((employee | async) | getEmployeePhotoSrc : imageService | async) && !deleteEmployeePhotoId) || imageToUpload?.length > 0">
                        {{getInitials()}}
                    </div>
                    <div class="profile-image" *ngIf="imageToUpload?.length > 0">
                        <img [src]="imageToUpload" alt="image">
                    </div>
                    <div class="profile-image"
                         *ngIf="((employee | async) | getEmployeePhotoSrc : imageService | async) && !imageToUpload && !deleteEmployeePhotoId">
                        <img [src]="(employee | async) | getEmployeePhotoSrc : imageService | async " alt="employee image">
                    </div>
                </div>
            </div>
        </div>
        <div class="addProfilePicture mb-3" *ngIf="addingEmployee">
            <div class="profile-image" *ngIf="imageToUpload?.length > 0" (click)="addProfilePicture()">
                <img [src]="imageToUpload" alt="image">
            </div>
            <div class="placeholder-profilePic no-top-padding" *ngIf="imageToUpload === null">
                <img class="add-employee-icon" alt="plus-icon" [src]="'assets/img/icons/add.svg'">
            </div>
            <div class="ml-3" *ngIf="imageToUpload === null">
                <button class="teth-button blue-button" (click)="addProfilePicture()" i18n>Add Profile Picture</button>
            </div>
            <div class="ml-3" *ngIf="imageToUpload?.length > 0">
                <button class="teth-button grey-button mr-2" (click)="addProfilePicture()" i18n>Add New Picture</button>
                <button class="teth-button white-button bordered-button" (click)="deletePicture()" i18n>Delete Picture
                </button>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="!addingEmployee">
        <div class="mr-auto mt-2 mb-4">
            <div class="headline">{{employee.value.primaryName}}&nbsp;{{employee.value.familyName}}</div>
        </div>
        <div class="ml-3">
            <button class="teth-button destructive-button" *ngIf="employeeForm.value.enabled && userId"
                    (click)="disableEmployee()" i18n>Disable Employee
            </button>
        </div>
        <div class="ml-3">
            <button class="teth-button blue-button" *ngIf="!employeeForm.value.enabled && userId"
                    (click)="enableEmployee()" i18n>
                Enable Employee
            </button>
        </div>
        <div class="ml-3" *ngIf="!employeePhoto && imageToUpload === null" [hidden]="!employeeForm.value.enabled">
            <button class="teth-button blue-button" (click)="addProfilePicture()" i18n>Add Profile Picture</button>
        </div>
        <div class="ml-3" *ngIf="employeePhoto || imageToUpload?.length > 0" [hidden]="!employeeForm.value.enabled">
            <button class="teth-button grey-button mr-2" (click)="addProfilePicture()" i18n>Add New Picture</button>
            <button class="teth-button white-button bordered-button" (click)="deletePicture()" i18n>Delete Picture
            </button>
        </div>
    </div>
    <div class="row profile-card mb-4" [class.disabled-section]="!employeeForm.value.enabled">
        <div class="row profile-row">
            <div class="col-12 col-lg-4 profile-card-description">
                <p class="profile-card-description-header" i18n>Personal</p>
                <p *ngIf="(!addingEmployee && !userId)" class="profile-card-description-subtext" i18n>This is the profile information that has been entered into Tetherport when your account was created. If anything needs to be changed, contact your administrator.</p>
                <p *ngIf="addingEmployee || ((userId && !addingEmployee))" class="profile-card-description-subtext" i18n>Add personal information for a new employee to help identify them.</p>
            </div>
            <div class="col-6 col-lg-4">
                <form class="teth-textfield-form" [formGroup]="employeeForm"
                      [class.error]="employeeForm.controls.firstName.invalid && (employeeForm.controls.firstName.dirty || employeeForm.controls.firstName.touched)">
                    <label class="teth-textfield-label" [ngClass]="{'required': addingEmployee}" for="firstName" i18n>First Name</label>
                    <input [ngClass]="{'input-readOnly': disableFields, 'teth-textfield': !disableFields }" type="text"
                           id="firstName" name="firstName"
                           i18n-placeholder
                           placeholder="Enter your first name" [formControlName]="'firstName'">
                    <span class="teth-textfield-error" i18n>First name is required </span>
                </form>
            </div>
            <div class="col-6 col-lg-4">
                <form class="teth-textfield-form" [formGroup]="employeeForm"
                      [class.error]="employeeForm.controls.lastName.invalid && (employeeForm.controls.lastName.dirty || employeeForm.controls.lastName.touched)">
                    <label class="teth-textfield-label" [ngClass]="{'required': addingEmployee}" for="lastName" i18n>Last Name</label>
                    <input [ngClass]="{'input-readOnly': disableFields, 'teth-textfield': !disableFields }" type="text"
                           id="lastName" name="lastName"
                           i18n-placeholder
                           placeholder="Enter your last name" [formControlName]="'lastName'">
                    <span class="teth-textfield-error" i18n>Last name is required</span>
                </form>
            </div>
        </div>
        <div class="row profile-row">
            <div class="col-6 col-lg-4 offset-lg-4">
                <form class="teth-textfield-form" [formGroup]="employeeForm">
                    <label class="teth-textfield-label" for="prefix" i18n>Prefix</label>
                    <select [ngClass]="{'dropdown-readOnly': disableFields, 'teth-dropdown': !disableFields, 'teth-textfield-dropdown': !disableFields}"
                            name="prefix" id="prefix"
                            [formControlName]="'prefix'">
                        <option *ngIf="addingEmployee" value="" selected disabled hidden i18n>Choose your prefix</option>
                        <option *ngFor="let prefix of prefixes"
                                [attr.selected]="employee.value.prefix === prefix ? true : null">
                            {{prefix}}
                        </option>
                    </select>
                </form>
            </div>
            <!--            <div class="col-6 col-lg-4">-->
            <!--                <form class="teth-textfield-form" [formGroup]="employeeForm"-->
            <!--                      [class.error]="employeeForm.controls.email.invalid && (employeeForm.controls.email.dirty || employeeForm.controls.email.touched)">-->
            <!--                    <label class="teth-textfield-label" for="email">Email</label>-->
            <!--                    <input class="teth-textfield" type="email" id="email" name="email"-->
            <!--                           placeholder="Enter your email"-->
            <!--                           [formControlName]="'email'">-->
            <!--                    <span class="teth-textfield-error">Email address is invalid</span>-->
            <!--                </form>-->
            <!--            </div>-->
        </div>
        <ng-container *ngIf="true">
            <div class="row profile-row">
                <div class="col-12 col-lg-4 offset-lg-4 mt-4">
                    <div class="left-padding">
                        <ng-container *ngIf="disableFields">
                            <div *ngIf="this.currentCompanyType !== companyTypes.Specialist && isEmployeeDentist" i18n>
                                Employee has been marked as a dentist
                            </div>
                        </ng-container>
                        <label *ngIf="!disableFields && this.currentCompanyType !== companyTypes.DentalLab" class="teth-checkbox-container" for="doctorType">
                            <input type="checkbox" class="teth-checkbox" id="doctorType"
                                   name="doctorType"
                                   [(ngModel)]="isEmployeeDentist"
                                   [disabled]="!this.addingEmployee && !this.userId"
                                   [hidden]="!this.addingEmployee && !this.userId">
                            <ng-container *ngIf="this.currentCompanyType === companyTypes.Dentist">
                                <span class="check-box-label" i18n>Employee is a Dentist</span>
                            </ng-container>
                            <ng-container *ngIf="this.currentCompanyType === companyTypes.Specialist">
                                <span class="check-box-label" i18n>Employee is a Specialist</span>
                            </ng-container>
                            <span class="teth-checkmark"></span>
                        </label>
                    </div>
                </div>
                <ng-container *ngIf="isEmployeeDentist && this.currentCompanyType === companyTypes.Specialist">
                    <div class="col-12 col-lg-4 offset-lg-4">
                        <form class="teth-textfield-form" [formGroup]="specialtyForm">
                            <label class="teth-textfield-label" for="specialtyType" i18n>Specialty Type</label>
                            <select [ngClass]="{'dropdown-readOnly': disableFields, 'teth-dropdown': !disableFields, 'teth-textfield-dropdown': !disableFields}"
                                    name="specialtyType"
                                    id="specialtyType"
                                    (change)="onSpecialtyChange()"
                                    [formControlName]="'specialty'">
                                <option value="-1" selected disabled hidden i18n>Choose specialty</option>
                                <option *ngFor="let specialty of specialtyTypes.value"
                                        [ngValue]="specialty.id">
                                    {{specialty.name}}
                                </option>
                            </select>
                        </form>
                    </div>
                    <ng-container *ngIf="specialtyForm.value.specialty">
                        <div class="col-12 col-lg-4 offset-lg-4 mt-3">
                            <div class="left-padding">
                                <button class="teth-button grey-button" (click)="viewEditProcedures()" i18n>View Procedures
                                </button>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
            </div>
        </ng-container>
    </div>
    <div *ngIf="addingEmployee || !addingEmployee && userId" class="row profile-card mb-4">
        <div class="row profile-row">
            <div class="col-12 col-lg-4 profile-card-description">
                <p class="profile-card-description-header" i18n>Role</p>
                <p class="profile-card-description-subtext" i18n>An employee role will affect what an employee can and cannot do while using Tetherport. Custom roles can be created from the settings tab.</p>
            </div>
            <div class="col-6 col-lg-4">
                <form class="teth-textfield-form" [formGroup]="employeeForm">
                    <label class="teth-textfield-label" [ngClass]="{'required': addingEmployee}" for="role" i18n>Employee Role</label>
                    <select [ngClass]="{'dropdown-readOnly': disableFields, 'teth-dropdown': !disableFields, 'teth-textfield-dropdown': !disableFields}"
                            name="role"
                            id="role"
                            formControlName="role">
                        <option [value]="null" selected disabled hidden i18n>Choose your role</option>
                        <option *ngFor="let role of companyRoles.value" [value]="role.id">{{role.name}}</option>
                    </select>
                </form>
                <div class="left-padding">
                    <span class="text-link" (click)="showRoleInfoModal()" i18n>Role Info</span>
                </div>
            </div>
            <div *ngIf="addingEmployee" class="row profile-row">
                <div class="col-12 col-lg-4 offset-lg-4">
                    <div class="left-padding">
                        <button class="teth-button grey-button" (click)="addCustomRole()" i18n>Add a Custom Role</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row profile-card mb-4" [hidden]="!employeeForm.value.enabled">
        <div class="row profile-row">
            <div class="col-12 col-lg-4 profile-card-description">
                <p class="profile-card-description-header" i18n>Locations</p>
                <p *ngIf="addingEmployee || ((userId && !addingEmployee))" class="profile-card-description-subtext" i18n>Add clinic locations that this employee will be working from. They will only be able to see information from clinics that are added here.</p>
                <p *ngIf="(!addingEmployee && !userId)" class="profile-card-description-subtext" i18n>These are the locations you have been assigned to.</p>
            </div>
            <div class="location-list">
                <ng-container *ngIf="addingEmployee || userId">
                    <div class="location-card">
                        <form class="teth-textfield-form" [formGroup]="employeeForm"
                              [class.error]="employeeForm.controls.locationCheckbox.errors != null &&
                              (employeeForm.controls.locationCheckbox.dirty || employeeForm.controls.locationCheckbox.touched || employeeForm.controls.allLocationsCheckbox.dirty || employeeForm.controls.allLocationsCheckbox.touched)">
                            <label class="teth-checkbox-container" for="checkbox-all">
                                <input type="checkbox" class="teth-checkbox" id="checkbox-all" name="checkbox-all"
                                       [class.indeterminate]="locationPercentageChecked > 0 && locationPercentageChecked < 1"
                                       (change)="selectAllClicked($event)"
                                       [formControlName]="'allLocationsCheckbox'">
                                <span class="teth-checkbox-label ml-2" i18n>Select All</span>
                                <span class="teth-checkmark"></span>
                            </label>
                            <label class="teth-checkbox-container mb-4" for="{{ 'location-checkbox-' + i }}"
                                   *ngFor="let location of locations.value; let i = index;">
                                <input type="checkbox" class="teth-checkbox"
                                       id="{{ 'location-checkbox-' + i }}"
                                       name="{{ 'location-checkbox-' + i }}"
                                       [checked]="employeeForm.controls.locationCheckbox.value?.includes(location.id)"
                                       (change)="locationCheckboxSelected($event, location)">
                                <img class="img-fluid location-image ml-2 mr-2" alt="image of location"
                                     [src]="(getLocationDisplayPhoto(location) | async)
                                         || 'assets/img/placeholder/company-placeholder.jpg'">
                                <span class="add-location-name">{{ location.name }}</span>
                                <span class="add-location-address">{{ addressUtils.singleLineFormat(location.address) }}</span>
                                <span class="teth-checkmark"></span>
                            </label>
                            <span class="teth-textfield-error" i18n>One location needs to be selected</span>
                        </form>
                    </div>
                </ng-container>
                <ng-container *ngIf="!addingEmployee && !userId">
                    <div class="mb-3" *ngFor="let location of locations.value">
                        <img class="img-fluid location-image ml-2 mr-2"
                             [src]="(getLocationDisplayPhoto(location) | async)
                                     || 'assets/img/placeholder/company-placeholder.jpg'"
                             alt="image of locations">
                        <span class="add-location-name">
                            {{location.name}}
                        </span>
                        <span class="add-location-address">
                            {{addressUtils.singleLineFormat(location.address)}}
                        </span>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
    <div class="row profile-card mb-4" [hidden]="!employeeForm.value.enabled || this.companyPractitioners.value?.length === 0 || (this.currentCompanyType != companyTypes.Specialist && this.currentCompanyType != companyTypes.Dentist)">
        <div class="row profile-row">
            <div class="col-12 col-lg-4 profile-card-description">
                <p class="profile-card-description-header" *ngIf="this.currentCompanyType == companyTypes.Specialist" i18n>Assigned Specialists</p>
                <p class="profile-card-description-header" *ngIf="this.currentCompanyType == companyTypes.Dentist" i18n>Assigned Dentists</p>
                <p *ngIf="addingEmployee || ((userId && !addingEmployee))" class="profile-card-description-subtext" i18n>Add practitioners with whom this employee will be working. They will only be able to see referral information from these practitioners.</p>
                <p *ngIf="(!addingEmployee && !userId)" class="profile-card-description-subtext" i18n>These are the practitioners to whom you have been assigned.</p>
            </div>
            <div class="location-list">
                <ng-container *ngIf="addingEmployee || userId">
                    <div class="location-card">
                        <form class="teth-textfield-form" [formGroup]="employeeForm"
                              [class.error]="employeeForm.controls.practitionerCheckbox.errors != null &&
                              (employeeForm.controls.practitionerCheckbox.dirty || employeeForm.controls.practitionerCheckbox.touched || employeeForm.controls.allLocationsCheckbox.dirty || employeeForm.controls.allLocationsCheckbox.touched)">
                            <label class="teth-checkbox-container" for="practitioner-checkbox-all">
                                <input type="checkbox" class="teth-checkbox" id="practitioner-checkbox-all" name="practitioner-checkbox-all"
                                       [class.indeterminate]="practitionerPercentageChecked > 0 && practitionerPercentageChecked < 1"
                                       (change)="selectAllPractitionersClicked($event)"
                                       [formControlName]="'allPractitionersCheckbox'">
                                <span class="teth-checkbox-label ml-2" i18n>Select All</span>
                                <span class="teth-checkmark"></span>
                            </label>
                            <label class="teth-checkbox-container mb-4" for="{{ 'practitioner-checkbox-' + i }}"
                                   *ngFor="let practitioner of companyPractitioners.value; let i = index;">
                                <input type="checkbox" class="teth-checkbox"
                                       id="{{ 'practitioner-checkbox-' + i }}"
                                       name="{{ 'practitioner-checkbox-' + i }}"
                                       [checked]="employeeForm.controls.practitionerCheckbox.value?.includes(practitioner.id)"
                                       (change)="practitionerCheckboxSelected($event, practitioner)">
                                <img class="img-fluid location-image ml-2 mr-2" alt="image of practitioner"
                                     [src]="(getPractitionerDisplayPhoto(practitioner) | async)
                                         || 'assets/img/placeholder/company-placeholder.jpg'">
                                <span class="add-location-name">{{practitioner.prefix}} {{practitioner.primaryName}} {{practitioner.familyName}}</span>
                                <span class="add-location-address">{{ practitioner.role }}</span>
                                <span class="teth-checkmark"></span>
                            </label>
                            <span class="teth-textfield-error" i18n>One practitioner needs to be selected</span>
                        </form>
                    </div>
                </ng-container>
                <ng-container *ngIf="!addingEmployee && !userId">
                    <div class="mb-3" *ngFor="let location of locations.value">
                        <img class="img-fluid location-image ml-2 mr-2"
                             [src]="(getLocationDisplayPhoto(location) | async)
                                     || 'assets/img/placeholder/company-placeholder.jpg'"
                             alt="image of locations">
                        <span class="add-location-name">
                            {{location.name}}
                        </span>
                        <span class="add-location-address">
                            {{addressUtils.singleLineFormat(location.address)}}
                        </span>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
    <div class="row profile-card mb-4" [hidden]="!employeeForm.value.enabled">
        <div class="row profile-row">
            <div class="col-12 col-lg-4 profile-card-description">
                <p class="profile-card-description-header" i18n>Login</p>
                <p class="profile-card-description-subtext" i18n>This information will be used to access the Tetherport system.</p>
            </div>
            <div class="col-12 col-lg-8">
                <form class="teth-textfield-form" [formGroup]="employeeForm"
                      [class.error]="employeeForm.controls.username.invalid && (employeeForm.controls.username.dirty || employeeForm.controls.username.touched)">
                    <label class="teth-textfield-label" for="username" i18n>Username</label>
                    <input [ngClass]="{'input-readOnly': !addingEmployee, 'teth-textfield': addingEmployee }" type="text"
                           id="username" name="username"
                           placeholder="Enter username"
                           i18n-placeholder
                           [formControlName]="'username'">
                    <span class="teth-textfield-error" i18n>Username cannot contain special characters</span>
                </form>
            </div>
        </div>
        <div *ngIf="addingEmployee" class="row profile-row">
            <div class="col-12 col-lg-8 offset-lg-4">
                <form class="teth-textfield-form" [formGroup]="employeeForm">
                    <label class="teth-textfield-label" for="password" i18n>Password</label>
                    <input class="teth-textfield" type="password" id="password" name="password"
                           placeholder="Enter your new password"
                           i18n-placeholder
                           autocomplete="new-password"
                           [formControlName]="'password'">
                    <span class="teth-textfield-error"></span>
                </form>
            </div>
            <div class="col-12 col-lg-8 offset-lg-4"
                 *ngIf="employeeForm.errors?.passwordRequirementsNotMet && (employeeForm.controls.password.dirty || employeeForm.controls.password.touched)">
                <form class="teth-textfield-form error">
                    <span class="teth-textfield-error" i18n>Passwords require 8 characters, at least 1 uppercase and lowercase, at least 1 number, and at least 1 special character</span>
                </form>
            </div>
        </div>
        <div *ngIf="addingEmployee" class="row profile-row">
            <div class="col-12 col-lg-8 offset-lg-4">
                <form class="teth-textfield-form" [formGroup]="employeeForm">
                    <label class="teth-textfield-label" for="confirmPassword" i18n>Confirm Password</label>
                    <input class="teth-textfield" type="password" id="confirmPassword" name="confirmPassword"
                           placeholder="Enter your new password"
                           autocomplete="new-password"
                           i18n-placeholder
                           [formControlName]="'confirmPassword'">
                    <span class="teth-textfield-error"></span>
                </form>
            </div>
            <div class="col-12 col-lg-8 offset-lg-4"
                 *ngIf="employeeForm.errors?.passwordNotMatching && (employeeForm.controls.confirmPassword.dirty || employeeForm.controls.confirmPassword.touched)">
                <form class="teth-textfield-form error">
                    <span class="teth-textfield-error" i18n>Passwords do not match</span>
                </form>
            </div>
        </div>
        <div class="row profile-row mt-3" *ngIf="!addingEmployee && !userId">
            <div class="col-12 col-lg-8 offset-lg-4 password-button">
                <button class="teth-button grey-button" (click)="showPasswordResetModal(true)" i18n>Change Password</button>
            </div>
        </div>
        <div class="row profile-row mt-3" *ngIf="!addingEmployee && userId">
            <div class="col col-md-4 offset-md-4">
                <button class="teth-button grey-button" (click)="showPasswordResetModal(false)" i18n>Reset Password</button>
            </div>
        </div>
    </div>
    <div class="row mt-5">
        <div class="ml-auto">
            <button class="teth-button blue-button"
                    [class.grey-button]="!this.employeeForm.valid"
                    [disabled]="!this.employeeForm.valid" (click)="saveEmployee()">
                {{getSaveButtonTitle()}}
            </button>
        </div>
    </div>
</div>
