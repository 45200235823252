import {Component, OnInit, Input} from '@angular/core';

@Component({
    selector: 'app-progress-bar',
    templateUrl: './progress-bar.component.html',
    styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit {
    @Input() progress = 0;
    @Input() errored: boolean;
    @Input() barColor: string;

    constructor() {

    }

    ngOnInit() {

    }

    getOverrideBarColor(): string {
        if (this.barColor && this.barColor !== '') {
            return this.barColor;
        } else {
            return null;
        }
    }
}
