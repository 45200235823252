import {BehaviorSubject, defer, Observable, Subject, Subscription} from 'rxjs';
import {filter, finalize, take} from 'rxjs/operators';
import {TimeRangeFilter} from '../../../models/time-range-filter';

declare module 'rxjs/internal/Observable' {
    interface Observable<T> {
        filterValidTimeRange(): Observable<T>;
        firstNotNull(): Observable<T>;
        notNull(): Observable<T>;
        once(run?: (data: T) => void): Subscription;
    }
}

Observable.prototype.notNull =
    function (): Observable<any> {
        return this.pipe(filter(x => x != null));
    };

Observable.prototype.firstNotNull =
    function (): Observable<any> {
        return this.pipe(filter(x => x != null), take(1));
    };

// To be used with TimeRangeFilter, to ensure that both startDate and endDate are set before emitting the value.
Observable.prototype.filterValidTimeRange = function <T>(this: Observable<T>): Observable<T> {
    return this.pipe(
        filter((value: T) => {
            if (value instanceof TimeRangeFilter) {
                const timeRange: TimeRangeFilter = value as TimeRangeFilter;
                return !!timeRange.startDate && !!timeRange.endDate;
            }
            return true;
        })
    );
};

Observable.prototype.once = function<T>(run?: (data: T) => void): Subscription {
    return this.pipe(take(1)).subscribe(data => run?.(data));
};


export function indicate<T>(indicator: BehaviorSubject<number>): (source: Observable<T>) => Observable<T> {
    return (source: Observable<T>): Observable<T> => source.pipe(
        prepare(() => indicator.next(indicator.value + 1)),
        finalize(() => indicator.next(indicator.value - 1))
    );
}

export function prepare<T>(callback: () => void): (source: Observable<T>) => Observable<T> {
    return (source: Observable<T>): Observable<T> => defer(() => {
        callback();
        return source;
    });
}
