import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {InfoCard, InfoCardType} from '../../../models/info-card';

@Component({
    selector: 'app-info-cards',
    templateUrl: './info-cards.component.html',
    styleUrls: ['./info-cards.component.scss']
})
export class InfoCardsComponent implements OnInit {

    @Input() public title: string = '';
    @Input() public infoCards: InfoCard[] = [];
    public cardTypes = InfoCardType;

    @Output() public cardClicked = new EventEmitter<InfoCard>();

    constructor() {
    }

    ngOnInit(): void {
    }

    public getPercentChangeTextColor(index: number): string {
        if (this.infoCards[index].percentChangeTextColor !== '') {
            return this.infoCards[index].percentChangeTextColor;
        } else {
            this.getBackgroundColor(index);
        }
    }

    public getBackgroundColor(index: number): string {
        if (this.infoCards[index].hovered) {
            if (this.infoCards[index].backgroundHoverColor && this.infoCards[index].backgroundHoverColor !== '' && this.infoCards[index].type !== this.cardTypes.InfoWithIcon) {
                return this.infoCards[index].backgroundHoverColor;
            } else {
                return '#DADBDE';
            }
        } else {
            if (this.infoCards[index].backgroundColor && this.infoCards[index].backgroundColor !== '' && this.infoCards[index].type !== this.cardTypes.InfoWithIcon) {
                return this.infoCards[index].backgroundColor;
            } else {
                return '#F2F2F2';
            }
        }
    }

    public getPercentageChangeBoxColor(index: number): string {
        if (this.infoCards[index].percentChangeBoxColor && this.infoCards[index].percentChangeBoxColor !== '') {
            return this.infoCards[index].percentChangeBoxColor;
        } else {
            return '#FDFDFD';
        }
    }

    public getIconBackgroundColor(index: number): string {
        if (this.infoCards[index].backgroundColor && this.infoCards[index].backgroundColor !== '') {
            return this.infoCards[index].backgroundColor;
        } else {
            return '#F2F2F2';
        }
    }

    public getTextColor(index: number): string {
        if (this.infoCards[index].textColor && this.infoCards[index].textColor !== '') {
            return this.infoCards[index].textColor;
        } else {
            return '#222';
        }
    }

    public formatPercentText(index: number): string {
        const pc = this.infoCards[index].percentChange;
        if (pc !== null) {
            return pc + '%';
        }
        return '';
    }

    public getPercentChangeIconName(index: number): string {
        if (this.infoCards[index].percentChange === 0) {
            return 'minus.svg';
        }
        return this.infoCards[index].percentChange >= 0 ? 'trending-up.svg' : 'trending-down.svg';
    }

    public getPercentChangeHidden(index: number): boolean {
        return this.infoCards[index].hidePercentChange;
    }
}
