<div class="d-flex flex-column" [style.gap.rem]="0.75">
    <div
            class="d-flex"
            [style.gap.rem]="0.5">
        <label class="teth-checkbox-container">
            <input type="checkbox" class="teth-checkbox" [class.indeterminate]="displayIndeterminate$ | async" [checked]="(percentSelected$ | async) === 100" (click)="selectAllClicked.emit()">
            <span class="teth-checkbox-label"> {{ (searchedFilterGroup$ | async)?.groupTitle }} </span>
            <span class="teth-checkmark"></span>
        </label>
    </div>
    <div class="option-header" (click)="toggleOptionVisibilty()">
        <img [hidden]="optionsAreVisible$ | async" src="assets/img/icons/chevron-right.svg" alt="right indicator">
        <img [hidden]="!(optionsAreVisible$ | async)" src="assets/img/icons/chevron-down.svg" alt="down indicator">
        <div class="group-options-title">{{ (searchedFilterGroup$ | async)?.groupOptionsTitle}}</div>
    </div>
    <div [hidden]="!(optionsAreVisible$ | async)" class="option-selections">
        <label *ngFor="let option of (searchedFilterGroup$ | async)?.groupOptions" class="teth-checkbox-container">
            <input type="checkbox" [checked]="option | optionIsSelected : searchedFilterGroup$ | async " (click)="optionClicked.emit(option)">
            <span class="teth-checkbox-label small-checkbox-label"> {{ option?.displayableName }} </span>
            <span class="teth-checkmark small-checkmark"></span>
        </label>
    </div>
</div>




