import {Deserializable} from "../views/shared/utils/json-utils";

export class FeeGuideProcedureFee implements Deserializable {

    id: string;
    feeGuideProcedureId: string;
    feeName: string;
    feeCode: string;
    feeAmount: number;
    overrideFeeAmount: number;
    updatedDate: string;
    updatedById: number;
    updatedBy: string;
    createdDate: string;
    createdById: number;
    createdBy: string;

    isComplete(): boolean {
        return this.feeCode !== null && this.feeAmount !== null;
    }

    onDeserialize() {
    }
}
