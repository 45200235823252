<div class="modal-body">
    <div class="modal-title mb-3" i18n>Tetherport Terms of Use</div>
    <div class="p-3 mb-4" [class.scrollable]="expandAgreement">
        <p class="section-title" i18n>KEY TERMS:</p>
        <ul>
            <li i18n>You are permitted to use Tetherport for the purpose of managing patient referrals and collaborating with
                respect to patient diagnosis and care and for no other purpose whatsoever.
            </li>
            <li i18n>Responsibility for personal and personal health information transmitted through Tetherport remains with
                the applicable healthcare provider. The Service Provider is an information management service provider
                and does not assume custody or control of such information.
            </li>
            <li i18n>Tetherport does not verify, check or monitor the information sent or received by healthcare providers
                within the system. You agree that your use of Tetherport with respect to an individual patient is at
                your sole risk. The Tetherport Terms of Use contain additional information regarding your use of
                Tetherport and include important limits on your legal remedies. Please review the Terms of Use in detail
                prior to using Tetherport.
            </li>
        </ul>
        <p i18n>BY CLICKING “I ACCEPT” YOU ACKNOWLEDGE THAT YOU HAVE READ AND ACCEPT THE TERMS OF USE BELOW.</p>
        <a [hidden]="expandAgreement" [routerLink]="" (click)="expandAgreement = true" i18n>View Terms of Use</a>
        <ng-container *ngIf="expandAgreement">
            <ol>
                <li><ng-container i18n>Introduction</ng-container>
                    <p i18n>
                        The Tetherport application (“Tetherport”) is intended to create a secure medical collaboration
                        platform for healthcare providers to take advantage of technology to manage patient referrals
                        and to
                        collaborate on patient diagnosis and care.
                        These Terms of Use govern your access to and use of Tetherport.
                    </p>
                    <p i18n>
                        Please read these Terms of Use carefully. They are a legal agreement between you and 102104853
                        Saskatchewan Ltd. (the “Service Provider”). By using Tetherport, or by clicking the acceptance
                        button, you agree to be bound by these Terms of Use, including all amendments made from time to
                        time. If you do not agree to these Terms of Use, do not use Tetherport.
                        The Terms of Use were last updated on December 4, 2020.
                    </p>
                </li>
                <li><ng-container i18n>Role of IMSP and Accountability for Personal Health Information</ng-container>
                    <p i18n>
                        The Service Provider acts as an information management service provider to the applicable
                        healthcare provider with respect to the services provided through Tetherport. Responsibility for
                        the personal and personal health information transmitted through Tetherport remains at all times
                        with the applicable healthcare provider. For greater certainty, the Service Provider assumes no
                        ownership in, nor custody or control of, the personal health information transmitted or stored
                        using the Tetherport application.
                    </p>
                    <p i18n>
                        Healthcare providers are responsible for obtaining and managing consent from each individual
                        patient prior to disclosing their personal health information in the Tetherport application.
                        Healthcare providers are required to meet the obligations under applicable health privacy laws
                        (ex. The Health Information Protection Act (Saskatchewan)).
                    </p>
                </li>
                <li><ng-container i18n>Use of Tetherport</ng-container>
                    <p i18n>
                        You may use Tetherport for the purpose of providing or supporting care to individual patients,
                        including managing referrals and collaborating with respect to patient diagnosis and care. You
                        may not use Tetherport for any other purpose. You are not permitted to make any commercial or
                        derivative use of Tetherport, or to use any data mining, robots, or similar data gathering and
                        extraction methods with respect to Tetherport or the medical information or other content
                        contained therein.
                    </p>
                </li>
                <li><ng-container i18n>Medical Information and Other Content</ng-container>
                    <p i18n>The Service Provider does not create and has no responsibility for the medical information and
                        other content that may be available through your use of Tetherport. Tetherport is simply a tool
                        to securely contact other healthcare providers in order to manage referrals and collaborate on
                        patient diagnosis and care. </p>
                    <p i18n><b>Further, Tetherport does not verify, check or monitor the information sent or received by
                        healthcare providers within the system.</b> You agree that your use of Tetherport with respect
                        to an individual patient is at your sole risk.</p>
                    <p i18n>Tetherport is provided on an ‘as is’ basis without representations, warranties or conditions of
                        any kind. You agree that the Service Provider is not liable in any way for the reliability,
                        validity, accuracy, completeness, integrity, quality and timeliness of any medical information
                        or other content available through Tetherport, and that your use or non-use of, and your
                        reliance or non-reliance on, any such medical information or other content is at your own
                        risk.
                    </p>
                </li>
                <li><ng-container i18n>Termination of Access to Tetherport</ng-container>
                    <p i18n>
                        The Service Provider may, in its sole discretion, terminate or suspend your access to all or any
                        part of Tetherport at any time, with or without notice, for any reason, including, without
                        limitation, breach of these Terms of Use. Without limiting the generality of the foregoing, any
                        fraudulent, abusive, or otherwise illegal activity, or activity that may otherwise affect the
                        enjoyment of Tetherport or access to Tetherport by others, may be grounds for termination of
                        your access to all or part of Tetherport at the sole discretion of the Service Provider.
                    </p>
                </li>
                <li><ng-container i18n>Intellectual Property Rights</ng-container>
                    <p i18n>
                        The Service Provider owns the intellectual property rights in the Tetherport application
                        including, without limitation, all rights in source code, object code, data structures, modules,
                        objects, user interfaces, design documents, test plans and scripts, technical specifications,
                        manuals, operating procedures and other user documents. Except as otherwise provided herein,
                        nothing in these Terms of Use vest or transfer any intellectual property rights in or to you and
                        any use, alteration, or replication of any of Tetherport’s intellectual property is a violation
                        of these Terms of Use and applicable Canadian and international intellectual property laws. You
                        agree to not take any action to jeopardize, limit or interfere with Tetherport’s intellectual
                        property rights.
                    </p>
                </li>
                <li><ng-container i18n>Privacy and Security</ng-container>
                <p i18n>
                    By agreeing to these Terms of Use, you are also agreeing to Tetherport’s Privacy and Security
                    Policy. The Privacy and Security Policy should be reviewed prior to accessing and using Tetherport.
                </p>
                </li>
                <li><ng-container i18n>Warranty Disclaimer</ng-container>
                    <p i18n>
                        You expressly acknowledge and agree that Tetherport is provided on an “as-is and where-is” basis
                        without warranty of any kind, whether express or implied, including without limitations, any
                        warranty with respect to right, title, interest, including fitness for a particular use or
                        non-infringement of intellectual property.
                    </p>
                </li>
                <li>
                    <ng-container i18n>Limitation of Liability</ng-container>
                    <p i18n>
                        To the maximum extent permitted by applicable law, the Service Provider, its agents, assigns,
                        employees, contractors, consultants, and service providers and their respective successors and
                        assigns shall not be liable to you or any third party for any direct, indirect, special,
                        incidental, punitive, or consequential damages whatsoever incurred through the use of
                        Tetherport. The aggregate liability of the Service Provider in connection with these Terms of
                        Use, your use of the Tetherport or any other circumstance giving rise to liability shall be
                        limited to direct damages arising out of the bad faith, willful misconduct or gross negligence
                        of the Service Provider.
                    </p>
                </li>
                <li>
                    <ng-container i18n>Governing Law and Jurisdiction</ng-container>
                    <p i18n>
                        These Terms of Use shall be governed and construed in accordance with the laws of the Province
                        of Saskatchewan and the federal laws of Canada applicable therein, and each party attorns to the
                        exclusive jurisdiction of the courts of the Province of Saskatchewan.
                    </p>
                </li>
                <li>
                    <ng-container i18n>Monitoring</ng-container>
                    <p i18n>
                        Please note that the Service Provider reserves the right to monitor and review all
                        communications and data available through Tetherport for the purposes of managing Tetherport,
                        investigating incidents, and adding functionality. By accessing Tetherport, you are expressly
                        consenting to these monitoring activities.
                    </p>
                </li>
                <li>
                    <ng-container i18n>Changes to the Terms of Use</ng-container>
                    <p i18n>
                        The Service Provider may modify these Terms of Use from time to time. If you do not agree to any
                        modifications, you should stop your use of Tetherport. Your continued use of Tetherport now, or
                        following the posting of notice of any changes in these Terms of Use, will constitute a binding
                        acceptance by you of these Terms of Use, and any subsequent modifications. You can review the
                        most current version of the Terms of Use at any time within Tetherport.
                    </p>
                </li>
                <li>
                    <ng-container i18n>Contact</ng-container>
                    <p i18n>
                        Should you have any questions concerning these Terms of Use, please contact the Service Provider:
                        <br><br>
                        3344 Albert Street
                        <br>
                        Regina, Saskatchewan S4S 3P1
                        <br>
                        Canada
                        <br>
                        Email: info@tetherport.ca
                        <br>
                        Phone: 306-359-6552
                    </p>
                </li>
            </ol>
        </ng-container>
    </div>
    <div class="footer-button">
        <button class="teth-button destructive-button" (click)="closeModal()" i18n>Decline</button>
        <button class="teth-button blue-button ml-3" (click)="continue()" i18n>I Accept</button>
    </div>
</div>
