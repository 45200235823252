import {Procedure} from './procedure.model';
import {ReferringDentistSearchItem} from '../views/shared/components/manual-referral-modal/manual-referral-modal.component';
import {Employee} from './employee.model';
import {LocationAssignedSpecialty} from './location-assigned-specialty';
import {HydratedLocation} from './hydrated-location';

export class ManualReferralDetails {
    procedures: Procedure[];
    referringDentist: ReferringDentistSearchItem;
    referredSpecialist: Employee;
    referredLocation: HydratedLocation;
    specialty: LocationAssignedSpecialty;
}
