import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {EntityService} from '../../../../services/entity.service';
import {forkJoin} from 'rxjs';
import {distinctUntilChanged, tap} from 'rxjs/operators';
import {AbstractControl, FormControl, FormGroup, Validators} from '@angular/forms';
import {SpecialtyType} from '../../../../models/specialty-type.model';
import {SessionService} from '../../../../services/session.service';
import {Procedure} from '../../../../models/procedure.model';
import {Location} from '../../../../models/location.model';
import {ReferralProcedure} from '../../../../models/referral-procedure.model';
import {HydratedReferral} from '../../../../models/hydrated-referral';
import {ReferralService} from '../../../../services/referral.service';
import {TransferReferralRequestModel} from '../../../../models/transferReferralRequest.model';

@Component({
    selector: 'app-transfer-referral-modal',
    templateUrl: './transfer-referral-modal.component.html',
    styleUrls: ['./transfer-referral-modal.component.scss', '../../../stylesheet/stylesheet.component.scss']
})
export class TransferReferralModalComponent implements OnInit {
    loading = false;
    specialties: SpecialtyType[] = null;
    allProcedures: Procedure[] = null;
    filteredProcedures: Procedure[] = null;
    locations: Location[] = null;
    referral: HydratedReferral;
    transferInfoForm = new FormGroup({
        locationId: new FormControl(null, Validators.required),
        specialtyId: new FormControl(null, Validators.required),
        procedureIds: new FormGroup({}, this.validateProcedures),
    });

    constructor(private activeModal: NgbActiveModal,
                private entityService: EntityService,
                private sessionService: SessionService,
                private cd: ChangeDetectorRef,
                private referralService: ReferralService) {
    }

    ngOnInit() {
        this.setupBindings();
        this.loadStaticData();
    }

    initWithReferral(referral: HydratedReferral) {
        this.referral = referral;
    }

    setupBindings() {
        this.transferInfoForm.controls.specialtyId.valueChanges.pipe(distinctUntilChanged()).subscribe(specialtyId => {
            this.filteredProcedures = this.specialties.find(s => s.id === specialtyId)?.procedures;
            this.uncheckAllProcedures();
        });
    }

    loadStaticData() {
        const specialtiesData = this.entityService.specialtyTypes$.firstNotNull().pipe(tap(s => {
            this.specialties = s;
            this.allProcedures = s.flatMap(specialty => specialty.procedures);
            this.setupProcedureFormGroup();
        }));

        const locationsData = this.sessionService.sessionContainer.firstNotNull().pipe(tap(s => {
            this.locations = s.companyLocations.filter(l => l.acceptingReferrals && l.enabled);
        }));

        this.loading = true;
        forkJoin([specialtiesData, locationsData]).subscribe(result => {
            this.setupDefaultValues();
            this.loading = false;
        }, error => {
            this.loading = false;
            console.log(error);
        });
    }

    setupDefaultValues() {
        this.transferInfoForm.patchValue({
            locationId: this.referral.referredLocationId,
            specialtyId: this.referral.specialtyTypeId
        });

        this.referral.procedures.map(p => {
            return {[p.procedureTypeId.toString()]: true};
        }).forEach( defaultProcedure => {
            this.transferInfoForm.controls.procedureIds.patchValue(defaultProcedure);
        });
    }

    setupProcedureFormGroup() {
        const procedureFormGroup = this.transferInfoForm.controls.procedureIds as FormGroup;
        this.allProcedures.forEach(p => {
            procedureFormGroup.addControl(p.id.toString(), new FormControl(false));
        });
    }

    uncheckAllProcedures() {
        const procedureFormGroup = this.transferInfoForm.controls.procedureIds as FormGroup;
        const procedureValues = {};
        this.allProcedures.forEach(p => {
            procedureValues[p.id.toString()] = false;
        });
        procedureFormGroup.patchValue(procedureValues);
    }

    closeModal() {
        this.activeModal.close();
    }

    generateTransferRequest(): TransferReferralRequestModel {
        const r = new TransferReferralRequestModel();

        r.locationId = this.transferInfoForm.controls.locationId.value;
        r.specialtyTypeId = this.transferInfoForm.controls.specialtyId.value;
        const procedures = this.transferInfoForm.controls.procedureIds.value;
        const selectedProcedureIds: number[] = [];
        Object.keys(procedures).forEach(id => {
            if (procedures[id] === true) {
                selectedProcedureIds.push(parseInt(id, 10));
            }
        });

        r.procedures = selectedProcedureIds.map(pId => {
            const p = new ReferralProcedure();
            p.procedureTypeId = pId;
            return p;
        });

        return r;
    }

    transferReferral() {
        this.transferInfoForm.markAllAsTouched();
        if (this.transferInfoForm.valid) {
            this.loading = true;
            const req = this.generateTransferRequest();
            this.referralService.transferReferral(this.referral.id, req).subscribe(result => {
                this.activeModal.close(result);
            }, error => {
                console.log(error);
                this.loading = false;
            });
        }
    }

    validateProcedures(c: AbstractControl) {
        return Object.keys(c.value).find(id => c.value[id]) ? null : {validProcedures: false};
    }
}
