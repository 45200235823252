import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-modal-footer',
  templateUrl: './modal-footer.component.html',
  styleUrls: ['../../../stylesheet/stylesheet.component.scss', './modal-footer.component.scss']
})
export class ModalFooterComponent {

  @Input() primaryButtonText: string;
  @Input() secondaryButtonText: string;
  @Input() primaryButtonClass = 'blue-button';
  @Input() primaryButtonDisabled = false;
  @Input() paddingTopInRem: number = 1.5;
  @Input() sticky: boolean = false;

  @Output() primaryButtonClicked = new EventEmitter<void>();
  @Output() secondaryButtonClicked = new EventEmitter<void>();

}
