export class NavItem {
    public name: string;
    public iconSrc: string;
    public iconFilledSrc: string;
    public routerPath: string;
    public hideForSpecialist: boolean;
    public hideForGeneralist: boolean;
    public hideForAdmin: boolean;
    public hideForDentalLab: boolean;
    public childNavItems: NavItem[];

    constructor(name: string, routerPath: string, iconSrc: string, iconFilledSrc: string, hideForSpecialist: boolean,
                hideForGeneralist: boolean, hideForAdmin: boolean, hideForDentalLab: boolean, childNavItems: NavItem[] = []) {
        this.name = name;
        this.iconSrc = iconSrc;
        this.iconFilledSrc = iconFilledSrc;
        this.routerPath = routerPath;
        this.hideForSpecialist = hideForSpecialist;
        this.hideForGeneralist = hideForGeneralist;
        this.hideForAdmin = hideForAdmin;
        this.hideForDentalLab = hideForDentalLab;
        this.childNavItems = childNavItems;
    }
}
