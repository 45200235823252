import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ReleaseNote} from '../../../../models/releaseNote.model';
import {Router} from '@angular/router';

@Component({
    selector: 'app-version-info-modal',
    templateUrl: './version-info-modal.component.html',
    styleUrls: ['./version-info-modal.component.scss', '../../../stylesheet/stylesheet.component.scss']
})
export class VersionInfoModalComponent implements OnInit {

    releaseNote: ReleaseNote;

    constructor(private activeModal: NgbActiveModal,
                private router: Router) {
    }

    ngOnInit() {

    }

    initWithReleaseNote(releaseNote: ReleaseNote) {
        this.releaseNote = releaseNote;
    }

    closeModal() {
        this.activeModal.close();
    }

    learnMore() {
        this.closeModal();
        this.router.navigate(['/help'], {queryParams: {'segment': 'releaseNotes'}});
    }
}
