import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {NewMessage} from '../../../models/message.model';
import {Employee} from '../../../models/employee.model';
import DateUtils from '../utils/date-utils';
import {Logable} from '../utils/json-utils';
import {LoadingOptions} from '../../../models/loading-options';
import {PresetMessage} from '../../../models/preset-message.model';
import {InputModalComponent} from '../input-modal/input-modal.component';
import {ReferralStatus} from '../../../models/referral-status.model';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {CdkTextareaAutosize} from '@angular/cdk/text-field';

@Component({
    selector: 'app-messages',
    templateUrl: './messages.component.html',
    styleUrls: [
        './messages.component.scss',
        '../../stylesheet/stylesheet.component.scss'
    ]
})
export class MessagesComponent implements OnInit, OnChanges {

    // Message component inputs
    @Input() public messages: Logable[] = [];
    @Input() public locationEmployees: Employee[];
    @Input() public updateMessageScroll: EventEmitter<any>;
    @Input() public loadingOptions: LoadingOptions;
    @Input() public presetMessages: PresetMessage[];

    // Message component outputs
    @Output() public sendMessage = new EventEmitter<NewMessage>();
    @Output() public reloadMessages = new EventEmitter<boolean>();

    // Message component variables
    @ViewChild('messageScrollContainer') private messageScrollContainer: ElementRef;
    @ViewChild('messageTextArea') private messageTextArea: CdkTextareaAutosize;
    newMessageText: string = '';
    sendButtonEnabled: boolean = false;
    reloadMessagesHover: boolean = false;

    constructor(
        private modalService: NgbModal
    ) {
    }

    ngOnInit(): void {
        this.updateMessageScroll.subscribe(() => {
            setTimeout(function () {
                this.scrollToBottom();
            }.bind(this), 10);
        });

        this.scrollToBottom();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.messages && changes.messages.previousValue) {
            if (changes.messages.currentValue.length > 0) {
                this.messages = changes.messages.currentValue;
            }
        }
    }

    isSentMessage(m: Logable): boolean {
        return this.locationEmployees.map((e) => e.id).includes(m.createdById);
    }

    getFormattedMessageSender(m: Logable): string {
        let sender = '';
        const splitNames = m.createdBy !== '' ? m.createdBy.split(' ') : $localize`Employee`;
        if (splitNames[1].length > 0) {
            sender += splitNames[0] + ' ' + splitNames[1][0];
        } else {
            sender += splitNames;
        }
        sender += ' - ';
        sender += DateUtils.formatDateTimeToMessageString(m.createdDate);
        return sender;
    }

    getFormattedMessageDate(m: Logable): string {
        return DateUtils.formatDateTimeToMessageString(m.createdDate);
    }

    newMessageTextUpdated() {
        this.sendButtonEnabled = (this.newMessageText.length > 0);
    }

    sendNewMessage(enterKey: boolean = false) {
        const nm = new NewMessage();
        nm.content = this.newMessageText;
        if (enterKey) {
            // Required to remove the ↵ character from the end of the message
            nm.content = nm.content.substring(0, nm.content.length - 1);
        }
        this.sendMessage.emit(nm);
        this.newMessageText = '';
        this.messageTextArea.reset();
    }

    scrollToBottom() {
        if (this.messageScrollContainer) {
            this.messageScrollContainer.nativeElement.scrollTop = this.messageScrollContainer.nativeElement.scrollHeight;
        }
    }

    trackById(index, item) {
        return item ? item.id : undefined;
    }

    showPresetMessages() {
        const modalRef = this.modalService.open(InputModalComponent, InputModalComponent.defaultModalOptions());
        (modalRef.componentInstance as InputModalComponent)
            .initWithSelectionList(
                $localize`Preset Chat Messages`,
                this.presetMessages,
                null,
                $localize`Select a Preset Message`,
                i => i.value,
                $localize`Cancel`,
                $localize`Continue`
            );
        modalRef.result.then(r => {
            if (r !== null) {
                this.newMessageText = r.value;
                this.newMessageTextUpdated();
            }
        }, () => {
        });
    }

    getSenderInitials(m: Logable): string {
        const splitNames = m.createdBy.split(' ');
        return splitNames[0][0] + splitNames[1][0];
    }
}
