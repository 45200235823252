<div *ngIf="employee != null">
    <div class="row">
        <div class="col">
            <div class="employee-initials">
                {{employee.primaryName[0]}}{{employee.familyName[0]}}
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="employee-name">
                {{employee.primaryName}} {{employee.familyName}}
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-lg-6 mt-5" [hidden]="true">
            <div class="employee-info-section">
                <div class="employee-info-icon">
                    <img [src]="'/assets/img/icons/profile-white.svg'" alt="role">
                </div>
                <div class="employee-info-title mt-3" i18n>
                    About
                </div>
                <div class="employee-info-about" i18n>An employee role will affect what an employee can and cannot do while using Tetherport. Custom roles can be created from the settings tab.</div>
            </div>
        </div>
        <div class="col-6 col-lg-3 mt-5">
            <div class="employee-info-section">
                <div class="employee-info-icon">
                    <img [src]="'/assets/img/icons/role-white.svg'" alt="role">
                </div>
                <div class="employee-info-title mt-3" i18n>
                    Role
                </div>
                <div class="employee-info-value">
                    {{employee.role}}
                </div>
            </div>
        </div>
        <div class="col-6 col-lg-3 mt-5">
            <div class="employee-info-section">
                <div class="employee-info-icon">
                    <img [src]="'/assets/img/icons/email-white.svg'" alt="role">
                </div>
                <div class="employee-info-title mt-3" i18n>
                    Contact
                </div>
                <div class="employee-info-value">
                    <ng-container *ngIf="employee.email">{{employee.email}}</ng-container>
                    <ng-container *ngIf="!employee.email" i18n>N/A</ng-container>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-5">
        <div class="col-12 employee-info-title mb-3" i18n>Locations</div>
        <div class="col-6 col-lg-3 d-flex" *ngFor="let location of locations">
            <div class="location-card" (click)="locationSelected.emit(location)">
                <div class="location-img">
                    <img [src]="'/assets/img/icons/location-filled.svg'" alt="location">
                </div>
                <div class="location-title">{{location.name}}</div>
                <div class="location-subtitle">
                    <ng-container *ngIf="location.address">{{addressUtils.singleLineFormat(location?.address)}}</ng-container>
                    <ng-container *ngIf="!location.address" i18n>Address N/A</ng-container>
                </div>
            </div>
        </div>
    </div>
</div>
