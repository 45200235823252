export class Address {
    public id: number;
    public addressLine1: string;
    public addressLine2: string;
    public locality: string;
    public stateId: number;
    public stateCode: string;
    public state: string;
    public countryId: number;
    public countryCode: string;
    public country: string;
    public postalCode: string;
    public latitude: number;
    public longitude: number;
}
