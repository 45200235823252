import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {FeeGuideProcedureFee} from "../../../../models/fee-guide-procedure-fee.model";
import {EditFeeGuideFormService} from "../../../../services/edit-fee-guide-form.service";
import {BehaviorSubject, combineLatest, Observable, Subscription} from "rxjs";
import {map} from "rxjs/operators";

@Component({
  selector: 'app-edit-fee-guide-procedure-fee-form',
  templateUrl: './fee-guide-procedure-fee-form.component.html',
  styleUrls: ['../../../stylesheet/stylesheet.component.scss', './fee-guide-procedure-fee-form.component.scss']
})
export class FeeGuideProcedureFeeFormComponent implements OnChanges, OnInit, OnDestroy {

  constructor(private fb: FormBuilder, private formService: EditFeeGuideFormService) { }

  @Input() procedureFee: FeeGuideProcedureFee;
  @Input() disabled: boolean;
  @Input() locationFeeGuideMode = false;

  public form: FormGroup;
  private formValueSubscription: Subscription;
  private formStatusSubscription: Subscription;
  private formDisabledSubscription: Subscription;

  private _disabled = new BehaviorSubject(false);
  public disabled$ = this._disabled as Observable<boolean>;

  private _locationFeeGuideMode = new BehaviorSubject(false);
  public locationFeeGuideMode$ = this._locationFeeGuideMode as Observable<boolean>;

  public feeCodePlaceholder$ = this.locationFeeGuideMode$.pipe(
      map(locationFeeGuideMode => locationFeeGuideMode ? '--' : $localize`Enter a fee code`)
  );

  ngOnInit() {
    this.formDisabledSubscription = combineLatest([this.disabled$, this.locationFeeGuideMode$]).subscribe(([disabled, locationFeeGuideMode]) => {
      if (disabled) {
        this.form?.disable({ emitEvent: false });
      } else {
        if (!disabled && locationFeeGuideMode) {
          this.form?.get('feeAmount')?.disable({emitEvent: false});
          this.form?.get('feeCode')?.disable({emitEvent: false});
          this.form?.get('overrideFeeAmount').enable({emitEvent: false});
        } else if (!disabled && !locationFeeGuideMode) {
          this.form?.get('feeAmount')?.enable({emitEvent: false});
          this.form?.get('feeCode')?.disable({emitEvent: false});
          this.form?.get('overrideFeeAmount').disable({emitEvent: false});
        }
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.procedureFee) {
      this.initializeForm();
      this.formValueSubscription?.unsubscribe();
      this.formValueSubscription = this.form.valueChanges.subscribe(() => {
        this.formService.addProcedureFeeFormToMap(this.form);
        this.formValueSubscription?.unsubscribe();
      });

      if (changes.disabled) {
        this._disabled.next(this.disabled)
      }
      this.formStatusSubscription?.unsubscribe();
      this.formStatusSubscription = this.form.statusChanges.subscribe(() => {
        if (this.form.valid) {
          this.formService.removeFormFromInvalidForms(this.form);
        } else {
            this.formService.addFormToInvalidForms(this.form);
        }
      });
    }

    if (changes.locationFeeGuideMode) {
      this._locationFeeGuideMode.next(this.locationFeeGuideMode);
      if (this.locationFeeGuideMode) {
        this.form?.get('feeAmount')?.disable({emitEvent: false});
        this.form?.get('feeCode')?.disable({emitEvent: false});
      } else {
        this.form?.get('feeAmount')?.enable({emitEvent: false});
        this.form?.get('feeCode')?.enable({emitEvent: false});
      }
      this._locationFeeGuideMode.next(this.locationFeeGuideMode);
    }
  }

  private initializeForm(): void {
    this.form = this.fb.group({
      id: [this.procedureFee?.id],
      feeGuideProcedureId: [this.procedureFee?.feeGuideProcedureId],
      feeName: [this.procedureFee?.feeName],
      feeCode: [this.procedureFee?.feeCode],
      feeAmount: [this.procedureFee?.feeAmount, [Validators.max(999999.99), Validators.min(0)]],
      overrideFeeAmount: [this.procedureFee?.overrideFeeAmount,[Validators.max(999999.99), Validators.min(0)] ],
    });
    if (this.disabled) {
      this.form.disable({ emitEvent: false });
    }
  }

  ngOnDestroy() {
    this.formValueSubscription?.unsubscribe();
    this.formStatusSubscription?.unsubscribe();
    this.formDisabledSubscription?.unsubscribe();
  }

}
