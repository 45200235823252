import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {AdminService} from '../../../../services/admin.service';
import {Company} from '../../../../models/company.model';
import {Observable, of} from 'rxjs';
import {SafeResourceUrl} from '@angular/platform-browser';
import {ImageService} from '../../../../services/image.service';
import AddressUtils from '../../utils/address-utils';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';

@Component({
    selector: 'app-choose-company-modal',
    templateUrl: './choose-company-modal.component.html',
    styleUrls: ['./choose-company-modal.component.scss', '../../../stylesheet/stylesheet.component.scss']
})
export class ChooseCompanyModalComponent implements OnInit {

    searchText: string = null;
    loading = false;
    companies: Company[];
    filteredCompanies: Company[];
    addressUtils = AddressUtils;

    constructor(private activeModal: NgbActiveModal,
                private imageService: ImageService,
                private toastr: ToastrService,
                private router: Router,
                private adminService: AdminService) {
    }

    ngOnInit(): void {
        this.loading = true;
        this.adminService.getCompanies().subscribe(c => {
            this.loading = false;
            this.companies = c ?? [];
            this.filterResults();
        }, error => {
            this.loading = false;
            console.log(error);
        });
    }

    filterResults() {
        this.filteredCompanies = this.companies.filter(c => {
            if (this.searchText == null || this.searchText?.length === 0) {
                return true;
            }
            return c.name.toUpperCase().includes(this.searchText.toUpperCase());
        }).sort((a, b) => {
            return a.name.localeCompare(b.name);
        });
    }

    close() {
        this.activeModal.close();
    }

    companySelected(selectedCompany: Company) {
        this.loading = true;
        this.adminService.selectCompanyToEdit(selectedCompany).subscribe(() => {
            this.loading = false;
            this.toastr.success($localize`Now Editing ${selectedCompany.name}:companyName:`);
            this.activeModal.close();

            // reload route but preserve route strategies
            const oldRouterMethod = this.router.routeReuseStrategy.shouldReuseRoute;
            const oldOnSameUrlNavigation = this.router.onSameUrlNavigation;
            this.router.onSameUrlNavigation = 'reload';
            this.router.routeReuseStrategy.shouldReuseRoute = () => false;
            this.router.navigate([this.router.url]).finally(() => {
                this.router.onSameUrlNavigation = oldOnSameUrlNavigation;
                this.router.routeReuseStrategy.shouldReuseRoute = oldRouterMethod;
            });
        }, error => {
            this.loading = false;
            console.log(error);
            this.toastr.error($localize`Please try again`);
        });
    }

    getCompanyDisplayPhoto(companyId: number): Observable<string | SafeResourceUrl> {
        if (companyId) {
            return this.imageService.getCompanyImage(companyId, 'medium');
        } else {
            return of(null);
        }
    }
}
