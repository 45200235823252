import {Deserializable} from '../views/shared/utils/json-utils';

export class InfoCard implements Deserializable {
    public id: string;
    public type: InfoCardType;
    public title: string;
    public value: string;
    public percentChange: number;
    public hidePercentChange: boolean;
    public ctaTitle: string;
    public iconName: string;
    public backgroundColor: string;
    public backgroundHoverColor: string;
    public percentChangeBoxColor: string;
    public percentChangeTextColor: string;
    public hovered: boolean;
    public hidden: boolean = false;
    public textColor: string;
    public classNames: string;

    public setPercentChange(change: number) {
        if (change == null || !isFinite(change) || isNaN(change)) {
            this.hidePercentChange = true;
            this.percentChange = 0;
            this.percentChangeBoxColor = '#00000000';
            this.percentChangeTextColor = '#00000000';
            return;
        }

        this.percentChange = Math.round(change);
        this.hidePercentChange = false;
        if (change === 0) {
            this.percentChangeBoxColor = '#FFD74B';
            this.percentChangeTextColor = '#FFFFFF';
        } else if (change > 0) {
            this.percentChangeBoxColor = '#63D58F';
            this.percentChangeTextColor = '#FFFFFF';
        } else {
            this.percentChangeBoxColor = '#FA5555';
            this.percentChangeTextColor = '#FFFFFF';
        }
    }

    onDeserialize() {
    }
}

export enum InfoCardType {
    Info,
    InfoWithIcon,
    InfoWithPercentChange,
    InfoWithCTA
}
