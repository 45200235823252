import { Pipe, PipeTransform } from '@angular/core';
import {AbstractControl} from "@angular/forms";
import {Observable} from "rxjs";

@Pipe({
  name: 'isInvalidAndTouched',
  pure: false
})
export class IsInvalidAndTouchedPipe implements PipeTransform {

  transform(statusChange: any, control: AbstractControl): boolean {
    return control?.invalid && control?.touched;
  }

}
