import {Component, EventEmitter, OnInit} from '@angular/core';
import AddressUtils from '../../utils/address-utils';
import {Patient} from '../../../../models/patient.model';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';
import {ReferralService} from '../../../../services/referral.service';
import DateUtils from '../../utils/date-utils';
import {DateService} from '../../../../services/date.service';
import {HydratedReferral} from '../../../../models/hydrated-referral';
import {AbstractControl, FormControl, FormGroup, Validators} from '@angular/forms';
import PhoneUtils from '../../utils/phone-utils';
import {EntityService} from '../../../../services/entity.service';
import {GenderType} from '../../../../models/gender-type.model';
import {IntegrationType} from '../../../../models/integration-type';

type LinkPatientStep = 'searchPatients' | 'createPatient' | 'comparePatient';

@Component({
    selector: 'app-link-patient-modal',
    templateUrl: './link-patient-modal.component.html',
    styleUrls: ['./link-patient-modal.component.scss', '../../../stylesheet/stylesheet.component.scss']
})
export class LinkPatientModalComponent implements OnInit {

    phoneUtils = PhoneUtils;
    searchText: string = null;
    clearPatientSearchInput = new EventEmitter();
    loading = false;
    patients: Patient[];
    filteredPatients: Patient[];
    addressUtils = AddressUtils;
    dateUtils = DateUtils;
    companyId: number;
    locationId: number;
    incomingReferral: boolean;
    referral: HydratedReferral;
    selectedPatient: Patient;
    patientToSave: Patient;
    genderTypes: GenderType[];
    patientConflicts: PatientConflictModel[];
    currentConflictIndex = -1;
    linkPatientStep: LinkPatientStep = 'searchPatients';
    locationIntegrationType: IntegrationType;
    newPatientForm = new FormGroup({
        firstName: new FormControl('', Validators.required),
        lastName: new FormControl('', Validators.required),
        birthDate: new FormControl('', [Validators.required, this.dateService.validateDateControl.bind(this.dateService)]),
        gender: new FormControl(-1, [Validators.required, Validators.min(0)]),
        homePhone: new FormControl('', [Validators.pattern(PhoneUtils.phoneRegex)]),
        mobilePhone: new FormControl('', [Validators.pattern(PhoneUtils.phoneRegex)]),
        email: new FormControl('', [Validators.email]),
    }, [this.validateAtLeastOnePhone]);

    constructor(private activeModal: NgbActiveModal,
                private toastr: ToastrService,
                private referralService: ReferralService,
                private entityService: EntityService,
                public dateService: DateService,
                private router: Router) {
    }

    ngOnInit(): void {
        this.entityService.genderTypes$.subscribe(e => {
            this.genderTypes = e;
        }, error => {
            console.log(error);
        });
    }

    initWith(referral: HydratedReferral, incomingReferral: boolean, initialSearchText: string, locationIntegrationType: IntegrationType) {
        this.incomingReferral = incomingReferral;
        this.locationIntegrationType = locationIntegrationType;
        this.referral = referral;
        this.companyId = this.incomingReferral ? referral.referredCompanyId : referral.referringCompanyId;
        this.locationId = this.incomingReferral ? referral.referredLocationId : referral.referringLocationId;
        this.searchText = initialSearchText;
        this.searchPatients(this.searchText);
        this.loading = false;
    }

    searchPatients(patientName: string) {
        this.loading = true;
        this.referralService.searchPatientsWithName(this.companyId, this.locationId, patientName).subscribe((patients) => {
            this.filteredPatients = patients;
            this.loading = false;
        }, error => {
            this.loading = false;
            console.log(error);
        });
    }

    patientSelected(selectedPatient: Patient) {
        if (!selectedPatient) {
            return;
        }

        this.selectedPatient = selectedPatient;
        if (selectedPatient?.practiceManagementPatientId?.length > 0) {
            this.checkPatientConflicts();
        } else {
            this.addPatientToPracticeManagement();
        }
    }

    addPatientToPracticeManagement() {
        this.loading = true;
        this.selectedPatient.updatePracticeManagementSystem = true;
        this.referralService.updatePatient(this.companyId, this.locationId, this.selectedPatient).subscribe(() => {
            this.updatePatientIdOnReferral(this.selectedPatient.id);
        }, error => {
            this.toastr.error($localize`Update referral patient failed.`);
            this.loading = false;
            console.log(error);
        });
    }

    populateNewPatientFormDefaults() {
        const referringPatient = this.referral.referringPatient;
        this.newPatientForm.patchValue({
            firstName: referringPatient.firstName,
            lastName: referringPatient.lastName,
            birthDate: this.dateService.formatDateToString(referringPatient.birthdate),
            gender: referringPatient.genderTypeId,
            homePhone: referringPatient.homePhone,
            mobilePhone: referringPatient.mobilePhone,
            email: referringPatient.email,
        });
        this.newPatientForm.markAllAsTouched();
    }

    createNewPatientClicked() {
        this.selectedPatient = null;
        this.populateNewPatientFormDefaults();
        this.linkPatientStep = 'createPatient';
    }

    checkPatientConflicts() {
        this.patientToSave = Object.assign(new Patient(), this.selectedPatient);
        this.generatePatientConflicts();
        if (this.patientConflicts?.length > 0) {
            this.currentConflictIndex = 0;
            this.linkPatientStep = 'comparePatient';
        } else {
            this.updatePatientIdOnReferral(this.patientToSave.id);
        }
    }

    generatePatientConflicts() {
        const conflicts: PatientConflictModel[] = [];
        const referralPatient = this.referral.referringPatient;
        conflicts.push(new PatientConflictModel('firstName', $localize`First Name`, referralPatient.firstName, this.selectedPatient.firstName));
        conflicts.push(new PatientConflictModel('lastName', $localize`Last Name`, referralPatient.lastName, this.selectedPatient.lastName));
        conflicts.push(new PatientConflictModel('birthdate', $localize`Birth Date`, referralPatient.birthdate, this.selectedPatient.birthdate, v => {
            return this.dateService.formatDateToString(v);
        }));
        conflicts.push(new PatientConflictModel('email', $localize`Email`, referralPatient.email, this.selectedPatient.email));
        conflicts.push(new PatientConflictModel('homePhone', $localize`Home Phone`, referralPatient.homePhone, this.selectedPatient.homePhone));
        conflicts.push(new PatientConflictModel('mobilePhone', $localize`Mobile Phone`, referralPatient.mobilePhone, this.selectedPatient.mobilePhone));
        conflicts.push(new PatientConflictModel('genderTypeId', $localize`Gender`, referralPatient.genderTypeId, referralPatient.genderTypeId, v => {
            return this.getGenderName(v);
        }));

        const emptyValueConflicts = conflicts.filter(c => c.proposedPatientValue == null || c.proposedPatientValue === '' || c.referralPatientValue == null || c.referralPatientValue === '');
        emptyValueConflicts.forEach(c => {
            this.patientToSave[c.fieldName] = (c.proposedPatientValue == null || c.proposedPatientValue === '') ? c.referralPatientValue : c.proposedPatientValue;
        });
        this.patientConflicts = conflicts.filter(c => c.valuesAreDifferent() && !emptyValueConflicts.includes(c));
    }

    resolvePatientConflicts() {
        this.patientConflicts.forEach(conflict => {
            this.patientToSave[conflict.fieldName] = conflict.useProposedValue ? conflict.proposedPatientValue : conflict.referralPatientValue;
        });

        this.updatePatientRecord();
    }

    getConflictsResolvedString(): string {
        return $localize`${this.patientConflicts?.length}:totalConflicts: Conflicts Resolved`;
    }

    getConflictCountString(): string {
        return $localize`Conflict ${this.currentConflictIndex + 1}:conflictNumber: of ${this.patientConflicts?.length}:totalConflicts:`;
    }

    getGenderName(genderTypeId): string {
        return this.genderTypes.find(g => g.id === genderTypeId)?.name;
    }

    updatePatientIdOnReferral(patientId: string) {
        this.loading = true;
        const updateReferral = Object.assign(new HydratedReferral(), this.referral);
        if (this.incomingReferral) {
            updateReferral.referredPatientId = patientId;
            delete updateReferral.referredPatient;
        } else {
            updateReferral.referringPatientId = patientId;
            delete updateReferral.referringPatient;
        }
        this.referralService.updateReferral(updateReferral).subscribe(() => {
            this.activeModal.close(true);
            this.toastr.success($localize`Referral Patient Updated Successfully.`);
        }, error => {
            this.toastr.error($localize`Update referral patient failed.`);
            this.loading = false;
            console.log(error);
        });
    }

    updatePatientRecord() {
        this.loading = true;
        this.selectedPatient.updatePracticeManagementSystem = true;
        this.referralService.updatePatient(this.companyId, this.locationId, this.patientToSave).subscribe(result => {
            this.updatePatientIdOnReferral(this.patientToSave.id);
        }, error => {
            this.toastr.error($localize`Update referral patient failed.`);
            this.loading = false;
            console.log(error);
        });
    }

    createPatientRecord() {
        this.loading = true;
        const patient = this.generatePatient();
        this.referralService.createPatient(this.companyId, this.locationId, patient).subscribe(resultPatient => {
            this.updatePatientIdOnReferral(resultPatient?.id);
        }, error => {
            this.toastr.error($localize`Create referral patient failed.`);
            this.loading = false;
            console.log(error);
        });
    }

    generatePatient(): Patient {
        const newPatient = Object.assign(new Patient(), this.referral?.referringPatient);
        delete newPatient.id;
        if (newPatient.address?.id) {
            delete newPatient.address.id;
        }
        newPatient.updatePracticeManagementSystem = true;
        newPatient.firstName = this.newPatientForm.controls.firstName.value;
        newPatient.lastName = this.newPatientForm.controls.lastName.value;
        newPatient.birthdate = this.dateService.stringToDate(this.newPatientForm.controls.birthDate.value);
        newPatient.genderTypeId = this.newPatientForm.controls.gender.value;
        newPatient.homePhone = this.newPatientForm.controls.homePhone.value;
        newPatient.mobilePhone = this.newPatientForm.controls.mobilePhone.value;
        newPatient.email = this.newPatientForm.controls.email.value;
        return newPatient;
    }

    validateAtLeastOnePhone(c: AbstractControl) {
        const homeControl = c.get('homePhone');
        const mobileControl = c.get('mobilePhone');
        const homePhone: string = homeControl?.value;
        const mobilePhone: string = mobileControl?.value;
        const errors: any = {};
        if (mobilePhone?.length === 0 && homePhone?.length === 0) {
            errors.phoneRequired = true;
        }

        return errors;
    }

    showBackButton(): boolean {
        return this.linkPatientStep !== 'searchPatients';
    }

    showContinueButton(): boolean {
        return this.linkPatientStep !== 'searchPatients';
    }

    continueButtonEnabled(): boolean {
        if (this.linkPatientStep === 'createPatient') {
            return this.newPatientForm.valid;
        }
        return true;
    }

    continueButtonText(): string {
        return this.linkPatientStep === 'createPatient' ? $localize`Save` : $localize`Continue`;
    }

    backButtonClicked() {
        if (this.linkPatientStep === 'createPatient') {
            this.linkPatientStep = 'searchPatients';
        } else if (this.linkPatientStep === 'comparePatient') {
            if (this.currentConflictIndex === 0) {
                this.linkPatientStep = 'searchPatients';
            } else {
                this.currentConflictIndex--;
            }
        }
    }

    continueButtonClicked() {
        if (this.linkPatientStep === 'createPatient') {
            this.createPatientRecord();
        } else if (this.linkPatientStep === 'comparePatient') {
            if (this.currentConflictIndex < this.patientConflicts?.length) {
                this.currentConflictIndex++;
            } else {
                this.resolvePatientConflicts();
            }
        }
    }

    close() {
        this.activeModal.close();
    }
}

class PatientConflictModel {
    public referralPatientValue: any;
    public proposedPatientValue: any;
    public useProposedValue: boolean;
    public fieldName: string;
    public fieldDisplayName: string;
    public displayValueMethod: (v: any) => string;

    constructor(fieldName: string, fieldDisplayName: string, referralPatientValue: any, proposedPatientValue: any, displayValueMethod?: (v: any) => string) {
        this.referralPatientValue = referralPatientValue;
        this.proposedPatientValue = proposedPatientValue;
        this.fieldName = fieldName;
        this.fieldDisplayName = fieldDisplayName;
        this.useProposedValue = false;
        if (displayValueMethod == null) {
            this.displayValueMethod = v => {
                return v;
            };
        } else {
            this.displayValueMethod = displayValueMethod;
        }
    }

    valuesAreDifferent(): boolean {
        return this.referralPatientDisplayValue() !== this.proposedPatientDisplayValue();
    }

    valueOrNA(v: string): string {
        return v?.length > 0 ? v : $localize`N/A`;
    }

    referralPatientDisplayValue(): string {
        return this.valueOrNA(this.displayValueMethod(this.referralPatientValue));
    }

    proposedPatientDisplayValue(): string {
        return this.valueOrNA(this.displayValueMethod(this.proposedPatientValue));
    }
}
