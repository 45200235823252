import {Deserializable} from '../views/shared/utils/json-utils';

export class LocationActivity implements Deserializable {
    public id: number;
    public location: string;
    public companyId: number;
    public company: string;
    public locationEmployeeCount: number;
    public locationPhoneNumber: string;
    public locationCreatedDate: Date;
    public sentReferralCount: number;
    public lastSentReferralDate: Date;
    public sentDentalLabReferralCount: number;
    public lastSentDentalLabReferralDate: Date;
    public acceptingReferrals: boolean;

    onDeserialize() {
    }
}


