import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {BehaviorSubject, Observable, ReplaySubject, throwError} from 'rxjs';
import {Router} from '@angular/router';
import {catchError, switchMap, take, filter, finalize} from 'rxjs/operators';
import 'rxjs-compat/add/operator/map';
import {SessionService} from '../session.service';
import {AccountService} from '../account.service';
import {Session} from '../../models/session.model';
import '../../views/shared/utils/replay-subject.extensions';
import {environment} from '../../../environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    private refreshObservable: ReplaySubject<Session>;

    private refreshingToken = false;
    private refreshSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);


    constructor(
        private _router: Router,
        private session: SessionService,
        private accountService: AccountService
    ) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (environment.useNewAuthInterceptor) {
            return next.handle(request).pipe(catchError(err => {
                if (err && err.status === 401) {
                    if (this.refreshingToken) {
                        // wait until new token is ready and we can retry the request
                        return this.refreshSubject.pipe(filter(result => result !== null), take(1),
                            switchMap(() => next.handle(this.addAuthenticationToken(request, this.session.getAuthToken()))));
                    } else {
                        this.refreshingToken = true;
                        // ensure subsequent calls are waiting until token is retrieved
                        this.refreshSubject.next(null);
                        return this.accountService.isAuthenticated(true).pipe(
                            switchMap((session: Session) => {
                                this.refreshSubject.next(session);
                                return next.handle(this.addAuthenticationToken(request, session?.accessToken));
                            }),
                            finalize(() => this.refreshingToken = false)
                        );
                    }
                } else {
                    return throwError(err);
                }
            }));
        } else {
            return next.handle(request).pipe(
                catchError(err => {
                    if (err.status === 401) {
                        // Stall all incoming requests that wont have a valid token
                        if (!this.refreshObservable) {
                            this.refreshObservable = new ReplaySubject(1);
                            this.refreshObservable.bind(this.accountService.isAuthenticated(true));
                        }
                        // Refresh Session
                        return new Observable<HttpEvent<any>>(subscriber => {
                            this.refreshObservable.subscribe((session) => {
                                this.refreshObservable = null;
                                next.handle(this.addAuthenticationToken(request, session.accessToken)).subscribe(response => {
                                    console.log(response);
                                    subscriber.next(response);
                                }, e => {
                                    subscriber.error(e);
                                });
                            }, error => {
                                this.refreshObservable = null;
                                this.accountService.signOut();
                                subscriber.error(error);
                            });
                        });
                    } else {
                        return throwError(err);
                    }
                })
            );
        }
    }

    addAuthenticationToken(request, token: string) {
        return request.clone({
            setHeaders: {
                'Authorization': `Bearer ${token}`
            }
        });
    }

}
