import {Component, Input} from '@angular/core';
import {FeeGuideProcedure} from "../../../../models/fee-guide-procedure.model";

@Component({
  selector: 'app-fee-guide-procedure-fees-list',
  templateUrl: './fee-guide-procedure-fees-list.component.html',
  styleUrls: ['../../../stylesheet/stylesheet.component.scss', './fee-guide-procedure-fees-list.component.scss']
})
export class FeeGuideProcedureFeesListComponent {

  @Input() expanded: boolean = false;
  @Input() feeGuideExpired: boolean = false;
  @Input() procedure: FeeGuideProcedure;
  @Input() locationFeeGuideMode: boolean;

}
