import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {File as _File} from '../../../models/file';

@Component({
    selector: 'app-file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: [
        './file-upload.component.scss',
        '../../stylesheet/stylesheet.component.scss'
    ]
})

export class FileUploadComponent implements OnInit {
    id = Math.random().toString(36).substring(2);
    @ViewChild('fileDropRef', {static: false}) fileDropEl: ElementRef;
    @Input() files: _File[];
    @Input() hideFilesUploaded: boolean = false;
    @Input() handleFileAdded: (file: _File) => void;
    @Input() imageOnly: boolean = false;
    @Input() fileTypeOverride: string;
    @Input() mimeTypeOverride: string;
    @Input() iconSrc: string = '/assets/img/icons/manage.svg';

    constructor() {
    }

    ngOnInit(): void {
    }

    onFileDropped($event) {
        this.handleUploadedFiles($event);
    }

    fileBrowseHandler(target) {
        this.handleUploadedFiles(target.files);
    }

    handleUploadedFiles(uploadedFiles: FileList) {
        const renamedFiles: File[] = [];

        Array.from(uploadedFiles).forEach( oldFile => {
            const newName = oldFile.name.replace(/[^a-zA-Z0-9 ._-]/g, '');
            const renamedFile: File = new File([oldFile], newName, {type: oldFile.type});
            renamedFiles.push(renamedFile);
        });

        Array.from(renamedFiles).forEach(file => {
            // ensure the content type is image
            const mimeType = file.type;
            if (this.mimeTypeOverride) {
                if (!this.mimeTypeOverride.includes(mimeType)) {
                    console.warn('File type not supported.');
                    return;
                }
            } else if (mimeType.match(/image\/*/) == null &&
                mimeType !== 'application/pdf' &&
                mimeType !== 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' &&
                mimeType !== 'application/msword') {
                console.warn('Only images and pdfs are supported.');
                return;
            }

            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (event) => {
                const f = new _File();
                f.name = file.name;
                f.type = file.type;
                f.size = file.size;
                f.url = event.target.result;
                f.hasPreview = !mimeType.match(`^[application]+/[-\\w.]+|[video]+/[-\\w.]+$`); // no preview for pdf types
                this.prepareFilesList(new Array(f));
            };

        });
    }

    deleteFile(index: number) {
        if (this.files[index].progress < 100) {
            console.log('Upload in progress.');
            return;
        }
        this.files.splice(index, 1);
    }

    retryUpload(index: number) {
        this.files[index].failed = false;
        this.files[index].failureError = '';
        this.files[index].success = false;
        this.files[index].progress = 0;
        this.uploadFile(index);
    }

    uploadFile(index: number) {
        if (this.handleFileAdded != null) {
            this.handleFileAdded(this.files[index]);
            this.files[index].progress = 100;
            this.files[index].failed = false;
            this.files[index].success = true;
            // Show as successful because we will handle the upload logic elsewhere
            return;
        }
    }

    prepareFilesList(files: _File[]) {
        const indexesToUpload = [];
        for (let i = 0; i < files.length; i++) {
            if (!files[i].success && !files[i].failed) {
                // if no success or failure, the file can be processed
                files[i].progress = 0;
                this.files.push(files[i]);
                indexesToUpload.push(this.files.indexOf(files[i]));
            }
        }
        this.fileDropEl.nativeElement.value = '';
        if (indexesToUpload.length > 0) {
            for (const index of indexesToUpload) {
                this.uploadFile(index);
            }
        }
    }

    formatBytes(bytes, decimals = 2) {
        if (bytes === 0) {
            return '0 Bytes';
        }
        const k = 1024;
        const dm = decimals <= 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    imagePreview(index: number) {
        if (this.files[index].url && this.files[index].hasPreview) {
            return this.files[index].url;
        } else {
            switch (this.files[index].type) {
                case 'image/jpg':
                    return '../../../../assets/img/icons/JPG.svg';
                case 'image/jpeg':
                    return '../../../../assets/img/icons/JPG.svg';
                case 'image/png':
                    return '../../../../assets/img/icons/PNG.svg';
                case 'application/pdf':
                    return '../../../../assets/img/icons/PDF.svg';
                case 'application/msword':
                case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                default:
                    return '../../../../assets/img/icons/manage.svg';

            }
        }
    }

    getPlaceholderImagePadding(index: number) {
        if (this.files[index].url && this.files[index].hasPreview) {
            return '0';
        } else {
            return '0.625rem';
        }
    }

    getAcceptValue(): string {
        if (this.mimeTypeOverride) {
            return this.mimeTypeOverride;
        } else if (this.imageOnly) {
            return 'image/*';
        } else {
            return 'image/*,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword';
        }
    }

    getLocalizedFileTypeText(): string {
        if (this.fileTypeOverride) {
            return this.fileTypeOverride;
        }

        const fileTypes = this.imageOnly ? `PNG/JPG/TIF` : `PNG, JPG, TIF, PDF, Doc/Docx`;
        const maxSizeMB = 10;
        return $localize`${fileTypes}:fileTypes: up to ${maxSizeMB}:maxSizeMB:MB`;
    }
}
