<div class="modal-body">
    <div class="container">
        <div class="modal-title mb-3">{{titleText}}</div>
        <form class="teth-textfield-form mb-3" [formGroup]="roleForm"
              [class.error]="roleForm.controls.roleName.invalid && (roleForm.controls.roleName.dirty || roleForm.controls.roleName.touched)">
            <label class="teth-textfield-label" for="roleName" i18n>Role Name</label>
            <input class="teth-textfield" type="text" id="roleName" name="roleName"
                   placeholder="Enter new role name"
                   [formControlName]="'roleName'"
                   i18n-placeholder>
            <span class="teth-textfield-error" i18n>Role must have a name</span>
        </form>
        <div *ngFor="let pc of permissionCategories" class="mb-3">
            <div class="switch-container permission-group-title"
                [hidden]="pc.primaryPermission == null">
                {{pc.primaryPermission?.name}}
                <img [src]="'assets/img/icons/help-filled.svg'" [ngbTooltip]="pc.primaryPermission?.description" tooltipClass="tooltip" alt="description">
                <label class="switch" for="role{{pc.primaryPermission?.id}}" [class.disabled-section]="!addingNewRole">
                    <input type="checkbox"
                           id="role{{pc.primaryPermission?.id}}"
                           name="role{{pc.primaryPermission?.id}}"
                           [checked]="isPermissionChecked(pc.primaryPermission)"
                           (click)="permissionCheckboxClicked(pc.primaryPermission)">
                    <span class="slider"></span>
                </label>
            </div>
            <div class="child-permission-group"
                 [hidden]="!isPermissionChecked(pc.primaryPermission) || pc.childPermissions === null || pc.childPermissions[0] === undefined">
                <div class="switch-container my-2" *ngFor="let permission of pc.childPermissions; let i = index">
                    {{permission?.name}}
                    <img [src]="'assets/img/icons/help-filled.svg'" [ngbTooltip]="permission?.description" tooltipClass="tooltip" alt="description">
                    <label class="switch" for="role{{permission?.id}}" [class.disabled-section]="!addingNewRole">
                        <input type="checkbox" id="role{{permission?.id}}"
                               name="role{{permission?.id}}"
                               [checked]="isPermissionChecked(permission)"
                               (click)="permissionCheckboxClicked(permission)">
                        <span class="slider"></span>
                    </label>
                </div>
            </div>
        </div>
        <div class="footer-button mt-4 mb-4">
            <button class="teth-button grey-button" (click)="closeModal()" i18n>Close</button>
            <button class="teth-button blue-button ml-3" *ngIf="addingNewRole"
                    [class.grey-button]="!this.roleForm.valid" (click)="saveNewRole()" i18n>Save New Role
            </button>
        </div>
    </div>
</div>
