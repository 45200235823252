<div class="container mt-5">
    <div class="row">
        <div class="col-md-12">
            <app-datatable
                #datatable
                [options]="datatableOptions"
                [data]="datatableOptions.data"
                [loadingOptions]="loadingOptions"
                [dataSegments]="datatableSegmentOptions"
                [resetBulkEdit]="resetTableBulkEdit"
                (dataSegmentSelected)="segmentSelected($event)"
                (rowClickedEmitter)="handleRowClicked($event)"
                (bulkEditPressed)="markSelectedAsApproved($event)"
                (primaryFilterChangedEmitter)="newLocationSelected($event)"
                (timeRangeChangedEmitter)="timeRangeChanged($event)">
            </app-datatable>
        </div>
    </div>
</div>
