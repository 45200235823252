<div class="modal-body">
    <app-loading
            [determinateLoading]="false"
            [hidden]="!loading"
            [loadingText]="null"
            [fullscreen]="false"
            [backgroundColor]="'#FDFDFD'"
            [color]="'#388EFC'">
    </app-loading>
    <div [hidden]="!showBackButton()"
         (click)="backButtonClicked()"
         class="button-back">
        Back
    </div>
    <ng-container *ngIf="linkPatientStep === 'searchPatients'">
        <div class="modal-title mb-3" i18n>Choose a Patient to Link</div>
        <div class="mb-4">
            <app-type-ahead-search
                    [fieldLabel]="'Search'"
                    [resultsDisplayConfiguration]="'{firstName} {lastName} ({birthdate})'"
                    [clearSearch]="clearPatientSearchInput"
                    [showSearchButton]="true"
                    (searchButtonClicked)="searchPatients($event)"
                    (itemSelected)="patientSelected($event)">
            </app-type-ahead-search>
            <ul class="teth-list scrollable-list px-0">
                <li class="list-item"
                    *ngFor="let patient of filteredPatients"
                    (click)="patientSelected(patient)">
                    <img class="list-item-image-icon"
                         [src]="'/assets/img/icons/patient-referral.svg'"
                         alt="result-image">
                    <div class="list-item-text-container">
                        <div class="list-item-text">
                            {{patient.fullName}}
                        </div>
                        <div class="list-item-subtext" [hidden]="!patient.address">
                            {{addressUtils.singleLineFormat(patient.address)}}
                        </div>
                        <div class="list-item-subtext" [hidden]="!patient.birthdate">
                            {{dateService.formatDateToString(patient.birthdate)}}
                        </div>
                    </div>
                </li>
                <li class="list-item"
                    (click)="createNewPatientClicked()">
                    <img class="list-item-image-icon"
                         [src]="'/assets/img/icons/plus.svg'"
                         alt="result-image">
                    <div class="list-item-text-container">
                        <div class="list-item-text" i18n>
                            Create New Entry
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </ng-container>
    <ng-container *ngIf="linkPatientStep === 'createPatient'">
        <div class="modal-title mb-3" i18n>Create New Patient Entry</div>
        <div class="mb-4">
            <form class="teth-textfield-form px-0"
                  [formGroup]="newPatientForm">
                <div class="row">
                    <div class="col-6"
                         [class.error]="newPatientForm.controls.firstName.invalid && (newPatientForm.controls.firstName.dirty || newPatientForm.controls.firstName.touched)">
                        <label class="teth-textfield-label" for="firstName" i18n>
                            First Name
                        </label>
                        <input class="teth-textfield"
                               type="text"
                               id="firstName"
                               name="firstName"
                               placeholder="Name"
                               i18n-placeholder
                               formControlName="firstName">
                        <span class="teth-textfield-error" i18n>First name is required.</span>
                    </div>
                    <div class="col-6"
                         [class.error]="newPatientForm.controls.lastName.invalid && (newPatientForm.controls.lastName.dirty || newPatientForm.controls.lastName.touched)">
                        <label class="teth-textfield-label" for="lastName" i18n>
                            Last Name
                        </label>
                        <input class="teth-textfield"
                               type="text"
                               id="lastName"
                               name="lastName"
                               placeholder="Name"
                               i18n-placeholder
                               formControlName="lastName">
                        <span class="teth-textfield-error" i18n>Last name is required.</span>
                    </div>
                    <div class="col-6"
                         [class.error]="newPatientForm.controls.birthDate.invalid && (newPatientForm.controls.birthDate.dirty || newPatientForm.controls.birthDate.touched)">
                        <label class="teth-textfield-label" for="birthdateField" i18n>Birthdate</label>
                        <input class="teth-textfield"
                               type="text"
                               id="birthdateField"
                               name="birthdateField"
                               [placeholder]="dateService.getSessionDateFormat()"
                               [textMask]="{mask: dateService.getDateTextMask()}"
                               [formControlName]="'birthDate'">
                        <span class="teth-textfield-error" i18n>Valid date of birth is required.</span>
                    </div>
                    <div class="col-6"
                         [class.error]="newPatientForm.controls.gender.invalid && (newPatientForm.controls.gender.dirty || newPatientForm.controls.gender.touched)">
                        <label class="teth-textfield-label" for="genderField" i18n>Gender</label>
                        <select class="teth-dropdown teth-textfield-dropdown"
                                name="genderField"
                                id="genderField"
                                [formControlName]="'gender'">
                            <option value="-1" selected disabled hidden i18n>Select Gender</option>
                            <option *ngFor="let gender of genderTypes"
                                    value="{{gender.id}}">{{gender.name}}</option>
                        </select>
                        <span class="teth-textfield-error" i18n>Select a gender.</span>
                    </div>
                    <div class="col-6"
                         [class.error]="newPatientForm.controls.homePhone.invalid && (newPatientForm.controls.homePhone.dirty || newPatientForm.controls.homePhone.touched)">
                        <label class="teth-textfield-label" for="homePhoneField" i18n>Home Phone</label>
                        <input class="teth-textfield" type="text" id="homePhoneField" name="homePhoneField"
                               placeholder="1 (000) 000-0000"
                               [textMask]="{mask: phoneUtils.phoneMask}"
                               [formControlName]="'homePhone'">
                        <span class="teth-textfield-error" i18n>Phone Number is Invalid.</span>
                    </div>
                    <div class="col-6"
                         [class.error]="newPatientForm.controls.mobilePhone.invalid && (newPatientForm.controls.mobilePhone.dirty || newPatientForm.controls.mobilePhone.touched)">
                        <label class="teth-textfield-label" for="mobilePhoneField" i18n>Mobile Phone</label>
                        <input class="teth-textfield" type="text" id="mobilePhoneField" name="mobilePhoneField"
                               placeholder="1 (000) 000-0000"
                               [textMask]="{mask: phoneUtils.phoneMask}"
                               [formControlName]="'mobilePhone'">
                        <span class="teth-textfield-error" i18n>Phone Number is Invalid.</span>
                    </div>
                    <div class="col-md-12 patient-field-container"
                         *ngIf="newPatientForm.errors?.phoneRequired && (newPatientForm.controls.homePhone.dirty || newPatientForm.controls.homePhone.touched || newPatientForm.controls.mobilePhone.dirty || newPatientForm.controls.mobilePhone.touched)">
                        <form class="teth-textfield-form error">
                            <span class="teth-textfield-error" i18n>At least one phone number is required.</span>
                        </form>
                    </div>
                    <div class="col-6"
                         [class.error]="newPatientForm.controls.email.invalid && (newPatientForm.controls.email.dirty || newPatientForm.controls.email.touched)">
                        <label class="teth-textfield-label" for="emailField" i18n>Email</label>
                        <input class="teth-textfield" type="text" id="emailField" name="emailField"
                               placeholder="hello@email.com"
                               [formControlName]="'email'">
                        <span class="teth-textfield-error" i18n>Email Address is Invalid.</span>
                    </div>
                </div>
            </form>
        </div>
    </ng-container>
    <ng-container *ngIf="linkPatientStep === 'comparePatient'">
        <div class="modal-title mb-3" i18n>Review Conflicts</div>
        <div class="modal-subtitle mb-4" i18n>There are some pieces of information from the referral that don't match this patient's {{locationIntegrationType?.name}} records. For each conflict, choose the correct information.</div>
        <div class="mb-4">
            <div *ngIf="currentConflictIndex < patientConflicts?.length">
                <div class="conflict-count">
                    {{getConflictCountString()}}
                </div>
                <div class="conflict-type-title mb-4">
                    {{patientConflicts[currentConflictIndex].fieldDisplayName}}
                </div>
                <div class="conflict-option-container"
                     (click)="patientConflicts[currentConflictIndex].useProposedValue = true"
                     [class.selected]="patientConflicts[currentConflictIndex].useProposedValue">
                    <div class="conflict-option-text">
                        <div class="conflict-value">
                            {{patientConflicts[currentConflictIndex].proposedPatientDisplayValue()}}
                        </div>
                        <div class="conflict-source">
                            {{locationIntegrationType?.name}}
                        </div>
                    </div>
                    <label class="teth-checkbox-container mb-0" for="integrationValueCheckbox">
                        <input type="checkbox" class="teth-checkbox" id="integrationValueCheckbox" [checked]="patientConflicts[currentConflictIndex].useProposedValue">
                        <span class="teth-checkmark"></span>
                    </label>
                </div>
                <div class="conflict-option-container"
                     (click)="patientConflicts[currentConflictIndex].useProposedValue = false"
                     [class.selected]="!patientConflicts[currentConflictIndex].useProposedValue">
                    <div class="conflict-option-text">
                        <div class="conflict-value">
                            {{patientConflicts[currentConflictIndex].referralPatientDisplayValue()}}
                        </div>
                        <div class="conflict-source" i18n>
                            Referral
                        </div>
                    </div>
                    <label class="teth-checkbox-container mb-0" for="referralValueCheckbox">
                        <input type="checkbox" class="teth-checkbox" id="referralValueCheckbox" [checked]="!patientConflicts[currentConflictIndex].useProposedValue">
                        <span class="teth-checkmark"></span>
                    </label>
                </div>
            </div>
            <div *ngIf="currentConflictIndex === patientConflicts?.length" class="conflict-resolved">
                <img [src]="'assets/img/icons/check-circle-green.svg'" alt="">
                {{getConflictsResolvedString()}}
            </div>
        </div>
    </ng-container>
    <div class="footer-button">
        <button class="teth-button grey-button" (click)="close()" i18n>Cancel</button>
        <button class="teth-button blue-button ml-3"
                [hidden]="!showContinueButton()"
                [class.disabled]="!continueButtonEnabled()"
                (click)="continueButtonClicked()">{{continueButtonText()}}</button>
    </div>
</div>
