import {Attachment} from '../../../models/attachment.model';
import DateUtils from './date-utils';
import * as moment from 'moment';

export default class AttachmentUtils {
    static attachmentThumbnailSrc(attachment: Attachment): string {
        const thumbnailUrl = attachment?.links?.find(l => l.size === 'thumb') ?? attachment?.links?.find(l => l.size === 'small');
        if (thumbnailUrl?.presignedUrl && thumbnailUrl.presignedUrl !== '') {
            return thumbnailUrl.presignedUrl;
        } else {
            switch (attachment.mediaType) {
                case 'image/jpg':
                    return '../../../../assets/img/icons/JPG.svg';
                case 'image/jpeg':
                    return '../../../../assets/img/icons/JPG.svg';
                case 'image/png':
                    return '../../../../assets/img/icons/PNG.svg';
                case 'application/pdf':
                    return '../../../../assets/img/icons/PDF.svg';
            }
            return '../../../../assets/img/icons/manage.svg';
        }
    }

    static getPlaceholderImagePadding(attachment: Attachment, placeholderPadding: string = '0.625rem') {
        const thumbnailUrl = attachment?.links?.find(l => l.size === 'thumb') ?? attachment?.links?.find(l => l.size === 'small');
        if (thumbnailUrl?.presignedUrl && thumbnailUrl.presignedUrl !== '') {
            return '0';
        } else {
            return placeholderPadding;
        }
    }

    static canPrint(attachment: Attachment): boolean {
        return attachment.mediaType === 'image/jpg' ||
            attachment.mediaType === 'image/jpeg' ||
            attachment.mediaType === 'image/png' ||
            attachment.mediaType === 'application/pdf';
    }

    static canPreview(attachment: Attachment): boolean {
        return attachment.mediaType === 'image/jpg' ||
            attachment.mediaType === 'image/jpeg' ||
            attachment.mediaType === 'image/png' ||
            attachment.mediaType === 'image/tiff' ||
            attachment.mediaType === 'application/pdf';
    }

    static getUploadInfoString(attachment: Attachment): string {
        return $localize`Uploaded ${DateUtils.formatDateToReadableString(attachment.createdDate)}:date: by ${attachment.createdBy}:employee:`;
    }

    static getImageTakenString(attachment: Attachment): string {
        return $localize`Taken ${DateUtils.formatDateToReadableStringUTC(attachment.imageTakenDate)}:date:`;
    }
}
