import {Component, OnDestroy, OnInit} from '@angular/core';
import {AccountService} from '../../services/account.service';
import {SignInRequest} from '../../models/sign-in-request.model';
import {ActivatedRoute, Router} from '@angular/router';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {SessionService} from '../../services/session.service';
import {Location} from '../../models/location.model';
import {PermissionService} from '../../services/permission.service';
import {EmployeeService} from '../../services/employee.service';
import * as moment from 'moment';
import {PasswordResetModalComponent} from '../shared/components/password-reset-modal/password-reset-modal.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {environment} from '../../../environments/environment';
import {EntityService} from '../../services/entity.service';
import {CompanyType} from '../../models/company.model';
import {GenericModalComponent} from '../shared/components/generic-modal/generic-modal.component';
import {UserAgreementModalComponent} from '../shared/components/user-agreement-modal/user-agreement-modal.component';
import {Subscription} from 'rxjs';
import {VersionInfoModalComponent} from '../shared/components/version-info-modal/version-info-modal.component';
import ModalUtils from '../shared/utils/modal-utils';
import {HelpService} from '../../services/help.service';
import DateUtils from '../shared/utils/date-utils';
import LocalizationUtils from '../shared/utils/localization-utils';

@Component({
    selector: 'app-sign-in',
    templateUrl: './sign-in.component.html',
    styleUrls: ['./sign-in.component.scss', '../stylesheet/stylesheet.component.scss'],
})

export class SignInComponent implements OnInit, OnDestroy {
    rememberMe = false;
    signInFailed = false;
    signingIn = false;
    pickLocation = false;
    availableLocations: Location[] = [];
    setDefaultLocation = false;
    signInTitle = $localize`Sign In`;
    subscriptions: Subscription[] = [];

    public signInForm = new FormGroup({
        companyId: new FormControl('', Validators.required),
        username: new FormControl('', Validators.required),
        password: new FormControl('', Validators.required),
        rememberMe: new FormControl(false),
    });

    constructor(private accountService: AccountService,
                private sessionService: SessionService,
                private permissionService: PermissionService,
                private activatedRoute: ActivatedRoute,
                private employeeService: EmployeeService,
                private modalService: NgbModal,
                private entityService: EntityService,
                private helpService: HelpService,
                private router: Router) {
    }

    ngOnInit(): void {
        this.setupBindings();
        if (environment.administratorAccess) {
            this.signInTitle = $localize`Admin Sign In`;
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    setupBindings() {
        this.subscriptions.push(this.activatedRoute.params.subscribe(p => {
            const companyId = p['companyId'];
            if (companyId) {
                this.formatCompanyId(companyId);
            }
            const username = p['username'];
            if (username) {
                this.signInForm.controls.username.setValue(username);
            }
        }));

        this.subscriptions.push(this.sessionService.sessionContainer.firstNotNull().subscribe(s => {
            // ensure user role is loaded before attempting navigation
            this.subscriptions.push(this.permissionService.currentUserRole.firstNotNull().subscribe((_) => {
                this.continueSignIn();
            }));
        }));

        this.subscriptions.push(this.signInForm.controls.companyId.valueChanges.subscribe(c => {
            this.formatCompanyId(c);
        }));
    }

    continueSignIn() {
        if (environment.administratorAccess) {
            this.verifyAdminAccess();
            return;
        }

        const s = this.sessionService.sessionContainer.value;

        if (s.company.companyTypeId === CompanyType.Administrator) {
            this.accountService.signOut(false);
            this.signingIn = false;
            this.showAdminLoginModal();
        } else if (!s.employee.eulaConfirmation) {
            this.promptForAgreement();
        } else if (moment().diff(moment(s.employee.passwordChangedDate), 'month') >= 3) {
            this.promptForNewPassword();
        } else if (s.employee.assignedLocations.length !== 1) {
            this.promptForLocation();
        } else {
            this.transitionToSignedInApp();
        }
    }

    promptForAgreement() {
        const s = this.sessionService.sessionContainer.value;
        const modalRef = this.modalService.open(UserAgreementModalComponent, {
            size: 'lg',
            backdrop: 'static',
            centered: true
        });

        modalRef.result.then(r => {
            if (r === true) {
                s.employee.eulaConfirmation = true;
                s.employee.eulaConfirmationDate = new Date();
                this.employeeService.updateEmployee(s.employee, s.employee.companyId, s.employee.id).subscribe(e => {
                    this.continueSignIn();
                });
            } else {
                this.accountService.signOut();
                window.location.reload();
            }
        }, () => {
        });
    }

    showUpdateInfoModal() {
        const employee = this.sessionService.sessionContainer.value?.employee;
        this.helpService.getReleaseNotes().subscribe(rn => {
            const sortedRn = rn.sort((a, b) => {
                return b.releaseDate.getTime() - a.releaseDate.getTime();
            });
            const mostRecentVersion = sortedRn[0];
            if (employee.lastLoginDate !== null && DateUtils.dateIsBefore(employee.lastLoginDate, mostRecentVersion?.releaseDate) <= 0) {
                const modalRef = this.modalService.open(VersionInfoModalComponent, ModalUtils.modalOptions('lg', true));
                (modalRef.componentInstance as VersionInfoModalComponent).initWithReleaseNote(mostRecentVersion);
            }
        }, error => {
            console.log(error);
        });
    }

    promptForNewPassword() {
        const s = this.sessionService.sessionContainer.value;
        const modalRef = this.modalService.open(PasswordResetModalComponent, {
            size: 'md',
            backdrop: 'static',
            centered: true
        });
        (modalRef.componentInstance as PasswordResetModalComponent)
            .initWith(s.employee, true, true);
        modalRef.result.then(r => {
            if (r === false) {
                this.accountService.signOut();
                window.location.reload();
            } else if (r === true) {
                this.transitionToSignedInApp();
            }
        }, () => {
        });
    }

    promptForLocation() {
        const s = this.sessionService.sessionContainer.value;
        this.signingIn = false;
        this.pickLocation = true;
        let defaultLocation: Location = null;
        s.companyLocations.forEach((loc) => {
            if (s.employee.assignedLocations.map(l => l.locationId).includes(loc.id)) {
                if (loc.id === this.sessionService.sessionContainer.value?.employee.defaultLocationId) {
                    this.pickLocation = false;
                    defaultLocation = loc;
                }
                this.availableLocations.push(loc);
            }
        });
        if (!this.pickLocation) {
            // use default location
            this.locationSelected(defaultLocation);
        }
    }

    locationSelected(loc: Location) {
        if (this.setDefaultLocation) {
            // set default location
            this.employeeService.setEmployeeDefaultLocation(this.sessionService.sessionContainer.value?.employee, loc.id, this.sessionService.getCompanyId(), this.sessionService.sessionContainer.value?.employee.id).subscribe((updatedEmployee) => {
                this.sessionService.sessionContainer.value.employee = updatedEmployee;
            });
        }
        this.sessionService.currentLocation.next(loc);
        this.transitionToSignedInApp();
    }

    signIn() {
        this.signInFailed = false;
        this.signInForm.markAllAsTouched();
        if (!this.signInForm.valid) {
            return;
        }

        const companyId = parseInt(this.signInForm.controls.companyId.value, 10);
        const request = new SignInRequest();
        request.username = this.signInForm.controls.username.value;
        request.password = this.signInForm.controls.password.value;
        const rememberUser = this.signInForm.controls.rememberMe.value;
        this.signingIn = true;
        this.accountService.signIn(request, companyId, rememberUser).subscribe(result => {
            console.log('Successful sign in');
        }, error => {
            console.log('Sign-in Failure.');
            this.signingIn = false;
            this.signInFailed = true;
        });
    }

    handleInputKeyUp(e) {
        let code;
        if (e.key !== undefined) {
            code = e.key;
        } else if (e.keyIdentifier !== undefined) {
            code = e.keyIdentifier;
        } else if (e.keyCode !== undefined) {
            code = e.keyCode;
        }
        if ((code === 13 || code === 'Enter')
            && this.signInForm.controls.companyId.value !== ''
            && this.signInForm.controls.username.value !== ''
            && this.signInForm.controls.password.value !== '') {
            this.signIn();
        }
    }

    formatCompanyId(e) {
        let parsed: any = parseInt(e, 10);
        if (isNaN(parsed)) {
            parsed = '';
        }
        const r = ('0000' + parsed).slice(-4);

        this.signInForm.patchValue({
            companyId: r
        }, {emitEvent: false});
    }

    verifyAdminAccess() {
        const companyId = this.sessionService.sessionContainer.value.employee.companyId;
        this.entityService.getCompany(companyId).subscribe(c => {
            if (c.companyTypeId === CompanyType.Administrator) {
                this.transitionToSignedInApp();
            } else {
                this.accountService.signOut(false);
                this.signingIn = false;
                this.showRegularUserInAdminPortalModal();
            }
        });
    }

    changeLanguage() {
        LocalizationUtils.changeLanguage();
    }

    showAdminLoginModal() {
        const title = $localize`Oops!`;
        const message = $localize`It looks like you are trying to sign in with an administrator account.` + ' ' + $localize`Clicking 'okay' will redirect you to the proper sign in page.`;
        const modalRef = this.modalService.open(GenericModalComponent, GenericModalComponent.defaultModalOptions());
        (modalRef.componentInstance as GenericModalComponent).initWith(title, message, $localize`Okay`, $localize`Cancel`);
        modalRef.result.then(r => {
            if (r === true) {
                window.location.href = environment.adminBaseUrl;
            }
        }, () => {
        });
    }

    showHelpModal() {
        const title = $localize`Support Info`;
        const message = $localize`Looking for Assistance?\nCall us at 306-359-6555 or email us at Onboarding@tetherport.ca.`;
        const modalRef = this.modalService.open(GenericModalComponent, GenericModalComponent.defaultModalOptions());
        (modalRef.componentInstance as GenericModalComponent).initWith(title, message, $localize`Okay`, null, 'blue-button', 'hidden');
        modalRef.result.then(r => {
        }, () => {
        });
    }

    showRegularUserInAdminPortalModal() {
        const title = $localize`Oops!`;
        const message = $localize`It looks like you are trying to sign in to the administrator app with a non-administrator account.` + ' ' + $localize`Clicking 'okay' will redirect you to the proper sign in page.`;
        const modalRef = this.modalService.open(GenericModalComponent, GenericModalComponent.defaultModalOptions());
        (modalRef.componentInstance as GenericModalComponent).initWith(title, message, $localize`Okay`, $localize`Cancel`);
        modalRef.result.then(r => {
            if (r === true) {
                window.location.href = environment.appBaseUrl;
            }
        }, () => {
        });
    }

    transitionToSignedInApp() {
        this.router.navigate(['']).then(() => {
            setTimeout(() => {
                this.showUpdateInfoModal();
            }, 2000);
        });
    }
}
