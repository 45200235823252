import {Deserializable} from '../views/shared/utils/json-utils';

export class DateFormatType implements Deserializable {
    static defaultId = 1;
    static MMDDYYYYString = 'MM/DD/YYYY';
    static DDMMYYYYString = 'DD/MM/YYYY';
    static YYYYMMDDString = 'YYYY/MM/DD';


    public id: number;
    public format: string;

    onDeserialize() {
    }
}
