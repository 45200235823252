<div class="row">
    <div class="col-12">
        <form class="teth-radio-form" [formGroup]="parentForm"
              [class.error]="parentForm.get(control.toString()).invalid && parentForm.get(control.toString()).touched">
            <label [ngClass]="{'required': required}" class="radio-group-label">{{textLabel}}</label>
            <div *ngFor="let rb of radioButtonsCopy" class="teth-radio-option teth-radio-option-inline col-6 pb-3">
                <input class="teth-radio" type="radio"
                       id="{{textLabel + rb.id}}"
                       [value]="rb.value"
                       formControlName="{{control}}">
                <span class="teth-custom-radio"></span>
                <label class="teth-radio-label" for="{{textLabel + rb.id}}">{{rb.label}}</label>
            </div>
            <span class="teth-textfield-error" i18n>Field must be completed</span>
        </form>
    </div>
</div>
