import { Injectable } from '@angular/core';
import {CanDeactivate} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ConfirmationModalComponent} from '../views/shared/components/confirmation-modal/confirmation-modal.component';

export interface ComponentCanDeactivate {
  canDeactivate(): boolean | Promise<any>;
}

export const CanDeactivateState = {
  defendAgainstBrowserBackButton: false,
};

@Injectable({
  providedIn: 'root'
})
export class CanDeactivateGuardService implements CanDeactivate<ComponentCanDeactivate> {

  constructor(private modalService: NgbModal) { }

  canDeactivate(component: ComponentCanDeactivate): boolean | Promise<any> {
      const handleDeactivation = async (deactivate: boolean) => {
          const result = deactivate || await this.openAbandonModal();
          if (result && CanDeactivateState.defendAgainstBrowserBackButton) {
              history.pushState(null, '', '');
          }
          return result;
      };
      const deactivation = component.canDeactivate();
      return deactivation instanceof Promise ? deactivation.then(handleDeactivation) : handleDeactivation(deactivation);
  }

  private async openAbandonModal(): Promise<boolean> {
      return await this.modalService.open(
          ConfirmationModalComponent,
          ConfirmationModalComponent.defaultModalOptions()
      ).result;
  }

}


