<div class="d-flex flex-column" [style.gap.rem]="0.5">

<div class="d-flex" [style.gap.rem]="1">
    <div [style.flex]="1">
        <div class="position-relative">
            <input
                    class="hidden-input"
                    ngbDatepicker
                    #dp="ngbDatepicker"
                    [autoClose]="'outside'"
                    [tabIndex]="-1"
                    [container]="'body'"
                    [dayTemplate]="t"
                    [outsideDays]="'hidden'"
                    (dateSelect)="onDateSelection($event)">
            <div
                    class="teth-dropdown teth-textfield-dropdown"
                    [style.line-height]="'unset'"
                    id="fromDate"
                    [style.cursor]="'pointer'"
                    (click)="dp.toggle()">
                {{ rangeString$ | async }}
            </div>
            <div [hidden]="hideClearButton$ | async" class="clear-button" (click)="resetDates()" i18n>CLEAR</div>
        </div>
    </div>
</div>

</div>
<ng-template #t let-date="date" let-focused="focused">
        <span class="custom-day"
              [class.focused]="focused"
              [class.range]="isRange(date)"
              [class.faded]="isInside(date) || isHovered(date)"
              [class.current]="isCurrentDate(date)"
              (mouseenter)="hoveredDate = date"
              (mouseleave)="hoveredDate = null">
          {{ date.day }}
        </span>
</ng-template>
