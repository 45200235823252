import {Component, OnInit} from '@angular/core';
import {Router, NavigationEnd} from '@angular/router';
import {CanDeactivateState} from './services/can-deactivate-guard.service';
import {filter, tap} from 'rxjs/operators';

import {PermissionService} from './services/permission.service';

@Component({
    // tslint:disable-next-line
    selector: 'body',
    template: '<router-outlet></router-outlet>'
})
export class AppComponent implements OnInit {
    constructor(private router: Router, private permissionService: PermissionService) {
        window.onpopstate = () => CanDeactivateState.defendAgainstBrowserBackButton = true;

        router.events.pipe(
            filter(e => e instanceof NavigationEnd),
            tap(() => CanDeactivateState.defendAgainstBrowserBackButton = false)
        ).subscribe();
    }

    ngOnInit() {
        this.router.events.subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0);
        });
    }
}
