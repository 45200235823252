import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {EditFeeGuideFormService} from "../../../../services/edit-fee-guide-form.service";
import {FeeGuideProcedure} from "../../../../models/fee-guide-procedure.model";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {BehaviorSubject, combineLatest, Observable, Subscription} from "rxjs";

@Component({
  selector: 'app-fee-guide-default-average-value-form',
  templateUrl: './fee-guide-default-average-value-form.component.html',
  styleUrls: ['../../../stylesheet/stylesheet.component.scss', './fee-guide-default-average-value-form.component.scss']
})
export class FeeGuideDefaultAverageValueFormComponent implements OnChanges, OnInit, OnDestroy {

  constructor(private fb: FormBuilder, private formService: EditFeeGuideFormService) {
  }

  @Input() procedure: FeeGuideProcedure;
  @Input() disabled: boolean;
  @Input() locationFeeGuideMode: boolean;

  private _disabled = new BehaviorSubject(false);
  public disabled$ = this._disabled as Observable<boolean>;

  private _locationFeeGuideMode = new BehaviorSubject(false);
  public locationFeeGuideMode$ = this._locationFeeGuideMode as Observable<boolean>;

  public form: FormGroup;

  private formDisabledSubscription: Subscription;
  private formStatusSubscription: Subscription;
  private formValueSubscription: Subscription;

  private initializeForm(): void {
    this.form = this.fb.group({
      id: [this.procedure?.id],
      feeGuideId: [this.procedure?.feeGuideId],
      defaultAverageFeeAmount: [this.procedure?.defaultAverageFeeAmount, [Validators.min(0), Validators.max(999999.99)]],
      overrideDefaultAverageFeeAmount: [this.procedure?.overrideDefaultAverageFeeAmount,[Validators.min(0), Validators.max(999999.99)]],
    });
    if (this.disabled) {
      this.form.disable({ emitEvent: false });
    }
  }

  public ngOnInit(): void {
    this.formDisabledSubscription = combineLatest([this.disabled$, this.locationFeeGuideMode$]).subscribe(([disabled, locationFeeGuideMode]) => {
      if (disabled) {
        this.form?.disable({ emitEvent: false });
      } else {
        if (!disabled && locationFeeGuideMode) {
          this.form?.get('defaultAverageFeeAmount').disable({emitEvent: false});
        } else {
          this.form?.get('defaultAverageFeeAmount').enable({emitEvent: false});
        }
      }
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.procedure) {
      this.initializeForm();
      this.formValueSubscription?.unsubscribe();
      this.formValueSubscription = this.form.valueChanges.subscribe(() => {
        this.formService.addDefaultAverageFeeAmountFormToMap(this.form);
        this.formValueSubscription?.unsubscribe();
      });

      this.formStatusSubscription?.unsubscribe();
      this.formStatusSubscription = this.form.statusChanges.subscribe(() => {
        if (this.form.valid) {
          this.formService.removeFormFromInvalidForms(this.form);
        } else {
          this.formService.addFormToInvalidForms(this.form);
        }
      });
    }

    if (changes.disabled) {
      this._disabled.next(this.disabled);
    }

    if (changes.locationFeeGuideMode) {
      this._locationFeeGuideMode.next(this.locationFeeGuideMode);
    }
  }

  public ngOnDestroy(): void {
    this.formValueSubscription?.unsubscribe();
    this.formStatusSubscription?.unsubscribe();
    this.formDisabledSubscription?.unsubscribe();
  }

}
