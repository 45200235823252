import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Employee} from '../../../../models/employee.model';
import {PasswordChange} from '../../../../models/passwordChange.model';
import {EmployeeService} from '../../../../services/employee.service';
import {SessionService} from '../../../../services/session.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import UserUtils from '../../utils/user-utils';


@Component({
    selector: 'app-password-reset-modal',
    templateUrl: './password-reset-modal.component.html',
    styleUrls: ['./password-reset-modal.component.scss', '../../../stylesheet/stylesheet.component.scss']
})
export class PasswordResetModalComponent implements OnInit {
    generatedPassword = '';
    loading = false;
    loadingText = $localize`Changing Password`;
    employee: Employee;
    passwordPattern: string = '^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])(?=.*[\\`\\!\\%\\@\\#\\$\\%\\&\\^\\,\\+\\=\\.\\*\\(\\)\\?\\-\\_]).{8,}$';
    changingPassword: boolean = false;
    requireChange: boolean = false;
    changePasswordForm = new FormGroup({
        currentPassword: new FormControl('', [Validators.required, Validators.pattern(this.passwordPattern)]),
        newPassword: new FormControl('', [Validators.required, Validators.pattern(this.passwordPattern)]),
        confirmPassword: new FormControl('', [Validators.required, Validators.pattern(this.passwordPattern)])
    });

    constructor(private activeModal: NgbActiveModal,
                private employeeService: EmployeeService,
                private sessionService: SessionService,
                private toastr: ToastrService) {
    }

    ngOnInit() {

    }

    initWith(employee: Employee, changingPassword: boolean, requireChange: boolean = false) {
        this.employee = employee;
        this.changingPassword = changingPassword;
        this.requireChange = requireChange;
    }

    resetPassword() {
        this.loading = true;
        this.generatedPassword = UserUtils.generateNewPassword();
        this.employeeService.resetPassword(this.sessionService.getCompanyId(), this.employee.userName, this.generatedPassword).subscribe(() => {
            this.loading = false;
        }, error => {
            this.toastr.error(error.statusText, $localize`Password reset failed. Please try again.`);
            this.loading = false;
        });
    }

    changePassword() {
        const passwordObject = new PasswordChange();
        passwordObject.currentPassword = this.changePasswordForm.controls.currentPassword.value;
        passwordObject.newPassword = this.changePasswordForm.controls.newPassword.value;
        this.loading = true;
        this.employeeService.changePassword(this.sessionService.getCompanyId().toString(), this.employee.userName, passwordObject).subscribe(() => {
            this.loading = false;
            this.toastr.success($localize`Password Changed Successfully`);
            this.activeModal.close(true);
        }, err => {
            this.loading = false;
            this.toastr.error(err.statusText, $localize`Unable to change password`);
            console.log(err);
        });
    }

    closeModal(passwordChanged: boolean = false) {
        this.activeModal.close(passwordChanged);
    }

    getLocalizedTitle(): string {
        return this.requireChange ? $localize`New Password Required` : `Set New Password`;
    }
    getLocalizedPasswordCreatedText(): string {
        return $localize`${this.employee?.primaryName}:primaryName: ${this.employee?.familyName}:familyName: will only be able to log in to their account using this username and password. Admin users can edit certain information on this account at any time from the Employees tab.`;
    }
}
