import {Deserializable} from '../views/shared/utils/json-utils';

export class CompanyDuplicateCheck implements Deserializable {
    public name: string;
    public latitude: number;
    public longitude: number;
    public duplicateCompany: boolean;

    onDeserialize() {
    }
}
