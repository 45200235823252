import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {ProfileComponent} from './profile.component';
import {CanDeactivateGuardService} from '../../services/can-deactivate-guard.service';

const routes: Routes = [
    {
        path: '',
        component: ProfileComponent,
        data: {
            title: 'Profile'
        },
        canDeactivate: [CanDeactivateGuardService],
    },
    {
        path: ':userId',
        component: ProfileComponent,
        data: {
            title: 'User'
        },
        canDeactivate: [CanDeactivateGuardService],
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ProfileRoutingModule {
}
