<div class="modal-body">
    <div class="modal-title">{{titleText}}</div>
    <div class="mt-3 pt-1">
        <app-file-upload
                [files]="files"
                [handleFileAdded]="handleFileAdded">
        </app-file-upload>
    </div>
    <div class="footer-button">
        <button class="teth-button grey-button" (click)="closeModal()" i18n>Cancel</button>
        <button class="teth-button blue-button ml-3" [hidden]="files.length < 1" (click)="apply()" i18n>Save</button>
    </div>
</div>
