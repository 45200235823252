<div class="releaseNote-date mb-3">
    {{formatReleaseNoteDate(releaseNote.releaseDate)}} - <span class="releaseNote-version">{{releaseNote.version}}</span>
</div>
<ng-container *ngIf="filterReleaseNoteChangesByType(releaseNote.releaseNotes, 'NewLocation').length > 0">
    <span i18n>New Locations</span>
    <ul>
        <li *ngFor="let changes of filterReleaseNoteChangesByType(releaseNote.releaseNotes, 'NewLocation')">{{changes.description}}</li>
    </ul>
</ng-container>
<ng-container *ngIf="filterReleaseNoteChangesByType(releaseNote.releaseNotes, 'Feature').length > 0">
    <span i18n>Features</span>
    <ul>
        <li *ngFor="let changes of filterReleaseNoteChangesByType(releaseNote.releaseNotes, 'Feature')">{{changes.description}}</li>
    </ul>
</ng-container>
<ng-container *ngIf="filterReleaseNoteChangesByType(releaseNote.releaseNotes, 'BugFix').length > 0">
    <span i18n>Bug Fixes</span>
    <ul>
        <li *ngFor="let changes of filterReleaseNoteChangesByType(releaseNote.releaseNotes, 'BugFix')">{{changes.description}}</li>
    </ul>
</ng-container>
