<div class="simple-card">
    <mat-expansion-panel [expanded]="expanded">
        <mat-expansion-panel-header [collapsedHeight]="'9rem'" [expandedHeight]="'10.5rem'">
            <div class="d-flex flex-column w-100" [style.gap.rem]="1">
                <div class="procedure-name">{{ procedure?.procedure }}</div>
                <app-fee-guide-default-average-value-form
                        [procedure]="procedure"
                        [disabled]="feeGuideExpired"
                        [locationFeeGuideMode]="locationFeeGuideMode">
                </app-fee-guide-default-average-value-form>
            </div>
        </mat-expansion-panel-header>
        <div class="divider mt-0" [style.padding-right.rem]="0.5"></div>
        <ng-container *ngIf="procedure?.fees?.length; else noFees">
            <div class="d-flex flex-column" [style.padding-right.rem]="0.5" [style.gap.rem]="1.5">
                <app-edit-fee-guide-procedure-fee-form
                        *ngFor="let procedureFee of procedure?.fees"
                        [disabled]="feeGuideExpired"
                        [locationFeeGuideMode]="locationFeeGuideMode"
                        [procedureFee]="procedureFee">
                </app-edit-fee-guide-procedure-fee-form>
            </div>
        </ng-container>
        <ng-template #noFees>
            <div class="empty-state-text" i18n>No variants have been added for this procedure</div>
        </ng-template>
    </mat-expansion-panel>
</div>
