import {Deserializable} from '../views/shared/utils/json-utils';
import * as moment from 'moment';

export class Attachment implements Deserializable {
    public id: string;
    public attachmentType: string;
    public attachmentTypeId: number;
    public fileName: string;
    public mediaType: string;
    public links: AttachmentLink[];
    public createdBy: string;
    public createdById: number;
    public createdDate: Date;
    public imageTakenDate: Date;

    onDeserialize() {
        this.createdDate = moment.utc(this.createdDate.toString()).toDate();
        this.imageTakenDate = this.imageTakenDate ? moment.utc(this.imageTakenDate.toString()).toDate() : null;
    }
}

export class AttachmentLink {
    static OriginalSize = 'original';
    public size: string;
    public presignedUrl: string;
}
