import {Deserializable} from '../views/shared/utils/json-utils';

export class Role implements Deserializable {
    public id: number;
    public companyId: number;
    public name: string;
    public enabled: boolean;
    public permissions: RolePermission[];

    onDeserialize() {
    }
}

export class RolePermission {
    permissionId: number;
    permission: string;
}
