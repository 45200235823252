import {ReferralNotification} from './referral-notification.model';

export class ReferralUpdatedNotification extends ReferralNotification {
    public propertyModified: string;
    public originalValue: string;
    public newValue: string;

    onDeserialize() {
        super.onDeserialize();
    }
}

export class LabReferralUpdatedNotification extends ReferralUpdatedNotification {
    public productType: string;

    onDeserialize() {
    }
}
