<div class="modal-body">
    <app-loading
            [determinateLoading]="false"
            [hidden]="!(loading$|async)"
            [loadingText]="null"
            [fullscreen]="false"
            [backgroundColor]="'#FDFDFD'"
            [color]="'#388EFC'">
    </app-loading>
    <ng-container *ngIf="!(selectedReferringDentist|async)">
        <div class="default-modal-title mb-3" i18n>Add Manual Referral</div>
        <div class="default-modal-subtitle mb-3" i18n>
            Add a referral that was received from somewhere else in order to keep track of it in Tetherport.
        </div>
        <div class="row">
            <div class="col px-0">
                <app-type-ahead-search
                        fieldLabel="Search Referring Dentist"
                        fieldPlaceholder="Search by Dentist or Clinic"
                        i18n-fieldPlaceholder
                        i18n-fieldLabel
                        [customClass]="'manual-referral'"
                        [searchObjects]="referringDentistSearchItems$|async"
                        [resultsDisplayConfiguration]="'{displayValue}'"
                        [resultIconPropertyName]="'typeaheadResultIconSrc'"
                        [resultActionButtonText]="createNewText"
                        [searchableProperties]="getSearchableTypeaheadProperties()"
                        [clearSearch]="clearTypeaheadInput"
                        (itemSelected)="typeaheadItemSelected($event)"
                        (resultsActionButtonEvent)="createNewDentist($event)">
                </app-type-ahead-search>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="(selectedReferringDentist|async)">
        <div (click)="backButtonClicked()"
             class="button-back">
            Back
        </div>
        <div class="default-modal-title mb-3" i18n>Add Referral Details</div>
        <div class="section-title mb-3" i18n>
            Location
        </div>
        <label class="teth-textfield-label" for="locationsDropdown" i18n>Choose Specialist Location</label>
        <select class="teth-dropdown teth-textfield-dropdown mb-3"
                name="locationsDropdown"
                id="locationsDropdown"
                [ngModel]="selectedSpecialistLocation|async"
                (ngModelChange)="specialistLocationSelected($event)">
            <option *ngFor="let specialistLocation of locations"
                    [ngValue]="specialistLocation">
                {{specialistLocation.name}}
            </option>
        </select>
        <div class="section-title mb-3" i18n>
            Type
        </div>
        <div class="specialty-option-container"
             *ngFor="let specialty of (availableSpecialties$|async)"
             (click)="this.clickedSpecialty(specialty)"
             [class.selected]="(selectedSpecialty|async) && (selectedSpecialty|async).specialtyTypeId === specialty.specialtyTypeId">
            <div class="specialty-option-text">
                <div class="specialty-value">
                    {{specialty.specialty}}
                </div>
            </div>
            <div class="specialty-checkbox" [ngClass]="{'checked': (selectedSpecialty|async) && (selectedSpecialty|async).specialtyTypeId === specialty.specialtyTypeId}">
                <img [src]="'assets/img/icons/check-white.svg'" alt="">
            </div>
        </div>
        <div class="section-title mb-3" i18n>
            Procedure
        </div>
        <div class="section-description mb-3" *ngIf="!(selectedSpecialty|async)" i18n>
            Choose a type in order to select a procedure.
        </div>
        <div *ngIf="(availableProcedures$|async)">
            <div *ngFor="let procedure of (availableProcedures$|async)">

            </div>
            <form class="teth-textfield-form checkbox-container">
                <label class="teth-checkbox-container col-6 mb-4"
                       *ngFor="let procedure of (availableProcedures$|async)">
                    <input type="checkbox" class="teth-checkbox"
                           id="{{procedure.id}}"
                           name="{{procedure.name}}"
                           [checked]="isProcedureSelected(procedure)"
                           (click)="toggleProcedure(procedure)">
                    <span class="checkBox-label">{{procedure.name}}</span>
                    <span class="teth-checkmark"></span>
                </label>
            </form>
        </div>
        <div class="section-title mb-3" i18n>
            Specialist
        </div>
        <div class="section-description" [hidden]="(selectedProcedures|async)?.length > 0" i18n>
            Type and procedure must be selected in order to view available specialists.
        </div>
        <label class="teth-textfield-label" for="specialistDropdown" i18n>Choose Specialist</label>
        <select class="teth-dropdown teth-textfield-dropdown mb-3"
                name="specialistDropdown"
                id="specialistDropdown"
                [ngModel]="selectedSpecialist|async"
                (ngModelChange)="specialistSelected($event)"
                [hidden]="!(selectedProcedures|async) || (selectedProcedures|async).length === 0">
            <option [ngValue]="null" selected disabled hidden i18n>Select a Specialist</option>
            <option *ngFor="let specialist of (availableSpecialists$|async)"
                    [ngValue]="specialist">
                {{specialist.prefix}} {{specialist.primaryName}} {{specialist.familyName}}
            </option>
        </select>
    </ng-container>
    <div class="default-modal-button-footer mt-4">
        <button class="teth-button grey-button" (click)="close()" i18n>Cancel</button>
        <button class="teth-button blue-button ml-3"
                [class.disabled]="!continueButtonEnabled()"
                (click)="continueButtonClicked()" i18n>Continue
        </button>
    </div>
</div>
