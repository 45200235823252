import {Patient} from './patient.model';
import {Stage} from './stage.model';
import {Deserializable, DeserializeHelper} from '../views/shared/utils/json-utils';
import * as moment from 'moment';

export class Referral implements Deserializable {
    public id: string;
    public referringPatient: Patient;
    public referringPatientId: string;
    public referredPatient: Patient;
    public referredPatientId: string;
    public recallPreference: string;
    public recallPreferenceId: number;
    public nextAppointment: Date;
    public teeth: string;
    public otherTeeth: string;
    public caseAccepted: boolean;
    public comments: string;
    public isUrgent: boolean;
    public implantType: string;
    public status: string;
    public stages: Stage[];
    public referringEmployeeId: number;
    public referringEmployee: string;
    public preferredSpecialistId: number;
    public preferredSpecialist: string;
    public assignedSpecialistId: number;
    public assignedSpecialist: string;
    public lastSyncedDate: Date;
    public viewedDate: Date;
    public viewedById: number;
    public viewedBy: string;
    public createdDate: Date;
    public createdById: number;
    public createdBy: string;
    public specialtyTypeId: number;
    public specialtyType: string;
    public referringCompanyId: number;
    public referringLocationId: number;
    public referringLocation: string;
    public referredCompanyId: number;
    public referredLocationId: number;
    public referredLocation: string;
    public implantTypeId: number;
    public statusTypeId: number;
    public updatedDate: Date;
    public updatedById: number;
    public updatedBy: string;
    public revenueValue: number;
    public waitingStatusReasonId: number;
    public waitingStatusReason: string;
    public externalReferringDentist: string;
    public externalReferral: boolean;

    // non api model properties
    public highSortPriority: boolean = false;

    onDeserialize() {
        this.createdDate = moment.utc(this.createdDate.toString()).toDate();
        this.stages = DeserializeHelper.arrayOf(Stage, this.stages);
        this.stages?.sort((a, b) => a?.stageId - b?.stageId);
        this.highSortPriority = this.isUrgent && this.statusTypeId < 4;
        if (this.referringPatient) {
            this.referringPatient = DeserializeHelper.deserializeToInstance(Patient, this.referringPatient);
        }
        if (this.referredPatient) {
            this.referredPatient = DeserializeHelper.deserializeToInstance(Patient, this.referredPatient);
        }
        if (this.externalReferringDentist?.length > 0 && this.externalReferral) {
            this.referringEmployee = this.externalReferringDentist;
        }
    }

    public revenueValueIsEstimated(): boolean {
        return !this.stages?.some(stage => {
            return stage?.appointments?.some(appointment => appointment?.fees?.length)
        });
    }
}

