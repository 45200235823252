export default class ToothUtils {
    public static toothLocaleCanada = 'CAN';
    public static toothLocaleUSA = 'US';

    static toothConversion = {
        18: '1',
        17: '2',
        16: '3',
        15: '4',
        14: '5',
        13: '6',
        12: '7',
        11: '8',
        21: '9',
        22: '10',
        23: '11',
        24: '12',
        25: '13',
        26: '14',
        27: '15',
        28: '16',
        38: '17',
        37: '18',
        36: '19',
        35: '20',
        34: '21',
        33: '22',
        32: '23',
        31: '24',
        41: '25',
        42: '26',
        43: '27',
        44: '28',
        45: '29',
        46: '30',
        47: '31',
        48: '32',
        // Primary Teeth
        55: 'A',
        54: 'B',
        53: 'C',
        52: 'D',
        51: 'E',
        61: 'F',
        62: 'G',
        63: 'H',
        64: 'I',
        65: 'J',
        75: 'K',
        74: 'L',
        73: 'M',
        72: 'N',
        71: 'O',
        81: 'P',
        82: 'Q',
        83: 'R',
        84: 'S',
        85: 'T',
    };

    static CAN_US_ADULT = {
        18: 1,
        17: 2,
        16: 3,
        15: 4,
        14: 5,
        13: 6,
        12: 7,
        11: 8,
        21: 9,
        22: 10,
        23: 11,
        24: 12,
        25: 13,
        26: 14,
        27: 15,
        28: 16,
        38: 17,
        37: 18,
        36: 19,
        35: 20,
        34: 21,
        33: 22,
        32: 23,
        31: 24,
        41: 25,
        42: 26,
        43: 27,
        44: 28,
        45: 29,
        46: 30,
        47: 31,
        48: 32
    };

    static US_CAN_ADULT = {
        1: 18,
        2: 17,
        3: 16,
        4: 15,
        5: 14,
        6: 13,
        7: 12,
        8: 11,
        9: 21,
        10: 22,
        11: 23,
        12: 24,
        13: 25,
        14: 26,
        15: 27,
        16: 28,
        17: 38,
        18: 37,
        19: 36,
        20: 35,
        21: 34,
        22: 33,
        23: 32,
        24: 31,
        25: 41,
        26: 42,
        27: 43,
        28: 44,
        29: 45,
        30: 46,
        31: 47,
        32: 48
    };

    static CAN_US_PRIMARY = {
        // Primary Teeth
        55: 'A',
        54: 'B',
        53: 'C',
        52: 'D',
        51: 'E',
        61: 'F',
        62: 'G',
        63: 'H',
        64: 'I',
        65: 'J',
        75: 'K',
        74: 'L',
        73: 'M',
        72: 'N',
        71: 'O',
        81: 'P',
        82: 'Q',
        83: 'R',
        84: 'S',
        85: 'T',
    };
    static US_CAN_PRIMARY = {
        // Primary Teeth
        'A': 55,
        'B': 54,
        'C': 53,
        'D': 52,
        'E': 51,
        'F': 61,
        'G': 62,
        'H': 63,
        'I': 64,
        'J': 65,
        'K': 75,
        'L': 74,
        'M': 73,
        'N': 72,
        'O': 71,
        'P': 81,
        'Q': 82,
        'R': 83,
        'S': 84,
        'T': 85,
    };

    static localizeTooth(tooth: number, locale: string = this.toothLocaleCanada): string {
        if (locale === 'CAN') {
            return `${tooth}`;
        } else {
            return this.toothConversion[tooth];
        }
    }

    static isCanPermanent(tooth: number): boolean {
        return !!this.CAN_US_ADULT[tooth];
    }

    static isCanPrimary(tooth: number): boolean {
        return !!this.CAN_US_PRIMARY[tooth];
    }

    static canToUsPermanent(tooth: number): number {
        return this.CAN_US_ADULT[tooth];
    }

    static usToCanPermanent(tooth: number): number {
        return this.US_CAN_ADULT[tooth];
    }

    static canToUsPrimary(tooth: number): string {
        return this.CAN_US_PRIMARY[tooth];
    }

    static usToCanPrimary(tooth: string): number {
        return this.US_CAN_PRIMARY[tooth];
    }

}
