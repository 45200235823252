<main>
    <div class="sign-up-container">
        <div class="splash-img-container">
            <img [src]="'assets/img/brand/teth-sign-in-splash.png'"
                 class="splash-img"
                 alt="splash-img">
            <img [src]="'assets/img/brand/tetherport-logo.svg'"
                 alt="splash-logo"
                 class="splash-logo">
            <div class="language" (click)="changeLanguage()" i18n>Français</div>
        </div>
        <div class="sign-up-form-container">
            <ng-container *ngIf="!creating && !createSuccess">
                <div class="sign-up-body">
                    <div class="sign-up-steps">
                        <div class="mr-4">{{getStepText()}}</div>
                        <div class="step-circle"
                             *ngFor="let step of stepsArray"
                             [ngClass]="{'completed': step <= currentStep}">
                            <div class="current-step" *ngIf="step === currentStep">
                            </div>
                        </div>
                    </div>
                    <div [hidden]="currentStep !== 1">
                        <div class="step-title" i18n>Account Information</div>
                        <div class="step-description" i18n>
                            To start your set up with Tetherport as a general dental clinic, we just need to know a few details about your company. Are you looking to join Tetherport as a specialist clinic or dental lab? Please email us at onboarding@tetherport.ca to start the process.
                        </div>
                        <form class="teth-textfield-form px-0"
                              autocomplete="off"
                              [formGroup]="companyInfoForm">
                            <div class="row">
                                <div class="col-6"
                                     [class.error]="companyInfoForm.controls.name.invalid && (companyInfoForm.controls.name.dirty || companyInfoForm.controls.name.touched)">
                                    <label class="teth-textfield-label" for="orgName" i18n>
                                        Company Name
                                    </label>
                                    <input class="teth-textfield"
                                           type="text"
                                           id="orgName"
                                           name="orgName"
                                           placeholder="Name"
                                           i18n-placeholder
                                           autocomplete="off"
                                           formControlName="name">
                                    <span class="teth-textfield-error" i18n>Company name is required.</span>
                                </div>
                                <div class="col-6"
                                     [class.error]="companyInfoForm.controls.phoneNumber.invalid && (companyInfoForm.controls.phoneNumber.dirty || companyInfoForm.controls.phoneNumber.touched)">
                                    <label class="teth-textfield-label"
                                           for="phoneNumber"
                                           i18n>Phone Number</label>
                                    <input class="teth-textfield"
                                           type="text"
                                           id="phoneNumber"
                                           name="phoneNumber"
                                           autocomplete="off"
                                           placeholder="1 (000) 000-0000"
                                           [textMask]="{mask: phoneUtils.phoneMask}"
                                           formControlName="phoneNumber">
                                    <span class="teth-textfield-error"
                                          i18n>Valid phone number is required.</span>
                                </div>
                                <div class="col-6">
                                    <app-auto-complete-address
                                            i18n-label
                                            label="Company Address"
                                            [countryData]="countries"
                                            (addressSelected)="autoCompleteAddressSelected($event, companyInfoForm)"
                                            [control]="$any(companyInfoForm.controls.address1)">
                                    </app-auto-complete-address>
                                </div>
                                <div class="col-6"
                                     [class.error]="companyInfoForm.controls.address2.invalid && (companyInfoForm.controls.address2.dirty || companyInfoForm.controls.address2.touched)">
                                    <label class="teth-textfield-label"
                                           for="address2"
                                           i18n>Company Address 2</label>
                                    <input class="teth-textfield"
                                           type="text"
                                           id="address2"
                                           name="address2"
                                           placeholder="Address"
                                           i18n-placeholder
                                           autocomplete="off"
                                           formControlName="address2">
                                    <span class="teth-textfield-error">&nbsp;</span>
                                </div>
                                <div class="col-6"
                                     [class.error]="companyInfoForm.controls.postalCode.invalid && (companyInfoForm.controls.postalCode.dirty || companyInfoForm.controls.postalCode.touched)">
                                    <label class="teth-textfield-label"
                                           for="postalCode"
                                           i18n>Postal Code/Zip Code</label>
                                    <input class="teth-textfield"
                                           type="text"
                                           id="postalCode"
                                           name="postalCode"
                                           placeholder="Code"
                                           i18n-placeholder
                                           autocomplete="off"
                                           formControlName="postalCode">
                                    <span class="teth-textfield-error" i18n>Postal Code is required.</span>
                                </div>
                                <div class="col-6"
                                     [class.error]="companyInfoForm.controls.city.invalid && (companyInfoForm.controls.city.dirty || companyInfoForm.controls.city.touched)">
                                    <label class="teth-textfield-label" for="city" i18n>City</label>
                                    <input class="teth-textfield"
                                           type="text"
                                           id="city"
                                           name="city"
                                           placeholder="City"
                                           i18n-placeholder
                                           autocomplete="off"
                                           formControlName="city">
                                    <span class="teth-textfield-error" i18n>City is required.</span>
                                </div>
                                <div class="col-6"
                                     [class.error]="companyInfoForm.controls.state.invalid && (companyInfoForm.controls.state.dirty || companyInfoForm.controls.state.touched)">
                                    <label class="teth-textfield-label" for="state"
                                           i18n>Province/State</label>
                                    <select class="teth-dropdown teth-textfield-dropdown"
                                            name="state"
                                            id="state"
                                            formControlName="state">
                                        <option [value]="null" selected disabled hidden
                                                i18n>Select State/Province
                                        </option>
                                        <option *ngFor="let state of states"
                                                value="{{state.id}}">{{state.name}}</option>
                                    </select>
                                    <span class="teth-textfield-error" i18n>Select a province/state.</span>
                                </div>
                                <div class="col-6"
                                     [class.error]="companyInfoForm.controls.country.invalid && (companyInfoForm.controls.country.dirty || companyInfoForm.controls.country.touched)">
                                    <label class="teth-textfield-label" for="country" i18n>Country</label>
                                    <select class="teth-dropdown teth-textfield-dropdown"
                                            name="country"
                                            id="country"
                                            formControlName="country"
                                            (change)="filterCountryStates($any($event.target).value*1)">
                                        <option [value]="null" selected disabled hidden i18n>Select Country
                                        </option>
                                        <option *ngFor="let country of countries"
                                                [value]="country.id">{{country.name}}</option>
                                    </select>
                                    <span class="teth-textfield-error" i18n>Country is required.</span>
                                </div>
                            </div>
                        </form>
                        <div class="divider mt-0">
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <form class="d-flex flex-column px-0" autocomplete="off">
                                    <div class="teth-radio-option teth-radio-option-inline mb-4 px-0">
                                        <input class="teth-radio" type="radio"
                                               name="companyAddressForClinic"
                                               id="UseCompanyAddress"
                                               autocomplete="off"
                                               [(ngModel)]="useCompanyAddressForClinic"
                                               [value]="true">
                                        <span class="teth-custom-radio"></span>
                                        <label class="teth-radio-label"
                                               for="UseCompanyAddress"
                                               i18n>Our clinic is physically located at the address above</label>
                                    </div>
                                    <div class="teth-radio-option teth-radio-option-inline mb-4 px-0">
                                        <input class="teth-radio" type="radio"
                                               name="companyAddressForClinic"
                                               id="UseOtherAddress"
                                               autocomplete="off"
                                               [(ngModel)]="useCompanyAddressForClinic"
                                               [value]="false">
                                        <span class="teth-custom-radio"></span>
                                        <label class="teth-radio-label"
                                               for="UseOtherAddress"
                                               i18n>Our clinic is located at a different address</label>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <form class="teth-textfield-form px-0" [formGroup]="clinicInfoForm"
                              autocomplete="off"
                              [hidden]="useCompanyAddressForClinic">
                            <div class="row">
                                <div class="col-6"
                                     [class.error]="clinicInfoForm.controls.name.invalid && (clinicInfoForm.controls.name.dirty || clinicInfoForm.controls.name.touched)">
                                    <label class="teth-textfield-label"
                                           for="clinicName"
                                           i18n>Clinic Name</label>
                                    <input class="teth-textfield"
                                           type="text"
                                           id="clinicName"
                                           name="clinicName"
                                           placeholder="Clinic Name"
                                           i18n-placeholder
                                           autocomplete="off"
                                           formControlName="name">
                                    <span class="teth-textfield-error" i18n>Clinic name is required.</span>
                                </div>
                                <div class="col-6"
                                     [class.error]="clinicInfoForm.controls.phoneNumber.invalid && (clinicInfoForm.controls.phoneNumber.dirty || clinicInfoForm.controls.phoneNumber.touched)">
                                    <label class="teth-textfield-label"
                                           for="clinicPhoneNumber"
                                           i18n>Clinic Phone Number</label>
                                    <input class="teth-textfield" type="text" id="clinicPhoneNumber"
                                           name="clinicPhoneNumber"
                                           placeholder="1 (000) 000-0000"
                                           autocomplete="off"
                                           [textMask]="{mask: phoneUtils.phoneMask}"
                                           formControlName="phoneNumber">
                                    <span class="teth-textfield-error"
                                          i18n>Valid phone number is required.</span>
                                </div>
                                <div class="col-6">
                                    <app-auto-complete-address
                                            i18n-label
                                            label="Clinic Address"
                                            [countryData]="countries"
                                            (addressSelected)="autoCompleteAddressSelected($event, clinicInfoForm)"
                                            [control]="$any(clinicInfoForm.controls.address1)">
                                    </app-auto-complete-address>
                                </div>
                                <div class="col-6"
                                     [class.error]="clinicInfoForm.controls.address2.invalid && (clinicInfoForm.controls.address2.dirty || clinicInfoForm.controls.address2.touched)">
                                    <label class="teth-textfield-label"
                                           for="clinicAddress2"
                                           i18n>Clinic Address 2</label>
                                    <input class="teth-textfield" type="text" id="clinicAddress2"
                                           name="clinicAddress2"
                                           placeholder="Address"
                                           i18n-placeholder
                                           autocomplete="off"
                                           formControlName="address2">
                                    <span class="teth-textfield-error">&nbsp;</span>
                                </div>
                                <div class="col-6"
                                     [class.error]="clinicInfoForm.controls.postalCode.invalid && (clinicInfoForm.controls.postalCode.dirty || clinicInfoForm.controls.postalCode.touched)">
                                    <label class="teth-textfield-label"
                                           for="clinicPostalCode"
                                           i18n>Postal Code/Zip Code</label>
                                    <input class="teth-textfield"
                                           type="text"
                                           id="clinicPostalCode"
                                           name="clinicPostalCode"
                                           placeholder="Code"
                                           i18n-placeholder
                                           autocomplete="off"
                                           formControlName="postalCode">
                                    <span class="teth-textfield-error" i18n>Postal Code is required.</span>
                                </div>
                                <div class="col-6"
                                     [class.error]="clinicInfoForm.controls.city.invalid && (clinicInfoForm.controls.city.dirty || clinicInfoForm.controls.city.touched)">
                                    <label class="teth-textfield-label" for="clinicCity" i18n>City</label>
                                    <input class="teth-textfield"
                                           type="text"
                                           id="clinicCity"
                                           name="clinicCity"
                                           placeholder="City"
                                           i18n-placeholder
                                           autocomplete="off"
                                           formControlName="city">
                                    <span class="teth-textfield-error" i18n>City is required.</span>
                                </div>
                                <div class="col-6"
                                     [class.error]="clinicInfoForm.controls.state.invalid && (clinicInfoForm.controls.state.dirty || clinicInfoForm.controls.state.touched)">
                                    <label class="teth-textfield-label" for="clinicState"
                                           i18n>Province/State</label>
                                    <select class="teth-dropdown teth-textfield-dropdown"
                                            name="clinicState"
                                            id="clinicState"
                                            formControlName="state">
                                        <option [value]="null" selected disabled hidden
                                                i18n>Select State/Province
                                        </option>
                                        <option *ngFor="let state of states"
                                                value="{{state.id}}">{{state.name}}</option>
                                    </select>
                                    <span class="teth-textfield-error" i18n>Select a province/state.</span>
                                </div>
                                <div class="col-6"
                                     [class.error]="clinicInfoForm.controls.country.invalid && (clinicInfoForm.controls.country.dirty || clinicInfoForm.controls.country.touched)">
                                    <label class="teth-textfield-label" for="clinicCountry" i18n>Country</label>
                                    <select class="teth-dropdown teth-textfield-dropdown"
                                            name="clinicCountry"
                                            id="clinicCountry"
                                            formControlName="country"
                                            (change)="filterCountryStates($any($event.target).value*1)">
                                        <option [value]="null" selected disabled hidden i18n>
                                            Select Country
                                        </option>
                                        <option *ngFor="let country of countries"
                                                [value]="country.id">{{country.name}}</option>
                                    </select>
                                    <span class="teth-textfield-error" i18n>Country is required.</span>
                                </div>
                            </div>
                        </form>
                        <div class="divider mt-0">
                        </div>
                    </div>
                    <div [hidden]="currentStep !== 2">
                        <div class="step-title" i18n>System Administrator Contact</div>
                        <div class="step-description" i18n>Each Tetherport account has a system
                            administrator. This individual is able to make changes to company settings and
                            add staff members to the company. The system administrator can be changed later
                            if needed.
                        </div>
                        <form class="teth-textfield-form px-0"
                              [formGroup]="adminUserForm">
                            <div class="row">
                                <div class="col-6"
                                     [class.error]="adminUserForm.controls.firstName.invalid && (adminUserForm.controls.firstName.dirty || adminUserForm.controls.firstName.touched)">
                                    <label class="teth-textfield-label" for="firstName" i18n>
                                        First Name
                                    </label>
                                    <input class="teth-textfield"
                                           type="text"
                                           id="firstName"
                                           name="firstName"
                                           placeholder="Name"
                                           i18n-placeholder
                                           autocomplete="off"
                                           formControlName="firstName">
                                    <span class="teth-textfield-error" i18n>First name is required.</span>
                                </div>
                                <div class="col-6"
                                     [class.error]="adminUserForm.controls.lastName.invalid && (adminUserForm.controls.lastName.dirty || adminUserForm.controls.lastName.touched)">
                                    <label class="teth-textfield-label" for="lastName" i18n>
                                        Last Name
                                    </label>
                                    <input class="teth-textfield"
                                           type="text"
                                           id="lastName"
                                           name="lastName"
                                           placeholder="Name"
                                           i18n-placeholder
                                           autocomplete="off"
                                           formControlName="lastName">
                                    <span class="teth-textfield-error" i18n>Last name is required.</span>
                                </div>
                                <div class="col-6"
                                     [class.error]="adminUserForm.controls.prefix.invalid && (adminUserForm.controls.prefix.dirty || adminUserForm.controls.prefix.touched)">
                                    <label class="teth-textfield-label" for="prefix" i18n>Prefix</label>
                                    <select class="teth-dropdown teth-textfield-dropdown"
                                            name="prefix" id="prefix"
                                            formControlName="prefix">
                                        <option value="" selected disabled hidden i18n>Choose your prefix
                                        </option>
                                        <option *ngFor="let prefix of prefixes">
                                            {{prefix}}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-6"
                                     [class.error]="adminUserForm.controls.username.invalid && (adminUserForm.controls.username.dirty || adminUserForm.controls.username.touched)">
                                    <label class="teth-textfield-label" for="username" i18n>
                                        Username
                                    </label>
                                    <input class="teth-textfield"
                                           type="text"
                                           id="username"
                                           name="username"
                                           placeholder="Username"
                                           i18n-placeholder
                                           autocomplete="off"
                                           formControlName="username">
                                    <span class="teth-textfield-error" i18n>Valid username is required.</span>
                                </div>
                                <div class="col-6"
                                     [class.error]="adminUserForm.controls.password.invalid && (adminUserForm.controls.password.dirty || adminUserForm.controls.password.touched)">
                                    <label class="teth-textfield-label" for="password" i18n>Password</label>
                                    <input class="teth-textfield"
                                           type="password"
                                           id="password"
                                           name="password"
                                           placeholder="Password"
                                           i18n-placeholder
                                           autocomplete="off"
                                           formControlName="password">
                                    <span class="teth-textfield-error error"
                                          [class.invisible]="!adminUserForm.errors?.passwordRequirementsNotMet || !(adminUserForm.controls.password.dirty || adminUserForm.controls.password.touched)"
                                          i18n>Passwords require 8 characters, at least 1 uppercase and lowercase, at least 1 number, and at least 1 special character
                                </span>
                                </div>
                                <div class="col-6"
                                     [class.error]="adminUserForm.controls.confirmPassword.invalid && (adminUserForm.controls.confirmPassword.dirty || adminUserForm.controls.confirmPassword.touched)">
                                    <label class="teth-textfield-label"
                                           for="confirmPassword"
                                           i18n>Confirm Password</label>
                                    <input class="teth-textfield"
                                           type="password"
                                           id="confirmPassword"
                                           name="confirmPassword"
                                           placeholder="Password"
                                           i18n-placeholder
                                           autocomplete="off"
                                           formControlName="confirmPassword">
                                    <span class="teth-textfield-error error"
                                          [class.invisible]="!adminUserForm.errors?.passwordNotMatching || !(adminUserForm.controls.confirmPassword.dirty || adminUserForm.controls.confirmPassword.touched)"
                                          i18n>Passwords do not match</span>
                                </div>
                                <div class="col-6"
                                     [class.error]="adminUserForm.controls.isDentist.invalid && (adminUserForm.controls.isDentist.dirty || adminUserForm.controls.isDentist.touched)">
                                    <label class="teth-checkbox-container" for="isDentist">
                                        <input type="checkbox"
                                               class="teth-checkbox"
                                               id="isDentist"
                                               name="isDentist"
                                               formControlName="isDentist">
                                        <span class="teth-checkbox-label ml-2" i18n>Is this user a dentist?</span>
                                        <span class="teth-checkmark"></span>
                                        <img class="help-icon"
                                             [src]="'assets/img/icons/help-filled.svg'"
                                             [ngbTooltip]="getLocalizedDentistTooltip()"
                                             alt="Help">
                                    </label>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div [hidden]="currentStep !== 3">
                        <div class="step-title" i18n>Client Agreement</div>
                        <div class="step-description" i18n>
                            In order to proceed, you need to accept the Tetherport End User Licence Agreement and
                            Privacy Policy by checking the items below.
                        </div>
                        <form class="teth-textfield-form px-0"
                              autocomplete="off"
                              [formGroup]="agreementForm">
                            <div class="row">
                                <div class="col-12">
                                    <div class="divider"></div>
                                </div>
                                <div class="col-12 d-flex"
                                     [class.error]="agreementForm.controls.eula.invalid && (agreementForm.controls.eula.dirty || agreementForm.controls.eula.touched)">
                                    <label class="teth-checkbox-container my-2" for="eula">
                                        <input type="checkbox" class="teth-checkbox" id="eula" name="eula"
                                               formControlName="eula">
                                        <span class="teth-checkmark"></span>
                                    </label>
                                    <span class="agreement-link ml-2"
                                          (click)="viewEULA()"
                                          i18n>End User Licence Agreement</span>
                                </div>
                                <div class="col-12">
                                    <div class="divider"></div>
                                </div>
                                <div class="col-12 d-flex"
                                     [class.error]="agreementForm.controls.privacyPolicy.invalid && (agreementForm.controls.privacyPolicy.dirty || agreementForm.controls.privacyPolicy.touched)">
                                    <label class="teth-checkbox-container my-2" for="privacyPolicy">
                                        <input type="checkbox"
                                               class="teth-checkbox"
                                               id="privacyPolicy"
                                               name="privacyPolicy"
                                               formControlName="privacyPolicy">
                                        <span class="teth-checkmark"></span>
                                    </label>
                                    <span class="agreement-link ml-2"
                                          (click)="viewPrivacyPolicy()"
                                          i18n>Privacy Policy</span>
                                </div>
                                <div class="col-12">
                                    <div class="divider"></div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div [hidden]="currentStep !== 4">
                        <div class="step-title" i18n>Review your Information</div>
                        <div class="step-description" i18n>Make sure that all of these details are correct
                            before launching your clinic on Tetherport.
                        </div>
                        <div class="row">
                            <div class="col-12 review-section">
                                <div class="review-section-title" i18n>Account Information</div>
                                <div class="review-section-edit"
                                     (click)="currentStep = 1"
                                     i18n>Edit Section
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="review-title" i18n>Organization Name</div>
                                <div class="review-value">{{generatedCompany?.name}}</div>
                            </div>
                            <div class="col-4">
                                <div class="review-title" i18n>Phone Number</div>
                                <div class="review-value">{{generatedCompany?.phoneNumber}}</div>
                            </div>
                            <div class="col-4">
                                <div class="review-title" i18n>Company Address</div>
                                <div class="review-value">{{generatedCompany?.address?.addressLine1}}</div>
                            </div>
                            <div class="col-4">
                                <div class="review-title" i18n>Company Address 2</div>
                                <div class="review-value">{{generatedCompany?.address?.addressLine2}}</div>
                            </div>
                            <div class="col-4">
                                <div class="review-title" i18n>City</div>
                                <div class="review-value">{{generatedCompany?.address?.locality}}</div>
                            </div>
                            <div class="col-4">
                                <div class="review-title" i18n>ZIP/Postal Code</div>
                                <div class="review-value">{{generatedCompany?.address?.locality}}</div>
                            </div>
                            <div class="col-4">
                                <div class="review-title" i18n>Province/State</div>
                                <div class="review-value">{{generatedCompany?.address?.state}}</div>
                            </div>
                            <div class="col-4">
                                <div class="review-title" i18n>Country</div>
                                <div class="review-value">{{generatedCompany?.address?.country}}</div>
                            </div>
                            <div class="col-12">
                                <div class="divider"></div>
                            </div>
                            <div class="col-4">
                                <div class="review-title" i18n>Clinic Name</div>
                                <div class="review-value">{{generatedLocation?.name}}</div>
                            </div>
                            <div class="col-4">
                                <div class="review-title" i18n>Phone Number</div>
                                <div class="review-value">{{generatedLocation?.phoneNumber}}</div>
                            </div>
                            <div class="col-4">
                                <div class="review-title" i18n>Company Address</div>
                                <div class="review-value">{{generatedLocation?.address?.addressLine1}}</div>
                            </div>
                            <div class="col-4">
                                <div class="review-title" i18n>Company Address 2</div>
                                <div class="review-value">{{generatedLocation?.address?.addressLine2}}</div>
                            </div>
                            <div class="col-4">
                                <div class="review-title" i18n>City</div>
                                <div class="review-value">{{generatedLocation?.address?.locality}}</div>
                            </div>
                            <div class="col-4">
                                <div class="review-title" i18n>ZIP/Postal Code</div>
                                <div class="review-value">{{generatedLocation?.address?.locality}}</div>
                            </div>
                            <div class="col-4">
                                <div class="review-title" i18n>Province/State</div>
                                <div class="review-value">{{generatedLocation?.address?.state}}</div>
                            </div>
                            <div class="col-4">
                                <div class="review-title" i18n>Country</div>
                                <div class="review-value">{{generatedLocation?.address?.country}}</div>
                            </div>
                            <div class="col-12">
                                <div class="divider"></div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 review-section">
                                <div class="review-section-title" i18n>System Administrator Contact</div>
                                <div class="review-section-edit"
                                     (click)="currentStep = 2"
                                     i18n>Edit Section
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="review-title" i18n>First Name</div>
                                <div class="review-value">{{generatedEmployee?.primaryName}}</div>
                            </div>
                            <div class="col-4">
                                <div class="review-title" i18n>Last Name</div>
                                <div class="review-value">{{generatedEmployee?.familyName}}</div>
                            </div>
                            <div class="col-4">
                                <div class="review-title" i18n>Prefix</div>
                                <div class="review-value">{{generatedEmployee?.prefix}}</div>
                            </div>
                            <div class="col-4">
                                <div class="review-title" i18n>Username</div>
                                <div class="review-value">{{generatedEmployee?.userName}}</div>
                            </div>
                            <div class="col-4">
                                <div class="review-title" i18n>Is a Dentist?</div>
                                <div class="review-value">
                                    <ng-container i18n *ngIf="generatedEmployee?.isDentist">Yes</ng-container>
                                    <ng-container i18n *ngIf="!generatedEmployee?.isDentist">No</ng-container>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="divider"></div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 review-section">
                                <div class="review-section-title" i18n>Client Agreement</div>
                                <div class="review-section-edit"
                                     (click)="currentStep = 3"
                                     i18n>Edit Section
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="review-title" i18n>End User License Agreement</div>
                                <div class="review-value" i18n>Complete</div>
                            </div>
                            <div class="col-4">
                                <div class="review-title" i18n>Privacy Policy</div>
                                <div class="review-value" i18n>Complete</div>
                            </div>
                            <div class="col-12">
                                <div class="divider"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="sign-up-footer">
                    <button class="teth-button grey-button mr-3"
                            [hidden]="currentStep === 1"
                            (click)="backClicked()" i18n>
                        Back
                    </button>
                    <button class="teth-button blue-button"
                            [class.disabled]="!continueButtonEnabled()"
                            (click)="continueClicked()">
                        <ng-container *ngIf="currentStep !== 3" i18n>Continue</ng-container>
                        <ng-container *ngIf="currentStep === 3" i18n>I Agree</ng-container>
                    </button>
                </div>
            </ng-container>
            <ng-container *ngIf="creating || createSuccess">
                <div class="container creating-container">
                    <div class="creating-spinner" *ngIf="creating"></div>
                    <div class="create-success" *ngIf="createSuccess">
                        <img [src]="'assets/img/icons/check-white.svg'"
                             alt="Checkmark Icon">
                    </div>
                    <div class="creating-title">
                        <ng-container *ngIf="creating" i18n>
                            Creating Your Clinic
                        </ng-container>
                        <ng-container *ngIf="createSuccess" i18n>
                            Your Clinic is Ready!
                        </ng-container>
                    </div>
                    <div class="creating-description">
                        <ng-container *ngIf="creating" i18n>
                            We're tailoring Tetherport to fit your needs. This may take a few moments to complete.
                        </ng-container>
                        <ng-container *ngIf="createSuccess" i18n>
                            Click the 'Get Started' button to being using Tetherport.
                        </ng-container>
                    </div>
                    <div class="created-info" *ngIf="createSuccess">
                        <div>
                            <ng-container i18n>Company Identifier:</ng-container>
                            <span class="created-info-value">{{getCompanyIdentifier()}}</span>
                        </div>
                        <div>
                            <ng-container i18n>Administrator Username:</ng-container>
                            <span class="created-info-value">{{generatedEmployee?.userName}}</span>
                        </div>
                    </div>
                    <button class="teth-button"
                            [class.blue-button]="!creating"
                            [class.disabled]="creating"
                            (click)="getStarted()">
                        <ng-container *ngIf="creating" i18n>
                            Loading...
                        </ng-container>
                        <ng-container *ngIf="createSuccess" i18n>
                            Get Started
                        </ng-container>
                    </button>
                </div>
            </ng-container>
        </div>
    </div>
</main>
