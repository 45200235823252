import {Employee} from './employee.model';
import {Company} from './company.model';
import {Location} from './location.model';

export class SessionContainer {
    public employee: Employee;
    public company: Company;
    public currentLocation: Location;
    public companyLocations: Location[];
    public loginTime: number;

    constructor(employee: Employee, company: Company, currentLocation: Location, locations: Location[], loginTime: number) {
        this.employee = employee;
        this.company = company;
        this.currentLocation = currentLocation;
        this.companyLocations = locations;
        this.loginTime = loginTime;
    }
}
