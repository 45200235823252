import * as moment from 'moment';
import {formatDate} from '@angular/common';
import {NgbDate} from '@ng-bootstrap/ng-bootstrap';

export default class DateUtils {
    static formatDateToReadableString(date: Date): string {
        date = DateUtils.validateDate(date);
        return formatDate(date, 'MMM dd, yyyy', 'en-US');
    }

    static formatDateToReadableStringUTC(date: Date): string {
        const utcMoment = moment.utc(date);
        return moment(utcMoment).format('MMM D, yyyy');
    }

    static formatDateToQueryParamString(date: Date): string {
        date = DateUtils.validateDate(date);
        return formatDate(date, 'yyyy-MM-ddTHH:mm:ssZ', 'en-US');
    }

    // For matching Odata query params
    static formatDateToISOQueryParam(date: Date): string {
        date = DateUtils.validateDate(date);
        return date.toISOString();
    }

    static formatDateTimeToReadableString(date: Date): string {
        const utcMoment = moment.utc(date);
        return moment(utcMoment).local().format('llll');
    }

    static formatDateTimeToLongFormat(date: Date): string {
        const utcMoment = moment.utc(date);
        return moment(utcMoment).local().format('lll');
    }

    static formatDateTimeToMessageString(date: Date): string {
        const utcMoment = moment.utc(date);
        return moment(utcMoment).local().format('ddd, MMM D LT');
    }

    // returns Sunday, February 14th
    static formatDateToDayMonthString(date: Date): string {
        const utcMoment = moment.utc(date);
        return moment(utcMoment).local().format('dddd, MMMM Do');
    }

    // returns Sunday, February 14th, 2021
    static formatDateToDayMonthYearString(date: Date): string {
        const utcMoment = moment.utc(date);
        return moment(utcMoment).local().format('dddd, MMMM Do YYYY');
    }

    static formatNgbDateRangeToString(dates: NgbDate[]): string {
        if (dates.length !== 2) {
            return '';
        } else {
            const startDate = this.getDateFromNgbDate(dates[0]);
            const endDate = this.getDateFromNgbDate(dates[1]);
            if (dates[0].month !== dates[1].month) {
                // show both month abbreviations
                const sd = formatDate(startDate, 'MMM dd', 'en-US');
                const ed = formatDate(endDate, ' - MMM dd, yyyy', 'en-US');
                return sd + ed;
            } else {
                // only show one month abbreviation
                const sd = formatDate(startDate, 'MMM dd', 'en-US');
                const ed = formatDate(endDate, ' - dd, yyyy', 'en-US');
                return sd + ed;
            }
        }
    }

    static getNgbDateFromDate(d: Date): NgbDate {
        return new NgbDate(d.getFullYear(), d.getMonth() + 1, d.getDate());
    }

    static getDateFromNgbDate(d: NgbDate): Date {
        return new Date(d.year, d.month - 1, d.day);
    }

    static dateIsBefore(one, two: Date): number {
        one = DateUtils.validateDate(one);
        two = DateUtils.validateDate(two);
        return moment(one).isBefore(moment(two)) ? -1 : moment(one).isAfter(moment(two)) ? 1 : 0;
    }

    static dateIsOneDayBefore(one: Date, two: Date) {
        return moment(one).add(1, 'days').isSame(two, 'day');
    }

    static dateIsBeforeToday(date: Date): boolean {
        return moment(date).isBefore(moment(), 'day');
    }

    static dateIsWithinRange(date: Date, startDate: Date, endDate: Date): boolean {
        return moment(date).isBetween(startDate, endDate, 'day', '[]');
    }

    static validateDate(d): Date {
        if (d instanceof Date) {
            return d;
        } else {
            return new Date(d);
        }
    }

    static ngbDateIsBeforeToday(year, month, day: number): boolean {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth() + 1; // ngb
        const currentDay = currentDate.getDate();
        const pastYear = (currentYear > year);
        const pastMonth = (currentYear === year && currentMonth > month);
        const pastDay = (currentYear === year && currentMonth === month && currentDay > day);
        return pastYear || pastMonth || pastDay;
    }

    static endOfDay(date: Date): Date {
        const d = new Date(date);
        d.setHours(23, 59, 59, 999);
        return d;
    }

    static startOfDay(date: Date): Date {
        const d = new Date(date);
        d.setHours(0, 0, 0, 0);
        return d;
    }

    // returns time, ex: 11:32 AM, using system Time Zone,
    static getTime(date: Date): string {
        const dateFromTimeZone = new Date(date.getTime() + date.getTimezoneOffset() * 600 * 1000 * 2);
        return dateFromTimeZone.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'});
    }

    static getQuarter(date: Date): number {
        const month = date.getMonth() + 1;
        return Math.ceil(month / 3);
    }

    static getMonthLabel(date: Date): string {
        const year = date.getFullYear();
        const month = date.getMonth() + 1; // Months are zero-based, so add 1
        return `${year}-${month}`;
    }

    static addNDays(date: Date, nDays: number): Date {
        const d = new Date(date);
        return moment(d).add(nDays, 'days').toDate();
    }

}
