import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ApiService} from './api.service';
import {BehaviorSubject, combineLatest, EMPTY, forkJoin, Observable, of} from 'rxjs';
import {environment} from '../../environments/environment';
import {Company, CompanyType} from '../models/company.model';
import {Location} from '../models/location.model';
import {PresetMessage} from '../models/preset-message.model';
import {LocationImplant} from '../models/location-implant.model';
import {SpecialtyType} from '../models/specialty-type.model';
import {AttachmentType} from '../models/attachment-type.model';
import {CorrespondenceType} from '../models/correspondence-type.model';
import {GenderType} from '../models/gender-type.model';
import {CorrespondenceStatus} from '../models/correspondence-status.model';
import {DeserializeHelper} from '../views/shared/utils/json-utils';
import {ReferralStatus} from '../models/referral-status.model';
import {Country} from '../models/country.model';
import {ImplantType} from '../models/implant-type.model';
import {Permission} from '../models/permission.model';
import {SessionService} from './session.service';
import {HydratedLocation} from '../models/hydrated-location';
import {LocationAssignedSpecialty} from '../models/location-assigned-specialty';
import {LocationNotificationContact} from '../models/location-notification-contact';
import {NetworkContact} from '../models/network-contact.model';
import {StatusReason} from '../models/status-reason.model';
import {DateFormatType} from '../models/date-format-type';
import {CompanyDuplicateCheck} from '../models/company-duplicate-check.model';
import {IntegrationType} from '../models/integration-type';
import {LocationIntegration} from '../models/location-integration';
import {CompanyReferralSource} from '../models/company-referral-source.model';
import {ExternalReferringDentist} from '../models/external-referring-dentist.model';
import {NetworkInvitationCustomText} from '../models/network-invitation-custom-text';
import {catchError, map, switchMap, take, tap} from 'rxjs/operators';
import {FilterableDoctor} from '../models/filterable-doctor';
import {Procedure} from "../models/procedure.model";
import {ProcedureVariant} from "../models/procedure-variant.model";
import {FeeGuideService} from "./fee-guide.service";

export type ProximityType = 'nat' | 'prov';

@Injectable({
    providedIn: 'root'
})
export class EntityService {

    constructor(
        private http: HttpClient,
        private api: ApiService,
        private sessionService: SessionService,
        private feeGuideService: FeeGuideService
    ) {
        this.sessionService.sessionContainer.notNull().pipe(take(1)).subscribe(() => {
            this.initCachedData();
        });
    }

    private locationId$ = this.sessionService.locationId$;
    private companyId$ = this.sessionService.companyId$;
    private _incomingReferrals = new BehaviorSubject<boolean>(null);
    public incomingReferrals$ = this._incomingReferrals as Observable<boolean>;

    specialtyTypes$ = new BehaviorSubject<SpecialtyType[]>(null);
    procedureVariantTypes$ = new BehaviorSubject(new Map<number, ProcedureVariant[]>());
    attachmentTypes$ = new BehaviorSubject<AttachmentType[]>(null);
    correspondenceTypes$ = new BehaviorSubject<CorrespondenceType[]>(null);
    correspondenceStatusTypes$ = new BehaviorSubject<CorrespondenceStatus[]>(null);
    genderTypes$ = new BehaviorSubject<GenderType[]>(null);
    referralStatusTypes$ = new BehaviorSubject<ReferralStatus[]>(null);
    countries$ = new BehaviorSubject<Country[]>(null);
    implantTypes$ = new BehaviorSubject<ImplantType[]>(null);
    permissionTypes$ = new BehaviorSubject<Permission[]>(null);
    dateFormatTypes$ = new BehaviorSubject<DateFormatType[]>(null);
    integrationTypes$ = new BehaviorSubject<IntegrationType[]>(null);
    companyReferralSources$ = new BehaviorSubject<IntegrationType[]>(null);
    appVersion$ = new BehaviorSubject<string>(null);
    assignedSpecialistFilters$ = new BehaviorSubject<FilterableDoctor[]>(null);
    preferredSpecialistFilters$ = new BehaviorSubject<FilterableDoctor[]>(null);
    referringDentistFilters$ = new BehaviorSubject<FilterableDoctor[]>(null);
    locationWaitingStatusReasons$ = new BehaviorSubject<StatusReason[]>(null);

    private _numberOfSpecialistNewReferrals = new BehaviorSubject<number>(null);
    public numberOfSpecialistNewReferrals$ = this._numberOfSpecialistNewReferrals as Observable<number>;

    initCachedData() {
        const logError = error => console.log(error);
        this.getAppVersion().subscribe(r => this.appVersion$.next(r), logError);
        this.getSpecialties().pipe(
            tap(specialties => this.specialtyTypes$.next(specialties)),
            switchMap(specialties => {
                if (!environment.administratorAccess) return EMPTY;

                const procedures = specialties?.flatMap(s => {
                    return s?.procedures;
                })
                const calls = procedures?.map(p => {
                    return this.getProcedureVariants(p).pipe(
                        catchError((error) => {
                            console.error(error);
                            return of(null);
                        })
                    );
                })
                return calls?.length ? forkJoin(calls) : EMPTY;
            })
        ).subscribe(r => {
            const map = new Map<number, ProcedureVariant[]>();
            r?.forEach(pv => {
                if (pv?.length > 0) {
                    map.set(pv[0].procedureTypeId, pv);
                }
            });
            this.procedureVariantTypes$.next(map);
        }, logError);
        this.getAttachmentTypes().subscribe(r => this.attachmentTypes$.next(r), logError);
        this.getCorrespondenceTypes().subscribe(r => this.correspondenceTypes$.next(r), logError);
        this.getCorrespondenceStatusTypes().subscribe(r => this.correspondenceStatusTypes$.next(r), logError);
        this.getGenderTypes().subscribe(r => this.genderTypes$.next(r), logError);
        this.getReferralStatuses().subscribe(r => this.referralStatusTypes$.next(r), logError);
        this.getCountries().subscribe(r => this.countries$.next(r), logError);
        this.getImplantTypes().subscribe(r => this.implantTypes$.next(r), logError);
        this.getPermissions().subscribe(r => this.permissionTypes$.next(r), logError);
        this.getDateFormatTypes().subscribe(r => this.dateFormatTypes$.next(r), logError);
        this.getIntegrationTypes().subscribe(r => this.integrationTypes$.next(r), logError);
        this.getCompanyReferralSources().subscribe(r => this.companyReferralSources$.next(r), logError);

        combineLatest([
            this.locationId$.notNull(),
            this.companyId$.notNull(),
            this.incomingReferrals$.notNull()
        ]).pipe(
            switchMap(([locationId, companyId, incoming]) => {
                return this.getLocationReferringDentistFilters(companyId, locationId, incoming).pipe(
                    take(1),
                    tap(d => this.referringDentistFilters$.next(d)),
                    catchError(err => {
                        console.log(err);
                        return EMPTY;
                    }),
                );
            })
        ).subscribe();

        combineLatest([
            this.locationId$.notNull(),
            this.companyId$.notNull(),
            this.incomingReferrals$.notNull()
        ]).pipe(
            switchMap(([locationId, companyId, isIncoming]) => {
                return this.getLocationAssignedSpecialists(companyId, locationId, isIncoming).pipe(
                    take(1),
                    tap(d => this.assignedSpecialistFilters$.next(d)),
                    catchError(err => {
                        console.log(err);
                        return EMPTY;
                    }),
                );
            })
        ).subscribe();

        combineLatest([
            this.locationId$.notNull(),
            this.companyId$.notNull(),
            this.incomingReferrals$.notNull()
        ]).pipe(
            switchMap(([locationId, companyId, isIncoming]) => {
                return this.getLocationPreferredSpecialists(companyId, locationId, isIncoming).pipe(
                    take(1),
                    tap(d => this.preferredSpecialistFilters$.next(d)),
                    catchError(err => {
                        console.log(err);
                        return EMPTY;
                    }),
                );
            })
        ).subscribe();

        combineLatest([
            this.locationId$.notNull(),
            this.companyId$.notNull()
        ]).pipe(
            switchMap(([locationId, companyId]) => {
                return this.getLocationWaitingStatusReasons(companyId, locationId).pipe(
                    take(1),
                    tap(sr => this.locationWaitingStatusReasons$.next(sr)),
                    catchError(err => {
                        console.log(err);
                        return EMPTY;
                    }),
                );
            })
        ).subscribe();
    }

    public getCompany(companyId: number): Observable<Company> {
        return this.api.get<Company>(
            environment.apiBaseUrl + `/companies/${companyId}`)
            .map(r => DeserializeHelper.deserializeToInstance(Company, r));
    }

    public createCompany(company: Company): Observable<Company> {
        return this.api.post<Company>(
            environment.apiBaseUrl + `/Companies`, company);
    }

    public checkForDuplicateCompany(companyDuplicateCheck: CompanyDuplicateCheck): Observable<CompanyDuplicateCheck> {
        return this.api.post<CompanyDuplicateCheck>(
            environment.apiBaseUrl + `/companies/checkForDuplicateCompany`, companyDuplicateCheck)
            .map(r => DeserializeHelper.deserializeToInstance(CompanyDuplicateCheck, r));
    }

    public updateCompany(company: Company): Observable<Company> {
        return this.api.put<Company>(
            environment.apiBaseUrl + `/Companies/${company.id}`, company);
    }

    public getLocations(companyId: number): Observable<Location[]> {
        return this.api.get<Location[]>(
            environment.apiBaseUrl + `/Companies/${companyId}/Locations`)
            .map(r => r.map(d => DeserializeHelper.deserializeToInstance(Location, d)));
    }

    public getHydratedLocation(locationId: number): Observable<HydratedLocation> {
        return this.api.get<HydratedLocation>(
            environment.apiBaseUrl + `/hydratedlocations/${locationId}`)
            .map(r => DeserializeHelper.deserializeToInstance(HydratedLocation, r));
    }

    public getHydratedGeneralistLocations(): Observable<HydratedLocation[]> {
        const params = new Map<string, string>();
        params['companytypeid'] = CompanyType.Dentist;
        return this.api.get<HydratedLocation[]>(
            environment.apiBaseUrl + `/hydratedlocations`, params)
            .map(r => r?.map(d => DeserializeHelper.deserializeToInstance(HydratedLocation, d)));
    }

    public getExternalReferringDentists(companyId: number): Observable<ExternalReferringDentist[]> {
        return this.api.get<ExternalReferringDentist[]>(
            `${environment.apiBaseUrl}/companies/${companyId}/externalReferringDentists`)
            .map(r => r?.map(d => DeserializeHelper.deserializeToInstance(ExternalReferringDentist, d)));
    }

    public getHydratedLocationsForReferral(specialtyTypeId: number, requestingLocationId: number, proximityDistance: number, proximityType: ProximityType): Observable<HydratedLocation[]> {
        const params = new Map<string, string>();
        params['SpecialtyTypeId'] = specialtyTypeId;
        params['requestingLocationId'] = requestingLocationId;
        if (proximityDistance !== null) {
            params['proximityDistance'] = proximityDistance;
        } else if (proximityType) {
            params['proximityType'] = proximityType;
        }

        return this.api.recursiveGet<HydratedLocation[]>(`/hydratedlocations`, params)
            .map(r => r?.map(d => DeserializeHelper.deserializeToInstance(HydratedLocation, d)));
    }

    public createLocation(location: Location): Observable<Location> {
        return this.api.post<Location>(
            environment.apiBaseUrl + `/Companies/${location.companyId}/Locations`, location);
    }

    public updateLocation(location: Location): Observable<Location> {
        return this.api.put<Location>(
            environment.apiBaseUrl + `/Companies/${location.companyId}/Locations/${location.id}`, location);
    }

    public getLocationAssignedSpecialties(companyId: number, locationId: number): Observable<LocationAssignedSpecialty[]> {
        return this.api.get<LocationAssignedSpecialty[]>(
            environment.apiBaseUrl + `/Companies/${companyId}/Locations/${locationId}/assignedspecialties`);
    }

    public createLocationAssignedSpecialty(specialty: LocationAssignedSpecialty): Observable<LocationAssignedSpecialty> {
        return this.api.post<LocationAssignedSpecialty>(
            environment.apiBaseUrl + `/Companies/${specialty.companyId}/Locations/${specialty.locationId}/assignedspecialties`, specialty);
    }

    public deleteLocationAssignedSpecialty(specialty: LocationAssignedSpecialty): Observable<string> {
        return this.api.delete(
            environment.apiBaseUrl + `/Companies/${specialty.companyId}/Locations/${specialty.locationId}/assignedspecialties/${specialty.id}`,
            null,
            null,
            'text');
    }

    public createLocationWaitingStatusReason(reason: StatusReason): Observable<StatusReason> {
        return this.api.post<StatusReason>(
            environment.apiBaseUrl + `/Companies/${reason.companyId}/Locations/${reason.locationId}/waitingstatusreasons`, reason);
    }

    public deleteLocationWaitingStatusReason(reason: StatusReason): Observable<string> {
        return this.api.delete(
            environment.apiBaseUrl + `/Companies/${reason.companyId}/Locations/${reason.locationId}/waitingstatusreasons/${reason.id}`,
            null,
            null,
            'text');
    }

    public getLocationDeclinedStatusReasons(companyId: number, locationId: number): Observable<StatusReason[]> {
        return this.api.get<StatusReason[]>(
            environment.apiBaseUrl + `/Companies/${companyId}/Locations/${locationId}/declinedstagestatusreasons`);
    }

    public createLocationDeclinedStatusReason(reason: StatusReason): Observable<StatusReason> {
        return this.api.post<StatusReason>(
            environment.apiBaseUrl + `/Companies/${reason.companyId}/Locations/${reason.locationId}/declinedstagestatusreasons`, reason);
    }

    public deleteLocationDeclinedStatusReason(reason: StatusReason): Observable<string> {
        return this.api.delete(
            environment.apiBaseUrl + `/Companies/${reason.companyId}/Locations/${reason.locationId}/declinedstagestatusreasons/${reason.id}`,
            null,
            null,
            'text');
    }

    public getLocationNotificationContacts(companyId: number, locationId: number): Observable<LocationNotificationContact[]> {
        return this.api.get<LocationNotificationContact[]>(
            environment.apiBaseUrl + `/Companies/${companyId}/Locations/${locationId}/notificationcontacts`);
    }

    public createLocationNotificationContact(contact: LocationNotificationContact): Observable<LocationNotificationContact> {
        return this.api.post<LocationNotificationContact>(
            environment.apiBaseUrl + `/Companies/${contact.companyId}/Locations/${contact.locationId}/notificationcontacts`, contact);
    }

    public deleteLocationNotificationContact(contact: LocationNotificationContact): Observable<string> {
        return this.api.delete(
            environment.apiBaseUrl + `/Companies/${contact.companyId}/Locations/${contact.locationId}/notificationcontacts/${contact.id}`,
            null,
            null,
            'text');
    }

    public getLocationPresetMessages(locationId: number, companyId: number): Observable<PresetMessage[]> {
        return this.api.get<PresetMessage[]>(
            environment.apiBaseUrl + `/Companies/${companyId}/Locations/${locationId}/presetchatmessages`);
    }

    public updateLocationPresetMessages(presetMessage: PresetMessage): Observable<PresetMessage> {
        return this.api.put<PresetMessage>(
            environment.apiBaseUrl + `/Companies/${presetMessage.companyId}/Locations/${presetMessage.locationId}/presetchatmessages/${presetMessage.id}`, presetMessage);
    }

    public deleteLocationPresetMessages(presetMessage: PresetMessage): Observable<string> {
        return this.api.delete(
            environment.apiBaseUrl + `/Companies/${presetMessage.companyId}/Locations/${presetMessage.locationId}/presetchatmessages/${presetMessage.id}`, null);
    }

    public createLocationPresetMessages(presetMessage: PresetMessage): Observable<PresetMessage> {
        return this.api.post<PresetMessage>(
            environment.apiBaseUrl + `/Companies/${presetMessage.companyId}/Locations/${presetMessage.locationId}/presetchatmessages`, presetMessage);
    }

    public getLocationIntegrations(companyId: number, locationId: number): Observable<LocationIntegration[]> {
        return this.api.get<LocationIntegration[]>(
            environment.apiBaseUrl + `/Companies/${companyId}/Locations/${locationId}/integrations`)
            .map(r => r.map(d => DeserializeHelper.deserializeToInstance(LocationIntegration, d)));
    }

    public createLocationIntegration(locationIntegration: LocationIntegration, companyId: number, locationId: number): Observable<LocationIntegration> {
        return this.api.post<LocationIntegration>(
            environment.apiBaseUrl + `/Companies/${companyId}/Locations/${locationId}/integrations`, locationIntegration)
            .map(r => DeserializeHelper.deserializeToInstance(LocationIntegration, r));
    }

    public updateLocationIntegration(locationIntegration: LocationIntegration, companyId: number, locationId: number): Observable<LocationIntegration> {
        return this.api.put<LocationIntegration>(
            environment.apiBaseUrl + `/Companies/${companyId}/Locations/${locationId}/integrations/${locationIntegration.id}`, locationIntegration)
            .map(r => DeserializeHelper.deserializeToInstance(LocationIntegration, r));
    }


    public getLocationImplants(companyId: string, locationId: string): Observable<LocationImplant[]> {
        return this.api.get<LocationImplant[]>(
            environment.apiBaseUrl + `/Companies/${companyId}/Locations/${locationId}/Implants`)
            .map(r => r.map(d => DeserializeHelper.deserializeToInstance(LocationImplant, d)));
    }

    public updateLocationImplant(locationImplant: LocationImplant, companyId: string, locationId: string): Observable<LocationImplant> {
        return this.api.put<LocationImplant>(
            environment.apiBaseUrl + `/Companies/${companyId}/Locations/${locationId}/Implants`, locationImplant);
    }

    public getAppVersion(): Observable<string> {
        return this.api.ping().map(r => r?.applicationVersion);
    }

    // Enum Types
    public getSpecialties(): Observable<SpecialtyType[]> {
        return this.api.get<SpecialtyType[]>(environment.apiBaseUrl + `/specialtytypes`)
            .map(r => {
                const specialties = DeserializeHelper.arrayOf(SpecialtyType, r);
                specialties?.sort((a, b) => a.name.localeCompare(b.name));
                return specialties;
            });
    }

    public getProcedureVariants(procedure: Procedure): Observable<ProcedureVariant[]> {
        return this.api.get<ProcedureVariant[]>(`${environment.apiBaseUrl}/specialtytypes/${procedure?.specialtyTypeId}/proceduretypes/${procedure?.id}/procedurevarianttypes`)
    }

    public getAttachmentTypes(): Observable<AttachmentType[]> {
        return this.api.get<AttachmentType[]>(environment.apiBaseUrl + `/attachmenttypes`)
            .map(r => r.map(d => DeserializeHelper.deserializeToInstance(AttachmentType, d)));
    }

    public getCorrespondenceTypes(): Observable<CorrespondenceType[]> {
        return this.api.get<CorrespondenceType[]>(environment.apiBaseUrl + `/correspondencetypes`)
            .map(r => r.map(d => DeserializeHelper.deserializeToInstance(CorrespondenceType, d)));
    }

    public getGenderTypes(): Observable<GenderType[]> {
        return this.api.get<GenderType[]>(environment.apiBaseUrl + `/gendertypes`)
            .map(r => r.map(g => DeserializeHelper.deserializeToInstance(GenderType, g)));
    }

    public getCorrespondenceStatusTypes(): Observable<CorrespondenceStatus[]> {
        return this.api.get<CorrespondenceStatus[]>(environment.apiBaseUrl + `/correspondencestatustypes`)
            .map(r => r.map(d => DeserializeHelper.deserializeToInstance(CorrespondenceStatus, d)));
    }

    public getReferralStatuses(): Observable<ReferralStatus[]> {
        return this.api.get<ReferralStatus[]>(
            environment.apiBaseUrl + `/referralstatustypes`)
            .map(r => r.map(d => DeserializeHelper.deserializeToInstance(ReferralStatus, d)));
    }

    public getCountries(): Observable<Country[]> {
        return this.api.get<Country[]>(
            environment.apiBaseUrl + `/countrytypes`)
            .map(r => r.map(d => DeserializeHelper.deserializeToInstance(Country, d)));
    }

    public getDateFormatTypes(): Observable<DateFormatType[]> {
        return this.api.get<DateFormatType[]>(
            environment.apiBaseUrl + `/dateFormatTypes`)
            .map(r => r.map(d => DeserializeHelper.deserializeToInstance(DateFormatType, d)));
    }

    public getIntegrationTypes(): Observable<IntegrationType[]> {
        return this.api.get<IntegrationType[]>(
            environment.apiBaseUrl + `/IntegrationTypes`)
            .map(r => r.map(d => DeserializeHelper.deserializeToInstance(IntegrationType, d)));
    }

    public getCompanyReferralSources(): Observable<CompanyReferralSource[]> {
        return this.api.get<CompanyReferralSource[]>(
            environment.apiBaseUrl + `/companyReferralSources`)
            .map(r => r.map(d => DeserializeHelper.deserializeToInstance(CompanyReferralSource, d)));
    }

    public getImplantTypes(): Observable<ImplantType[]> {
        return this.api.get<ImplantType[]>(
            environment.apiBaseUrl + `/implanttypes`)
            .map(r => r.map(d => DeserializeHelper.deserializeToInstance(ImplantType, d)));
    }

    public getPermissions(): Observable<Permission[]> {
        return this.api.get<Permission[]>(environment.apiBaseUrl + `/permissiontypes`)
            .map(r => r.map(d => DeserializeHelper.deserializeToInstance(Permission, d)));
    }

    public getLocationNetworkContacts(companyId: number, locationId: number): Observable<NetworkContact[]> {
        return this.api.get<NetworkContact[]>(environment.apiBaseUrl + `/companies/${companyId}/locations/${locationId}/networkcontacts`)
            .map(r => r.map(d => DeserializeHelper.deserializeToInstance(NetworkContact, d)));
    }

    public createLocationNetworkContact(companyId: number, locationId: number, networkContact: NetworkContact): Observable<NetworkContact> {
        return this.api.post<NetworkContact>(environment.apiBaseUrl + `/companies/${companyId}/locations/${locationId}/networkcontacts`, networkContact)
            .map(r => DeserializeHelper.deserializeToInstance(NetworkContact, r));
    }
    public deleteLocationNetworkContact(networkContact: NetworkContact): Observable<string> {
        return this.api.delete(
            environment.apiBaseUrl + `/companies/${networkContact.companyId}/locations/${networkContact.locationId}/networkcontacts/${networkContact.id}`,
            null,
            null,
            'text');
    }

    public getLocationNetworkInvitationCustomTexts(companyId: number, locationId: number): Observable<NetworkInvitationCustomText[]> {
        return this.api.get<NetworkInvitationCustomText[]>(environment.apiBaseUrl + `/companies/${companyId}/locations/${locationId}/networkinvitationcustomtext`)
            .map(r => r.map(d => DeserializeHelper.deserializeToInstance(NetworkInvitationCustomText, d)));
    }

    public createLocationNetworkInvitationCustomText(companyId: number, locationId: number, customText: NetworkInvitationCustomText): Observable<NetworkInvitationCustomText> {
        return this.api.post<NetworkInvitationCustomText>(environment.apiBaseUrl + `/companies/${companyId}/locations/${locationId}/networkinvitationcustomtext`, customText)
            .map(r => DeserializeHelper.deserializeToInstance(NetworkInvitationCustomText, r));
    }
    public deleteLocationNetworkInvitationCustomText(customText: NetworkInvitationCustomText): Observable<string> {
        return this.api.delete(
            environment.apiBaseUrl + `/companies/${customText.companyId}/locations/${customText.locationId}/networkinvitationcustomtext/${customText.id}`,
            null,
            null,
            'text');
    }


    public sendLocationNetworkContactInvitations(locationId: number, companyId: number): Observable<any> {
        const fr = $localize.locale === 'fr';
        const params = new Map<string, string>();
        params['fr'] = fr;
        return this.api.post<any>(environment.apiBaseUrl + `/companies/${companyId}/locations/${locationId}/networkcontacts/sendinvitations`, params, null);
    }

    getAttachmentTypeFromMediaType(mediaType: string): AttachmentType {
        if (this.attachmentTypes$.value == null) {
            console.warn('Attachment Types not initialized.');
        }

        if (mediaType.match(`^[image]+/[-\\w.]+$`)) {
            return this.attachmentTypes$.value?.find(t => t.name === 'Image');
        } else if (mediaType.match(`^[application]+/[pdf]+$`)) {
            return this.attachmentTypes$.value?.find(t => t.name === 'PDF');
        } else if (mediaType.match(`^[video]+/[-\\w.]+$`)) {
            return this.attachmentTypes$.value?.find(t => t.name === 'Video');
        } else {
            return this.attachmentTypes$.value?.find(t => t.name === 'Doc');
        }
    }

    private getLocationReferringDentistFilters(companyId: number, locationId: number, incoming: boolean): Observable<FilterableDoctor[]> {
        const params = new Map<string, string>();
        params['isIncoming'] = incoming.toString();
        return this.api.get<any[]>(environment.apiBaseUrl + `/companies/${companyId}/locations/${locationId}/filters/referringdentists`, params)
            .map(d => DeserializeHelper.arrayOf(FilterableDoctor, d));
    }

    private getLocationPreferredSpecialists(companyId: number, locationId: number, isIncoming: boolean): Observable<FilterableDoctor[]> {
        const params = new Map<string, string>();
        params['isIncoming'] = isIncoming.toString();
        return this.api.get<any[]>(environment.apiBaseUrl + `/companies/${companyId}/locations/${locationId}/filters/preferredspecialists`, params)
            .map(d => DeserializeHelper.arrayOf(FilterableDoctor, d));
    }

    private getLocationAssignedSpecialists(companyId: number, locationId: number, isIncoming: boolean): Observable<FilterableDoctor[]> {
        const params = new Map<string, string>();
        params['isIncoming'] = isIncoming.toString();
        return this.api.get<any[]>(environment.apiBaseUrl + `/companies/${companyId}/locations/${locationId}/filters/assignedspecialists`, params)
            .map(d => DeserializeHelper.arrayOf(FilterableDoctor, d));
    }

    private getLocationWaitingStatusReasons(companyId: number, locationId: number): Observable<StatusReason[]> {
        const route = `/Companies/${companyId}/Locations/${locationId}/waitingstatusreasons`;
        return this.api.recursiveGet(route).map(d => DeserializeHelper.arrayOf(StatusReason, d));
    }

    public setIncomingReferrals(incoming: boolean) {
        this._incomingReferrals.next(incoming);
    }

    public setNewSpecialistReferrals(n: number) {
        this._numberOfSpecialistNewReferrals.next(n);
    }

    public createSpecialtyType(specialtyType: SpecialtyType): Observable<SpecialtyType> {
        return this.api.post<SpecialtyType>(environment.apiBaseUrl + `/specialtytypes`, specialtyType).pipe(
            tap(s => {
                this.specialtyTypes$.once(st => {
                    const updatedSpecialtyTypes = DeserializeHelper.arrayOf(SpecialtyType, st);
                    updatedSpecialtyTypes.push(s);
                    this.specialtyTypes$.next(updatedSpecialtyTypes);
                });
            })
        );
    }

    public updateSpecialtyType(specialtyType: SpecialtyType): Observable<SpecialtyType> {
        return this.api.put<SpecialtyType>(environment.apiBaseUrl + `/specialtytypes/${specialtyType.id}`, specialtyType).pipe(
            tap(s => {
                this.specialtyTypes$.once(st => {
                    const updatedSpecialtyTypes = DeserializeHelper.arrayOf(SpecialtyType, st);
                    const index = updatedSpecialtyTypes.findIndex(st => st.id === s.id);
                    updatedSpecialtyTypes[index] = s;
                    this.specialtyTypes$.next(updatedSpecialtyTypes);
                });
            })
        );
    }

    public archiveSpecialtyType(specialtyType: SpecialtyType): Observable<string> {
        return this.api.delete(environment.apiBaseUrl + `/specialtytypes/${specialtyType.id}`, null).pipe(
            tap(() => {
                this.specialtyTypes$.once(st => {
                    const updatedSpecialtyTypes = st?.filter(st => st.id !== specialtyType.id);
                    this.specialtyTypes$.next(updatedSpecialtyTypes);
                });
            })
        );
    }

    public fetchProcedureTypes(specialtyTypeId: number): Observable<Procedure[]> {
        return this.api.get<Procedure[]>(environment.apiBaseUrl + `/specialtytypes/${specialtyTypeId}/procedureTypes`);
    }

    public createProcedure(procedure: Procedure): Observable<Procedure> {
        const url = environment.apiBaseUrl + `/specialtytypes/${procedure.specialtyTypeId}/procedureTypes`;
        return this.api.post<Procedure>(url, procedure).pipe(
            map(p => DeserializeHelper.deserializeToInstance(Procedure, p)),
            tap(p => {
                this.specialtyTypes$.once(st => {
                    const updatedSpecialtyTypes = DeserializeHelper.arrayOf(SpecialtyType, st);
                    const specialtyType = updatedSpecialtyTypes.find(st => st.id === p.specialtyTypeId);
                    specialtyType.procedures.push(p);
                    this.specialtyTypes$.next(updatedSpecialtyTypes);
                });
            }),
            switchMap((p) => combineLatest([this.feeGuideService.reloadHydratedFeeGuideRegions(), of(p)])),
            map(([, p]) => p)
        );
    }

    public updateProcedure(procedure: Procedure): Observable<Procedure> {
        const url = environment.apiBaseUrl + `/specialtytypes/${procedure.specialtyTypeId}/procedureTypes/${procedure.id}`;
        return this.api.put<Procedure>(url, procedure).pipe(
            map(p => DeserializeHelper.deserializeToInstance(Procedure, p)),
            tap(p => {
                this.specialtyTypes$.once(st => {
                    const updatedSpecialtyTypes = DeserializeHelper.arrayOf(SpecialtyType, st);
                    const specialtyType = updatedSpecialtyTypes.find(st => st.id === p.specialtyTypeId);
                    const procedureIndex = specialtyType.procedures.findIndex(proc => proc.id === procedure.id);
                    specialtyType.procedures[procedureIndex] = p;
                    this.specialtyTypes$.next(updatedSpecialtyTypes);
                });
            }),
            switchMap((p) => combineLatest([this.feeGuideService.reloadHydratedFeeGuideRegions(), of(p)])),
            map(([, p]) => p)
        );
    }

    public archiveProcedure(procedure: Procedure): Observable<string> {
        const url = environment.apiBaseUrl + `/specialtytypes/${procedure.specialtyTypeId}/procedureTypes/${procedure.id}`;
        return this.api.delete(url, null).pipe(
            tap(() => {
                this.specialtyTypes$.once(st => {
                    const updatedSpecialtyTypes = DeserializeHelper.arrayOf(SpecialtyType, st);
                    const specialtyToUpdate = updatedSpecialtyTypes.find(st => st.id === procedure.specialtyTypeId);
                    specialtyToUpdate.procedures = specialtyToUpdate.procedures.filter(p => p.id !== procedure.id);
                    this.specialtyTypes$.next(updatedSpecialtyTypes);
                });
            }),
            switchMap((s) => combineLatest([this.feeGuideService.reloadHydratedFeeGuideRegions(), of(s)])),
            map(([, s]) => s)
        );
    }

    public createProcedureVariant(procedureVariant: ProcedureVariant, procedureId: number, specialtyId: number): Observable<ProcedureVariant> {
        const url = `${environment.apiBaseUrl}/specialtytypes/${specialtyId}/procedureTypes/${procedureId}/procedurevarianttypes`;
        return this.api.post<ProcedureVariant>(url, procedureVariant).pipe(
            map(pv => DeserializeHelper.deserializeToInstance(ProcedureVariant, pv)),
            tap(pv => this.updateProcedureVariantTypes(procedureId, pv)),
            switchMap(() => this.feeGuideService.reloadHydratedFeeGuideRegions()),
            switchMap(() => this.procedureVariantTypes$),
            map(pvt => pvt.get(procedureId)?.find(pv => pv.id === procedureVariant.id))
        );
    }

    private updateProcedureVariantTypes(procedureId: number, newProcedureVariant: ProcedureVariant) {
        this.procedureVariantTypes$.once(pvt => {
            const updatedProcedureVariantTypes = new Map(pvt);
            const procedureVariants = updatedProcedureVariantTypes.get(procedureId) || [];
            procedureVariants.push(newProcedureVariant);
            updatedProcedureVariantTypes.set(procedureId, procedureVariants);
            this.procedureVariantTypes$.next(updatedProcedureVariantTypes);
        });
    }

}
