<div class="date-picker-container">
    <ngb-datepicker class="teth-date-picker"
                    [ngClass]="{    'multi-month': this.datePickerConfig.displayMonths > 1,
                                    'hide-arrows': this.navigationType === datePickerNavigationType.Select,
                                    'hide-border': this.hideBorder }"
                    [displayMonths]="this.datePickerConfig.displayMonths"
                    [navigation]="this.datePickerConfig.navigation"
                    [firstDayOfWeek]="this.datePickerConfig.firstDayOfWeek"
                    [startDate]="this.datePickerConfig.startDate"
                    [minDate]="minDate"
                    [markDisabled]="this.datePickerConfig.markDisabled"
                    [dayTemplate]="dayTemplate"
                    [dayTemplateData]="this.datePickerConfig.dayTemplateData"
                    (dateSelect)="handleDateSelection($event)">
    </ngb-datepicker>
</div>

<ng-template #dayTemplate let-date="date" let-currentMonth="currentMonth" let-focused="focused">
  <span class="custom-day"
        [class.focused]="isFocused(date, focused)"
        [class.range]="isRange(date)"
        [class.faded]="isHovered(date) || isInside(date)"
        [class.hidden]="isPastMonth(date, currentMonth)"
        [class.grey]="isOutOfMonth(date, currentMonth)"
        [class.current]="isCurrentDate(date)"
        [class.threshold]="isThresholdDate(date)"
        (mouseenter)="hoveredDate = date"
        (mouseleave)="hoveredDate = null">
    {{ date.day }}
  </span>
</ng-template>
