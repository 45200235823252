import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormGroup} from '@angular/forms';

@Component({
  selector: 'app-crownsplus-form',
  templateUrl: './crownsplus-form.component.html',
  styleUrls: ['./crownsplus-form.component.scss', '../../../stylesheet/stylesheet.component.scss']
})
export class CrownsplusFormComponent implements OnInit {

  constructor() { }

  @Input() parentForm: FormGroup;
  @Input() radiobuttons = [];

  @Output() public textfieldChangedEmitter = new EventEmitter();
  @Output() public checkboxChangedEmitter = new EventEmitter();

  showAdvancedOptions = false;

  ngOnInit(): void {
  }

  toggleOptions() {
    this.showAdvancedOptions = !this.showAdvancedOptions;
  }

  getLocalizedRestorationTypeTitle(): string {
    return $localize`Restoration Type`;
  }

  getLocalizedProductTypeTitle(): string {
    return $localize`Product Type`;
  }

  getLocalizedMetalTypeTitle(): string {
    return $localize`Type of Metal`;
  }

  getLocalizedCeramicsTypeTitle(): string {
    return $localize`Ceramics`;
  }

  getLocalizedShadePlaceholder(): string {
    return $localize`Enter Shade...`;
  }

  getLocalizedShadeTitle(): string {
    return $localize`Shade`;
  }

  getLocalizedStumpShadeTitle(): string {
    return $localize`Stump Shade`;
  }

  getLocalizedStumpShadePlaceholderTitle(): string {
    return $localize`Enter Stump Shade...`;
  }

  getLocalizedMarginTitle(): string {
    return $localize`Margin`;
  }

  getLocalizedPonticDesignTitle(): string {
    return $localize`Pontic Design`;
  }

  getLocalizedOcclusalContactTitle(): string {
    return $localize`Occlusal Contact`;
  }

  getLocalizedToothMorphTitle(): string {
    return $localize`Tooth Morphology`;
  }

  getLocalizedSurfaceTextureTitle(): string {
    return $localize`Surface Texture`;
  }

  getLocalizedInterproximalContactTitle(): string {
    return $localize`Interproximal Contact`;
  }

  getLocalizedIncisalTranslucencyTitle(): string {
    return $localize`Incisal Translucency`;
  }

  getLocalizedCustomShadeTitle(): string {
    return $localize`Custom Shade`;
  }

  getLocalizedDeliveryTitle(): string {
    return $localize`Delivery`;
  }

  getLocalizedShowOptionsTitle(): string {
    return $localize`Show Advanced Options`;
  }

  getLocalizedHideOptionsTitle(): string {
    return $localize`Hide Advanced Options`;
  }

  emitTextFieldValue(value: string) {
    this.textfieldChangedEmitter.emit(value);
  }

  emitCheckboxValue(value: boolean) {
    this.checkboxChangedEmitter.emit(value);
  }


}
