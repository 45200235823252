import {Deserializable} from '../views/shared/utils/json-utils';
import * as moment from 'moment';

export class ReleaseNote implements Deserializable {
    public releaseDate: Date;
    public version: string;
    public releaseNotes: ReleaseNotes[];

    onDeserialize() {
        this.releaseDate = moment.utc(this.releaseDate.toString()).toDate();
    }
}

export class ReleaseNotes {
    public changeType: string;
    public description: string;
}
