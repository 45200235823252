import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-pagination',
    templateUrl: './pagination.component.html',
    styleUrls: ['./pagination.component.scss', '../../../stylesheet/stylesheet.component.scss']
})
export class PaginationComponent implements OnInit {

    @Input() public dataLength: number;
    @Input() public numberOfEntries: number;
    @Input() public maxNumberOfPages: number;
    @Input() public currentPage: number;
    @Input() public hidePositionText = false;
    @Input() public useDayButtons = false;
    @Output() public pageClickedEvent = new EventEmitter<number>();

    constructor() {
    }

    ngOnInit(): void {
    }


    public nextDisabled(): boolean {
        return this.currentPage + 1 >= this.numberOfPages();
    }

    public previousDisabled(): boolean {
        return this.currentPage === 0;
    }

    public endDisabled(): boolean {
        return this.nextDisabled();
    }

    public previousClicked(): void {
        if (this.currentPage > 0) {
            this.pageClickedEvent.emit(this.currentPage - 1);
        }
    }

    public nextClicked(): void {
        if (this.currentPage + 1 < this.numberOfPages()) {
            this.pageClickedEvent.emit(this.currentPage + 1);
        }
    }

    public isPageNumber(page): boolean {
        const selected = parseInt(page, 10) - 1;
        return !isNaN(selected);
    }

    public pageClicked(page): void {
        const selected = parseInt(page, 10) - 1;
        this.pageClickedEvent.emit(isNaN(selected) ? this.numberOfPages() - 1 : selected);
    }

    public isActivePage(page): boolean {
        return this.currentPage === parseInt(page, 10) - 1;
    }

    public pages(): string[] {
        const total = this.numberOfPages();
        const arr = [];
        const sidePadding = 2;
        const maxRun = 3;
        let start;
        let end;

        if (total <= this.maxNumberOfPages) {
            start = 0;
            end = total;
        } else if (this.currentPage >= total - this.maxNumberOfPages) {
            const tempStart = total - this.maxNumberOfPages - sidePadding;
            start = tempStart >= 0 ? tempStart : 0;
            end = total;
        } else if (this.currentPage >= maxRun) {
            start = this.currentPage - sidePadding;
            end = start + this.maxNumberOfPages;
        } else if (this.currentPage < this.maxNumberOfPages) {
            start = 0;
            end = this.maxNumberOfPages;
        }

        for (let i = start + 1; i < end + 1; i++) {
            arr.push(i.toString());
        }

        if (!arr.includes('1')) {
            if (!arr.includes('2')) {
                arr.unshift('...');
            }
            arr.unshift('1');
        }

        if (!arr.includes(total.toString())) {
            if (!arr.includes((total - 1).toString())) {
                arr.push('...');
            }
            arr.push(total.toString());
        }

        return arr;
    }

    public beginAmount(): number {
        return this.currentPage * this.numberOfEntries;
    }

    public endAmount(): number {
        return this.currentPage * this.numberOfEntries + this.numberOfEntries;
    }

    private numberOfPages(): number {
        if (this.dataLength && this.numberOfEntries) {
            return Math.ceil(
                this.dataLength / this.numberOfEntries,
            );
        } else {
            return 0;
        }
    }
}
