import {HttpClient, HttpHeaders} from '@angular/common/http';
import {EMPTY, Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {SessionService} from './session.service';
import {DEFAULT_PAGINATION_TAKE, UrlBuilder} from './url-builder';
import {expand, map, reduce} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import * as CryptoJS from 'crypto-js';
import {ODataQueryOptions} from '../models/odata-query-options';
import {ODataResponse} from '../shared/interfaces/odata-response';

export type SupportedAPILocale = 'en-US' | 'fr-CA';

@Injectable({
    providedIn: 'root'
})
export class ApiService {

    locale: SupportedAPILocale = $localize.locale === 'fr' ? 'fr-CA' : 'en-US';

    constructor(private http: HttpClient, private sessionService: SessionService) {
    }

    private createHeaders(additionalHeaders: any): HttpHeaders {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Locale': this.locale,
        });
        const token = this.sessionService.getAuthToken();
        if (token?.length > 0) {
            headers = headers.set('Authorization', `Bearer ${token}`);
        }

        if (environment.encryptionBypass) {
            headers = headers.set('DisableEncryptionKey', environment.disableEncryptionKey);
        }

        if (additionalHeaders != null) {
            Object.keys(additionalHeaders).forEach(h => {
                headers = headers.set(h, additionalHeaders[h]);
            });
        }
        return headers;
    }

    public get<T>(url: string, params: Map<string, string> = null, additionalHeaders: any = null): Observable<T> {
        let responseType = 'text';
        if (environment.encryptionBypass) {
            responseType = 'json';
        }

        let completeUrl = url;
        if (params) {
            completeUrl += `?${this.encrypt(UrlBuilder.buildQueryParamsString(params).slice(1), false)}`;
        }

        return this.http.get<any>(completeUrl, {
            headers: this.createHeaders(additionalHeaders),
            responseType: responseType as 'json'
        }).map(r => this.decrypt<T>(r));
    }

    public getOdata<T>(
        url: string,
        odataQueryOptions?: ODataQueryOptions,
        additionalHeaders: any = null
    ): Observable<ODataResponse<T>> {
        let completeUrl = url;
        let responseType = 'text';
        if (environment.encryptionBypass) {
            responseType = 'json';
        }
        if (odataQueryOptions) {
            completeUrl += `?${this.encrypt(odataQueryOptions?.toQueryString(),
                false
            )}`;
        }
        return this.http.get<ODataResponse<T>>(completeUrl, {
            headers: this.createHeaders(additionalHeaders),
            responseType: responseType as 'json'
        }).pipe(
            map(r => this.decrypt<ODataResponse<T>>(r)),
            map(response => {
                const data = Array.isArray(response.value) ? response.value : [response.value];
                return { ...response, value: data };
            }),
            map((response: ODataResponse<T>) => {
                const mappedData = response.value.map(item => item);
                return { ...response, value: mappedData };
            })
        );
    }

    public ping(): Observable<any> {
        return this.http.get<any>(environment.apiBaseUrl + '/ping', {
            headers: this.createHeaders(null),
            responseType: 'json'
        });
    }

    public recursiveGet<T extends any[]>(route: string, params: Map<string, string> = new Map<string, string>(), skip = 0, take = DEFAULT_PAGINATION_TAKE): Observable<T> {
        let currentSkip = skip;
        const reqParams = Object.assign({}, params);
        reqParams['Skip'] = skip.toString();
        reqParams['Take'] = take.toString();
        const url = UrlBuilder.build(route);

        return this.get<T>(url, reqParams).pipe(
            expand((res) => {
                if (res && res?.length === take) {
                    currentSkip += take;
                    reqParams['Skip'] = currentSkip;
                    return this.get<T>(url, reqParams);
                } else {
                    return EMPTY;
                }
            }),
            reduce((acc, val) => {
                acc.push(...val);
                return acc;
            })
        );
    }

    public post<T>(url, payload, additionalHeaders: any = null, responseType: string = 'text'): Observable<T> {
        let encryptedPayload = this.encrypt(payload);
        if (environment.encryptionBypass) {
            responseType = 'json';
            encryptedPayload = payload; // send original payload
        }
        return this.http.post<T | string>(url, encryptedPayload, {
            headers: this.createHeaders(additionalHeaders),
            responseType: responseType as 'json'
        }).map(r => this.decrypt<T>(r));
    }

    public put<T>(url, payload: any = null, additionalHeaders: any = null): Observable<T> {
        let responseType = 'text';
        let encryptedPayload = this.encrypt(payload);
        if (environment.encryptionBypass) {
            responseType = 'json';
            encryptedPayload = payload; // send original payload
        }
        return this.http.put<T | string>(url, encryptedPayload, {
            headers: this.createHeaders(additionalHeaders),
            responseType: responseType as 'json'
        }).map(r => this.decrypt<T>(r));
    }

    public delete(url, payload, additionalHeaders: any = null, responseType: string = 'text'): Observable<string> {
        let encryptedPayload = this.encrypt(payload);
        if (environment.encryptionBypass) {
            responseType = 'text';
            encryptedPayload = payload;
        }
        return this.http.request<string>('DELETE', url, {
            headers: this.createHeaders(additionalHeaders),
            body: encryptedPayload,
            responseType: responseType as 'json'
        }).map(r => this.decryptToString(r));
    }

    decrypt<T>(base64Cipher: any): T {
        if (environment.encryptionBypass) {
            return base64Cipher;
        }

        const decryptedString = this.decryptToString(base64Cipher);
        if (decryptedString === '') {
            return null;
        }
        return JSON.parse(decryptedString) as T;
    }

    decryptToString(base64Cipher: any): string {
        if (environment.encryptionBypass) {
            return base64Cipher;
        }
        if (base64Cipher === null) {
            return '';
        }
        const decodedKey = CryptoJS.enc.Utf8.parse(environment.encryptionKey);
        const decodedCipher = CryptoJS.enc.Base64.parse(base64Cipher);
        const iv = CryptoJS.enc.Utf8.parse(environment.encryptionIV);
        const decrypted = CryptoJS.AES.decrypt(CryptoJS.lib.CipherParams.create({
            ciphertext: decodedCipher,
            algorithm: CryptoJS.algo.AES,
            padding: CryptoJS.pad.Pkcs7
        }), decodedKey, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
        });

        return decrypted.toString(CryptoJS.enc.Utf8);
    }

    encrypt(message: any, messageIsJson: boolean = true): any {
        if (environment.encryptionBypass) {
            return message;
        }
        const stringifyMessage = messageIsJson ? JSON.stringify(message) : message;
        const decodedKey = CryptoJS.enc.Utf8.parse(environment.encryptionKey);
        const iv = CryptoJS.enc.Utf8.parse(environment.encryptionIV);
        const encrypted = CryptoJS.AES.encrypt(stringifyMessage, decodedKey, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            algorithm: CryptoJS.algo.AES,
            padding: CryptoJS.pad.Pkcs7
        });
        return encrypted.toString();
    }
}

