<app-radio-group
        [textLabel]="getLocalizedRestorationTypeTitle()"
        [control]="'restorationTypeId'"
        [parentForm]="parentForm"
        [radioButtons]="radiobuttons[0]"
        [required]="true">
</app-radio-group>
<hr>
<ng-container
        *ngIf="parentForm.controls.restorationTypeId?.value == 1 || parentForm.controls.restorationTypeId?.value == 2">
    <app-text-group
            [placeholderText]="getLocalizedShadePlaceholder()"
            [textLabel]="getLocalizedShadeTitle()"
            [parentForm]="parentForm"
            [textId]="'shade'"
            [required]="true">
    </app-text-group>
    <hr>
</ng-container>
<ng-container *ngIf="parentForm.controls.restorationTypeId?.value == 2">
    <app-radio-group
            [textLabel]="getLocalizedCompleteDentureTitle()"
            [control]="'completeDenturesTypeId'"
            [parentForm]="parentForm"
            [radioButtons]="radiobuttons[2]"
            [required]="true">
    </app-radio-group>
    <hr>
    <app-radio-group
            [textLabel]="getLocalizedDeliveryTitle()"
            [control]="'deliveryTypeId'"
            [parentForm]="parentForm"
            [radioButtons]="radiobuttons[5]"
            [required]="true">
    </app-radio-group>
    <hr>
</ng-container>
<ng-container *ngIf="parentForm.controls.restorationTypeId?.value == 1">
    <app-radio-group
            [textLabel]="getLocalizedPartialDentureTitle()"
            [control]="'partialDentureTypeId'"
            [parentForm]="parentForm"
            [radioButtons]="radiobuttons[1]"
            [required]="true">
    </app-radio-group>
    <hr>

    <app-radio-group
            [textLabel]="getLocalizedMaterialTypeTitle()"
            [control]="'materialTypeId'"
            [parentForm]="parentForm"
            [radioButtons]="radiobuttons[3]"
            [required]="true">
    </app-radio-group>
    <hr>
    <app-radio-group
            [textLabel]="getLocalizedMajorConnectorTitle()"
            [control]="'majorConnectorTypeId'"
            [parentForm]="parentForm"
            [radioButtons]="radiobuttons[4]"
            [required]="true">
    </app-radio-group>
    <hr>
    <app-radio-group
            [textLabel]="getLocalizedDeliveryTitle()"
            [control]="'deliveryTypeId'"
            [parentForm]="parentForm"
            [radioButtons]="radiobuttons[5]"
            [required]="true">
    </app-radio-group>
</ng-container>
