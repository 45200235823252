<div class="modal-body">
    <ng-container *ngIf="eulaAgreement">
        <div class="modal-title mb-3" i18n>
            TETHERPORT APPLICATION LICENSE TERMS AND CONDITIONS
        </div>
        <pre class="p-3 mb-4 scrollable">
In these Application License Terms and Conditions (the “Terms”), “Service Provider” will refer to 102014853 Saskatchewan Ltd., a company incorporated under the laws of the Province of Saskatchewan having its principal place of business at 3344 Albert Street. “Clinic” will refer to the clinic wishing to license the Tetherport application as described in these Terms. In order to license the Tetherport application as described in these Terms, the Clinic must review and accept these Terms by clicking on the “Accept” button or other acceptance mechanism provided. The individual accepting these Terms on behalf of the Clinic hereby acknowledges and agrees that he/she/they has authority to bind the Clinic to these Terms.
Please review these Terms carefully. Once accepted, these Terms becomes a binding legal commitment between the Clinic and the Service Provider. If the Clinic does not agree to be bound by these Terms, it should not click the “Accept” button or otherwise use the Tetherport application.
These Terms were last updated January 4, 2021.

1. - INTERPRETATION
In these Terms, the following terms have the following meanings:
(a)	“Application” means the executable version of the Tetherport application;
(b)	“Authorized Users” means the Clinic’s healthcare providers and administrative staff who have accepted and agreed to the Tetherport Terms of Use with respect to their use of the Licensed Materials;
(c)	“Confidential Information” means all information, in whatever form, furnished by or on behalf of one party to the other party in connection with these Terms including (but not limited to) any Personal Information, business plans, business strategies, business transactions, pricing, technical information, Intellectual Property, trade secrets, contracts, financial information, financial strategies, economic data, marketing information, marketing strategies, marketing methods, computer application, specifications, models, and any other information which is or may be either applicable to or related in any way to the assets, business or affairs of a party or its affiliates, suppliers or business partners;
(d)	“Documentation” means such manuals, documentation and any other supporting materials relating to the Application as are currently maintained by the Service Provider and generally provided to its licensees;
(e)	“Enhancements” means all modifications, revisions, enhancements, additions, bug fixes, patches, work-arounds, improvements, updates, upgrades, and new versions and releases for the Application and the Documentation which are made commercially available by the Service Provider during the Term of these Terms;
(f)	“Intellectual Property” means any intellectual property or intellectual property rights recognized by law (including any intellectual property rights protected through legislation such as that governing patents, copyrights and industrial designs) and includes, without limitation, all rights in any invention, discovery, improvement, utility, model, copyright or industrial design and all rights of whatsoever nature in computer software (including the source code related thereto) and all intangible rights or privileges of a nature similar to any of the foregoing, whether or not registered or patentable, and shall include all rights in any applications and granted registrations for any of the foregoing;
(g)	“Licensed Materials” means the Application, the Documentation, and the Enhancements;
(h)	“Personal Information” means information about an identifiable individual that is either personal information or personal health information within the meaning of applicable privacy laws; and

2. – Grant of license
2.1.	License
Subject to these Terms, the Service Provider hereby grants the Clinic and its Authorized Users a non-transferable and non-exclusive license to use the Licensed Material for the Clinic’s internal business purposes for the Term of these Terms.
2.2.	Restriction on Rights
Copies of the Licensed Material created or transferred pursuant to these Terms are licensed, not sold, and the Clinic receives no title to or ownership of the Licensed Material. Any rights not expressly granted in these Terms are expressly reserved for the Service Provider.
Without limiting the generality of the foregoing, the Clinic shall not and shall ensure its Authorized Users do not:
(a)	exploit the Licensed Material or use the Licensed Material in any way not permitted by these Terms;
(b)	modify, copy, create derivative works from, distribute, publicly display, publicly perform, or sublicense the Licensed Material; or
(c)	reverse engineer, decompile, disassemble, or otherwise attempt to derive any of the source code of the Licensed Material.
2.3.	Tetherport Terms of Use
The Clinic acknowledges and agrees that each of its Authorized Users will be required to accept the Tetherport Terms of Use (available within the Application) prior to accessing the Licensed Materials which will govern their use thereof.
2.4.	Enhancements
The Service Provider will, at its sole cost, make Enhancements available to the Clinic within 90 (ninety) days of such Enhancements being commercially available. Upon delivery, the Enhancements will form a part of the Licensed Material. For greater certainty, the Service Provider’s obligations in this Section 2.4 are subject to the Clinic meeting its obligations pursuant to these Terms including, without limitation, payment of any annual license fee (if applicable). For greater certainty, the Service Provider shall be under no obligation to provide any Enhancements during the Term of these Terms.

3. – LICENSE fEES
As consideration for the provision of the Licensed Material, the Clinic will pay the Service Provider the license fees as specified: complimentary promotional offer. Such fees exclude any taxes payable by the Clinic under applicable laws.
For greater certainty, the Service Provider may add or change the fees associated with the Licensed Material from time to time, including in connection with any Enhancements or other further development of or increased functionality in the Application. The Service Provider will provide you with reasonable advance notice of any new or changed fees as applicable.
3.1.	Confidentiality
Each of the parties shall:
(a)	maintain the other party’s Confidential Information in strict confidence;
(b)	only use the other party’s Confidential Information as reasonably necessary for the purposes of providing or receiving the Licensed Material or otherwise performing these Terms;
(c)	on a need to know basis, permit its representatives access to only such portions of the other party’s Confidential Information as is reasonably required for the purposes of providing or receiving the Licensed Material or otherwise performing these Terms;
(d)	be liable to the other party for any breach of this Article 4 by it or any of its representatives and in the case of access to any of the other party’s Confidential Information by its representatives, to require such representatives to agree to the terms and conditions of this Article;
(e)	not disclose, without the prior written consent of the other party, any Confidential Information of the other party to any person (other than to its own representatives in accordance with these Terms); and
(f)	protect the security and confidentiality of the other party’s Confidential Information in its possession to at least the same standard as it protects its own confidential information and, in any event, to a standard necessary to protect against such threats to the security and confidentiality of the other party’s Confidential Information that a reasonably prudent person would anticipate. For greater certainty, the Service Provider shall have in place reasonable policies, procedures and safeguards to protect the confidentiality and security of the Clinic’s Confidential Information, including reasonable security arrangements against such risks as unauthorized access, collection, use, disclosure, disposal, loss or modification.
3.2.	Exceptions
The obligations of the parties pursuant to Subsection 4.1 shall not apply to any information with respect to which a party is able to establish:
(a)	at the time of disclosure was or thereafter became generally available to the public, other than as a result of any act or omission by the party or its representatives;
(b)	was or became lawfully known to the party on a non-confidential basis and not in contravention of any applicable law from a source (other than the other party) that is entitled to disclose the information;
(c)	was already lawfully in the possession of the party or was lawfully acquired by them, provided that, such information is not subject to another confidentiality agreement or other obligation of secrecy; or
(d)	any circumstance where the party is required by law to disclose any Confidential Information of the other party, provided that, prior to any such disclosure, the party shall promptly provide to the other party written notice thereof so that the other party may seek an appropriate remedy.
3.3.	Return
Upon written request by the other party or upon termination of these Terms, a party shall promptly return to the other party, and ensure representatives immediately return, all Confidential Information and all copies thereof, including those existing in electronic form, and all notes, records, or documents made by any of them in whatever form to the extent they incorporate any Confidential Information, subject to the party’s right to retain copies of such information: (i) to enforce the terms of these Terms, (ii) for legal, regulatory, archival, or accounting purposes, (iii) to the extent such information is Confidential Information of both parties, or (iv) for which either party is granted a license under these Terms.
3.4.	Breach Notification
The parties shall immediately notify each other in writing of any known or suspected security breach, data loss or other event known or reasonably believed to have compromised the security, integrity, availability or confidentiality of the other party’s Confidential Information in its possession or under its care.
3.5.	Personal Information
To the extent that the Service Provider has access to Personal Information in order to perform its obligations under these Terms, the Service Provider will at all times comply with the requirements of any applicable privacy laws. For greater certainty, the Clinic is responsible for complying with all applicable privacy laws with respect to its use of the Licensed Material including, but not limited to, obtaining and maintaining any necessary consents or permissions from those whose information has been collected, recorded, processed, stored, used, disclosed, transferred, exchanged or otherwise handled as a result of, or in relation to, the Licensed Material, and the Clinic agrees to obtain and maintain all such consents or permissions throughout the Term.

4. – Term and Termination
4.1.	Term
These Terms, as may be amended from time to time, will commence on the date they are accepted by you and continue until terminated in accordance with Section 5.2 (Termination for Cause) below (the “Term”).
4.2.	Termination for Cause
Either party shall be in default if it fails to perform any of its duties or obligations hereunder and fails to substantially cure such default within thirty (30) days after written notice is given to the defaulting party. Upon an event of default, the non-defaulting party may terminate these Terms by providing written notice of termination to the defaulting party, reserving unto the non-defaulting party all other rights and remedies it may have under these Terms. If the Clinic is in default, the Service Provider reserves the right, in addition to all other rights and remedies it may have, to withhold further performance of its obligations under these Terms and may repossess the Licensed Material.
4.3.	Effect of Termination
Upon termination of these Terms for any reason, or of any licenses granted hereunder, the provisions of Articles 4, 6 and 7 will survive. Upon termination of any license granted hereunder, the Clinic will promptly remove all affected Licensed Material from all memory locations, return all copies of the affected Licensed Material to the Service Provider, and execute and deliver to the Service Provider a certificate stating that all copies of the affected Licensed Material have been removed and returned or destroyed.

5. – Warranties
5.1.	Disclaimer of Warranties
Except for the express warranties contained herein, each party expressly acknowledges and agrees that the license granted hereunder is on an “as-is and where-is” basis without warranty of any kind, whether express or implied, including without limitations, any warranty with respect to right, title, interest, including fitness for a particular use, or non-infringement of Intellectual Property.

6. – iNDEMNIFICATION and Limitation of Liability
6.1.	Intellectual Property Indemnity
The Service Provider shall defend, indemnify and hold the Clinic, and the Clinic’s officers, directors, shareholders, parents, subsidiaries, agents, insurers, successors, and assigns, harmless from and against any third party claim of Intellectual Property infringement in any way related to the Clinic’s exercise of its rights under these Terms, provided that the Clinic promptly notifies the Service Provider in writing of any such claim and allows the Service Provider to control, and fully co-operates with the Service Provider in, the defense of any such claim and all related settlement negotiations. In the event an injunction is sought or obtained against the Clinic’s use of the Licensed Material as a result of any such infringement claim, the Service Provider may, at its sole option and expense procure for the Clinic the right to continue using the affected Licensed Material or replace or modify the affected Licensed Material so that it does not infringe. In the event of a third party claim of Intellectual Property infringement, the Clinic’s sole remedy shall be limited to the foregoing.
6.2.	Mutual Indemnification
Each party shall be liable to the other for, and shall indemnify, defend and hold harmless the other party and its representatives, successors and assigns from and against any third party claims for any claims or losses in connection with or relating to any of the following in the performance of these Terms:
(a)	negligent acts or omissions by the indemnifying party or its representatives;
(b)	the indemnifying party’s breach of its obligations with respect to the other party’s Confidential Information (including for greater certainty Personal Information if applicable);
(c)	any willful misconduct or fraud, or any theft or other misappropriation of the other party’s property or funds by the indemnifying party or its representatives; and
(d)	any violation of any applicable laws by the indemnifying party or its representatives.
6.3.	Limitations of Liability
(a)	Neither party will be liable to the other for any special or incidental damages, loss of profits (other than the charges due and payable for the Licensed Material rendered), loss of business, all forms of consequential or indirect damages, and any exemplary or punitive damages, even if it has been advised of the possibility of such damages.
(b)	Except as provided in Section 7.3(c), each party’s total liability to the other arising out of, resulting from or in connection with these Terms, whether in contract or in tort (including breach of warranty, negligence and strict liability in tort), will be limited to an amount equal to the total amount paid or payable to the Service Provider pursuant to these Terms for the twelve (12) months prior to the month in which the most recent event giving rise to liability occurred.
(c)	The exclusions and limitations in Subsections (a) and (b) do not apply to any claims or losses attributable to the parties’ indemnification obligations under Section 7.2(b).

7. – General
7.1.	Assignment
Neither party may assign any of its rights or obligations hereunder without the prior written consent of the other party. Notwithstanding the foregoing, either party may assign these Terms to an entity that directly or indirectly controls, is controlled by or is under common control of that party or to a purchaser of substantially all of its assets without the prior consent of the other party. Either party shall be relieved of its obligations hereunder to the extent that such obligations are assumed in writing by such assignee.
7.2.	Enurement
These Terms will enure to the benefit of and be binding upon each party and its successors and permitted assigns.
7.3.	Further Assurances
The parties agree to execute any other instruments and further documents which may be necessary to carry out the purpose and intent of these Terms.
7.4.	Waiver
A party’s waiver of any provision of these Terms will not be effective unless it is signed by that party, and then will only be effective in the specific instance and for the purpose for which given.
7.5.	Severability
The invalidity or unenforceability of any provision of these Terms herein contained shall not affect the validity or enforceability of any other provision herein contained that remains capable of substantial performance and any such invalid or unenforceable provision shall be deemed to be severable.
7.6.	No Agency
The parties are and shall at all times remain independent parties and nothing contained herein shall be construed as constituting a partnership, joint venture or agency between the parties.
7.7.	Entire Agreement
These Terms set out the entire understanding between the parties with respect to its subject matter, and supersedes all prior agreements and communications between the parties, whether oral or written. There are no representations, warranties or conditions, expressed or implied, statutory or otherwise, other than those contained herein. These Terms may only be amended by written agreement of the parties.
7.8.	Governing Law and Jurisdiction
These Terms shall be governed and construed in accordance with the laws of the Province of Saskatchewan and the federal laws of Canada applicable therein, and each party attorns to the exclusive jurisdiction of the courts of the Province of Saskatchewan.
7.9.	Survival of Covenants
All covenants, conditions, representations, warranties, obligations and guarantees of either party that, by their nature, impliedly or expressly, will survive termination of these Terms.
7.10.	Force Majeure
If either party is delayed in the performance of its obligations under these Terms due to fire, flood or other unusually severe weather conditions, insurrection, riots, acts of the enemy, pandemic, public health orders, or other similar causes reasonably beyond their control, and which party could not prevent or provide against by the exercise of commercially reasonable diligence, then the time for performance of the obligations of that party under these Terms that are prevented or delayed by such event will be extended for a period equivalent to the time lost by reason of the event, provided that: (a) the other party is given written notice within five business days of the commencement of the delay; and (b) the party giving such notice takes reasonable steps to mitigate the effects of the event upon its performance of its obligations under these Terms.
7.11.	Changes to Application License Terms
The Service Provider may modify these Terms from time to time. If the Clinic does not agree to any modifications, the Clinic should stop its use of Tetherport. The Clinic’s or its Authorized Users’ continued use of Tetherport now, or following the posting of notice of any changes in these Terms, will constitute a binding acceptance by the Clinic of these Terms, and any subsequent modifications. The Clinic can review the most current version of the Terms at any time within Tetherport.
7.12.	Contact
Should you have any questions concerning these Terms of Use, please contact the Service Provider:

3344 Albert Street
Regina
Saskatchewan
S4S 3P1
Email: info@tetherport.ca
        </pre>
    </ng-container>
    <ng-container *ngIf="privacyPolicyAgreement">
        <div class="modal-title mb-3" i18n>
            Tetherport Privacy and Security Policy
        </div>
        <pre class="p-3 mb-4 scrollable">
The Tetherport Privacy and Security Policy was last updated on January 25, 2021.

1.	Background
102104853 Saskatchewan Ltd. (the "Company") is committed to safeguarding the personal and personal health information entrusted to us by healthcare providers and their patients through Tetherport. The Company has policies, procedures and guidelines in place designed to protect personal and personal health information, in accordance with applicable Canadian privacy laws (ex. the Personal Information Protection and Electronic Documents Act (Canada) ("PIPEDA") and The Health Information Protection Act (Saskatchewan) ("HIPA")). This Policy outlines the principles and practices the Company follows in protecting personal and personal health information.

2.	How is personal and personal health information collected, used and disclosed in connection with Tetherport?
a.	Patient's Personal and Personal Health Information
Through Tetherport, the Company is providing a service which enables healthcare providers and clinic administrative staff to securely share personal and personal health information for the purposes of managing patient referrals and collaborating with respect to patient diagnosis and care.
Healthcare providers will collect personal and personal health information directly from the individual patients. Each healthcare provider is responsible for collecting, using and disclosing the personal and personal health information of an individual patient in accordance with applicable privacy laws and ethical standards.
The Company will not use a patient's personal health information or a patient's identifiable personal information for any purpose. The Company may use a patient's anonymized personal information to plan, evaluate, monitor and improve the Tetherport application. Any personal information used for this purpose will be fully anonymized and aggregated.
The Company will not trade, rent, sell, use or disclose any of a patient's personal and personal health information for any other purpose, unless it has the patient's consent or is otherwise authorized by law.
b.	Healthcare Provider's Personal Information
The Company collects, uses and discloses the personal information of healthcare providers, as necessary, for the purpose of providing and supporting Tetherport and each healthcare provider's user account.
Healthcare providers should also be aware that anonymous technical information may be collected by the Company through the use of the Tetherport application. For example, this information may include the healthcare provider's IP address, browser type, operating system, access times and information about the way they use Tetherport. The Company uses this anonymous technical information to track usage patterns for purposes such as improving the user experience and improving the operation and content of the Tetherport application, and compiling aggregate and statistical information. The Company reserves the right to disclose such aggregate information to third parties.
The Company will not trade, rent, sell, use or disclose any of the healthcare provider's personal information for any other purpose, unless it has the healthcare provider's consent or is otherwise authorized by law.

3.	How is consent obtained for Tetherport?
a.	Patient Consent
The Company does not intend to collect, use or disclose any personal health information, and accordingly the Company is not responsible for obtaining or managing the consent of individual patients.
Before a patient's personal and/or personal health information is shared or disclosed in Tetherport, the patient's consent must be obtained in accordance with applicable health privacy laws. Healthcare providers are responsible for obtaining and managing consent from each individual patient.
b.	Healthcare Provider Consent
Healthcare providers who create a user account and use Tetherport to collaborate and share patient information must consent prior to using the application. Such consent may be express or implied.
It is assumed that by continuing to use Tetherport or deal with the Company after having had this Policy available to review that the healthcare provider consents to the collection, use and, where applicable, disclosure of personal information for the purposes for which the information was collected and as are set out in this Policy.
c.	Withdrawal of Consent
Patient
A request to withdraw consent by a patient must be made by contacting the applicable healthcare provider. The Company cannot respond to patient requests to withdraw consent.
Healthcare Provider
A healthcare provider may withdraw consent to the Company's collection, use and disclosure of personal information at any time, with reasonable notice, subject to applicable laws and contracts, and unless the personal information is necessary for the Company to fulfill its legal obligations. However, if consent is withheld or withdrawn, the Company may not be able to fulfill the purpose(s) for which the information was collected, or fulfill any obligations it may owe to the healthcare provider in connection with such purposes.
A request to withdraw consent by the healthcare provider in relation to the Tetherport application may be made by contacting the Company at the address noted below.

4.	Who is accountable for the personal and personal health information in Tetherport?
The Company acts as an information management service provider to trustees/custodians/caretakers (as defined under the applicable privacy law) of personal and personal health information, such as the patient's healthcare provider. Responsibility for the patient's personal and personal health information transmitted through Tetherport remains at all times with the applicable healthcare provider. The Company assumes no ownership in, nor custody or control of, patient personal and personal health information transmitted or stored using the Tetherport application.
The healthcare provider is responsible and accountable for the personal and personal health information of the patient in Tetherport, as well as for compliance with applicable privacy legislation, including HIPA and PIPEDA. The healthcare provider will be accountable to address all access/amendment requests and any privacy or security concerns as per this Policy.
The Company is responsible for compliance with applicable privacy legislation and the Company's privacy policies and procedures.

5.	Can personal and personal health information be accessed or amended?
a.	Patient
Patients have a right to access and amend their personal and personal health information pursuant to applicable privacy law. The Company is an information management services provider to the healthcare provider and accordingly any questions regarding accessing and/or amending a patient's personal and personal health information must be addressed to the patient's healthcare provider.
If any questions or concerns arise regarding the Company's use of personal and personal health information outside of the arrangement with the healthcare provider, please contact the Company at the address noted below.
b.	Healthcare Provider
Healthcare providers also have a right to access and amend their personal information pursuant to applicable privacy law. Any requests for access or amendment to a healthcare provider's personal information entered in Tetherport may be made by the healthcare provider by contacting the Company at the address noted below.

6.	What safeguards are in place to protect personal and personal health information?
Privacy safeguards, as outlined in applicable privacy laws including PIPEDA and HIPA, apply to the personal and personal health information in Tetherport. Personal and personal health information in Tetherport is kept in strict confidence and is only used or disclosed in accordance with this Policy or as authorized or required by law.
In addition, the Company has reasonable security safeguards in place, including policies, practices, and computer systems that are designed to protect information from theft, unauthorized use, error, or loss. These safeguards include, but are not limited to, the following:
•	Tetherport is password protected and encrypted;
•	Tetherport may only be accessed by registered users using their individual IDs and passwords;
•	Tetherport has a timeout function so that a healthcare provider is automatically logged off the system for non-use;
•	The Company requires employees who have access to personal health information to complete privacy training and sign an appropriate oath of confidentiality committing to maintain the confidentiality of any personal health information accessed;
It is important to note that Tetherport works by sending the patient's personal and personal health information, using encryption technology, via the internet. The internet is not a secure method of communication, and the Company cannot guarantee the privacy or security of information sent via the internet. No security or encryption the Company provides can protect against every circumstance. The Company will continue to monitor security issues and will update and improve security when it is reasonable and practical to do so.

Passwords
Healthcare providers will be assigned a user account and password. The healthcare provider will be required or permitted to set or change the password to their user account from time to time. Certain password selection rules will be automatically enforced at the time of selection. In addition, any password selected shall:
•	Be different from any other personal identification number or other secret code used for any other type of services;
•	Not contain any information about the healthcare provider that may be easily obtained or guessed by someone else (such as name, birth date, telephone number, address or username); and
•	Not be changed to any password previously used by the healthcare provider.
Healthcare providers are responsible for maintaining the security of their user account and password. In addition, healthcare providers are responsible for every action undertaken on Tetherport using their account and password.
Mobile Device Security
Healthcare providers are also responsible for the general security associated with personal devices that may be used to access Tetherport. The Company suggests that healthcare providers take additional measures to protect the security of such devices, including:
•	All devices used to access Tetherport should be protected by mobile encryption. Encryption prevents unauthorized users from being able to access the data on the device.
•	Ensure that Tetherport is only accessed from a secure device as the security of the Tetherport application relies on the underlying device's security. Jailbroken or rooted devices break the underlying security model of the phone and should not be used to access to use Tetherport.
•	Protect physical access to a device used to access Tetherport by requiring a long, complex password that must be re-entered frequently or a fingerprint scan. Users should not leave their mobile device unattended while logged into the Tetherport application and should log off immediately at the completion of each access.
•	If Tetherport is being accessed through a Wi-Fi hotspot, ensure that only secure Wi-Fi connections are used. Most public Wi-Fi hotspots do not encrypt the information being sent and are not secure.
•	Ensure that a device used to access Tetherport has up-to-date virus protection software and update any applications installed on the device and the device's operating system when new versions are available, as updates may include security patches.
•	Ensure that the functionality to remotely locate a lost or stolen device is enabled, as well as the functionality to, when necessary, remotely wipe all relevant data from a lost or stolen device.

7.	How can the Company be contacted if questions or concerns arise about the privacy or security of personal and personal health information in Tetherport?
The Company's Privacy Officer is responsible to receive and address privacy-related complaints and concerns associated with Tetherport. If you have a privacy-related complaint or concern regarding Tetherport, we encourage you to contact the Company's Privacy Officer at the following:  	Deryl Dangstorp 	Email: deryl@tetherport.ca
If the Company's Privacy Officer is unable to resolve your privacy-related complaint or concern to your satisfaction, you may contact the Saskatchewan Information and Privacy Commissioner for additional assistance and information. This provincial office oversees HIPA in Saskatchewan. The contact information for the Saskatchewan Information and Privacy Commissioner is as follows:
Saskatchewan Information and Privacy Commissioner
503 - 1801 Hamilton Street
Regina, SK
S4P 4B4
Phone: (306) 787-8350
Toll Free Phone (within Saskatchewan): 1-877-748-2298
As an alternative, you can contact the Office of the Privacy Commissioner in your jurisdiction.
        </pre>
    </ng-container>
    <div class="footer-button">
        <button class="teth-button grey-button" (click)="closeModal()" i18n>Close</button>
        <button class="teth-button blue-button ml-3" (click)="agree()" i18n>I Agree</button>
    </div>
</div>
