import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-input-modal',
    templateUrl: './input-modal.component.html',
    styleUrls: ['./input-modal.component.scss', '../../stylesheet/stylesheet.component.scss']
})
export class InputModalComponent implements OnInit {
    titleText = '';
    selectionItems: any[] = null;
    placeholderText = '';
    selectionDisplayMethod: (item: any) => string = null;
    selectedItem: any = null;
    showNumberInput = false;
    numberInput: string = null;
    textInput: string = null;
    showTextInput = false;
    checkboxItems: any[] = null;
    selectedCheckboxes: any[] = null;
    showCheckboxInput = false;
    customCheckboxClass = '';
    cancelButtonText = $localize`Cancel`;
    saveButtonText = $localize`Save`;
    preselectedCheckboxIds: number[] = null;
    filterProperty: string = null;
    disabled: boolean = false;

    static defaultModalOptions(): any {
        return {size: 'md', backdrop: 'static', centered: true};
    }

    constructor(private activeModal: NgbActiveModal) {
    }

    ngOnInit() {

    }

    initWithSelectionList(title: string, items: any[], selectedItem: any, selectionPlaceholderText: string, displayMethod: (item: any) => string, cancelText: string = null, saveText: string = null) {
        this.titleText = title;
        this.selectionItems = items;
        this.selectedItem = selectedItem;
        this.placeholderText = selectionPlaceholderText;
        this.selectionDisplayMethod = displayMethod;
        if (cancelText) {
            this.cancelButtonText = cancelText;
        }
        if (saveText) {
            this.saveButtonText = saveText;
        }
    }

    initWithNumberInput(title: string, initialValue: number, placeholderText: string, cancelText: string = null, saveText: string = null) {
        this.titleText = title;
        this.placeholderText = placeholderText;
        this.numberInput = initialValue?.toString();
        this.showNumberInput = true;
        if (cancelText) {
            this.cancelButtonText = cancelText;
        }
        if (saveText) {
            this.saveButtonText = saveText;
        }
    }

    initWithTextInput(title: string, initialValue: string, placeholderText: string, cancelText: string = null, saveText: string = null) {
        this.titleText = title;
        this.placeholderText = placeholderText;
        this.textInput = initialValue;
        this.showTextInput = true;
        if (cancelText) {
            this.cancelButtonText = cancelText;
        }
        if (saveText) {
            this.saveButtonText = saveText;
        }
    }

    initWithCheckboxInput(title: string, items: any[], cancelText: string = null, saveText: string = null,
                          populateCheckboxes: boolean,
                          selectedCheckboxes: any[] = null,
                          filterProperty: string = 'id',
                          disabled: boolean = false) {
        this.titleText = title;
        this.checkboxItems = items;
        this.showCheckboxInput = true;
        this.filterProperty = filterProperty;
        this.disabled = disabled;
        if (cancelText) {
            this.cancelButtonText = cancelText;
        }
        if (saveText) {
            this.saveButtonText = saveText;
        }
        if (populateCheckboxes) {
            this.populateCheckboxes(selectedCheckboxes);
        }
    }

    populateCheckboxes(selectedCheckboxes) {
        this.preselectedCheckboxIds = selectedCheckboxes.map(x => x[this.filterProperty]);
        this.checkboxItems.forEach(item => {
           if (this.preselectedCheckboxIds.includes(item.id)) {
               this.checkboxClicked(item);
           }
        });
    }

    isCheckboxChecked(checkbox: any): boolean {
        return (this.selectedCheckboxes?.findIndex(p => p.id === checkbox.id) ?? -1) > -1;
    }

    checkboxClicked(checkbox: any) {
        if (this.selectedCheckboxes == null) {
            this.selectedCheckboxes = [];
        }
        if (!this.isCheckboxChecked(checkbox)) {
            this.selectedCheckboxes.push(checkbox);
        } else {
            const existingPermissionIndex = this.selectedCheckboxes.findIndex(p => p.id === checkbox.id);
            this.selectedCheckboxes.splice(existingPermissionIndex, 1);
        }
    }

    closeModal() {
        this.activeModal.dismiss();
    }

    save() {
        if (this.selectionItems != null) {
            if (this.showCheckboxInput) {
                this.activeModal.close({
                    selectedItem: this.selectedItem,
                    selectedCheckboxes: this.selectedCheckboxes
                });
            } else {
                this.activeModal.close(this.selectedItem);
            }
        } else if (this.showNumberInput) {
            this.activeModal.close(this.numberInput);
        } else if (this.showCheckboxInput) {
            this.activeModal.close(this.selectedCheckboxes);
        } else if (this.showTextInput) {
            this.activeModal.close(this.textInput);
        }
    }
}
