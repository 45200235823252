import {Deserializable} from '../views/shared/utils/json-utils';

export class CorrespondenceStatus implements Deserializable {
    static PendingId = 1;
    static ApprovedId = 2;

    public id: number;
    public name: string;

    onDeserialize() {
    }
}
