import {Component, Input, OnInit} from '@angular/core';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
    selector: 'app-loading',
    templateUrl: './loading.component.html',
    styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {

    @Input() fullscreen: boolean = false;
    @Input() determinateLoading: boolean = false;
    @Input() progress = 0;
    @Input() loadingText: string;
    @Input() backgroundColor: string;
    @Input() spinnerColor: string;
    @Input() color: string;
    @Input() topMarginRem: number = 0;

    constructor(
        private spinner: NgxSpinnerService
    ) {
    }

    ngOnInit(): void {
        if (!this.backgroundColor || this.backgroundColor === '') {
            this.backgroundColor = 'rgba(55, 55, 55, 0.9)';
        }
        if (!this.color || this.color === '') {
            this.color = '#FFF';
        }
        if (!this.spinnerColor || this.spinnerColor === '') {
            this.spinnerColor = this.color;
        }
        this.spinner.show();
    }

}
