import {Deserializable, DeserializeHelper} from "../views/shared/utils/json-utils";
import {FeeGuideProcedureFee} from "./fee-guide-procedure-fee.model";

export class FeeGuideProcedure implements Deserializable {
    id: string;
    feeGuideId: string;
    specialtyId: number;
    specialty: string;
    procedureId: number;
    procedure: string;
    defaultAverageFeeAmount: number;
    overrideDefaultAverageFeeAmount: number;
    fees: FeeGuideProcedureFee[] = [];
    updatedDate: string;
    updatedById: number;
    updatedBy: string;
    createdDate: string;
    createdById: number;
    createdBy: string;

    numberOfIncompleteProcedureFees(): number {
        return this.fees?.filter(fee => !fee.isComplete())?.length ?? 0;
    }

    onDeserialize() {
        this.fees = DeserializeHelper.arrayOf(FeeGuideProcedureFee, this.fees) ?? [];
        this.fees?.sort((a, b) => a?.feeName?.localeCompare(b?.feeName));
    }
}
