import {Address} from './address.model';
import {TimeZone} from './time-zone.model';
import {Deserializable} from '../views/shared/utils/json-utils';
import {SpecialtyType} from './specialty-type.model';
import {Attachment} from './attachment.model';
import {DateFormatType} from './date-format-type';
import {ExpeditedManufacturingRequest} from './expedited-manufacturing-request.model';

export class Location implements Deserializable {
    public id: number;
    public companyId: number;
    public name: string;
    public address: Address;
    public timezone: TimeZone;
    public phoneNumber: string;
    public toothChartRegion: string;
    public toothChartRegionId: number;
    public toothChartRegionCode: string;
    public enabled: boolean;
    public acceptingReferrals: boolean;
    public createdDate: Date;
    public createdById: number;
    public createdBy: string;
    public recentReferrals: number;
    public preferredLocation: boolean;
    public dateFormatId: number;
    public dateFormat: string;
    public referralInformation: string;
    public dentalLabExpeditedManufacturingRequest: ExpeditedManufacturingRequest;

    onDeserialize() {
    }
}

