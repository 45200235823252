import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgModule} from '@angular/core';
import {LocationStrategy, HashLocationStrategy} from '@angular/common';

import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {PerfectScrollbarConfigInterface} from 'ngx-perfect-scrollbar';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true
};

import {AppComponent} from './app.component';

// Import containers
import {DefaultLayoutComponent} from './containers';

const APP_CONTAINERS = [
    DefaultLayoutComponent
];

import {
    AppAsideModule,
    AppBreadcrumbModule,
    AppHeaderModule,
    AppFooterModule,
    AppSidebarModule,
} from '@coreui/angular';

// Import routing module
import {AppRoutingModule} from './app.routing';

// Import 3rd party components
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {TabsModule} from 'ngx-bootstrap/tabs';
import {ChartsModule} from 'ng2-charts';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {SidebarNavComponent} from './views/sidebar-nav/sidebar-nav.component';
import {ToastrModule} from 'ngx-toastr';
import {SignInModule} from './views/sign-in/sign-in.module';
import {ProfileModule} from './views/profile/profile.module';
import {CorrespondenceModule} from './views/correspondence/correspondence.module';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {RouteReuseStrategy} from '@angular/router';
import {CustomReuseStrategy} from './views/shared/utils/cache-route-reuse.strategy';
import {AuthInterceptor} from './services/interceptors/auth.interceptor';
import {SignUpModule} from './views/sign-up/sign-up.module';

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        AppAsideModule,
        AppBreadcrumbModule.forRoot(),
        AppFooterModule,
        AppHeaderModule,
        AppSidebarModule,
        PerfectScrollbarModule,
        BsDropdownModule.forRoot(),
        TabsModule.forRoot(),
        ToastrModule.forRoot({
            closeButton: true,
            timeOut: 5000,
            positionClass: 'toast-bottom-center',
            preventDuplicates: true,
            resetTimeoutOnDuplicate: true,
            maxOpened: 1,
        }),
        ChartsModule,
        HttpClientModule,
        SignInModule,
        SignUpModule,
        ProfileModule,
        CorrespondenceModule,
    ],
    declarations: [
        AppComponent,
        SidebarNavComponent,
        ...APP_CONTAINERS,
    ],
    providers: [
        NgbActiveModal,
        {
            provide: LocationStrategy,
            useClass: HashLocationStrategy
        },
        {
            provide: RouteReuseStrategy,
            useClass: CustomReuseStrategy
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
