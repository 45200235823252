import {Component, Input, OnInit} from '@angular/core';
import DateUtils from '../../utils/date-utils';
import {ReleaseNote, ReleaseNotes} from '../../../../models/releaseNote.model';

export type ReleaseNoteChangeType = 'NewLocation' | 'Feature' | 'BugFix';

@Component({
    selector: 'app-release-notes',
    templateUrl: './release-notes.component.html',
    styleUrls: ['./release-notes.component.scss']
})
export class ReleaseNotesComponent implements OnInit {

    @Input() public releaseNote: ReleaseNote;

    constructor() {
    }

    ngOnInit(): void {
    }

    formatReleaseNoteDate(date: Date): String {
        return DateUtils.formatDateToReadableStringUTC(date);
    }

    filterReleaseNoteChangesByType(notes: ReleaseNotes[], type: ReleaseNoteChangeType) {
        return notes.filter(r => r.changeType === type);
    }
}
