import {Deserializable} from '../views/shared/utils/json-utils';

export class LabsReferralStatusType implements Deserializable {
    static StatusNewId = 1;
    static StatusInProgressId = 2;
    static StatusDeliveryPendingId = 3;
    static StatusDeliveredId = 4;

    public id: number;
    public name: string;

    public getStatusFontColor(): string {
        switch (this.id) {
            case 1:
                return '#001E72';
            case 2:
                return '#784700';
            case 3:
                return '#FC8B38';
            case 4:
                return '#085E28';
            default:
                return '#000000';
        }
    }

    public getStatusIndicatorColor(): string {
        switch (this.id) {
            case 1:
                return '#BCCEFF';
            case 2:
                return '#FFE8C8';
            case 3:
                return '#FFE8C8';
            case 4:
                return '#D2FFE3';
            default:
                return '#747781';
        }
    }

    public static getStatusColorClass(id: number): string {
        let colorClass = 'greyStatus';
        switch (id) {
            case this.StatusNewId:
                colorClass = 'blueStatus';
                break;
            case this.StatusInProgressId:
                colorClass = 'yellowStatus';
                break;
            case this.StatusDeliveryPendingId:
                colorClass = 'greenStatus';
                break;
            case this.StatusDeliveredId:
                colorClass = 'greenStatus';
                break;
        }
        return colorClass;
    }

    public static getStatusColorFromClass(colorClass: string, muted: boolean): string {
        let hexCode = '';
        switch (colorClass) {
            case 'blueStatus':
                hexCode = muted ? '#DEECFF' : '#388EFC';
                break;
            case 'greenStatus':
                hexCode = muted ? '#D0F2DD' : '#63D58F';
                break;
            case 'darkGreenStatus':
                hexCode = muted ? '#B7D5C2' : '#006C28';
                break;
            case 'orangeStatus':
                hexCode = muted ? '#FDDCC3' : '#FC8B38';
                break;
            case 'yellowStatus':
                hexCode = muted ? '#FEF2C9' : '#FFD74B';
                break;
            case 'redStatus':
                hexCode = muted ? '#FDCCCC' : '#FA5555';
                break;
            case 'greyStatus':
                hexCode = muted ? '#9FA2AD' : '#747781';
                break;
        }
        return hexCode;
    }

    public static getStatusCountProperty(id: number): string {
        let countProperty = null;
        switch (id) {
            case 1:
                countProperty = 'newCount';
                break;
            case 2:
                countProperty = 'inProgressCount';
                break;
            case 3:
                countProperty = 'deliveryPendingCount';
                break;
            case 4:
                countProperty = 'deliveredCount';
                break;
        }
        return countProperty;
    }

    onDeserialize() {
    }
}
