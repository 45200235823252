export enum TimeRangeFilterId {
    yearToDate = 'yearToDate',
    lastYear = 'lastYear',
    last2Years = 'last2Years',
    custom = 'custom',
    monthOverMonth = 'monthOverMonth',
    quarterOverQuarter = 'quarterOverQuarter',
    last4Months = 'last4Months',
    last6Months = 'last6Months',
    last90Days = 'last90Days',
}
