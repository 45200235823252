import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {CorrespondenceRoutingModule} from './correspondence-routing.module';
import {CorrespondenceComponent} from './correspondence.component';
import {SharedModule} from '../shared/shared.module';
import {IndividualCorrespondenceComponent} from './individual-correspondence/individual-correspondence.component';
import {FormsModule} from '@angular/forms';
import {NgbModal, NgbModalModule} from '@ng-bootstrap/ng-bootstrap';
import {BrowserModule} from '@angular/platform-browser';
import {NgxDocViewerModule} from 'ngx-doc-viewer';



@NgModule({
    declarations: [
        CorrespondenceComponent,
        IndividualCorrespondenceComponent
    ],
    exports: [
        IndividualCorrespondenceComponent,
    ],
    imports: [
        CommonModule,
        CorrespondenceRoutingModule,
        NgbModalModule,
        SharedModule,
        FormsModule,
        NgxDocViewerModule
    ]
})
export class CorrespondenceModule {
}
