import {Address} from '../../../models/address.model';

export default class AddressUtils {
    static singleLineFormat(address: Address): string {
        if (address == null) {
            return null;
        }

        const addressComponents: string[] = [];
        if (address.addressLine1?.length > 0) {
            addressComponents.push(address.addressLine1);
        }
        if (address.addressLine2?.length > 0) {
            addressComponents.push(address.addressLine2);
        }
        if (address.locality?.length > 0) {
            addressComponents.push(address.locality);
        }
        if (address.stateCode?.length > 0) {
            addressComponents.push(address.stateCode);
        }
        if (address.postalCode?.length > 0) {
            addressComponents.push(address.postalCode);
        }
        return addressComponents.join(', ');
    }
}
