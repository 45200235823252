import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {FeeGuideService} from "../../../../services/fee-guide.service";
import {FeeGuide} from "../../../../models/fee-guide.model";
import {map} from "rxjs/operators";
import DateUtils from "../../utils/date-utils";
import {BehaviorSubject, combineLatest, Observable} from "rxjs";

@Component({
  selector: 'app-fee-guide-procedures-container',
  templateUrl: './fee-guide-procedures-container.component.html',
  styleUrls: ['./fee-guide-procedures-container.component.scss']
})
export class FeeGuideProceduresContainerComponent implements OnChanges {

  constructor(protected feeGuideService: FeeGuideService) {
  }

  @Input() feeGuide: FeeGuide;
  @Input() forSpecialtyId: number;

  private _feeGuide = new BehaviorSubject<FeeGuide>(null);
  public feeGuide$ = this._feeGuide as Observable<FeeGuide>;

  public feeGuideExpired$ = this.feeGuide$.notNull().pipe(
      map(feeGuide => feeGuide?.endDate ? DateUtils.dateIsBeforeToday(new Date(feeGuide?.endDate)) : false)
  );

  private _forSpecialtyId = new BehaviorSubject<number>(null);
  public forSpecialtyId$ = this._forSpecialtyId as Observable<number>;

  public procedures$ = combineLatest([this.feeGuide$, this.forSpecialtyId$]).pipe(
      map(([feeGuide, specialtyId]) => {
        return feeGuide?.procedures?.filter(procedure => procedure?.specialtyId === specialtyId);
      })
  );

  ngOnChanges(changes: SimpleChanges) {
    if (changes.feeGuide) {
      this._feeGuide.next(this.feeGuide);
    }
    if (changes.forSpecialtyId) {
      this._forSpecialtyId.next(this.forSpecialtyId);
    }
  }

}
