import {Deserializable} from '../views/shared/utils/json-utils';

export class Permission implements Deserializable {
    public id: number;
    public name: string;
    public description: string;

    onDeserialize() {
    }
}

