import {Deserializable} from '../views/shared/utils/json-utils';
import {Location} from './location.model';

export class PreferredClinicLocation implements Deserializable {
    public id: number;
    public companyId: number;
    public locationId: number;
    public preferredClinicCompanyId: number;
    public preferredClinicCompany: string;
    public preferredClinicLocationId: number;
    public preferredClinicLocation: string;

    static fromLocation(location: Location): PreferredClinicLocation {
        const p = new PreferredClinicLocation();
        p.preferredClinicLocationId = location.id;
        p.preferredClinicCompanyId = location.companyId;
        return p;
    }

    onDeserialize() {
    }
}
