import {Insurance} from './insurance.model';
import {Deserializable} from '../views/shared/utils/json-utils';
import {Address} from './address.model';

export class Patient implements Deserializable {
    public id: string;
    public firstName: string;
    public lastName: string;
    public birthdate: Date;
    public homePhone: string;
    public mobilePhone: string;
    public workPhone: string;
    public email: string;
    public genderTypeId: number;
    public gender: string;
    public practiceManagementPatientId: string;
    public insurance: Insurance[];
    public address: Address;
    public updatePracticeManagementSystem: boolean;

    // Additional properties
    public fullName: string;

    static getSearchableProperties(): string[] {
        return [
            'firstName',
            'lastName',
            'email',
        ];
    }

    onDeserialize() {
        this.fullName = `${this.firstName} ${this.lastName}`;
        this.birthdate = this.birthdate ? new Date(this.birthdate) : null;
    }
}
