import {Deserializable} from '../views/shared/utils/json-utils';

export class Procedure implements Deserializable {
    public id: number;
    public name: string;
    public specialtyType: string;
    public specialtyTypeId: number;

    onDeserialize() {
    }
}
