import {Component, OnInit} from '@angular/core';
import {AccountService} from '../../services/account.service';
import {SessionService} from '../../services/session.service';
import {EmployeeService} from '../../services/employee.service';
import {PermissionService} from '../../services/permission.service';
import {EntityService} from '../../services/entity.service';
import {Location} from '../../models/location.model';
import {AssignedLocation, AssignedPractitioner, AssignedProcedure, Employee} from '../../models/employee.model';
import {Permission} from '../../models/permission.model';
import {PermissionCategory} from '../../models/permission-category.model';
import {Role} from '../../models/role.model';
import {BehaviorSubject, forkJoin, Observable, of} from 'rxjs';
import {AbstractControl, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {Location as _Location} from '@angular/common';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UploadImageModalComponent} from '../shared/components/upload-image-modal/upload-image-modal.component';
import {PasswordResetModalComponent} from '../shared/components/password-reset-modal/password-reset-modal.component';
import {RoleModalComponent} from '../shared/components/role-modal/role-modal.component';
import {InputModalComponent} from '../shared/input-modal/input-modal.component';
import {ComponentCanDeactivate} from '../../services/can-deactivate-guard.service';
import {SpecialtyType} from '../../models/specialty-type.model';
import {ToastrService} from 'ngx-toastr';
import {Procedure} from '../../models/procedure.model';
import {Attachment} from '../../models/attachment.model';
import AddressUtils from '../shared/utils/address-utils';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {ImageService} from '../../services/image.service';
import {CompanyType} from '../../models/company.model';
import UserUtils from '../shared/utils/user-utils';
import {tap} from 'rxjs/operators';


@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss', '../stylesheet/stylesheet.component.scss']
})
export class ProfileComponent implements OnInit, ComponentCanDeactivate {
    companyTypes = CompanyType;
    locations = new BehaviorSubject<Location[]>(null);
    companyPractitioners = new BehaviorSubject<Employee[]>(null);
    companyEmployees = new BehaviorSubject<Employee[]>(null);
    employee = new BehaviorSubject<Employee>(null);
    employeeRole = new BehaviorSubject<Role>(null);
    companyRoles = new BehaviorSubject<Role[]>(null);
    employeePhoto: Attachment = null;
    userId: number = null;
    addingEmployee: boolean = false;
    isLoading = true;
    locationPercentageChecked: number;
    practitionerPercentageChecked: number;
    formSnapshot: any;
    isEmployeeDentist = false;
    employeeCreatedSuccessfully = false;
    addressUtils = AddressUtils;
    currentCompanyType: CompanyType;
    selectedSpecialty: SpecialtyType;
    specialtyTypes = new BehaviorSubject<SpecialtyType[]>(null);
    specialtyProcedures: Procedure[] = [];
    permissions: PermissionCategory[] = [];
    imageToUpload: string = null;
    deleteEmployeePhotoId: string;
    disableFields: boolean = false;

    employeeForm: FormGroup = new FormGroup({
        firstName: new FormControl(null, Validators.required),
        lastName: new FormControl(null, Validators.required),
        prefix: new FormControl(null),
        // email: new FormControl(null, Validators.email),
        role: new FormControl(null, Validators.required),
        username: new FormControl(null, Validators.pattern('[A-Za-z0-9]+')),
        locationCheckbox: new FormControl([], Validators.required),
        allLocationsCheckbox: new FormControl(null),
        practitionerCheckbox: new FormControl([]),
        allPractitionersCheckbox: new FormControl(null),
        password: new FormControl(null),
        confirmPassword: new FormControl(null),
        // university: new FormControl(null),
        // degree: new FormControl(null),
        // gradYear: new FormControl(null),
        // yearsPracticing: new FormControl(null),
        // bio: new FormControl(null),
        enabled: new FormControl(true),
    });
    specialtyForm = new FormGroup({
        specialty: new FormControl('')
    });
    prefixes = UserUtils.prefixes;

    constructor(private accountService: AccountService,
                private sessionService: SessionService,
                private employeeService: EmployeeService,
                private route: ActivatedRoute,
                private modalService: NgbModal,
                private _location: _Location,
                private entityService: EntityService,
                private permissionService: PermissionService,
                private router: Router,
                private toastr: ToastrService,
                private sanitizer: DomSanitizer,
                public imageService: ImageService) {
    }

    ngOnInit(): void {
        this.sessionService.sessionContainer.notNull().subscribe(s => {
            this.currentCompanyType = s.company.companyTypeId;
        });

        this.getSpecialtyTypes();
        this.getRoles();

        this.route.params.subscribe(params => {
            this.userId = params['userId'];
            if (params['add']) {
                this.addingEmployee = true;
                this.employeeForm.setValidators(this.passwordRequirements);
            }
            if (this.userId && !this.addingEmployee) { // edit user flow
                this.loadUserFromId();
                this.employeeForm.controls.username.disable();
            } else if (!this.addingEmployee && !this.userId) { // viewing own profile
                this.loadUserFromSession();
                this.employeeForm.disable();
                this.specialtyForm.disable();
                this.disableFields = true;
            } else { // add user flow
                this.employee.next(new Employee());
                this.getCompanyData();
                this.formSnapshot = this.employeeForm.value;
            }
        });
    }

    logOut() {
        this.accountService.signOut();
    }

    public loadUserFromId() {
        this.loadEmployee();
        this.sessionService.userLocations.subscribe((locations) => {
            if (locations?.length > 0) {
                this.locations.next(locations);
            }
        });
    }

    public loadUserFromSession() {
        this.getCompanyData();
        this.sessionService.sessionContainer.firstNotNull().subscribe((s) => {
            this.employee.next(s.employee);
            this.employeeService.getRoleById(this.employee.value.companyId.toString(), this.employee.value.roleId?.toString()).subscribe(role => {
                this.employeeRole.next(role);
                this.getEmployeePhoto();
                this.getInitials();
                this.populateFields();
            }, () => {
                this.toastr.error($localize`Loading employee role failed`);
            });
        });
    }

    public getInitials(): string {
        if (this.employee.value !== null) {
            return this.employee.value.primaryName[0] + this.employee.value.familyName[0];
        }
    }

    getEmployeePhoto() {
        this.imageService.getEmployeePhotos(this.employee.value).subscribe(photos => {
            if (photos != null) {
                this.employeePhoto = photos[0];
            }
        });
    }

    public populateFields() {
        const employee = this.employee.value;
        if (employee !== null && this.locations) {
            this.employeeForm.setValue({
                firstName: employee.primaryName,
                lastName: employee.familyName,
                prefix: employee.prefix ?? 'N/A',
                // email: e.email ?? 'N/A',
                role: this.employeeRole.value.id ?? -1,
                locationCheckbox: employee.assignedLocations.map(l => l.locationId),
                allLocationsCheckbox: false,
                practitionerCheckbox: employee.assignedPractitioners.map(p => p.practitionerEmployeeId),
                allPractitionersCheckbox: false,
                username: employee.userName ?? '',
                password: 'placeholder',
                confirmPassword: 'placeholder',
                // university: e.university ?? '',
                // degree: e.degree ?? '',
                // gradYear: e.graduationYear ?? '',
                // yearsPracticing: e.yearsPracticing ?? '',
                // bio: e.biography ?? '',
                enabled: employee.enabled ?? false
            });
            this.specialtyForm.setValue({
                specialty: employee.specialtyTypeId ?? -1,
            });

            this.specialtyProcedures = employee.assignedProcedures?.map(ap => {
                const sp = new Procedure();
                sp.id = ap.procedureTypeId;
                sp.name = ap.procedure;
                return sp;
            });

            this.setCheckAllState();
            this.isEmployeeDentist = employee.isDentist;
            this.formSnapshot = this.employeeForm.value;
            this.isLoading = false;
        }
    }

    private loadEmployee() {
        this.sessionService.sessionContainer.firstNotNull().subscribe(s => {
            this.employeeService.getEmployee(this.sessionService.getCompanyId(), this.userId).subscribe(e => {
                this.employeeService.getRoleById(e.companyId.toString(), e.roleId?.toString()).subscribe(role => {
                    this.employee.next(e);
                    this.employeeRole.next(role);
                    this.getCompanyData();
                    this.getEmployeePhoto();
                    this.getInitials();
                    this.populateFields();
                }, () => {
                    this.toastr.error($localize`Loading employee role failed`);
                });
            });
        });
    }

    public goBack() {
        this._location.back();
    }

    addProfilePicture() {
        this.showUploadImageModal();
    }

    disableEmployee() {
        this.employeeForm.patchValue({enabled: false});
    }

    enableEmployee() {
        this.employeeForm.patchValue({enabled: true});
    }

    addCustomRole() {
        this.showAddNewRoleModal();
    }

    deletePicture() {
        this.imageToUpload = null;
        if (this.employeePhoto) {
            this.deleteEmployeePhotoId = this.employeePhoto.id;
            this.employeePhoto = null;
        }
    }

    showAddNewRoleModal() {
        const modalRef = this.modalService.open(RoleModalComponent, RoleModalComponent.defaultModalOptions());
        this.permissionService.currentUserRole.notNull().subscribe(c => {
            this.employeeService.getPermissions().subscribe(permissions => {
                this.permissions = this.permissionService.getCategorizedPermissions(permissions);
                (modalRef.componentInstance as RoleModalComponent)
                    .initWith($localize`Add New Role`, '', this.permissions, true);
                modalRef.result.then((success) => {
                    if (success) {
                        this.employeeService.getRolesForCompany(this.sessionService.getCompanyId().toString()).subscribe(roles => {
                            this.companyRoles.next(roles.filter(r => r.enabled));
                            this.employeeForm.patchValue({role: this.companyRoles.value.slice(-1)[0]});
                        });
                    }
                });
            });
        });
    }

    showRoleInfoModal() {
        let permissionsFromRolePermissions: Permission[];
        const modalRef = this.modalService.open(RoleModalComponent, RoleModalComponent.defaultModalOptions());
        const selectedRole = this.companyRoles.value.find(role => role.id === +this.employeeForm.value.role);
        permissionsFromRolePermissions = this.permissionService.rolePermissionToPermission(selectedRole.permissions);
        this.permissions = this.permissionService.getCategorizedPermissions(permissionsFromRolePermissions);
        (modalRef.componentInstance as RoleModalComponent)
            .initWith($localize`${selectedRole.name}:roleName: Role Info`, selectedRole.name, this.permissions, false);
    }

    showUploadImageModal() {
        const modalRef = this.modalService.open(UploadImageModalComponent, {size: 'md', centered: true});
        (modalRef.componentInstance as UploadImageModalComponent)
            .initWith($localize`Add New Picture`, $localize`Edit Picture`);
        modalRef.result.then(r => {
            if (r != null) {
                this.deletePicture();
                this.imageToUpload = r;
                this.employeeForm.markAsDirty();
            }
        }, () => {
        });
    }

    showPasswordResetModal(changePassword: boolean) {
        const modalRef = this.modalService.open(PasswordResetModalComponent, {
            size: 'md',
            backdrop: 'static',
            centered: true
        });
        (modalRef.componentInstance as PasswordResetModalComponent)
            .initWith(this.employee.value, changePassword);
        modalRef.result.then(r => {
            if (r != null) {
            }
        }, () => {
        });
    }

    public getRoles() {
        this.employeeService.getRolesForCompany(this.sessionService.getCompanyId().toString()).subscribe(roles => {
            this.companyRoles.next(roles?.filter(r => r.enabled));
        });
    }

    public getSpecialtyTypes() {
        this.entityService.getSpecialties().subscribe(specialties => {
            this.specialtyTypes.next(specialties);
        });
    }

    public getCompanyData() {
        const userLocations: Location[] = [];
        const locationData = this.entityService.getLocations(this.sessionService.getCompanyId()).pipe(tap(locations => {
            this.locations.next(locations.filter(l => l.enabled));
            if (!this.addingEmployee && !this.userId) {
                this.employee.value.assignedLocations.forEach(al => {
                    this.locations.value.forEach(l => {
                        if (al.locationId === l.id) {
                            userLocations.push(l);
                        }
                    });
                });
                this.locations.next(userLocations);
            } else if (this.userId && !this.addingEmployee) {
                this.locations.next(locations.filter(l => l.enabled));
            }

        }));

        const employeeData = this.employeeService.getEmployeesForCompany(this.sessionService.getCompanyId()).pipe(tap(employees => {
            this.companyEmployees.next(employees ?? []);
            this.companyPractitioners.next((employees ?? []).filter(e => {
                return e.enabled && e.isDentist && e.id !== this.employee.value?.id;
            }));
        }));

        forkJoin([employeeData, locationData]).subscribe(() => {
            this.isLoading = false;
            if (this.addingEmployee) {
                this.selectAllPractitioners(true);
            }
        });
    }

    public selectAllClicked(event) {
        const checked = event.target.checked;
        this.locationPercentageChecked = checked ? 1 : 0;
        if (checked) {
            this.employeeForm.controls.locationCheckbox.setValue(this.locations.value?.map(l => l.id));
        } else {
            this.employeeForm.controls.locationCheckbox.setValue([]);
        }
        this.validateAssignedLocations();
    }

    public locationCheckboxSelected(event, location: Location) {
        const checked = event.target.checked;
        if (checked) {
            this.employeeForm.value.locationCheckbox.push(location.id);
        } else {
            const locationIndex = this.employeeForm.value.locationCheckbox.findIndex(l => location.id === l);
            this.employeeForm.value.locationCheckbox.splice(locationIndex, 1);
        }
        this.setCheckAllState();
        this.validateAssignedLocations();
    }

    public setCheckAllState() {
        this.locationPercentageChecked = this.employeeForm.value.locationCheckbox?.length / this.locations.value?.length;
        this.employeeForm.patchValue({allLocationsCheckbox: this.locationPercentageChecked === 1});
    }

    public selectAllPractitionersClicked(event) {
        const checked = event.target.checked;
        this.selectAllPractitioners(checked);
    }

    selectAllPractitioners(selectAll: boolean) {
        this.practitionerPercentageChecked = selectAll ? 1 : 0;
        if (selectAll) {
            this.employeeForm.controls.practitionerCheckbox.setValue(this.companyPractitioners.value?.map(p => p.id));
        } else {
            this.employeeForm.controls.practitionerCheckbox.setValue([]);
        }
    }

    public practitionerCheckboxSelected(event, practitioner: Employee) {
        const checked = event.target.checked;
        if (checked) {
            this.employeeForm.value.practitionerCheckbox.push(practitioner.id);
        } else {
            const practitionerIndex = this.employeeForm.value.practitionerCheckbox.findIndex(l => practitioner.id === l);
            this.employeeForm.value.practitionerCheckbox.splice(practitionerIndex, 1);
        }
        this.setPractitionerCheckAllState();
    }

    public setPractitionerCheckAllState() {
        this.practitionerPercentageChecked = this.employeeForm.value.practitionerCheckbox?.length / this.companyPractitioners.value?.length;
        this.employeeForm.patchValue({allPractitionersCheckbox: this.practitionerPercentageChecked === 1});
    }

    public onSpecialtyChange() {
        this.selectedSpecialty = new SpecialtyType();
        this.selectedSpecialty = Object.assign(this.selectedSpecialty, this.specialtyTypes.value.filter(s => s.id === this.specialtyForm.value.specialty));
        // required to silence error, known ng-bootstrap issue/bug
        this.specialtyForm.controls.specialty.markAsTouched();
        const modalRef = this.modalService.open(InputModalComponent, InputModalComponent.defaultModalOptions());
        (modalRef.componentInstance as InputModalComponent)
            .initWithCheckboxInput($localize`Select Procedures`, this.selectedSpecialty[0].procedures, null, null, false);
        modalRef.result.then((success) => {
            if (success) {
                this.specialtyProcedures = success;
            }
        }, (reject) => {
            if (this.addingEmployee) {
                this.isEmployeeDentist = false;
            }
        });
    }

    viewEditProcedures() {
        this.selectedSpecialty = new SpecialtyType();
        this.selectedSpecialty = Object.assign(this.selectedSpecialty, this.employee.value.specialtyType, this.specialtyTypes.value.filter(s => s.id === this.specialtyForm.value.specialty));
        this.specialtyForm.controls.specialty.markAsTouched();
        const modalRef = this.modalService.open(InputModalComponent, InputModalComponent.defaultModalOptions());
        (modalRef.componentInstance as InputModalComponent)
            .initWithCheckboxInput($localize`Select Procedures`,
                this.employee.value.specialtyTypeId ? this.specialtyTypes.value.find((s) => s.id === this.employee.value.specialtyTypeId).procedures : this.selectedSpecialty[0].procedures,
                null,
                null,
                true,
                this.specialtyProcedures?.length > 0 ? this.specialtyProcedures : this.employee.value.assignedProcedures,
                this.specialtyProcedures?.length > 0 ? 'id' : 'procedureTypeId',
                this.disableFields);
        modalRef.result.then((success) => {
            if (success) {
                this.specialtyProcedures = success;
            }
        }, (reject) => {

        });

    }

    public validateAssignedLocations() {
        const valid = this.employeeForm.value.locationCheckbox.length > 0;
        if (valid) {
            this.employeeForm.controls.locationCheckbox.setErrors(null);
            return false;
        } else {
            this.employeeForm.controls.locationCheckbox.setErrors({notValid: true});
            return true;
        }
    }

    generateEmployee(): Employee {

        const assignedLocations = this.employeeForm.value.locationCheckbox.map(id => {
            const al = new AssignedLocation();
            al.locationId = id;
            return al;
        });

        const assignedPractitioners = this.employeeForm.value.practitionerCheckbox.map(id => {
            const ap = new AssignedPractitioner();
            ap.practitionerEmployeeId = id;
            return ap;
        });

        const generatedEmployee = new Employee();
        if (this.employee.value) {
            Object.assign(generatedEmployee, this.employee.value);
        }

        generatedEmployee.primaryName = this.employeeForm.value.firstName.trim();
        generatedEmployee.familyName = this.employeeForm.value.lastName.trim();
        if (this.addingEmployee) {
            generatedEmployee.userName = this.employeeForm.value.username ?? generatedEmployee.primaryName + generatedEmployee.familyName;
            generatedEmployee.password = this.employeeForm.value.password;
        }
        generatedEmployee.prefix = this.employeeForm.value.prefix;
        generatedEmployee.roleId = +this.employeeForm.value.role;
        generatedEmployee.assignedLocations = assignedLocations;
        generatedEmployee.assignedPractitioners = assignedPractitioners;
        generatedEmployee.isDentist = this.isEmployeeDentist;
        generatedEmployee.specialtyTypeId = this.selectedSpecialty ? this.selectedSpecialty[0].id : null;
        generatedEmployee.specialtyType = this.selectedSpecialty ? this.selectedSpecialty[0].name : null;
        generatedEmployee.assignedProcedures = this.specialtyProcedures.map(s => new AssignedProcedure(s));
        // generatedEmployee.university = this.employeeForm.value.university;
        // generatedEmployee.degree = this.employeeForm.value.degree;
        // generatedEmployee.graduationYear = this.employeeForm.value.graduationYear;
        // generatedEmployee.yearsPracticing = this.employeeForm.value.yearsPracticing;
        // generatedEmployee.biography = this.employeeForm.value.bio;
        generatedEmployee.enabled = this.employeeForm.value.enabled;

        if (this.imageToUpload?.length > 0) {
            this.employeePhoto = new Attachment();
            this.employeePhoto.fileName = generatedEmployee.primaryName + generatedEmployee.familyName + new Date().getTime() + '.png';
            this.employeePhoto.mediaType = 'image/png';
        }

        return generatedEmployee;
    }

    public saveEmployee() {
        if (!this.employeeForm.valid) {
            this.toastr.error($localize`Missing required fields`);
            return;
        } else {
            this.isLoading = true;
            const generatedEmployee = this.generateEmployee();
            if (!generatedEmployee.roleId) {
                this.isLoading = false;
                console.warn('Attempting to save employee without roleId.');
                return;
            }

            const saveEmployeeObservable = this.addingEmployee
                ? this.employeeService.createEmployee(generatedEmployee, this.sessionService.getCompanyId())
                : this.employeeService.updateEmployee(generatedEmployee, this.sessionService.getCompanyId(), this.userId);
            saveEmployeeObservable.subscribe(res => {
                this.saveOtherEmployeeSettings(res).subscribe(() => {
                    if (this.addingEmployee) {
                        this.employeeCreatedSuccessfully = true;
                    }
                    this.toastr.success($localize`Employee Saved Successfully`);
                    this.router.navigate([`/employees`]);
                });
            }, err => {
                console.log(err);
                this.toastr.error($localize`Save Employee Failed`);
                this.isLoading = false;
            });
        }
    }

    saveOtherEmployeeSettings(updatedEmployee: Employee): Observable<any> {
        const obs: Observable<any>[] = [];

        obs.push(this.saveEmployeePhoto(updatedEmployee));
        obs.push(this.saveAssignedPractitionerForAllOtherEmployees(updatedEmployee));

        return forkJoin(obs);
    }

    saveEmployeePhoto(employee: Employee): Observable<any> {
        if (!this.imageToUpload && !this.deleteEmployeePhotoId) {
            return of(null);
        } else {

            const obs: Observable<any>[] = [];

            if (this.imageToUpload) {
                obs.push(this.imageService.uploadEmployeeImage(this.employeePhoto?.fileName, this.employeePhoto?.mediaType, this.imageToUpload, employee));
            }

            if (this.deleteEmployeePhotoId) {
                obs.push(this.imageService.deleteEmployeePhoto(this.deleteEmployeePhotoId, employee.id));
            }

            return forkJoin(obs);
        }
    }

    saveAssignedPractitionerForAllOtherEmployees(employee: Employee): Observable<any> {
        if (!this.addingEmployee || !employee.isDentist || !this.companyEmployees.value || this.companyEmployees.value.length === 0) {
            return of(null);
        }

        const obs: Observable<any>[] = [];
        const allOtherEmployees = this.companyEmployees.value.filter(e => e.enabled && e.id !== employee.id);
        const ap = new AssignedPractitioner();
        ap.practitionerEmployeeId = employee.id;
        allOtherEmployees.forEach(e => {
            e.assignedPractitioners.push(ap);
            obs.push(this.employeeService.updateEmployee(e, e.companyId, e.id));
        });
        return forkJoin(obs);
    }

    canDeactivate() {
        if (this.employeeCreatedSuccessfully || this.isLoading) {
            return true;
        }
        return JSON.stringify(this.formSnapshot) === JSON.stringify(this.employeeForm.value) && this.imageToUpload === null;
    }

    passwordRequirements(c: AbstractControl) {
        const password = c.get('password').value as string;
        if (!password?.match('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$')) {
            return {passwordRequirementsNotMet: true};

        }
        if (password !== c.get('confirmPassword').value) {
            return {passwordNotMatching: true};
        }
        return null;
    }

    getLocationDisplayPhoto(location: Location): Observable<string | SafeResourceUrl> {
        if (location) {
            return this.imageService.getLocationImage(location, 'medium');
        } else {
            return of(null);
        }
    }

    getPractitionerDisplayPhoto(practitioner: Employee): Observable<string | SafeResourceUrl> {
        if (practitioner) {
            return this.imageService.getEmployeeImage(practitioner, 'medium');
        } else {
            return of(null);
        }
    }

    getSaveButtonTitle(): string {
        return this.addingEmployee ? $localize`Save New Employee` : $localize`Save Changes`;
    }
}
