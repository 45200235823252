import {Deserializable} from '../views/shared/utils/json-utils';

export class FilterableDoctor implements Deserializable {
    public id: number;
    public primaryName: string;
    public familyName: string;

    onDeserialize() {
    }

    // Returns a display name in the format "Dr. <familyName>, <primaryName>"
    public getDisplayName() {
        return `Dr. ${this.familyName}, ${this.primaryName}`;
    }

    // Returns a full name in the format "<primaryName> <familyName>"
    public getFullName() {
        return `${this.primaryName} ${this.familyName}`;
    }

}
