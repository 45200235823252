import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Address} from '../../../../models/address.model';
import {EntityService} from '../../../../services/entity.service';
import {FormControl} from '@angular/forms';
import {Country} from '../../../../models/country.model';

@Component({
    selector: 'app-auto-complete-address',
    templateUrl: './auto-complete-address.component.html',
    styleUrls: ['./auto-complete-address.component.scss', '../../../stylesheet/stylesheet.component.scss']
})
export class AutoCompleteAddressComponent implements OnInit, AfterViewInit {
    id = Math.random().toString(36).substring(2);

    @ViewChild('autoCompleteInput') autoCompleteInput: ElementRef;
    @Input() control: FormControl;
    @Input() label: string;
    @Output() addressSelected = new EventEmitter<Address>();
    @Input() countryData: Country[];

    constructor(private entityService: EntityService) {
    }

    ngOnInit(): void {
        this.entityService.countries$.firstNotNull().subscribe(c => {
            this.countryData = c;
        });
    }

    ngAfterViewInit() {
        const autocomplete = new google.maps.places.Autocomplete(this.autoCompleteInput.nativeElement,
            {
                componentRestrictions: { country: ['US', 'CA'] },
                types: ['address']  // 'establishment' / 'address' / 'geocode'
            });
        google.maps.event.addListener(autocomplete, 'place_changed', () => {
            const place = autocomplete.getPlace();
            this.convertPlaceToAddress(place);
        });
    }


    private convertPlaceToAddress(place: google.maps.places.PlaceResult) {
        if (place == null) {
            return;
        }
        const countries = this.countryData;
        const address = new Address();
        const streetNumber = place.address_components.find(c => c.types.includes('street_number'))?.long_name;
        const route = place.address_components.find(c => c.types.includes('route'))?.long_name;
        address.addressLine1 = `${streetNumber ?? ''} ${route}`;
        address.locality = place.address_components.find(c => c.types.includes('locality'))?.long_name;
        address.postalCode = place.address_components.find(c => c.types.includes('postal_code'))?.long_name;
        const state = place.address_components.find(c => c.types.includes('administrative_area_level_1'));
        address.state = state?.long_name;
        address.stateCode = state?.short_name;
        const country = place.address_components.find(c => c.types.includes('country'));
        address.country = country?.long_name;
        address.countryCode = country?.short_name;
        const lookupCountry = countries?.find(c => c.countryCode === address.countryCode);
        const lookupState = lookupCountry?.states?.find(s => s.stateCode === address.stateCode);
        address.countryId = lookupCountry?.id;
        address.stateId = lookupState?.id;

        address.latitude = place.geometry.location.lat();
        address.longitude = place.geometry.location.lng();

        this.addressSelected.emit(address);
    }
}
