<img class="teth-logo" [src]="'assets/img/brand/tetherport-logo.png'" alt="logo">
<ul class="nav-list teth-menu-nav">
    <ng-container *ngFor="let navItem of navItems">
        <li class="nav-item teth-nav-item"
            (click)="navItemSelected(navItem)"
            [class.active]="isNavItemActive(navItem)"
            [class.company-picker-nav]="navItem.routerPath === 'company-picker-modal'"
            *ngIf="!isNavItemHidden(navItem)">
            <div class="nav-item-edit-company"
                 *ngIf="navItem.routerPath === 'company-picker-modal'"
                 i18n>
                Now Editing
            </div>
            <div class="nav-item-icon">
                <img [src]="navItem.iconSrc" class="nav-bar-icon" alt="star">
                <img [src]="navItem.iconFilledSrc" class="nav-bar-icon-filled" alt="star-filled">
            </div>
            <div class="nav-item-label">
                {{navItem.name}}
            </div>
            <div *ngIf="getBadgeContentForNavItem(navItem)"
                 class="nav-item-notification-pill">{{getBadgeContentForNavItem(navItem)}}</div>
        </li>
        <li [hidden]="!isNavItemActive(navItem) || isNavItemHidden(childNavItem)"
            *ngFor="let childNavItem of navItem.childNavItems"
            class="child-nav-item"
            (click)="navItemSelected(childNavItem)"
            [class.active]="isNavItemActive(childNavItem)">
            {{childNavItem.name}}
            <div *ngIf="getBadgeContentForNavItem(childNavItem)"
                 class="nav-item-notification-pill">{{getBadgeContentForNavItem(childNavItem)}}</div>
        </li>
    </ng-container>
</ul>
<div class="teth-sidebar-footer">
    <div class="language" (click)="changeLanguage()" i18n>Français</div>
    <div class="app-version">{{appVersionText}}</div>
</div>

