import { Pipe, PipeTransform } from '@angular/core';
import {GroupOption} from "../../../../../models/group-option";
import {Observable} from "rxjs";
import {DataTableExtraFilterOptions} from "../../../../../models/data-table-options";
import {map} from "rxjs/operators";

@Pipe({
  name: 'optionIsSelectedFromAllFilterGroups'
})
export class OptionIsSelectedFromAllFilterGroupsPipe implements PipeTransform {

  transform(groupOption: GroupOption, fitlerOptions$: Observable<DataTableExtraFilterOptions>): Observable<boolean> {
    return fitlerOptions$.pipe(
        map(options => {
          const selectedOptions = options?.selectionOptions?.flatMap((g => g?.selectedOptions));
          return selectedOptions?.some(o => o?.getUniqueIdentifier() === groupOption?.getUniqueIdentifier());
        })
    );
  }

}
