import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-text-group',
  templateUrl: './text-group.component.html',
  styleUrls: ['./text-group.component.scss', '../../../../stylesheet/stylesheet.component.scss'],
})
export class TextGroupComponent implements OnInit {

  @Input() placeholderText = '';
  @Input() textLabel = '';
  @Input() showCheckbox = false;
  @Input() checkboxLabel = '';
  @Input() textId = '';
  @Input() checkId = '';
  @Input() parentForm: FormGroup;
  @Input() required: boolean = false;

  @Output() public textfieldChangedEmitter = new EventEmitter();
  @Output() public checkboxChangedEmitter = new EventEmitter();

  constructor() { }

  ngOnInit(): void {

  }

  checkboxChanged() {
    const checkboxControl = this.parentForm.get(this.checkId);
    this.checkboxChangedEmitter.emit(checkboxControl.value);
  }

  textfieldChanged() {
    const textfieldControl = this.parentForm.get(this.textId);
    this.textfieldChangedEmitter.emit(textfieldControl.value);
  }



}
