import {Deserializable, DeserializeHelper} from "../views/shared/utils/json-utils";
import {FeeGuideProcedure} from "./fee-guide-procedure.model";
import {formatDate} from "@angular/common";

export class FeeGuide implements Deserializable {

    id: string;
    regionId: string;
    region: string;
    startDate: string;
    endDate: string;
    changeCount: number;
    procedures: FeeGuideProcedure[] = [];
    updatedDate: string;
    updatedById: number;
    updatedBy: string;
    createdDate: string;
    createdById: number;
    createdBy: string;

    onDeserialize() {
        this.procedures = DeserializeHelper.arrayOf(FeeGuideProcedure, this.procedures) ?? [];
        this.procedures?.sort((a: FeeGuideProcedure, b: FeeGuideProcedure) => a?.procedure?.localeCompare(b?.procedure));
    }

    numberOfIncompleteProcedureFees(): number {
        return this.procedures
            ?.map(procedure => procedure.numberOfIncompleteProcedureFees())
            ?.reduce((a, b) => a + b, 0) ?? 0;
    }

    getCoverageString(separator = $localize`to`): string {
        const startDate = formatDate(this?.startDate, 'MMM d, y', 'en-US');
        const endDate = formatDate(this?.endDate, 'MMM d, y', 'en-US');
        return `${startDate} ${separator} ${endDate}`;
    }

    static isCurrentActiveFeeGuide(feeGuide: FeeGuide): boolean {
        if (!feeGuide) return false;
        const startDate = new Date(feeGuide?.startDate);
        const endDate = new Date(feeGuide?.endDate);
        const currentDate = new Date();
        return startDate <= currentDate && endDate >= currentDate;
    }

    static mostRecentFeeGuide(feeGuides: FeeGuide[]): FeeGuide {
        return feeGuides?.reduce((mostRecentFeeGuide, currentValue) => {
            const mostRecentStartDate = new Date(mostRecentFeeGuide?.startDate);
            const currentStartDate = new Date(currentValue?.startDate);
            return mostRecentStartDate > currentStartDate ? mostRecentFeeGuide : currentValue;
        });
    }

    static feeGuideForDate(feeGuides: FeeGuide[], date: Date): FeeGuide {
        return feeGuides?.find(feeGuide => {
            const startDate = new Date(feeGuide?.startDate);
            const endDate = new Date(feeGuide?.endDate);
            return startDate <= date && endDate >= date;
        });
    }


}
