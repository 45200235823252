import * as moment from 'moment';
import {TimeRangeFilterId} from './enum/time-range-filter-id.enum';
import {UniquelyIdentifiable} from '../shared/interfaces/uniquely-identifiable';

export class TimeRangeFilter implements UniquelyIdentifiable {
    public name: string;
    public customName: string;
    public startDate: Date;
    public endDate: Date;
    public customDateRange: boolean;
    public id: string;

    public static lastNDays(days: number): TimeRangeFilter {
        const timeRange = new TimeRangeFilter();
        const startDate = moment().utc(true).subtract(days, 'days').startOf('day').toDate();
        const endDate = moment().utc(true).endOf('day').toDate();
        timeRange.startDate = startDate;
        timeRange.endDate = endDate;
        return timeRange;
    }

    public static lastNMonths(months: number): TimeRangeFilter {
        const timeRange = new TimeRangeFilter();
        const endDate = moment().utc().endOf('month').toDate();
        const subtractBy = months >= 12 ? 0 : 1;
        timeRange.startDate = moment(endDate).subtract(months - subtractBy, 'months').startOf('month').toDate();
        timeRange.endDate = endDate;
        return timeRange;
    }

    public static yearToDate(): TimeRangeFilter {
        const timeRange = new TimeRangeFilter();
        const startDate = new Date(new Date().getFullYear(), 0, 1);
        const endDate = new Date();
        timeRange.startDate = startDate;
        timeRange.endDate = endDate;
        return timeRange;
    }

    public static customRange(): TimeRangeFilter {
        const timeRange = new TimeRangeFilter();
        timeRange.customDateRange = true;
        timeRange.id = TimeRangeFilterId.custom;
        return timeRange;
    }

    public static last4Months(): TimeRangeFilter {
        const timeRange = new TimeRangeFilter();
        const startDate = moment().utc().subtract(3, 'months').startOf('month').toDate();
        const endDate = moment().utc().endOf('month').toDate();
        timeRange.startDate = startDate;
        timeRange.endDate = endDate;
        return timeRange;
    }

    public static quarterOverQuarter(): TimeRangeFilter {
        const timeRange = new TimeRangeFilter();
        const startDate = moment().utc().subtract(1, 'year').toDate();
        const endDate = new Date();
        timeRange.startDate = startDate;
        timeRange.endDate = endDate;
        return timeRange;
    }

    public static monthOverMonth(): TimeRangeFilter {
        const timeRange = new TimeRangeFilter();
        const startDate = moment().utc().subtract(1, 'year').startOf('month').toDate();
        const endDate = new Date();
        timeRange.startDate = startDate;
        timeRange.endDate = endDate;
        return timeRange;
    }

    public setCustomName(dateFormat: string) {
        if (this.startDate && this.endDate) {
            this.customName = moment(this.startDate).format(dateFormat) + ' - ' + moment(this.endDate).format(dateFormat);
        }
    }

    public roundToFullMonths() {
        this.startDate = moment(this.startDate).startOf('month').toDate();
        this.endDate = moment(this.endDate).endOf('month').toDate();
    }

    public clearCustomRange() {
        if (this.customDateRange) {
            this.customName = '';
            this.startDate = null;
            this.endDate = null;
        }
    }

    getUniqueIdentifier(...opts: any): string {
        return `${this.name}
      -${this.id}
      -${this.startDate?.toString()}
      -${this.endDate?.toString()}
      -${this.customName}
      -${this.customDateRange}`;
    }
}
