import {Deserializable} from '../views/shared/utils/json-utils';

export class ReferralDuplicateCheck implements Deserializable {
    public patientFirstName: string;
    public patientLastName: string;
    public referringLocationId: number;
    public referredLocationId: number;
    public specialtyTypeId: number;
    public procedureTypeIds: number[];
    public duplicateReferral: boolean;

    onDeserialize() {
    }
}

export class LabReferralDuplicateCheck implements Deserializable {
    public patientFirstName: string;
    public patientLastName: string;
    public referringLocationId: number;
    public referredCompanyId: number;
    public referredLocationId: number;
    public locationDentalLabsProductTypeId: number;
    public dentalLabsProductTypeId: number;
    public dentalLabsReferralFileTypeId: number;
    public duplicateReferral: boolean;


    onDeserialize() {
    }
}
