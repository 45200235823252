import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {DataTableExtraFilterOptions} from "../../../../models/data-table-options";
import {BehaviorSubject, fromEvent, Observable, of, Subscription} from "rxjs";
import {ExtraFiltersSlideoutService} from "./extra-filters-slideout.service";

@Component({
  selector: 'app-datatable-extra-filters-slideout',
  templateUrl: './datatable-extra-filters-slideout.component.html',
  styleUrls: ['./datatable-extra-filters-slideout.component.scss'],
  providers: [ExtraFiltersSlideoutService]
})
export class DatatableExtraFiltersSlideoutComponent implements OnChanges, AfterViewInit, OnDestroy {

  constructor(private extraFiltersService: ExtraFiltersSlideoutService, private cdr: ChangeDetectorRef) {
  }

  @Input() extraFilters: DataTableExtraFilterOptions[];

  @Output() applyFiltersClicked = new EventEmitter<DataTableExtraFilterOptions[]>();
  @Output() clearAllFiltersClicked = new EventEmitter<void>();
  @Output() closeSlideoutClicked = new EventEmitter<void>();

  @ViewChild('header') header: ElementRef;
  @ViewChild('footer', { read: ElementRef }) footer: ElementRef;

  private resizeSubscription: Subscription;

  public extraFilters$ = this.extraFiltersService.extraFilters$;

  public primaryButtonText$ = of($localize`Apply Filters`);
  public secondaryButtonText$ = of($localize`Clear Filters`);

  private _formContainerHeightInPx = new BehaviorSubject<number>(0);
  public formContainerHeightInPx$ = this._formContainerHeightInPx as Observable<number>;

  public applyFilters(): void {
    this.extraFiltersService.extraFilters$.once(extraFilters => {
      this.applyFiltersClicked.emit(extraFilters);
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.extraFilters) {
      this.extraFiltersService.setExtraFilters(this.extraFilters);
    }
  }

  public ngAfterViewInit(): void {
    this.calculateFormContainerHeight();
    this.resizeSubscription = fromEvent(window, 'resize').subscribe(() => {
      this.calculateFormContainerHeight()
    });
  }

  public ngOnDestroy(): void {
    this.resizeSubscription?.unsubscribe();
  }

  private calculateFormContainerHeight(): void {
    const headerHeightInPx = this.header?.nativeElement?.offsetHeight;
    const footerHeightInPx = this.footer?.nativeElement?.offsetHeight;
    const viewportHeightInPx = window.innerHeight;
    const formContainerHeightInPx = viewportHeightInPx - headerHeightInPx - footerHeightInPx;
    this._formContainerHeightInPx.next(formContainerHeightInPx);
    this.cdr.detectChanges();
  }

  public extraFiltersTrackBy(index: number, extraFilterOptions: DataTableExtraFilterOptions): number {
    return extraFilterOptions?.filterId;
  }

}
