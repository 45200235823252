<div class="modal-content modal-lg">
    <div class="modal-header no-border">
        <h3 class="float-left preview-title">{{title}}</h3>
    </div>
    <div class="modal-body">
        <p class="secondary-status-text" i18n>
            Mark this referral with one of the statuses below to keep everyone updated.
        </p>
        <ng-container *ngFor="let status of stageOptions[stageIndex]" >
            <button (click)="statusButtonClicked(status.id)"
                    [class.selected]="selectAppointmentDate && status.id === scheduledStatusId"
                    class="status-button">
                <img [src]=status.src>
                {{status.title}}
            </button>
            <div *ngIf="selectAppointmentDate && status.id === scheduledStatusId">
                <form [formGroup]="form" class="teth-textfield-form px-0">
                    <ng-container formArrayName="formArray">
                        <div *ngFor="let control of formArray.controls; let i = index; let first = first" class="d-flex flex-column">
                            <div class="row mb-3">
                                <ng-container [formGroupName]="i">
                                    <div class="col-6"
                                         [class.error]="dateInputHasError(i)">
                                        <label class="teth-textfield-label" for="dateField" i18n>Appointment Date</label>
                                        <input class="teth-dropdown teth-textfield-dropdown cursor-pointer"
                                               ngbDatepicker
                                               id="dateField"
                                               [formControlName]="'appointmentDate'"
                                               [readOnly]="true"
                                               [container]="'body'"
                                               name="dateField"
                                               #d="ngbDatepicker"
                                               (click)="d.toggle()"
                                               (dateSelect)="dateSelected($event, i)"
                                               i18n-placeholder
                                               placeholder="Enter Date">
                                        <span
                                                class="input-requirement-state"
                                                [class.teth-textfield-error]="dateInputHasError(i)">
                                {{getDateInputRequirementText(i)}}
                                </span>
                                    </div>
                                    <div class="col-6">
                                        <label class="teth-textfield-label" for="timeField" i18n>Appointment Time</label>
                                        <input class="teth-textfield"
                                               [formControlName]="'appointmentTime'"
                                               type="time"
                                               id="timeField"
                                               name="timeField"
                                               i18n-placeholder
                                               placeholder="Enter a Time">
                                        <span class="input-requirement-state" i18n>Optional</span>
                                    </div>
                                </ng-container>
                            </div>
                            <div class="d-flex justify-content-end">
                                <div
                                        *ngIf="first; else removeAppointment"
                                        class="text-link deep-blue-text-link"
                                        i18n
                                        (click)="resetFormControl()">
                                    Reset Appointment
                                </div>
                                <ng-template #removeAppointment>
                                    <div class="text-link destructive" i18n (click)="removeFormControls(i)">Remove Appointment</div>
                                </ng-template>
                            </div>
                            <div><hr class="mb-4 mt-3"></div>
                        </div>
                        <div
                                class="d-flex align-items-center text-link deep-blue-text-link"
                                [style.max-width]="'fit-content'"
                                [style.gap.rem]="0.25"
                                (click)="addFormControl()">
                            <img src="assets/img/icons/plus-deep-blue.svg" [style.width.rem]="1">
                            <div i18n>Add Additional Appointment</div>
                        </div>
                    </ng-container>
                    <hr class="mb-3 mt-4">
                </form>
            </div>
        </ng-container>
    </div>
    <div class="button-container">
        <button class="teth-button grey-button"
                (click)="closeModal()" i18n>
            Cancel
        </button>
        <button class="teth-button deep-blue-button"
                (click)="updateClicked()"
                [class.disabled]="dateTimeFormsHaveErrors()"
                *ngIf="selectAppointmentDate" i18n>
            Update
        </button>
    </div>
</div>
