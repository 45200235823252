import {AfterViewInit, Component, ElementRef, OnDestroy, Renderer2, ViewChild} from '@angular/core';

@Component({
  selector: 'app-sticky-fixed-height-card',
  templateUrl: './sticky-fixed-height-card.component.html',
  styleUrls: ['./sticky-fixed-height-card.component.scss', '../../../stylesheet/stylesheet.component.scss']
})
export class StickyFixedHeightCardComponent implements AfterViewInit, OnDestroy {

  @ViewChild('card') card: ElementRef;
  @ViewChild('container') container: ElementRef;
  private observer: IntersectionObserver;

  constructor(private renderer: Renderer2) {
  }

  ngAfterViewInit(): void {
    this.observer = new IntersectionObserver((entries) => {
      entries?.forEach(() => {
        this.setCardFixedHeight();
      });
    });
    this.observer?.observe(this.container?.nativeElement);
  }

  setCardFixedHeight(): void {
    const mainContent = document.getElementById('main-content');
    const containerNativeEl = this.container?.nativeElement;
    const cardNativeEl = this.card?.nativeElement;
    const containerTop = containerNativeEl?.getBoundingClientRect()?.top;
    const mainContentPadding = parseInt(window?.getComputedStyle(mainContent)?.paddingBottom);
    const cardBottomBufferInPx = 48;
    const maxHeight = window?.innerHeight - containerTop - mainContentPadding - cardBottomBufferInPx;
    this.renderer.setStyle(cardNativeEl, 'max-height', `${maxHeight}px`);
  };

  ngOnDestroy(): void {
    this.observer?.disconnect();
  }
}
