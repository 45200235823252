import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, Validators} from '@angular/forms';
import PhoneUtils from '../shared/utils/phone-utils';
import {Company, CompanyType} from '../../models/company.model';
import AddressUtils from '../shared/utils/address-utils';
import {Country, StateProvince} from '../../models/country.model';
import {GenderType} from '../../models/gender-type.model';
import {EntityService} from '../../services/entity.service';
import UserUtils from '../shared/utils/user-utils';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {SignUpAgreementModalComponent} from './sign-up-agreement-modal/sign-up-agreement-modal.component';
import ModalUtils from '../shared/utils/modal-utils';
import {AssignedLocation, Employee} from '../../models/employee.model';
import {Address} from '../../models/address.model';
import {Location} from '../../models/location.model';
import {EmployeeService} from '../../services/employee.service';
import {ToastrService} from 'ngx-toastr';
import {ActivatedRoute, Router} from '@angular/router';
import {DateFormatType} from '../../models/date-format-type';
import {Observable, throwError} from 'rxjs';
import {CompanyDuplicateCheck} from '../../models/company-duplicate-check.model';
import {catchError, concatMap} from 'rxjs/operators';
import {GenericModalComponent} from '../shared/components/generic-modal/generic-modal.component';
import LocalizationUtils from '../shared/utils/localization-utils';
import {CompanyReferralSource} from '../../models/company-referral-source.model';
import {environment} from '../../../environments/environment';

@Component({
    selector: 'app-sign-up',
    templateUrl: './sign-up.component.html',
    styleUrls: ['./sign-up.component.scss', '../stylesheet/stylesheet.component.scss']
})
export class SignUpComponent implements OnInit {
    phoneUtils = PhoneUtils;
    companyType = CompanyType;
    addressUtils = AddressUtils;

    creating = false;
    createSuccess = false;
    createdCompanyId: number;
    numberOfSteps = 4;
    currentStep = 1;
    stepsArray: number[] = Array(this.numberOfSteps).fill(0).map((x, i) => i + 1);
    companyInfoForm = new FormGroup({
        name: new FormControl('', Validators.required),
        address1: new FormControl('', Validators.required),
        address2: new FormControl(''),
        lat: new FormControl(''),
        lng: new FormControl(''),
        postalCode: new FormControl('', Validators.required),
        city: new FormControl('', Validators.required),
        state: new FormControl(null, Validators.required),
        country: new FormControl(null, Validators.required),
        phoneNumber: new FormControl('', [Validators.required, Validators.pattern(PhoneUtils.phoneRegex)]),
    });

    clinicInfoForm = new FormGroup({
        name: new FormControl('', Validators.required),
        address1: new FormControl('', Validators.required),
        address2: new FormControl(''),
        lat: new FormControl(''),
        lng: new FormControl(''),
        postalCode: new FormControl('', Validators.required),
        city: new FormControl('', Validators.required),
        state: new FormControl(null, Validators.required),
        country: new FormControl(null, Validators.required),
        phoneNumber: new FormControl('', [Validators.required, Validators.pattern(PhoneUtils.phoneRegex)]),
    });

    adminUserForm = new FormGroup({
        firstName: new FormControl('', Validators.required),
        lastName: new FormControl('', Validators.required),
        prefix: new FormControl('', Validators.required),
        username: new FormControl('', [Validators.required, Validators.pattern(/^[a-zA-Z0-9_]*$/)]),
        isDentist: new FormControl(false, Validators.required),
        password: new FormControl(null),
        confirmPassword: new FormControl(null),
    });

    agreementForm = new FormGroup({
        eula: new FormControl(false, Validators.requiredTrue),
        privacyPolicy: new FormControl(false, Validators.requiredTrue),
    });

    countries: Country[] = null;
    states: StateProvince[] = null;
    genderTypes: GenderType[] = null;
    useCompanyAddressForClinic = true;
    prefixes = UserUtils.prefixes;
    companyReferralSource: CompanyReferralSource = null;

    generatedCompany: Company;
    generatedLocation: Location;
    generatedEmployee: Employee;

    constructor(private entityService: EntityService,
                private employeeService: EmployeeService,
                private toastr: ToastrService,
                private router: Router,
                private activatedRoute: ActivatedRoute,
                private cd: ChangeDetectorRef,
                private modalService: NgbModal) {
    }

    ngOnInit(): void {
        if (environment.administratorAccess) {
            // admin portal does not have access to sign up
            this.router.navigate(['']);
        }

        this.entityService.getCountries().subscribe(c => {
            this.countries = c;
            this.states = c.flatMap(x => x.states);
        });

        this.entityService.getGenderTypes().subscribe(g => {
            this.genderTypes = g;
        });

        this.adminUserForm.setValidators(this.passwordRequirements);

        this.entityService.getCompanyReferralSources().subscribe(sources => {
            this.activatedRoute.queryParams.subscribe(p => {
                if (p['source'] === 'ods') {
                    this.companyReferralSource = sources.find(s => s.id === CompanyReferralSource.DentsplyId);
                }
            });
        });
    }

    getStepText(): string {
        return $localize`Step ${this.currentStep}:currentStep: of ${this.numberOfSteps}:totalSteps:`;
    }

    filterCountryStates(countryId: number) {
        const index = this.countries.findIndex(c => c.id === countryId);
        this.states = this.countries[index].states;
    }

    generateSignUpData() {
        const c = new Company();
        c.name = this.companyInfoForm.controls.name.value;
        c.address = new Address();
        c.address.addressLine1 = this.companyInfoForm.controls.address1.value;
        c.address.addressLine2 = this.companyInfoForm.controls.address2.value;
        c.address.postalCode = this.companyInfoForm.controls.postalCode.value;
        c.address.locality = this.companyInfoForm.controls.city.value;
        c.address.countryId = parseInt(this.companyInfoForm.controls.country.value, null);
        c.address.stateId = parseInt(this.companyInfoForm.controls.state.value, null);
        const selectedCountry = this.countries.find(country => country.id === c.address.countryId);
        c.address.country = selectedCountry.name;
        c.address.state = selectedCountry.states.find(s => s.id === c.address.stateId).name;
        c.phoneNumber = this.companyInfoForm.controls.phoneNumber.value;
        c.companyTypeId = CompanyType.Dentist;
        c.administratorPhoneNumber = c.phoneNumber;
        c.referralSourceId = this.companyReferralSource?.id;
        if (this.companyInfoForm.controls.lat.value && this.companyInfoForm.controls.lng.value) {
            c.address.latitude = parseFloat(this.companyInfoForm.controls.lat.value);
            c.address.longitude = parseFloat(this.companyInfoForm.controls.lng.value);
        }

        const l = new Location();
        if (this.useCompanyAddressForClinic) {
            l.address = c.address;
            l.name = c.name;
            l.phoneNumber = c.phoneNumber;
        } else {
            l.name = this.clinicInfoForm.controls.name.value;
            l.address = new Address();
            l.address.addressLine1 = this.clinicInfoForm.controls.address1.value;
            l.address.addressLine2 = this.clinicInfoForm.controls.address2.value;
            l.address.postalCode = this.clinicInfoForm.controls.postalCode.value;
            l.address.locality = this.clinicInfoForm.controls.city.value;
            l.address.countryId = parseInt(this.clinicInfoForm.controls.country.value, null);
            l.address.stateId = parseInt(this.clinicInfoForm.controls.state.value, null);
            const locationCountry = this.countries.find(country => country.id === l.address.countryId);
            l.address.country = locationCountry.name;
            l.address.state = locationCountry.states.find(s => s.id === l.address.stateId).name;
            l.phoneNumber = this.clinicInfoForm.controls.phoneNumber.value;
            if (this.clinicInfoForm.controls.lat.value && this.clinicInfoForm.controls.lng.value) {
                l.address.latitude = parseFloat(this.clinicInfoForm.controls.lat.value);
                l.address.longitude = parseFloat(this.clinicInfoForm.controls.lng.value);
            }
        }
        l.toothChartRegionId = l.address.countryId;
        l.enabled = true;
        l.dateFormatId = DateFormatType.defaultId;
        l.acceptingReferrals = true;

        const e = new Employee();
        e.primaryName = this.adminUserForm.controls.firstName.value;
        e.familyName = this.adminUserForm.controls.lastName.value;
        e.prefix = this.adminUserForm.controls.prefix.value;
        e.userName = this.adminUserForm.controls.username.value;
        e.isDentist = this.adminUserForm.controls.isDentist.value;
        e.password = this.adminUserForm.controls.password.value;

        this.generatedCompany = c;
        this.generatedLocation = l;
        this.generatedEmployee = e;
    }

    continueClicked() {
        if (this.currentStep === this.numberOfSteps) {
            this.createNewCompany();
            return;
        }

        this.currentStep++;

        if (this.currentStep === this.numberOfSteps) {
            this.generateSignUpData();
        }
    }

    backClicked() {
        this.currentStep--;
    }

    continueButtonEnabled(): boolean {
        if (this.currentStep === 1) {
            return this.companyInfoForm.valid && (this.useCompanyAddressForClinic || this.clinicInfoForm.valid);
        } else if (this.currentStep === 2) {
            return this.adminUserForm.valid;
        } else if (this.currentStep === 3) {
            return this.agreementForm.valid;
        } else if (this.currentStep === 4) {
            return true;
        }
        return false;
    }

    passwordRequirements(c: AbstractControl) {
        const password = c.get('password').value as string;
        if (!password?.match('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$')) {
            return {passwordRequirementsNotMet: true};

        }
        if (password !== c.get('confirmPassword').value) {
            return {passwordNotMatching: true};
        }
        return null;
    }

    viewEULA() {
        const modalRef = this.modalService.open(SignUpAgreementModalComponent, ModalUtils.modalOptions('lg', true));
        (modalRef.componentInstance as SignUpAgreementModalComponent).initWith(true);
        modalRef.result.then(r => {
            if (r === true) {
                this.agreementForm.controls.eula.setValue(true);
            }
        }, () => {
        });
    }

    viewPrivacyPolicy() {
        const modalRef = this.modalService.open(SignUpAgreementModalComponent, ModalUtils.modalOptions('lg', true));
        (modalRef.componentInstance as SignUpAgreementModalComponent).initWith(false);
        modalRef.result.then(r => {
            if (r === true) {
                this.agreementForm.controls.privacyPolicy.setValue(true);
            }
        }, () => {
        });
    }

    createNewCompany() {
        const errorBlock = (error) => {
            this.creating = false;
            this.createSuccess = false;
            if (error != null) {
                this.toastr.error($localize`There was a problem creating this clinic.`);
            }
            console.log(error);
        };

        this.creating = true;
        this.checkForDuplicateCompany(this.generatedCompany).pipe(
            concatMap(() => {
                return this.entityService.createCompany(this.generatedCompany);
            }),
            concatMap(createdCompany => {
                this.createdCompanyId = createdCompany.id;
                this.generatedLocation.companyId = this.createdCompanyId;
                this.generatedEmployee.companyId = this.createdCompanyId;
                return this.entityService.createLocation(this.generatedLocation);
            }),
            concatMap(createdLocation => {
                const assignedLocation = new AssignedLocation();
                assignedLocation.locationId = createdLocation.id;
                this.generatedEmployee.assignedLocations = [assignedLocation];
                return this.employeeService.createCompanyAdministrator(this.generatedEmployee, createdLocation.companyId);
            }),
            catchError(e => throwError(e)))
            .subscribe(() => {
                this.creating = false;
                this.createSuccess = true;
            }, errorBlock);
    }

    checkForDuplicateCompany(company: Company): Observable<void> {
        const companyDuplicateCheck = new CompanyDuplicateCheck();
        companyDuplicateCheck.name = company.name;
        companyDuplicateCheck.longitude = company.address.longitude;
        companyDuplicateCheck.latitude = company.address.latitude;

        const title = $localize`Duplicate Company`;
        const message = $localize`It looks like '${company.name}:companyName:' already exists in Tetherport. Are you sure you want to continue?`;

        return new Observable(s => {
            this.entityService.checkForDuplicateCompany(companyDuplicateCheck).subscribe(result => {
                if (result.duplicateCompany) {
                    this.creating = false;
                    const modalRef = this.modalService.open(GenericModalComponent, GenericModalComponent.defaultModalOptions());
                    (modalRef.componentInstance as GenericModalComponent).initWith(title, message, $localize`Continue`, $localize`Cancel`);
                    modalRef.result.then(r => {
                        if (r === true) {
                            this.creating = true;
                            s.next();
                            s.complete();
                        } else {
                            s.error();
                            s.complete();
                        }
                    });
                } else {
                    s.next();
                    s.complete();
                }
            }, error => {
                console.log(error);
                // if there is an error, just continue
                s.next();
                s.complete();
            });
        });
    }

    getCompanyIdentifier(): string {
        let identifier = this.createdCompanyId?.toString() ?? '';
        while (identifier.length < 4) {
            identifier = '0' + identifier;
        }
        return identifier;
    }

    getStarted() {
        this.router.navigate([`/sign-in/${this.createdCompanyId}/${this.generatedEmployee?.userName}`]);
    }

    autoCompleteAddressSelected(address: Address, form: FormGroup) {
        form.patchValue({
            address1: address?.addressLine1 ?? '',
            postalCode: address?.postalCode ?? '',
            city: address?.locality ?? '',
            state: address?.stateId ?? '',
            country: address?.countryId ?? '',
            lat: address?.latitude ?? '',
            lng: address?.longitude ?? '',
        });
        form.markAllAsTouched();
        this.cd.detectChanges();
    }

    getLocalizedDentistTooltip(): string {
        return $localize`This user is a dentist that will be making specialist referrals and/or creating lab requests`;
    }

    changeLanguage() {
        LocalizationUtils.changeLanguage();
    }
}
