import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

// Import Containers
import {DefaultLayoutComponent} from './containers';
import {SignInComponent} from './views/sign-in/sign-in.component';
import {AuthGuardService} from './services/auth-guard.service';
import {RoleGuardService} from './services/role-guard.service';
import {SignUpComponent} from './views/sign-up/sign-up.component';

export const routes: Routes = [
    {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
    },
    {
        path: 'sign-in',
        component: SignInComponent,
        pathMatch: 'full',
    },
    {
        path: 'sign-up',
        component: SignUpComponent,
        pathMatch: 'full',
    },
    {
        path: 'sign-in/:companyId',
        component: SignInComponent,
        pathMatch: 'full',
    },
    {
        path: 'sign-in/:companyId/:username',
        component: SignInComponent,
        pathMatch: 'full',
    },
    {
        path: '',
        component: DefaultLayoutComponent,
        data: {
            title: 'Home'
        },
        children: [
            {
                path: 'admin-home',
                loadChildren: () => import('./views/admin-home/admin-home.module').then(m => m.AdminHomeModule),
                canActivate: [AuthGuardService, RoleGuardService],
            },
            {
                path: 'admin-reports',
                loadChildren: () => import('./views/admin-reports/admin-reports.module').then(m => m.AdminReportsModule),
                canActivate: [AuthGuardService, RoleGuardService],
            },
            {
                path: 'dashboard',
                loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule),
                canActivate: [AuthGuardService, RoleGuardService],
            },
            {
                path: 'stylesheet',
                loadChildren: () => import('./views/stylesheet/stylesheet.module').then(m => m.StylesheetModule),
                canActivate: [AuthGuardService, RoleGuardService],
            },
            {
                path: 'create-referral',
                loadChildren: () => import('./views/create-referral/create-referral.module').then(m => m.CreateReferralModule),
                canActivate: [AuthGuardService, RoleGuardService],
            },
            {
                path: 'directory',
                loadChildren: () => import('./views/directory/directory.module').then(m => m.DirectoryModule),
                canActivate: [AuthGuardService, RoleGuardService],
            },
            {
                path: 'activity-incoming-referrals',
                loadChildren: () => import('./views/notifications/notifications.module').then(m => m.NotificationsModule),
                canActivate: [AuthGuardService, RoleGuardService],
                data: {
                    incomingReferrals: true
                }
            },
            {
                path: 'activity-outgoing-referrals',
                loadChildren: () => import('./views/notifications/notifications.module').then(m => m.NotificationsModule),
                canActivate: [AuthGuardService, RoleGuardService],
                data: {
                    incomingReferrals: false
                }
            },
            {
                path: 'profile',
                loadChildren: () => import('./views/profile/profile.module').then(m => m.ProfileModule),
                canActivate: [AuthGuardService, RoleGuardService],
            },
            {
                path: 'settings',
                loadChildren: () => import('./views/settings/settings.module').then(m => m.SettingsModule),
                canActivate: [AuthGuardService, RoleGuardService],
            },
            {
                path: 'correspondence-incoming-referrals',
                loadChildren: () => import('./views/correspondence/correspondence.module').then(m => m.CorrespondenceModule),
                canActivate: [AuthGuardService, RoleGuardService],
                data: {
                    incomingReferrals: true
                }
            },
            {
                path: 'correspondence-outgoing-referrals',
                loadChildren: () => import('./views/correspondence/correspondence.module').then(m => m.CorrespondenceModule),
                canActivate: [AuthGuardService, RoleGuardService],
                data: {
                    incomingReferrals: false
                }
            },
            {
                path: 'employees',
                loadChildren: () => import('./views/employees/employees.module').then(m => m.EmployeesModule),
                canActivate: [AuthGuardService, RoleGuardService],
            },
            {
                path: 'procedures',
                loadChildren: () => import('./views/procedures/procedures.module').then(m => m.ProceduresModule),
                canActivate: [AuthGuardService, RoleGuardService],
            },
            {
                path: 'fees',
                loadChildren: () => import('./views/fees/fees.module').then(m => m.FeesModule),
                canActivate: [AuthGuardService, RoleGuardService],
            },
            {
                path: 'manage-referrals-incoming',
                loadChildren: () => import('./views/manage-referrals/manage-referrals.module').then(m => m.ManageReferralsModule),
                canActivate: [AuthGuardService, RoleGuardService],
                data: {
                    incomingReferrals: true
                }
            },
            {
                path: 'manage-referrals-outgoing',
                loadChildren: () => import('./views/manage-referrals/manage-referrals.module').then(m => m.ManageReferralsModule),
                canActivate: [AuthGuardService, RoleGuardService],
                data: {
                    incomingReferrals: false
                }
            },
            {
                path: 'help',
                loadChildren: () => import('./views/help/help.module').then(m => m.HelpModule),
                canActivate: [AuthGuardService, RoleGuardService],
            }
        ]
    },
    {
        path: '**',
        redirectTo: 'sign-in',
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
