import { Pipe, PipeTransform } from '@angular/core';
import {Employee} from "../../../../models/employee.model";
import {ImageService} from "../../../../services/image.service";
import {Observable} from "rxjs";
import {SafeResourceUrl} from "@angular/platform-browser";

@Pipe({
  name: 'getEmployeePhotoSrc'
})
export class GetEmployeePhotoSrcPipe implements PipeTransform {

  transform(employee: Employee, imageService: ImageService, size = 'medium'): Observable<string | SafeResourceUrl>   {
    return imageService.getEmployeeImage(employee, size);
  }

}
