import {Injectable} from "@angular/core";
import {ApiService} from "./api.service";
import {SessionService} from "./session.service";
import {FeeGuideRegion} from "../models/fee-guide-region.model";
import {BehaviorSubject, combineLatest, Observable, of} from "rxjs";
import {environment} from "../../environments/environment";
import {DeserializeHelper} from "../views/shared/utils/json-utils";
import {dematerialize, map, materialize, shareReplay, switchMap, take, tap, withLatestFrom} from "rxjs/operators";
import {FeeGuide} from "../models/fee-guide.model";
import {FeeGuideChangeLog} from "../models/fee-guide-change-log.model";
import {FeeGuideProcedure} from "../models/fee-guide-procedure.model";
import {Location} from "../models/location.model";

@Injectable({
    providedIn: 'root'
})
export class FeeGuideService {

    private _feeGuideRegions = new BehaviorSubject<FeeGuideRegion[]>(null);
    public feeGuideRegions$ = this._feeGuideRegions.pipe(
        map(r => r?.sort((a, b) => a.stateId - b.stateId))
    );

    public feeGuides$ = this.feeGuideRegions$.pipe(
        map(regions => regions?.map(region => region?.feeGuides)?.flat()),
        shareReplay({bufferSize: 1, refCount: true})
    );

    private _locationFeeGuides = new BehaviorSubject(new Map<number, FeeGuide[]>());
    public locationFeeGuides$ = this._locationFeeGuides as Observable<Map<number, FeeGuide[]>>;

    private _feeGuideChangeLogs = new BehaviorSubject(new Map<string, FeeGuideChangeLog[]>());
    public feeGuideChangeLogs$ = this._feeGuideChangeLogs as Observable<Map<string, FeeGuideChangeLog[]>>;

    private _changeLogApiCallsInProgress = new BehaviorSubject<Set<string>>(new Set<string>());
    public changeLogApiCallsInProgress$ = this._changeLogApiCallsInProgress as Observable<Set<string>>;

    constructor(private api: ApiService, private sessionService: SessionService) {
        this.sessionService.sessionContainer.notNull().subscribe(() => {
            this.initCachedData();
        });
    }

    initCachedData() {
        const logError = error => console.log(error);
        this.fetchHydratedFeeGuideRegions().subscribe(r => this._feeGuideRegions.next(r ?? []), logError);
    }

    public getChangeLogs(feeGuideId: string): Observable<FeeGuideChangeLog[]> {
        return this.feeGuideChangeLogs$.pipe(
            withLatestFrom(this.changeLogApiCallsInProgress$),
            map(([logs, apiCalls]) => {
                const feeGuideChangeLogs = logs?.get(feeGuideId);
                if (!feeGuideChangeLogs && !apiCalls.has(feeGuideId)) {
                    this.fetchChangeLogs(feeGuideId).subscribe({
                        error: error => console.log(error)
                    });
                }
                return feeGuideChangeLogs;
            })
        );
    }

    private fetchChangeLogs(feeGuideId: string): Observable<FeeGuideChangeLog[]> {
        return this.changeLogApiCallsInProgress$.pipe(
            take(1),
            switchMap(apiCalls => {
                apiCalls.add(feeGuideId);
                this._changeLogApiCallsInProgress.next(apiCalls);
                return this.api.get<FeeGuideChangeLog[]>(
                    environment.apiBaseUrl + `/feeguides/${feeGuideId}/changelogs`
                );
            }),
            map((r) => {
                const logs = DeserializeHelper.arrayOf(FeeGuideChangeLog, r) ?? [];
                logs.sort((a, b) => b?.createdDate?.localeCompare(a?.createdDate));
                this.updateChangeLogMap(feeGuideId, logs);
                return logs;
            }),
            materialize(),
            withLatestFrom(this.changeLogApiCallsInProgress$),
            map(([logNotifications, apiCalls]) => {
                if (logNotifications.kind === 'C' || logNotifications.kind === 'E') {
                    apiCalls.delete(feeGuideId);
                    this._changeLogApiCallsInProgress.next(apiCalls);
                }
                return logNotifications;
            }),
            dematerialize(),
        );
    }

    private updateChangeLogMap(feeGuideId: string, logs: FeeGuideChangeLog[]) {
        this.feeGuideChangeLogs$.once(logMap => {
            const logMapCopy = new Map(logMap);
            logMapCopy.set(feeGuideId, logs);
            this._feeGuideChangeLogs.next(logMapCopy);
        });
    }

    public createChangeLog(feeGuideId: string, changeLog: FeeGuideChangeLog): Observable<FeeGuideChangeLog> {
        return this.api.post<FeeGuideChangeLog>(
            environment.apiBaseUrl + `/feeguides/${feeGuideId}/changelogs`,
            changeLog
        ).pipe(
            withLatestFrom(this.feeGuideChangeLogs$),
            map(([r, logMap]) => {
                const cLog = DeserializeHelper.deserializeToInstance(FeeGuideChangeLog, r);
                const logMapCopy = new Map(logMap);
                const logs = logMapCopy.get(feeGuideId) ?? [];
                logs.push(cLog);
                logs.sort((a, b) => b?.createdDate?.localeCompare(a?.createdDate));
                logMapCopy.set(feeGuideId, logs);
                this._feeGuideChangeLogs.next(logMapCopy);
                return cLog;
            })
        );
    }

    private fetchHydratedFeeGuideRegions(): Observable<FeeGuideRegion[]> {
        return this.api.get<FeeGuideRegion[]>(
            environment.apiBaseUrl + `/hydratedfeeguideregions`)
            .map(r => DeserializeHelper.arrayOf(FeeGuideRegion, r));
    }

    public reloadHydratedFeeGuideRegions(): Observable<FeeGuideRegion[]> {
        this._locationFeeGuides.next(new Map<number, FeeGuide[]>());
        return this.fetchHydratedFeeGuideRegions().pipe(
            tap(r => this._feeGuideRegions.next(r))
        );
    }

    public addFeeGuideRegion(region: FeeGuideRegion): Observable<FeeGuideRegion> {
        return this.api.post<FeeGuideRegion>(
            environment.apiBaseUrl + `/feeguideregions`,
            region
        ).pipe(
            withLatestFrom(this.feeGuideRegions$),
            map(([r, regions]) => {
                const region = DeserializeHelper.deserializeToInstance(FeeGuideRegion, r);
                const regionsCopy = DeserializeHelper.arrayOf(FeeGuideRegion, regions) ?? [];
                regionsCopy.push(region);
                this._feeGuideRegions.next(regionsCopy);
                return region;
            })
        );
    }

    public getFeeGuideVersionsForRegion(regionId: string): Observable<FeeGuide[]> {
        return this.feeGuides$.pipe(
            map(feeGuides => feeGuides?.filter(fg => fg?.regionId === regionId)),
        );
    }

    public addFeeGuide(feeGuide: FeeGuide): Observable<FeeGuide> {
        return this.api.post<FeeGuide>(
            environment.apiBaseUrl + `/feeguideregions/${feeGuide.regionId}/feeguides`,
            feeGuide
        ).pipe(
            withLatestFrom(this.feeGuideRegions$),
            map(([r, regions]) => {
                const feeGuide = DeserializeHelper.deserializeToInstance(FeeGuide, r);
                const allRegionsCopy = DeserializeHelper.arrayOf(FeeGuideRegion, regions) ?? [];
                const region = allRegionsCopy?.find(region => region.id === feeGuide.regionId);
                const insertIndex = region?.feeGuides?.findIndex(fg => fg.startDate < feeGuide.startDate);
                if (insertIndex != null && insertIndex >= 0) {
                    region.feeGuides.splice(insertIndex, 0, feeGuide);
                } else {
                    region?.feeGuides?.push(feeGuide);
                }
                this._feeGuideRegions.next(allRegionsCopy);
                return feeGuide;
            })
        );
    }

    public updateFeeGuide(feeGuide: FeeGuide): Observable<FeeGuide> {
        return this.api.put<FeeGuide>(
            environment.apiBaseUrl + `/feeguideregions/${feeGuide?.regionId}/feeguides/${feeGuide?.id}`,
            feeGuide
        ).pipe(
            withLatestFrom(this.feeGuideRegions$),
            map(([r, regions]) => {
                const feeGuide = DeserializeHelper.deserializeToInstance(FeeGuide, r);
                const allRegionsCopy = DeserializeHelper.arrayOf(FeeGuideRegion, regions) ?? [];
                const region = allRegionsCopy?.find(region => region?.id === feeGuide?.regionId);
                const feeGuideIndex = region?.feeGuides?.findIndex(fg => fg?.id === feeGuide?.id);
                if (feeGuideIndex !== null && feeGuideIndex >= 0) {
                    region?.feeGuides?.splice(feeGuideIndex, 1, feeGuide);
                }
                this._feeGuideRegions.next(allRegionsCopy);
                return feeGuide;
            })
        );
    }

    public deleteFeeGuide(feeGuide: FeeGuide): Observable<string> {
        return this.api.delete(
            environment.apiBaseUrl + `/feeguideregions/${feeGuide.regionId}/feeguides/${feeGuide.id}`,
            null
        ).pipe(
            withLatestFrom(this.feeGuideRegions$),
            map(([s, regions]) => {
                const allRegionsCopy = DeserializeHelper.arrayOf(FeeGuideRegion, regions) ?? [];
                const region = allRegionsCopy?.find(region => region?.id === feeGuide?.regionId);
                const feeGuideIndex = region?.feeGuides?.findIndex(fg => fg?.id === feeGuide?.id);
                if (feeGuideIndex !== null && feeGuideIndex >= 0) {
                    region?.feeGuides?.splice(feeGuideIndex, 1);
                }
                this._feeGuideRegions.next(allRegionsCopy);
                return s;
            })
        );
    }

    public getFeeGuideForLocation(locationId: number, feeGuideId: string): Observable<FeeGuide> {
        return this.locationFeeGuides$.pipe(
            take(1),
            switchMap(feeGuideMap => {
               const feeGuides = feeGuideMap?.get(locationId);
               const feeGuide = feeGuides?.find(fg => fg?.id === feeGuideId);
               return feeGuide ? of(feeGuide) : this.fetchFeeGuideForLocation(locationId, feeGuideId);
            })
        )
    }

    private fetchFeeGuideForLocation(locationId: number, feeGuideId: string): Observable<FeeGuide> {
        return combineLatest([
            this.sessionService?.userLocations.notNull(),
            this.feeGuides$.notNull()
        ]).pipe(
            take(1),
            switchMap(([locations, feeGuides]) => {
                const location = locations?.find(loc => loc?.id === locationId);
                const feeGuide = feeGuides?.find(fg => fg?.id === feeGuideId);
                return this.api.get<FeeGuide>(
                    environment.apiBaseUrl +
                    `/companies/${location?.companyId}/locations/${location?.id}/feeguideregions/${feeGuide?.regionId}/feeguides/${feeGuide?.id}`
                ).pipe(
                    map(r => DeserializeHelper.deserializeToInstance(FeeGuide, r)),
                    tap(feeGuide => {
                        this.locationFeeGuides$.once(feeGuideMap => {
                            const feeGuideMapCopy = new Map(feeGuideMap);
                            const feeGuides = feeGuideMapCopy?.get(location?.id) ?? [];
                            const feeGuideIndex = feeGuides?.findIndex(fg => fg?.id === feeGuide?.id);
                            if (feeGuideIndex !== null && feeGuideIndex >= 0) {
                                feeGuides?.splice(feeGuideIndex, 1, feeGuide);
                            }
                            feeGuideMapCopy?.set(location?.id, feeGuides);
                            this._locationFeeGuides.next(feeGuideMapCopy);
                        });
                    })
                );
            })
        )
    }

    public fetchAllFeeGuidesForLocation(location: Location): Observable<FeeGuide[]> {
        return this.feeGuideRegions$.notNull().pipe(
            take(1),
            map(regions => regions?.find(r => r?.stateId === location?.address?.stateId)),
            switchMap(region => {
                return this.api.get<FeeGuide[]>(
                    environment.apiBaseUrl + `/companies/${location?.companyId}/locations/${location?.id}/feeguideregions/${region?.id}/feeguides`
                )
            }),
            map(r => DeserializeHelper.arrayOf(FeeGuide, r)),
            withLatestFrom(this.locationFeeGuides$),
            map(([feeGuides, locationFeeGuides]) => {
                const locationFeeGuidesCopy = new Map(locationFeeGuides);
                locationFeeGuidesCopy.set(location?.id, feeGuides);
                this._locationFeeGuides.next(locationFeeGuidesCopy);
                return feeGuides;
            })
        );
    }

    public updateProcedure(procedure: FeeGuideProcedure): Observable<FeeGuideProcedure> {
        return this.api.put<FeeGuideProcedure>(
            environment.apiBaseUrl + `/feeguides/${procedure?.feeGuideId}/procedures/${procedure?.id}`,
            procedure
        ).pipe(
            withLatestFrom(this.feeGuideRegions$),
            map(([r, regions]) => {
                const procedure = DeserializeHelper.deserializeToInstance(FeeGuideProcedure, r);
                const allRegionsCopy = DeserializeHelper.arrayOf(FeeGuideRegion, regions) ?? [];
                const existingFeeGuide = allRegionsCopy
                    ?.map(r => r?.feeGuides?.find(fg => fg?.id === procedure?.feeGuideId))
                    ?.first();
                if (existingFeeGuide) {
                    const procedureIndex = existingFeeGuide?.procedures?.findIndex(proc => {
                        return proc?.id === procedure?.id;
                    });
                    if (procedureIndex >= 0) {
                        existingFeeGuide?.procedures?.splice(procedureIndex, 1, procedure);
                    }
                }
                this._feeGuideRegions.next(allRegionsCopy);
                return procedure;
            })
        );
    }

    public updateLocationProcedure(procedure: FeeGuideProcedure, locationId: number): Observable<FeeGuideProcedure> {
        return this.sessionService.companyId$.pipe(
            take(1),
            switchMap(companyId => {
                return this.api.post<FeeGuideProcedure>(
                    environment.apiBaseUrl + `/companies/${companyId}/locations/${locationId}/feeguides/${procedure?.feeGuideId}/procedures/${procedure?.id}/feeoverrides`,
                    procedure
                )
            }),
            withLatestFrom(this.locationFeeGuides$),
            map(([r, allLocationFeeGuides]) => {
                    const procedure = DeserializeHelper.deserializeToInstance(FeeGuideProcedure, r);
                    const locationFeeGuidesCopy = new Map(allLocationFeeGuides);
                    const locationFeeGuides = locationFeeGuidesCopy?.get(locationId) ?? [];
                    const feeGuide = locationFeeGuides?.find(fg => fg?.id === procedure?.feeGuideId);
                    const procedureIndex = feeGuide?.procedures?.findIndex(proc => {
                        return proc?.id === procedure?.id;
                    });
                    if (procedureIndex >= 0) {
                        feeGuide?.procedures?.splice(procedureIndex, 1, procedure);
                    } else {
                        feeGuide?.procedures?.push(procedure);
                    }
                    locationFeeGuidesCopy.set(locationId, locationFeeGuides);
                    this._locationFeeGuides.next(locationFeeGuidesCopy);
                    return procedure;
                })
        );
    }

}
