import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {DeserializeHelper} from '../views/shared/utils/json-utils';
import {Company} from '../models/company.model';
import {Role} from '../models/role.model';
import {EntityService} from './entity.service';
import {AccountService} from './account.service';
import {SessionService} from './session.service';
import {LocationActivity} from '../models/location-activity.model';

@Injectable({
    providedIn: 'root'
})
export class AdminService {
    constructor(private api: ApiService,
                private accountService: AccountService,
                private sessionService: SessionService,
                private entityService: EntityService) {
    }

    public getCompanies(): Observable<Company[]> {
        return this.api.get<Company[]>(
            environment.apiBaseUrl + `/companies`)
            .map(r => r.map(d => DeserializeHelper.deserializeToInstance(Company, d)));
    }

    public createCompany(company: Company): Observable<Company> {
        return this.api.post<Company>(
            environment.apiBaseUrl + `/Companies`, company);
    }

    public getStockRoles(): Observable<Role[]> {
        return this.api.get<Role[]>(
            environment.apiBaseUrl + `/stockroles`);
    }

    public selectCompanyToEdit(selectedCompany: Company): Observable<Company> {
        return new Observable(s => {
            this.entityService.getLocations(selectedCompany.id).subscribe(l => {
                const currentSessionContainer = this.sessionService.sessionContainer.value;
                currentSessionContainer.company = selectedCompany;
                currentSessionContainer.companyLocations = l;
                this.accountService.updateUserSession(currentSessionContainer);
                s.next(selectedCompany);
                s.complete();
            }, error => {
                s.error(error);
                s.complete();
            });
        });
    }

    public getLocationActivity(): Observable<LocationActivity[]> {
        return this.api.get<LocationActivity[]>(
            environment.apiBaseUrl + `/adminPortal/locationActivity`)
            .map(r => r.map(d => DeserializeHelper.deserializeToInstance(LocationActivity, d)));
    }
}
