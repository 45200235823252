import {Component, EventEmitter, OnInit} from '@angular/core';
import {DatePickerNavigationType, DatePickerOutput} from '../date-picker/date-picker.component';
import {NgbActiveModal, NgbDate, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {TimeRangeFilter} from '../../../../models/time-range-filter';
import DateUtils from '../../utils/date-utils';

@Component({
    selector: 'app-date-picker-modal',
    templateUrl: './date-picker-modal.component.html',
    styleUrls: ['./date-picker-modal.component.scss', '../../../stylesheet/stylesheet.component.scss']
})
export class DatePickerModalComponent implements OnInit {

    datePickerNavTypes = DatePickerNavigationType;
    datePickerOutputs = DatePickerOutput;
    clearDateSelection = new EventEmitter();
    selectedDate = new Date();
    title: string;

    constructor(private activeModal: NgbActiveModal) {
    }

    ngOnInit(): void {
    }

    initWithTitle(title: string) {
        this.title = title;
    }

    public handleCustomDateRangeSelection(date: NgbDate[]) {
        const selectedDate = new Date(date[0].year, date[0].month - 1, date[0].day);
        selectedDate.setUTCHours(0, 0, 0, 0);
        this.selectedDate = selectedDate;
    }


    continueClicked() {
        this.activeModal.close(this.selectedDate);
    }

    cancelClicked() {
        this.activeModal.dismiss();
    }
}
