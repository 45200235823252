<app-radio-group
        [textLabel]="getLocalizedRestorationTypeTitle()"
        [control]="'restorationTypeId'"
        [parentForm]="parentForm"
        [radioButtons]="radiobuttons[0]"
        [required]="true">
</app-radio-group>
<hr>
<ng-container
        *ngIf="parentForm.controls.restorationTypeId?.value == 1 || parentForm.controls.restorationTypeId?.value == 2">
    <app-radio-group
            [textLabel]="getLocalizedRestorativeOptionTitle()"
            [control]="'restorativeOptionTypeId'"
            [parentForm]="parentForm"
            [radioButtons]="radiobuttons[1]"
            [required]="true">
    </app-radio-group>
    <hr>
    <ng-container *ngIf="parentForm.controls.restorativeOptionTypeId?.value == 1">
        <app-radio-group
                [textLabel]="getLocalizedAbutmentLevelTitle()"
                [control]="'abutmentLevelTypeId'"
                [parentForm]="parentForm"
                [radioButtons]="radiobuttons[2]"
                [required]="true">
        </app-radio-group>
        <hr>
        <app-radio-group
                [textLabel]="getLocalizedMaterialOptionTitle()"
                [control]="'materialOptionTypeId'"
                [parentForm]="parentForm"
                [radioButtons]="radiobuttons[4]"
                [required]="true">
        </app-radio-group>
        <hr>
        <app-radio-group
                [textLabel]="getLocalizedAngulatedCorrectionTitle()"
                [control]="'angulatedCorrectionTypeId'"
                [parentForm]="parentForm"
                [radioButtons]="radiobuttons[5]"
                [required]="true">
        </app-radio-group>
        <hr>
    </ng-container>
    <ng-container *ngIf="parentForm.controls.restorativeOptionTypeId?.value == 2">
        <app-radio-group
                [textLabel]="getLocalizedMaterialOptionTitle()"
                [control]="'materialOptionTypeId'"
                [parentForm]="parentForm"
                [radioButtons]="radiobuttons[4]"
                [required]="true">
        </app-radio-group>
        <hr>
        <app-radio-group
                [textLabel]="getLocalizedAbutmentOptionTitle()"
                [control]="'abutmentOptionTypeId'"
                [parentForm]="parentForm"
                [radioButtons]="radiobuttons[6]"
                [required]="true">
        </app-radio-group>
        <hr>
    </ng-container>
    <app-text-group
            [placeholderText]="getLocalizedShadePlaceholder()"
            [textLabel]="getLocalizedShadeTitle()"
            [parentForm]="parentForm"
            [textId]="'shade'"
            [showCheckbox]="true"
            [checkboxLabel]="getLocalizedCustomShadeTitle()"
            [checkId]="'customShade'"
            [required]="true"
            (checkboxChangedEmitter)="emitCheckboxValue($event)"
            (textfieldChangedEmitter)="emitTextFieldValue($event)">
    </app-text-group>
    <hr>
</ng-container>
<ng-container *ngIf="parentForm.controls.restorationTypeId?.value == 3">
    <app-radio-group
            [textLabel]="getLocalizedRemovableTitle()"
            [control]="'removableTypeId'"
            [parentForm]="parentForm"
            [radioButtons]="radiobuttons[3]"
            [required]="true">
    </app-radio-group>
    <hr>
    <app-radio-group
            [textLabel]="getLocalizedAbutmentLevelTitle()"
            [control]="'abutmentLevelTypeId'"
            [parentForm]="parentForm"
            [radioButtons]="radiobuttons[2]"
            [required]="true">
    </app-radio-group>
    <hr>
    <app-radio-group
            [textLabel]="getLocalizedAttachmentTypeTitle()"
            [control]="'attachmentTypeId'"
            [parentForm]="parentForm"
            [radioButtons]="radiobuttons[7]"
            [required]="true">
    </app-radio-group>
    <hr>
    <ng-container
            *ngIf="parentForm.controls.attachmentTypeId?.value == 1 || parentForm.controls.attachmentTypeId?.value == 2 ">
        <app-radio-group
                [textLabel]="getLocalizedDesignOptionTitle()"
                [control]="'designOptionTypeId'"
                [parentForm]="parentForm"
                [radioButtons]="radiobuttons[8]"
                [required]="true">
        </app-radio-group>
        <hr>
    </ng-container>
    <ng-container *ngIf="parentForm.controls.attachmentTypeId?.value == 3">
        <app-radio-group
                [textLabel]="getLocalizedBarOptionTitle()"
                [control]="'barOptionTypeId'"
                [parentForm]="parentForm"
                [radioButtons]="radiobuttons[9]"
                [required]="true">
        </app-radio-group>
        <hr>
    </ng-container>
</ng-container>
<app-radio-group
        [textLabel]="getLocalizedDeliveryTitle()"
        [control]="'deliveryTypeId'"
        [parentForm]="parentForm"
        [radioButtons]="radiobuttons[10]"
        [required]="true">
</app-radio-group>
<hr>
<ng-container *ngIf="parentForm.controls.restorationTypeId?.value == 2">
    <app-radio-group
            [textLabel]="getLocalizedPonticDesignTitle()"
            [control]="'ponticDesignTypeId'"
            [parentForm]="parentForm"
            [radioButtons]="radiobuttons[11]"
            [required]="true">
    </app-radio-group>
    <hr>
</ng-container>
<ng-container
        *ngIf="parentForm.controls.restorationTypeId?.value == 1 || parentForm.controls.restorationTypeId?.value == 2">
    <app-radio-group
            [textLabel]="getLocalizedOcclusalContactTitle()"
            [control]="'occlusalContactTypeId'"
            [parentForm]="parentForm"
            [radioButtons]="radiobuttons[12]"
            [required]="true">
    </app-radio-group>
    <hr>
</ng-container>
<ng-container *ngIf="showAdvancedOptions">
    <app-radio-group
            [textLabel]="getLocalizedToothMorphTitle()"
            [control]="'toothMorphologyTypeId'"
            [parentForm]="parentForm"
            [radioButtons]="radiobuttons[13]">
    </app-radio-group>
    <hr>
    <app-checkbox-group
            [textLabel]="getLocalizedSurfaceTextureTitle()"
            [control]="'surfaceTextureTypeId'"
            [parentForm]="parentForm"
            [radioButtons]="radiobuttons[14]">
    </app-checkbox-group>
    <hr>
    <app-radio-group
            [textLabel]="getLocalizedInterproximalContactTitle()"
            [control]="'interproximalContactTypeId'"
            [parentForm]="parentForm"
            [radioButtons]="radiobuttons[15]">
    </app-radio-group>
    <hr>
    <app-radio-group
            [textLabel]="getLocalizedIncisalTranslucencyTitle()"
            [control]="'incisalTranslucencyTypeId'"
            [parentForm]="parentForm"
            [radioButtons]="radiobuttons[16]">
    </app-radio-group>
</ng-container>
<div class="mt-3 mb-3">
    <button class="teth-button" (click)="toggleOptions()">
        <span>{{showAdvancedOptions ? getLocalizedHideOptionsTitle() : getLocalizedShowOptionsTitle()}}</span></button>
</div>
<hr>
