import {Deserializable} from '../views/shared/utils/json-utils';
import {Permission} from './permission.model';

export class PermissionCategory implements Deserializable {
    public name: string;
    public primaryPermission: Permission;
    public childPermissions: Permission[];

    constructor(title: string, primaryPermission: Permission, childPermissions: Permission[]) {
        this.name = title;
        this.primaryPermission = primaryPermission;
        this.childPermissions = childPermissions;
    }

    onDeserialize() {
    }
}

