import {Deserializable} from "../views/shared/utils/json-utils";

export class StageAppointmentFee implements Deserializable {
    public id: string;
    public referralStageAppointmentId: string;
    public stageId: number;
    public stage: string;
    public feeGuideProcedureFeeId: string;
    public feeName: string;
    public feeCode: string;
    public feeAmount: number;
    public overrideFeeAmount: number;
    public updatedDate: Date;
    public updatedById: number;
    public updatedBy: string;
    public createdDate: Date;
    public createdById: number;
    public createdBy: string;

    public onDeserialize(): void {
    }

}
