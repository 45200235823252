import {Component, EventEmitter, OnInit, ViewChild} from '@angular/core';
import {
    DataTableExtraFilterGroup,
    DataTableExtraFilterOptions,
    DataTableOptions, DataTableSegmentOptions
} from '../../models/data-table-options';
import {LoadingOptions} from '../../models/loading-options';
import {Location} from '../../models/location.model';
import {BehaviorSubject, merge, Observable} from 'rxjs';
import {DataTableColumn, DatatableColumnType} from '../../models/data-table-column';
import {SessionService} from '../../services/session.service';
import {EntityService} from '../../services/entity.service';
import {filter} from 'rxjs/operators';
import {ReferralService} from '../../services/referral.service';
import {HydratedCorrespondence} from '../../models/hydrated-correspondence';
import {ToastrService} from 'ngx-toastr';
import {ActivatedRoute, ActivationEnd, Router} from '@angular/router';
import {TimeRangeFilter} from '../../models/time-range-filter';
import {PermissionService} from '../../services/permission.service';
import '../shared/utils/observable.extensions';
import {DatatableComponent} from '../shared/datatable/datatable.component';
import {CorrespondenceStatus} from '../../models/correspondence-status.model';
import {DateService} from '../../services/date.service';
import {GroupOption} from '../../models/group-option';

@Component({
    selector: 'app-correspondence',
    templateUrl: './correspondence.component.html',
    styleUrls: ['./correspondence.component.scss', '../stylesheet/stylesheet.component.scss']
})
export class CorrespondenceComponent implements OnInit {

    @ViewChild('datatable') private datatable: DatatableComponent;
    public datatableOptions: DataTableOptions = new DataTableOptions();
    public loadingOptions: LoadingOptions = new LoadingOptions();
    public selectedLocation = new BehaviorSubject<Location>(null);
    public datatableSegmentOptions: DataTableSegmentOptions[] = [];
    public awaitingRequests = new BehaviorSubject<number>(0);
    public correspondenceData = new BehaviorSubject<HydratedCorrespondence[]>(null);
    public selectedTimeRange = new BehaviorSubject<TimeRangeFilter>(null);
    public resetTableBulkEdit = new EventEmitter();
    private incomingReferrals: boolean;

    constructor(private sessionService: SessionService,
                private entityService: EntityService,
                private activatedRoute: ActivatedRoute,
                private toastr: ToastrService,
                private router: Router,
                private permissionService: PermissionService,
                private dateService: DateService,
                private referralService: ReferralService) {
    }

    ngOnInit(): void {
        this.setupLoadingOptions();
        this.setupBindings();
    }

    private setupLoadingOptions() {
        this.loadingOptions = this.getTableLoadingOptions();
        this.loadingOptions.isLoading = true;
    }

    private setupBindings() {
        this.activatedRoute.data.subscribe(d => {
            this.incomingReferrals = d.incomingReferrals;
            this.permissionService.currentUserRole.firstNotNull().subscribe(() => {
                this.setupDatatable();
            });
        });

        this.router.events.subscribe(ev => {
            if (ev instanceof ActivationEnd && Object.is(ev?.snapshot?.component, CorrespondenceComponent)) {
                if (this.correspondenceData.value?.length > 0) {
                    this.refreshDataForLocation();
                }
            }
        });
    }

    handleRowClicked(row: any) {
        this.router.navigate([`${row.id}`],  {relativeTo: this.activatedRoute});
    }

    newLocationSelected(location: Location) {
        this.selectedLocation.next(location);
    }

    timeRangeChanged(timeRange: TimeRangeFilter) {
        this.selectedTimeRange.next(timeRange);
    }

    public setupDatatable() {
        this.datatableSegmentOptions = [
            {
                options: new DataTableOptions(),
                title: $localize`Pending`,
                showCountBadge: false,
                selected: true
            },
            {
                options: new DataTableOptions(),
                title: $localize`Approved`,
                showCountBadge: false,
                selected: false
            }
        ];

        // set up basic configuration
        const pendingOptions = this.datatableSegmentOptions[0].options;
        pendingOptions.tableName = $localize`Clinical Correspondence`;
        pendingOptions.noResultsTitle = $localize`No Pending Correspondences`;
        pendingOptions.noResultsText = $localize`All correspondences have been taken care of for now. New ones will appear here when they become available.`;
        pendingOptions.bulkEditText = $localize`Mark as Approved`;
        pendingOptions.columns = this.getPendingDatatableColumns();
        pendingOptions.extraFilterOptions = this.setupTableExtraFiltering();
        pendingOptions.data = [];
        pendingOptions.centeredTextInCell = true;
        pendingOptions.showClearFiltersButton = true;
        pendingOptions.showDateFilter = true;
        this.datatableOptions = pendingOptions;

        const approvedOptions = this.datatableSegmentOptions[1].options;
        approvedOptions.tableName = $localize`Clinical Correspondence`;
        approvedOptions.noResultsTitle = $localize`No Approved Correspondences`;
        approvedOptions.noResultsText = $localize`As soon as a correspondence has been approved, it will appear here. You can approve correspondences from the ‘Pending’ tab.`;
        approvedOptions.columns = this.getApprovedDatatableColumns();
        approvedOptions.extraFilterOptions = pendingOptions.extraFilterOptions;
        approvedOptions.primaryFilterOptions = pendingOptions.primaryFilterOptions;
        approvedOptions.centeredTextInCell = true;
        approvedOptions.showClearFiltersButton = true;
        approvedOptions.showDateFilter = true;
        approvedOptions.data = [];

        // setup bindings to data
        this.setupDatatableBindings();
    }

    public setupDatatableBindings() {
        this.sessionService.userLocations.notNull().subscribe(userLocations => {
            this.selectedLocation.next(this.sessionService.currentLocation.value);
            // set the first location to active
            this.datatableOptions.primaryFilterOptions.primaryFilterData = userLocations;
            this.datatableOptions.primaryFilterOptions.primaryFilterLabel = $localize`Filter by Location:`;
            this.datatableOptions.primaryFilterOptions.primaryFilterDisplayProperty = 'name';
            // set one of the locations as the active location
            this.datatableOptions.primaryFilterOptions.activePrimaryFilter = this.selectedLocation.value;
        });

        this.sessionService.currentLocation.notNull().subscribe((cl) => {
            this.selectedLocation.next(cl);
        });

        this.entityService.specialtyTypes$.notNull().subscribe(specialtyTypes => {
            const procedureFilter = this.datatableOptions.extraFilterOptions.find(f => f.filterPropertyName === 'procedureIds');
            if (procedureFilter) {
                const procedureFilterGroups: DataTableExtraFilterGroup[] = [];
                specialtyTypes.forEach((specialtyType) => {
                    const group = new DataTableExtraFilterGroup();
                    group.groupTitle = specialtyType.name;
                    group.displayPropertyName = 'name';
                    group.comparisonPropertyName = 'id';
                    group.pluralGroupName = $localize`Procedures`;
                    specialtyType.procedures.forEach((p) => {
                        const option = new GroupOption(p.name, p.id, 'id');
                        group.groupOptions.push(option);
                    });
                    group.sortOptions();
                    procedureFilterGroups.push(group);
                });
                procedureFilter.selectionOptions = procedureFilterGroups;
            }
        });

        this.entityService.correspondenceTypes$.pipe(filter(x => x !== null)).subscribe(correspondenceTypes => {
            const typeFilter = this.datatableOptions.extraFilterOptions.find(f => f.filterPropertyName === 'correspondenceTypeId');
            if (typeFilter) {
                const group = new DataTableExtraFilterGroup();
                group.groupTitle = $localize`Correspondence Type`;
                group.displayPropertyName = 'name';
                group.comparisonPropertyName = 'id';
                group.pluralGroupName = $localize`Types`;
                correspondenceTypes.forEach(type => {
                    const option = new GroupOption(type.name, type.id, 'id');
                    group.groupOptions.push(option);
                });
                group.sortOptions();
                typeFilter.selectionOptions = [group];
            }
        });

        // bind the loading spinner to awaiting api requests
        this.awaitingRequests.subscribe((count) => {
            this.loadingOptions.isLoading = (count > 0);
        });

        this.selectedLocation.notNull().subscribe(location => {
            this.loadDataForLocation();
            this.datatableOptions.primaryFilterOptions.activePrimaryFilter = location;
        });

        this.selectedTimeRange.notNull().subscribe(timeRange => {
            this.loadDataForLocation();
        });

        this.correspondenceData.notNull().subscribe(correspondenceData => {
            this.updateFilterOptionsForCorrespondenceData();
        });
    }

    public loadDataForLocation() {
        if (this.selectedLocation.value && this.selectedTimeRange.value) {
            this.awaitingRequests.next(this.awaitingRequests.value + 1);
            this.referralService.getHydratedCorrespondenceForLocation(this.incomingReferrals, this.selectedLocation.value.id, this.selectedTimeRange.value.startDate, this.selectedTimeRange.value.endDate).subscribe(correspondence => {
                this.setCorrespondenceData(correspondence);
                this.awaitingRequests.next(this.awaitingRequests.value - 1);
            });
        }
    }

    public refreshDataForLocation() {
        if (this.selectedLocation.value && this.selectedTimeRange.value) {
            this.referralService.getHydratedCorrespondenceForLocation(this.incomingReferrals, this.selectedLocation.value.id, this.selectedTimeRange.value.startDate, this.selectedTimeRange.value.endDate).subscribe(correspondence => {
                this.setCorrespondenceData(correspondence, true);
            });
        }
    }

    setCorrespondenceData(correspondence: HydratedCorrespondence[], silentUpdate: boolean = false) {
        const currentEmployeeId = this.sessionService.sessionContainer.value?.employee?.id;
        let modifiedCorrespondence = correspondence;

        if (this.incomingReferrals) {
            modifiedCorrespondence = modifiedCorrespondence.filter(c => c.assignedSpecialistId === currentEmployeeId);
            this.datatableSegmentOptions[0].options.data = modifiedCorrespondence.filter(c => c.referredCorrespondenceStatusTypeId === CorrespondenceStatus.PendingId);
            this.datatableSegmentOptions[1].options.data = modifiedCorrespondence.filter(c => c.referredCorrespondenceStatusTypeId === CorrespondenceStatus.ApprovedId);
        } else {
            const validCorrespondenceData = modifiedCorrespondence.filter(c => c.referredCorrespondenceStatusTypeId === CorrespondenceStatus.ApprovedId);
            this.datatableSegmentOptions[0].options.data = validCorrespondenceData.filter(c => c.referringCorrespondenceStatusTypeId === CorrespondenceStatus.PendingId);
            this.datatableSegmentOptions[1].options.data = validCorrespondenceData.filter(c => c.referringCorrespondenceStatusTypeId === CorrespondenceStatus.ApprovedId);
        }

        this.datatableSegmentOptions[0].showCountBadge = (this.datatableSegmentOptions[0].options.data?.length > 0);
        if (!silentUpdate) {
            this.correspondenceData.next(modifiedCorrespondence);
        } else {
            const c = this.correspondenceData.value;
            c.splice(0, c.length);
            c.push(...modifiedCorrespondence);
            this.datatable.applyExtraFilters();
        }
    }

    updateFilterOptionsForCorrespondenceData() {
        // set selection options for extra filters
        const dentistFilter = this.datatableSegmentOptions[0].options.extraFilterOptions.find((opt) => opt.filterPropertyName === 'referringEmployee');
        const group = new DataTableExtraFilterGroup();
        group.groupTitle = $localize`Referring Dentist`;
        group.displayPropertyName = 'referringEmployee';
        group.comparisonPropertyName = 'referringEmployee';
        const referringEmployeeNames = this.datatableSegmentOptions[0].options.data.map((d) => d.referringEmployee).filter((value, index, self) => {
            return self.indexOf(value) === index;
        });
        referringEmployeeNames.forEach((employeeName) => {
            const option = new GroupOption(employeeName, employeeName, 'referringEmployee');
            group.groupOptions.push(option);
        });
        dentistFilter.selectionOptions = [group];
    }

    segmentSelected(segment: DataTableSegmentOptions) {
        this.datatableSegmentOptions.forEach(s => s.selected = false);
        segment.selected = true;
        this.datatableOptions = segment.options;
    }

    public getTableLoadingOptions(): LoadingOptions {
        const opt = new LoadingOptions();
        opt.loadingText = $localize`Getting Correspondence`;
        opt.backgroundColor = 'rgba(255, 255, 255, 0.9)';
        opt.spinnerColor = '#388EFC';
        opt.color = '#222';
        opt.topMarginRem = 3;
        return opt;
    }

    public setupTableExtraFiltering(): DataTableExtraFilterOptions[] {
        const filterOptions: DataTableExtraFilterOptions[] = [];
        // Type Filter
        const typeFilter = new DataTableExtraFilterOptions();
        typeFilter.filterName = $localize`Type`;
        typeFilter.filterPropertyName = 'correspondenceTypeId';
        filterOptions.push(typeFilter);

        // Procedure Filter
        const procedureFilter = new DataTableExtraFilterOptions();
        procedureFilter.filterName = $localize`Procedure`;
        procedureFilter.filterPropertyName = 'procedureIds';
        filterOptions.push(procedureFilter);

        // Dentist Filter
        const dentistFilter = new DataTableExtraFilterOptions();
        dentistFilter.filterName = $localize`Referring Dentist`;
        dentistFilter.filterPropertyName = 'referringEmployee';
        filterOptions.push(dentistFilter);
        return filterOptions;
    }

    public getPendingDatatableColumns(): DataTableColumn[] {
        const columns: DataTableColumn[] = this.getApprovedDatatableColumns();
        // Patient Name column
        const bulkEdit = new DataTableColumn();
        bulkEdit.type = DatatableColumnType.Checkbox;
        bulkEdit.disableSorting = true;

        return [bulkEdit, ...columns];
    }

    public getApprovedDatatableColumns(): DataTableColumn[] {
        const columns: DataTableColumn[] = [];

        // Patient Name column
        const patientNameCol = new DataTableColumn();
        patientNameCol.title = $localize`Patient Name`;
        patientNameCol.type = DatatableColumnType.Text;
        patientNameCol.method = function (hydratedCorrespondence: HydratedCorrespondence) {
            return hydratedCorrespondence.patientFirstName + ' ' + hydratedCorrespondence.patientLastName;
        };
        columns.push(patientNameCol);

        // Date column
        const dateCol = new DataTableColumn();
        dateCol.title = $localize`Appointment Date`;
        dateCol.type = DatatableColumnType.Text;
        dateCol.method = (referral: HydratedCorrespondence) => {
            // format date
            return this.dateService.formatDateToString(referral.createdDate);
        };
        dateCol.customSorting = (a, b) => {
            return this.dateService.dateStringIsBefore(a, b) * -1;
        };
        columns.push(dateCol);

        // Type column
        const typeCol = new DataTableColumn();
        typeCol.title = $localize`Letter Type`;
        typeCol.type = DatatableColumnType.Text;
        typeCol.method = function (hydratedCorrespondence: HydratedCorrespondence) {
            return hydratedCorrespondence.correspondenceType ? hydratedCorrespondence.correspondenceType : 'N/A';
        };
        columns.push(typeCol);

        // Procedure column
        const procedureCol = new DataTableColumn();
        procedureCol.title = $localize`Procedure`;
        procedureCol.type = DatatableColumnType.Text;
        procedureCol.method = function (hydratedCorrespondence: HydratedCorrespondence) {
            let procedureName;
            if (hydratedCorrespondence.procedures.length === 1) {
                procedureName = hydratedCorrespondence.procedures[0].procedureName;
            } else if (hydratedCorrespondence.procedures.length > 1) {
                procedureName = $localize`${hydratedCorrespondence.procedures.length}:procedureCount: Procedures`;
            } else {
                procedureName = $localize`N/A`;
            }
            return procedureName;
        };
        columns.push(procedureCol);

        // Referring Dentist column
        const referringDentistCol = new DataTableColumn();
        referringDentistCol.id = 'referringEmployee';
        referringDentistCol.title = $localize`Referring Dentist`;
        referringDentistCol.type = DatatableColumnType.Text;
        referringDentistCol.method = function (hydratedCorrespondence: HydratedCorrespondence) {
            return hydratedCorrespondence.referringEmployee;
        };
        columns.push(referringDentistCol);

        return columns;
    }

    approveCorrespondence(correspondence: HydratedCorrespondence) {
        const approvedStatus = this.entityService.correspondenceStatusTypes$.value.find(t => t.id === CorrespondenceStatus.ApprovedId);
        if (this.incomingReferrals) {
            correspondence.referredCorrespondenceStatusTypeId = approvedStatus.id;
            correspondence.referredStatus = approvedStatus.name;
        } else {
            correspondence.referringCorrespondenceStatusTypeId = approvedStatus.id;
            correspondence.referringStatus = approvedStatus.name;
        }
    }

    markSelectedAsApproved(selectedRows: HydratedCorrespondence[]) {
        const obs: Observable<any>[] = [];
        selectedRows.forEach(correspondence => {
            this.approveCorrespondence(correspondence);
            obs.push(this.referralService.updateReferralCorrespondence(correspondence));
        });

        merge(...obs).subscribe(result => {
            this.toastr.success($localize`Approved Successfully`);
            this.resetTableBulkEdit.emit();
            this.loadDataForLocation();
        }, error => {
            this.toastr.error(error.message, $localize`Approve Correspondence Failed`);
            this.loadDataForLocation();
        });
    }
}
