import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Breadcrumb} from '../../../models/breadcrumb';
import {SessionService} from '../../../services/session.service';
import {CompanyType} from '../../../models/company.model';


@Component({
    selector: 'app-breadcrumbs',
    templateUrl: './breadcrumbs.component.html',
    styleUrls: [
        './breadcrumbs.component.scss',
        '../../stylesheet/stylesheet.component.scss'
    ]
})
export class BreadcrumbsComponent implements OnInit {

    @Input() breadcrumbs: Breadcrumb[];
    @Input() title: string;
    @Output() breadcrumbSelected = new EventEmitter<number>();

    constructor(private sessionService: SessionService) {
    }

    ngOnInit(): void {
        this.sessionService.sessionContainer.subscribe(s => {
            if (s && s.company.companyTypeId === CompanyType.Specialist) {
                const bodyStyles = window.getComputedStyle(document.body);
                const sppColor = bodyStyles.getPropertyValue('--nav-sidebar-background-spp');
                document.body.style.setProperty('--breadcrumb-icon-background', sppColor);
            } else if (s && s.company.companyTypeId === CompanyType.DentalLab) {
                const bodyStyles = window.getComputedStyle(document.body);
                const labColor = bodyStyles.getPropertyValue('--nav-sidebar-background-lab');
                document.body.style.setProperty('--breadcrumb-icon-background', labColor);
            } else {
                const bodyStyles = window.getComputedStyle(document.body);
                const gppColor = bodyStyles.getPropertyValue('--nav-sidebar-background-gpp');
                document.body.style.setProperty('--breadcrumb-icon-background', gppColor);
            }
        });
    }

    breadcrumbClicked(invalidChoice: boolean, index: number) {
        if (!invalidChoice) {
            this.breadcrumbSelected.emit(index);
        }
    }

}
