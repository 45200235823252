<div class="slideout-footer-container">
    <button
            class="teth-button white-button bordered-button slideout-footer-button"
            (click)="clearFiltersClicked.emit()" i18n>
        Clear Filters
    </button>
    <button
            class="teth-button deep-blue-button slideout-footer-button"
            (click)="applyFiltersClicked.emit()" i18n>
        Apply Filters
    </button>
</div>
