<div class="modal-body">
    <app-loading
            [determinateLoading]="false"
            [hidden]="!loading"
            [loadingText]="null"
            [fullscreen]="false"
            [backgroundColor]="'#FDFDFD'"
            [color]="'#388EFC'">
    </app-loading>
    <div class="modal-title mb-3" i18n>Choose a Company to Edit</div>
    <div class="mb-4">
        <form class="teth-textfield-form px-0">
            <label class="teth-textfield-label" for="searchText" i18n>Search</label>
            <input class="teth-textfield"
                   type="text"
                   id="searchText"
                   name="searchText"
                   placeholder="Enter Company Name"
                   i18n-placeholder
                   [(ngModel)]="searchText"
                   (ngModelChange)="filterResults()">
        </form>
        <ul class="teth-list scrollable-list px-0">
            <li class="list-item"
                *ngFor="let company of filteredCompanies"
                (click)="companySelected(company)">
                <img class="list-item-image"
                     [src]="(getCompanyDisplayPhoto(company.id) | async) || 'assets/img/placeholder/company-placeholder.jpg'"
                     alt="result-image">
                <div class="list-item-text-container">
                    <div class="list-item-text">
                        {{company.name}}
                    </div>
                    <div class="list-item-subtext" [hidden]="!company.address">
                        {{addressUtils.singleLineFormat(company.address)}}
                    </div>
                </div>
            </li>
        </ul>
    </div>
    <div>

    </div>
    <div class="footer-button">
        <button class="teth-button grey-button" (click)="close()" i18n>Cancel</button>
    </div>
</div>
