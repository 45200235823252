

<div class="app-body mt-0 mb-0">
  <app-sidebar class="sidebar-show"
               [fixed]="true">
    <app-sidebar-navigation>
    </app-sidebar-navigation>
  </app-sidebar>
  <!-- Main content -->
  <main class="main">
    <main #mainContent id="main-content" class="main-content">
      <div class="container-fluid">
        <router-outlet></router-outlet>
      </div><!-- /.container-fluid -->
    </main>
  </main>
  <app-aside [fixed]="true" [display]="false">

  </app-aside>
</div>
<!--<app-footer class="collapse">-->
<!--</app-footer>-->
