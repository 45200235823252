<div class="modal-body">
    <div class="modal-title mb-3" i18n>What's Changed?</div>
    <div class="p-3 mb-4">
        <app-release-notes [releaseNote]="releaseNote"></app-release-notes>
    </div>
    <div class="footer-button">
        <button class="teth-button grey-button" (click)="learnMore()" i18n>Learn More</button>
        <button class="teth-button blue-button ml-3" (click)="closeModal()" i18n>Continue</button>
    </div>
</div>
