import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {LabRadiobutton} from '../../../../../models/lab-radiobutton';

@Component({
  selector: 'app-radio-group',
  templateUrl: './radio-group.component.html',
  styleUrls: ['./radio-group.component.scss', '../../../../stylesheet/stylesheet.component.scss']
})
export class RadioGroupComponent implements OnInit, OnChanges {

  @Input() textLabel = '';
  @Input() textId = '';
  @Input() parentForm: FormGroup;
  @Input() radioButtons: LabRadiobutton[];
  @Input() control: FormControl;
  @Input() required: boolean = false;
  radioButtonsCopy: LabRadiobutton[];

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.radioButtonsCopy == null || this.radioButtonsCopy?.length === 0) {
      this.radioButtonsCopy = this.radioButtons;
    }
  }

}
