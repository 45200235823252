<div class="teth-modal">
    <div class="modal-content modal-lg">
        <div class="modal-header">
            <h3 class="float-left">{{titleText}}</h3>
        </div>
        <div class="modal-body">
            <select class="teth-dropdown teth-textfield-dropdown mb-3"
                    name="selectionItemsDropdown"
                    [(ngModel)]="selectedItem"
                    *ngIf="selectionItems != null"
                    aria-label="selectionItemsDropdown"
                    [disabled]="selectionItems.length === 0"
                    [ngClass]="{ 'disabled': selectionItems.length === 0 }">
                <option [ngValue]="null" [disabled]="true" *ngIf="placeholderText != null">{{placeholderText}}</option>
                <option *ngFor="let item of selectionItems"
                        [selected]="item === selectedItem"
                        [value]="item"
                        [ngValue]="item">
                    {{selectionDisplayMethod(item)}}
                </option>
            </select>
            <form class="teth-textfield-form" *ngIf="showNumberInput">
                <input class="teth-textfield"
                       type="number"
                       id="numberTextField"
                       name="numberTextField"
                       aria-label="Number Input"
                       [placeholder]="placeholderText" [(ngModel)]="numberInput">
                <span class="teth-textfield-error"></span>
            </form>
            <form class="teth-textfield-form" *ngIf="showTextInput">
                <input class="teth-textfield"
                       type="text"
                       id="textTextField"
                       name="textTextField"
                       aria-label="Text Input"
                       [placeholder]="placeholderText" [(ngModel)]="textInput">
                <span class="teth-textfield-error"></span>
            </form>
            <form class="teth-textfield-form checkbox-container" *ngIf="showCheckboxInput"
                  [ngClass]="customCheckboxClass">
                <label class="teth-checkbox-container col-6 mb-4"
                       *ngFor="let checkbox of checkboxItems">
                    <input type="checkbox" class="teth-checkbox"
                           id="{{checkbox.id}}"
                           name="{{checkbox.name}}"
                           [checked]="preselectedCheckboxIds?.includes(checkbox.id)"
                           (click)="checkboxClicked(checkbox)"
                           [disabled]="disabled">
                    <span class="checkBox-label">{{checkbox.name}}</span>
                    <span class="teth-checkmark"></span>
                </label>
            </form>
            <div class="default-modal-button-footer">
                <button class="teth-button bordered-button white-button"
                        [style]="{'margin-right': '0.5rem'}"
                        (click)="closeModal()">
                    {{cancelButtonText}}
                </button>
                <button class="teth-button blue-button"
                        [class.grey-button]="(selectedItem == null && numberInput == null && textInput == null && selectedCheckboxes == null) || this.disabled"
                        [class.disabled]="(selectedItem == null && numberInput == null && textInput == null && selectedCheckboxes == null) || this.disabled"
                        (click)="save()">
                    {{saveButtonText}}
                </button>
            </div>
        </div>
    </div>
</div>
