import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class CacheService {
    storageService = localStorage;

    constructor() {
    }

    isLocalStorageEnabled(): boolean {
        return this.storageService === localStorage;
    }

    useLocalStorage(useLocal: boolean) {
        this.storageService = useLocal ? localStorage : sessionStorage;
    }

    public getCachedObject<T>(key: string): T {
        const result = this.storageService.getItem(key);
        if (result) {
            return JSON.parse(result);
        } else {
            return null;
        }
    }

    public insertCachedObject<T>(key: string, object: T) {
        this.storageService.setItem(key, JSON.stringify(object));
    }

    public removeCachedObject(key: string, removeFromAllCaches: boolean = false) {
        if (removeFromAllCaches) {
            localStorage.removeItem(key);
            sessionStorage.removeItem(key);
        } else {
            this.storageService.removeItem(key);
        }
    }
}
