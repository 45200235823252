import {Deserializable, DeserializeHelper} from '../views/shared/utils/json-utils';
import {Employee} from './employee.model';
import {Location} from './location.model';
import {Company} from './company.model';

export class HydratedLocation extends Location implements Deserializable {
    public employees: Employee[];
    public company: Company;

    onDeserialize() {
        super.onDeserialize();
        this.employees.forEach(e => DeserializeHelper.deserializeToInstance(Employee, e));
    }
}

