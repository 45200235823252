<div class="loading-container" [ngStyle]="{'top': topMarginRem + 'rem'}">

    <div class="indeterminate-spinner" *ngIf="!determinateLoading">
        <ngx-spinner type="ball-clip-rotate" size="medium"
                     [fullScreen]="fullscreen"
                     [bdColor]="backgroundColor"
                     [color]="spinnerColor">
            <p class="loading-text" *ngIf="loadingText && loadingText !== ''" [style]="{ 'color':color }">
                {{ loadingText }}
            </p>
        </ngx-spinner>
    </div>

    <div class="determinate-spinner"
         *ngIf="determinateLoading"
         [ngClass]="{'fullscreen':fullscreen}"
         [ngStyle]="{'background-color':backgroundColor, 'color':color}">
        <div class="progress-bar-container">
            <app-progress-bar
                    [progress]="progress"
                    [barColor]="spinnerColor">
            </app-progress-bar>
            <span class="determinate-spinner-loading-text" *ngIf="loadingText && loadingText !== ''">
                {{ loadingText }}
            </span>
        </div>
    </div>

</div>
