import {Deserializable} from '../views/shared/utils/json-utils';

export class IntegrationType implements Deserializable {
    static DentrixId = 1;
    public id: number;
    public name: string;

    onDeserialize() {
    }
}
