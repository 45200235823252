import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {EntityService} from '../../../../services/entity.service';
import {forkJoin} from 'rxjs';
import {tap} from 'rxjs/operators';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import PhoneUtils from '../../utils/phone-utils';
import {Country, StateProvince} from '../../../../models/country.model';
import {Company, CompanyType} from '../../../../models/company.model';
import {Address} from '../../../../models/address.model';
import {AdminService} from '../../../../services/admin.service';
import {EmployeeService} from '../../../../services/employee.service';

@Component({
    selector: 'app-create-company-modal',
    templateUrl: './create-company-modal.component.html',
    styleUrls: ['./create-company-modal.component.scss', '../../../stylesheet/stylesheet.component.scss']
})
export class CreateCompanyModalComponent implements OnInit {
    loading = false;
    countries: Country[] = null;
    states: StateProvince[] = null;
    companyType = CompanyType;
    phoneUtils = PhoneUtils;
    companyInfoForm = new FormGroup({
        organizationName: new FormControl('', Validators.required),
        address1: new FormControl('', Validators.required),
        address2: new FormControl(''),
        lat: new FormControl(''),
        lng: new FormControl(''),
        postalCode: new FormControl('', Validators.required),
        city: new FormControl('', Validators.required),
        state: new FormControl('', Validators.required),
        country: new FormControl('', Validators.required),
        phoneNumber: new FormControl('', [Validators.required, Validators.pattern(PhoneUtils.phoneRegex)]),
        companyTypeId: new FormControl(3),
    });

    constructor(private activeModal: NgbActiveModal,
                private entityService: EntityService,
                private employeeService: EmployeeService,
                private cd: ChangeDetectorRef,
                private adminService: AdminService) {
    }

    filterCountryStates(countryId: number) {
        const index = this.countries.findIndex(c => c.id === countryId);
        this.states = this.countries[index].states;
    }

    ngOnInit() {
        this.loadStaticData();
        this.setupBindings();
    }

    setupBindings() {
    }

    loadStaticData() {
        const countryStateData = this.entityService.countries$.firstNotNull().pipe(tap(c => {
            this.countries = c;
            this.states = c.flatMap(x => x.states);
        }));

        this.loading = true;
        forkJoin([countryStateData]).subscribe(result => {
            this.loading = false;
        }, error => {
            this.loading = false;
            console.log(error);
        });
    }

    closeModal() {
        this.activeModal.close();
    }

    generateCompany(): Company {
        const c = new Company();

        c.name = this.companyInfoForm.controls.organizationName.value;
        c.address = new Address();
        c.address.addressLine1 = this.companyInfoForm.controls.address1.value;
        c.address.addressLine2 = this.companyInfoForm.controls.address2.value;
        c.address.postalCode = this.companyInfoForm.controls.postalCode.value;
        c.address.locality = this.companyInfoForm.controls.city.value;
        c.address.countryId = parseInt(this.companyInfoForm.controls.country.value, null);
        c.address.stateId = parseInt(this.companyInfoForm.controls.state.value, null);
        c.phoneNumber = this.companyInfoForm.controls.phoneNumber.value;
        c.companyTypeId = parseInt(this.companyInfoForm.controls.companyTypeId.value, null);

        if (this.companyInfoForm.controls.lat.value && this.companyInfoForm.controls.lng.value) {
            c.address.latitude = parseFloat(this.companyInfoForm.controls.lat.value);
            c.address.longitude = parseFloat(this.companyInfoForm.controls.lng.value);
        }
        return c;
    }

    saveCompanyChanges() {
        this.companyInfoForm.markAllAsTouched();
        if (this.companyInfoForm.valid) {
            this.loading = true;
            const c = this.generateCompany();
            this.adminService.createCompany(c).subscribe(updatedCompany => {
                this.activeModal.close(updatedCompany);
            }, error => {
                console.log(error);
                this.loading = false;
            });
        }
    }

    autoCompleteAddressSelected(address: Address) {
        this.companyInfoForm.patchValue({
            address1: address?.addressLine1 ?? '',
            postalCode: address?.postalCode ?? '',
            city: address?.locality ?? '',
            state: address?.stateId ?? '',
            country: address?.countryId ?? '',
            lat: address?.latitude ?? '',
            lng: address?.longitude ?? '',
        });
        this.cd.detectChanges();
    }
}
