import {Component, ContentChild, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, TemplateRef} from '@angular/core';
import {FeeGuide} from "../../../../models/fee-guide.model";
import {BehaviorSubject, Observable, Subscription} from "rxjs";
import {ActivatedRoute} from "@angular/router";
import {LoadingOptions} from "../../../../models/loading-options";
import {debounceTime, map, shareReplay} from "rxjs/operators";
import '../../utils/array-utils';
import {FeeGuideSpecialty} from "../../../../models/fee-guide-specialty.model";
import {
  FeeGuideTabBarContentDirective,
  FeeGuideTabBarContext
} from "../../directives/fee-guide-tab-bar-content.directive";

@Component({
  selector: 'app-fee-guide-tab-bar',
  templateUrl: './fee-guide-tab-bar.component.html',
  styleUrls: ['./fee-guide-tab-bar.component.scss']
})
export class FeeGuideTabBarComponent implements OnInit, OnChanges, OnDestroy {

  @Input() feeGuide: FeeGuide;

  @ContentChild(FeeGuideTabBarContentDirective, { read: TemplateRef }) tabContent: TemplateRef<FeeGuideTabBarContext>;

  private _feeGuide = new BehaviorSubject<FeeGuide>(null);
  public feeGuide$ = this._feeGuide as Observable<FeeGuide>;

  public specialties$: Observable<FeeGuideSpecialty[]> = this.feeGuide$.pipe(
      map(feeGuide => {
        return FeeGuideSpecialty.createSpecialtiesFromFeeGuide(feeGuide);
      }),
      shareReplay({ bufferSize: 1, refCount: true })
  );

  public loadingOptions = this.constructLoadingOptions();

  private subscriptions: Subscription[] = [];

  private _selectedIndex = new BehaviorSubject<number>(0);
  public selectedIndex$ = this._selectedIndex as Observable<number>;

  constructor(private activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    const routeSub = this.activatedRoute.params.pipe(debounceTime(1)).subscribe(() => {
      this._selectedIndex.next(0);
    });
    this.subscriptions.push(routeSub);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.feeGuide) {
      this._feeGuide.next(this.feeGuide);
    }
  }

  public handleSelectedIndexChange(index: number): void {
    this.loadingOptions.isLoading = true;
    this._selectedIndex.next(index);
    setTimeout(() => {
        this.loadingOptions.isLoading = false;
    }, 250);
  }

  private constructLoadingOptions(): LoadingOptions {
    return Object.assign(new LoadingOptions(), {
      isLoading: false,
      backgroundColor: '#FDFDFD',
      color: '#388EFC',
      spinnerColor: '#388EFC',
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription?.unsubscribe());
  }

}
