import {Injectable} from '@angular/core';
import {Subscriber, WebSocketSubject} from 'rxjs/internal-compatibility';
import {environment} from '../../environments/environment';
import {delay, retry, retryWhen, switchMap, take} from 'rxjs/operators';
import {WebSocketEvent} from '../models/webSocketEvent';
import {Observable, Subject, Subscription, timer} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class WebSocketService {

    locationWebSocketSubjects = new Map<number, Observable<WebSocketEvent>>();
    subscriptionDisposal: Subscription = new Subscription();

    constructor() {
    }

    connect(companyId: number, locationId: number): Observable<WebSocketEvent> {
        const existingWebSocketSubject = this.locationWebSocketSubjects.get(locationId);
        if (!!existingWebSocketSubject) {
            return existingWebSocketSubject;
        }

        const subject = new Subject<WebSocketEvent>();
        this.locationWebSocketSubjects.set(locationId, subject);
        const webSocketUrl = `${environment.webSocketUrl}?companyId=${companyId}&locationId=${locationId}`;
        this.createWebSocketSubjectForSubscriber(webSocketUrl, subject);
        return subject;
    }

    createWebSocketSubjectForSubscriber(url: string, subject: Subject<WebSocketEvent>) {
        const webSocketSubject = new WebSocketSubject<WebSocketEvent>({
            url: url,
            deserializer: ({data}) => data,
        });
        const subscription = webSocketSubject.pipe(
            // retry in case web socket server is warming up and doesnt connect first try
            // retry 10 times 1 second apart
            retryWhen(errors => errors.pipe(delay(1000), take(10))),
        ).subscribe(subject);

        // reconnect to socket after 5 minutes
        subscription.add(timer(5 * 60 * 1000).subscribe(() => {
            this.createWebSocketSubjectForSubscriber(url, subject);
            this.subscriptionDisposal.remove(subscription);
            subscription.unsubscribe();
        }));
        this.subscriptionDisposal.add(subscription);
    }

    disconnectAll() {
        this.subscriptionDisposal.unsubscribe();
    }
}
