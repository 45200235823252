import {Deserializable} from '../views/shared/utils/json-utils';

export class LoadingOptions implements Deserializable {
    public isLoading: boolean = false;
    public fullscreen: boolean = false;
    public determinateLoading: boolean = false;
    public progress = 0;
    public loadingText: string;
    public backgroundColor: string;
    public spinnerColor: string;
    public color: string;
    public topMarginRem: number;

    onDeserialize() {
    }
}
