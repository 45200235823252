import {Component, EventEmitter, Input, OnInit, Output, TemplateRef} from '@angular/core';
import * as moment from 'moment';

@Component({
    selector: 'app-type-ahead-search',
    templateUrl: './type-ahead-search.component.html',
    styleUrls: [
        './type-ahead-search.component.scss',
        '../../stylesheet/stylesheet.component.scss'
    ]
})
export class TypeAheadSearchComponent implements OnInit {
    @Input() searchObjects: any[] = [];
    @Input() clearSearch: EventEmitter<any>;
    @Input() fieldLabel: string = $localize`Search for an Existing Patient`;
    @Input() fieldPlaceholder: string = $localize`Patient Name`;
    @Input() customClass: string;
    @Input() resultActionButtonText: string = '';
    @Input() showSearchButton: boolean = false;
    @Input() updateSearchResults: EventEmitter<any[]>;
    @Input() showResultCount: boolean = true;

    // This configuration should be defined with object properties being contained within parentheses. ie: {propertyName} - {anotherPropertyName}
    @Input() resultsDisplayConfiguration: string = '';
    @Input() resultIconPropertyName: string = '';
    @Input() searchableProperties: string[] = [];
    @Input() dateFormat: string = '';
    @Input() resultTemplate: TemplateRef<any>;
    @Output() itemSelected = new EventEmitter<any>();
    @Output() searchButtonClicked = new EventEmitter<string>();
    @Output() resultsActionButtonEvent = new EventEmitter<string>();

    public searchResults: any[] = [];
    public hideResults: boolean = true;
    public searchInput: string = '';

    constructor() {
    }

    ngOnInit(): void {
        // validate displayConfiguration
        if (this.getDisplayConfigurationProperties() === null) {
            console.warn('Invalid resultsDisplayConfiguration provided.');
        }
        if (this.dateFormat === '') {
            // set the default date format
            this.dateFormat = 'MM/DD/YYYY';
        }
        this.clearSearch.subscribe(() => {
            this.resetSearch();
        });

        this.updateSearchResults?.subscribe(results => {
           this.searchResults = results;
           this.hideResults = false;
        });
    }

    resetSearch() {
        this.searchInput = '';
        this.hideResults = true;
        this.itemSelected.emit(null);
    }

    searchInputChanged() {
        if (this.showSearchButton) {
            this.hideResults = true;
            return;
        }

        if (!this.searchInput || this.searchInput === '') {
            this.resetSearch();
        } else {
            this.hideResults = false;
            this.filterResults();
        }
    }

    filterResults() {
        if (this.showSearchButton) {
            return;
        }

        this.searchResults = this.searchObjects?.filter((obj) => {
            let validResult = false;
            this.searchableProperties.forEach((prop) => {
                const strippedProperty = obj[prop]?.toLowerCase().replace(/\s/g, '') ?? '';
                const strippedInput = this.searchInput.toLowerCase().replace(/\s/g, '');
                if (obj.hasOwnProperty(prop) && obj[prop] && strippedProperty.includes(strippedInput)) {
                    validResult = true;
                }
            });
            return validResult;
        }) ?? [];
    }

    getSearchResultDisplayText(result: any): string {
        const formattedResultText = this.getFormattedSearchResultDisplayText(result);
        if (formattedResultText && formattedResultText !== '') {
            return formattedResultText;
        } else {
            return result.name ? result.name : '';
        }
    }

    getDisplayConfigurationProperties(): string[] {
        const getPropertyRegex = /{(.*?)}/g;
        const properties = this.resultsDisplayConfiguration.match(getPropertyRegex);
        if (properties.length === 0) {
            console.log('No valid display properties provided');
            return null;
        } else {
            return properties;
        }
    }

    getFormattedSearchResultDisplayText(result: any): string {
        let resultName = this.resultsDisplayConfiguration;
        const displayProperties = this.getDisplayConfigurationProperties();
        // ensure object contains all the required properties
        displayProperties.forEach((prop) => {
            const propName = prop.replace('{', '').replace('}', '');
            if (!result.hasOwnProperty(propName)) {
                return null;
            } else {
                const selectedProperty = result[propName];
                if (selectedProperty instanceof Date) {
                    // format date according to
                    const formattedDate = moment(result[propName]).format(this.dateFormat);
                    resultName = resultName.replace(prop, formattedDate);
                } else {
                    resultName = resultName.replace(prop, selectedProperty ?? '');
                }
            }
        });
        return resultName;
    }

    rowClicked(result: any) {
        this.searchInput = this.getFormattedSearchResultDisplayText(result);
        this.hideResults = true;
        this.itemSelected.emit(result);
    }

    resultsActionButtonClicked() {
        this.resultsActionButtonEvent.emit(this.searchInput);
    }

    getItemIconSrc(item: any): string {
        if (this.resultIconPropertyName?.length > 0) {
            return item[this.resultIconPropertyName];
        } else {
            return null;
        }
    }

    search() {
        if (this.showSearchButton) {
            this.searchButtonClicked.emit(this.searchInput);
        }
    }
}
