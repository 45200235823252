<div class="modal-body">
    <div class="modal-title" i18n>
        Choose a Date Range
    </div>
    <!-- Present Date Picker Modal -->
    <div class="date-picker-modal-container">
        <app-date-picker
                [displayMonths]="2"
                [navigationType]="datePickerNavTypes.Arrows"
                [hideBorder]="true"
                [datePickerOutput]="datePickerOutputs.DateRange"
                [clearDateSelection]="clearDateSelection"
                (dateSelected)="handleCustomDateRangeSelection($event)">
        </app-date-picker>
    </div>

    <div class="modal-button-footer">
        <button class="teth-button grey-button mr-3"
                (click)="cancelCustomDateRangePressed()" i18n>
            Cancel
        </button>
        <button class="teth-button blue-button"
                [class.disabled]="!enableDatePickerContinueButton"
                [disabled]="!enableDatePickerContinueButton"
                (click)="continueCustomDateRangePressed()" i18n>
            Continue
        </button>
    </div>
</div>
