import {Component, OnInit} from '@angular/core';
import {File} from '../../../../models/file';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-upload-file-modal',
    templateUrl: './upload-file-modal.component.html',
    styleUrls: ['./upload-file-modal.component.scss', '../../../stylesheet/stylesheet.component.scss']
})
export class UploadFileModalComponent implements OnInit {
    titleText = '';
    files: File[] = [];

    constructor(private activeModal: NgbActiveModal) {
    }

    ngOnInit() {

    }

    initWith(title: string) {
        this.titleText = title;
    }

    closeModal() {
        this.activeModal.dismiss();
    }

    apply() {
        this.activeModal.close(this.files);
    }

    handleFileAdded(file: File) {
    }
}
