import {Deserializable} from '../views/shared/utils/json-utils';

export class Country implements Deserializable {
    public id: number;
    public countryCode: string;
    public name: string;
    public states: StateProvince[];

    onDeserialize() {
    }
}

export class StateProvince implements Deserializable {
    public id: number;
    public stateCode: string;
    public name: string;

    onDeserialize() {
    }
}
