import {Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {LabRadiobutton} from '../../../../../models/lab-radiobutton';

@Component({
  selector: 'app-checkbox-group',
  templateUrl: './checkbox-group.component.html',
  styleUrls: ['./checkbox-group.component.scss', '../../../../stylesheet/stylesheet.component.scss']
})
export class CheckboxGroupComponent implements OnInit {

  @Input() textLabel = '';
  @Input() textId = '';
  @Input() parentForm: FormGroup;
  @Input() radioButtons: LabRadiobutton[];
  @Input() control: FormControl;
  @Input() required: boolean = false;

  selectedValues = [];

  constructor() { }

  ngOnInit(): void {
  }

  checkboxSelected(event, id: number) {
    const checked = event.target.checked;
    if (checked) {
      this.selectedValues.push(id);
      this.parentForm.get(this.control.toString()).patchValue(this.selectedValues);
    } else {
      const locationIndex = this.selectedValues.findIndex(l => id === l);
      this.selectedValues.splice(locationIndex, 1);
      this.parentForm.get(this.control.toString()).patchValue(this.selectedValues);
    }
  }

}
