import {AfterViewInit, Component, Input, OnChanges, OnDestroy, SimpleChanges, ViewChildren} from '@angular/core';
import {DataTableExtraFilterGroup, DataTableExtraFilterOptions} from "../../../../../models/data-table-options";
import {BehaviorSubject, combineLatest, Observable, Subscription} from "rxjs";
import {map, shareReplay} from "rxjs/operators";
import {GroupOption} from "../../../../../models/group-option";
import FuzzySearch from 'fuzzy-search';
import {DeserializeHelper} from "../../../utils/json-utils";
import {ExtraFiltersSlideoutService} from "../extra-filters-slideout.service";
import {NgbDate} from "@ng-bootstrap/ng-bootstrap";
import {NestedFilterOptionsFormComponent} from "./nested-filter-options-form/nested-filter-options-form.component";

@Component({
    selector: 'app-extra-filter-options-form',
    templateUrl: './extra-filter-options-form.component.html',
    styleUrls: ['../../../../stylesheet/stylesheet.component.scss', './extra-filter-options-form.component.scss']
})
export class ExtraFilterOptionsFormComponent implements OnChanges, AfterViewInit, OnDestroy {

    constructor(private extraFilterService: ExtraFiltersSlideoutService) {
    }

    @Input() options: DataTableExtraFilterOptions;

    @ViewChildren('nestedFilterForm') nestedFilterForms: NestedFilterOptionsFormComponent[];

    private searchTextSubscription: Subscription;

    private _options = new BehaviorSubject<DataTableExtraFilterOptions>(null);
    public options$ = this._options as Observable<DataTableExtraFilterOptions>;

    private forNestedCheckboxOptions$ = this.options$.pipe(
        map(options => options?.selectionOptions?.some(g => g?.groupTitle))
    );

    private _searchText = new BehaviorSubject<string>('');
    public searchText$ = this._searchText as Observable<string>;

    public searchedGroupOptions$ = combineLatest([this.searchText$, this.options$]).pipe(
        map(([searchText, options]) => {
            const searchableItems = options?.selectionOptions?.flatMap((g => g?.groupOptions));
            if (!searchText || searchText === ' ') {
                return searchableItems;
            }
            const searcher = new FuzzySearch(searchableItems, ['displayableName'], {caseSensitive: false});
            return searcher.search(searchText) as GroupOption[];
        })
    );

    public searchedFilterGroups$ = combineLatest([this.searchText$, this.options$, this.forNestedCheckboxOptions$]).pipe(
        map(([searchText, options, forNestedOptions]) => {
            if (!forNestedOptions || !searchText || searchText === ' ') {
                return options?.selectionOptions;
            }

            const selectionOptionsCopy = DeserializeHelper.arrayOf(DataTableExtraFilterGroup,options?.selectionOptions)
            const searchableGroupOptions = selectionOptionsCopy?.flatMap((g => g?.groupOptions));

            const searcher = new FuzzySearch(searchableGroupOptions, ['displayableName'], {caseSensitive: false});
            const searcherResults = searcher.search(searchText) as GroupOption[];

            const groupsWithSearchHits = selectionOptionsCopy?.filter((g) => {
                return g?.groupOptions?.some((o) => {
                    return searcherResults?.some((sr) => {
                        return sr?.getUniqueIdentifier() === o?.getUniqueIdentifier();
                    })
                });
            });
            // filter out options that don't match the search
            groupsWithSearchHits?.forEach((g) => {
                g.groupOptions = g?.groupOptions?.filter((o) => {
                    return searcherResults?.some((sr) => {
                        return sr?.getUniqueIdentifier() === o?.getUniqueIdentifier();
                    })
                });
            });
            return groupsWithSearchHits;
        }),
        shareReplay({ bufferSize: 1, refCount: true })
    );

    public showNoResultsText$ = combineLatest([
        this.forNestedCheckboxOptions$,
        this.searchedGroupOptions$,
        this.searchedFilterGroups$,
        this.searchText$
    ]).pipe(
        map(([forNestedOptions, searchedGroupOptions, searchedFilterGroups, searchText]) => {
            const searchResults = forNestedOptions ? searchedFilterGroups : searchedGroupOptions;
            return !searchResults?.length && !!searchText;
        })
    );

    private _showAllItems = new BehaviorSubject<boolean>(false);
    public showAllItems$ = this._showAllItems as Observable<boolean>;
    toggleShowAllItems = () => {
        this.showAllItems$.once((val) => {
            this._showAllItems.next(!val);
        });
    }

    public showVisibilityToggle$ = combineLatest([this.forNestedCheckboxOptions$, this.searchText$, this.searchedFilterGroups$]).pipe(
        map(([forNestedOptions, searchText, searchedItems]) => {
            return forNestedOptions && searchedItems?.length > 5 && !searchText;
        })
    );

    public visibilityToggleText$ = this.showAllItems$.pipe(
        map(showAll => showAll ? $localize`See less` : $localize`See more`)
    );

    public searchPlaceholder$ = this.options$.pipe(
        map(options => {
            const searchFor = $localize`Search for`;
            return `${searchFor} ${options?.filterName}`
        })
    );

    public selectionOptionsTrackBy(index: number, filterGroup: DataTableExtraFilterGroup): string {
        return filterGroup?.groupTitle;
    }

    public groupOptionTrackBy(index: number, groupOption: GroupOption): string {
        return groupOption?.getUniqueIdentifier();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.options) {
            this._options.next(this.options);
        }
    }

    public ngAfterViewInit(): void {
        this.openNestedOptionsOnSearch();
    }

    public ngOnDestroy(): void {
        this.searchTextSubscription?.unsubscribe();
    }

    private openNestedOptionsOnSearch(): void {
        this.searchTextSubscription = combineLatest([
            this.searchText$,
            this.forNestedCheckboxOptions$
        ]).subscribe(([searchText, forNestedOptions]) => {
            if (forNestedOptions && searchText) {
                this.nestedFilterForms?.forEach((form) => {
                    form.setOptionsAreVisible(true);
                })
            }
        });
    }

    public handleSelectionChange(groupOption: GroupOption): void {
        this.options$.once((options) => {
            const optionsCopy = DeserializeHelper.deserializeToInstance(DataTableExtraFilterOptions, options);
            const extraFilterGroup = optionsCopy?.selectionOptions?.find((g) => {
                return g?.groupOptions?.find((o) => {
                    return o?.getUniqueIdentifier() === groupOption?.getUniqueIdentifier();
                })
            });
            const selectedOptions = extraFilterGroup.selectedOptions;
            const selectedIndex = selectedOptions.findIndex((so) => so?.getUniqueIdentifier() === groupOption?.getUniqueIdentifier());
            if (selectedIndex >= 0) {
                selectedOptions.splice(selectedIndex, 1);
            } else {
                selectedOptions.push(groupOption);
            }
            this.extraFilterService.setDataTableExtraFilterGroup(optionsCopy);
        });
    }

    public handleSelectAllClicked(manufacturedGroup: DataTableExtraFilterGroup) {
        this.options$.once((options) => {
            const optionsCopy = DeserializeHelper.deserializeToInstance(DataTableExtraFilterOptions, options);
            const targetFilterGroup = optionsCopy?.selectionOptions?.find((fg) => {
                return fg?.getUniqueIdentifier() === manufacturedGroup?.getUniqueIdentifier();
            })

            const everyOptionIsAlreadySelected = manufacturedGroup?.groupOptions?.every((o) => {
                return manufacturedGroup?.selectedOptions?.some((so) => {
                    return so?.getUniqueIdentifier() === o?.getUniqueIdentifier();
                });
            });

            if (everyOptionIsAlreadySelected) {
                const removeAlreadySelectedOptions = (o: GroupOption) => {
                    return !manufacturedGroup?.groupOptions?.some((opt) => {
                        return opt?.getUniqueIdentifier() === o?.getUniqueIdentifier();
                    });
                }
                targetFilterGroup.selectedOptions = targetFilterGroup?.selectedOptions?.filter(removeAlreadySelectedOptions);
            } else {
                targetFilterGroup.selectedOptions = [
                    ...(targetFilterGroup?.selectedOptions ?? []),
                    ...(manufacturedGroup?.groupOptions ?? [])
                ]?.uniqueByMethod('getUniqueIdentifier');
            }

            this.extraFilterService.setDataTableExtraFilterGroup(optionsCopy);
        })
    }

    public handleDateRangeSelected(dateRange: NgbDate[]): void {
        this.options$.once((options) => {
            const optionsCopy = DeserializeHelper.deserializeToInstance(DataTableExtraFilterOptions, options);
            optionsCopy.selectedDates = dateRange;
            this.extraFilterService.setDataTableExtraFilterGroup(optionsCopy);
        })
    }

    public handleDateRangeCleared(): void {
        this.options$.once((options) => {
            const optionsCopy = DeserializeHelper.deserializeToInstance(DataTableExtraFilterOptions, options);
            optionsCopy.selectedDates = null;
            this.extraFilterService.setDataTableExtraFilterGroup(optionsCopy);
        })
    }

    public handleSearchInput(e: Event): void {
        const target = e?.target as HTMLInputElement;
        const value = target?.value;
        if (value) {
            this._showAllItems.next(true);
        }
        this._searchText.next(target?.value);
    }

}
