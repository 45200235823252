import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import 'rxjs-compat/add/operator/delay';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import 'rxjs-compat/add/operator/catch';
import {DomSanitizer, SafeValue} from '@angular/platform-browser';
import * as utif from 'utif';

@Component({
    selector: 'app-live-preview-display',
    templateUrl: './image-preview-modal.component.html',
    styleUrls: ['./image-preview-modal.component.scss', '../../stylesheet/stylesheet.component.scss']
})
export class ImagePreviewModalComponent implements OnInit {
    searching: boolean;
    error: string;
    url: string | SafeValue;
    printUrl: string;
    titleText = '';
    public s3Url: string;
    pdf = false;
    tiff = false;
    errorOnImageLoad = false;
    mediaType: string = null;
    blobUrl: string = null;
    iFrameLoaded: boolean = false;
    canPrint = true;
    @ViewChild('rgbaCanvas') rgbaCanvas: ElementRef;

    constructor(private activeModal: NgbActiveModal,
                private toastr: ToastrService,
                private http: HttpClient,
                private sanitizer: DomSanitizer,
    ) {

    }

    ngOnInit() {
    }

    initWithUrl(url: string, title: string, pdf: boolean, tiff: boolean, blobUrl: string, canPrint: boolean = true) {
        this.titleText = title;
        this.printUrl = url;
        this.pdf = pdf;
        this.tiff = tiff;
        this.blobUrl = blobUrl;
        this.canPrint = canPrint;
        if (pdf) {
            this.url = this.sanitizer.bypassSecurityTrustResourceUrl(url);
        } else if (tiff) {
            this.setURL(url);
        } else {
            this.url = this.sanitizer.bypassSecurityTrustUrl(url);
        }
    }

    closeModal() {
        this.activeModal.close();
    }

    download() {
        const a = document.createElement('a');
            a.href = this.blobUrl;
            a.download = this.titleText;
            a.setAttribute('target', '_blank');
            a.click();
    }

    print() {
        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        iframe.src = this.blobUrl;
        document.body.appendChild(iframe);
        iframe.contentWindow.print();
    }

    setURL(imgUrl: string) {
        this.s3Url = imgUrl;
        this.downloadResource();
    }

    doneLoading() {
        this.iFrameLoaded = true;
    }

    renderTiffImageOnCanvas(data: Response) {
        data.arrayBuffer().then(arrayBuffer => {
            const ifds = utif.decode(arrayBuffer);
            utif.decodeImage(arrayBuffer, ifds[0]);
            const rgba  = utif.toRGBA8(ifds[0]);  // Uint8Array with RGBA pixels
            const ctx = this.rgbaCanvas.nativeElement.getContext('2d') as CanvasRenderingContext2D;
            ctx.canvas.height = ifds[0].height;
            ctx.canvas.width = ifds[0].width;
            ctx.putImageData(new ImageData(new Uint8ClampedArray(rgba), ifds[0].width, ifds[0].height), 0, 0);
        });
    }

    downloadResource() {
        const headers = new HttpHeaders({
            'Accept': 'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,image/tiff,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9',
        });
        this.http.get<Response>(this.s3Url, {headers: headers, responseType: 'blob' as 'json'})
            .subscribe(d => {
                if (this.tiff) {
                    this.renderTiffImageOnCanvas(d);
                } else {
                    this.url = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(d));
                }
            }, error => {
                console.log(error);
            });
    }
}
