<div class="modal-body">
    <div class="modal-title" [hidden]="files.length > 0">{{titleText}}</div>
    <div class="modal-title" [hidden]="files.length < 1">{{editTitleText}}</div>
    <div class="mt-3 pt-1" [hidden]="files.length > 0">
        <app-file-upload
                [imageOnly]="true"
                [files]="files"
                [handleFileAdded]="handleFileAdded">
        </app-file-upload>
    </div>
    <div class="mt-3 pt-1 pb-4" [hidden]="files.length < 1">
        <image-cropper
                class="image-cropper"
                [imageURL]="files[0]?.url"
                [maintainAspectRatio]="true"
                [aspectRatio]="1"
                format="png"
                (imageCropped)="imageCropped($event)"
                (imageLoaded)="imageLoaded()"
                (cropperReady)="cropperReady()"
                [roundCropper]="roundedOverlay"
                [hideResizeSquares]="false"
                (loadImageFailed)="loadImageFailed()">
        </image-cropper>
    </div>
    <div class="footer-button">
        <button class="teth-button grey-button" (click)="closeModal()" i18n>Cancel</button>
        <button class="teth-button blue-button ml-3" [hidden]="files.length < 1" (click)="apply()" i18n>Apply</button>
    </div>
</div>
