import {DatatableExtraFilterId} from './enum/datatable-extra-filter-id.enum';
import {UniquelyIdentifiable} from '../shared/interfaces/uniquely-identifiable';
import {NgbDate} from '@ng-bootstrap/ng-bootstrap';

export class DatatableOdataParam implements UniquelyIdentifiable {
    public propertyName: string;
    public paramValue: string[] | number[] | boolean[] | NgbDate[];
    public paramId: DatatableExtraFilterId;

    constructor(name: string, value: string[] | number[] | boolean[] | NgbDate[], id: DatatableExtraFilterId) {
        this.propertyName = name;
        this.paramValue = value;
        this.paramId = id;
    }

    getUniqueIdentifier(...opts: any): string {
        return `${this.propertyName}
        -${this.paramValue.toString()}
        -${this.paramId}`;
    }
}
