import {Directive, ElementRef, HostListener, Input} from '@angular/core';
import {ContentScrollService} from "../../../services/content-scroll.service";

@Directive({
  selector: '[appScrollToTrigger]'
})
export class ScrollToTriggerDirective {

  @Input() enabled = true;
  @Input() scrollToKey: string;

  constructor(
      private elementRef: ElementRef,
      private contentScrollService: ContentScrollService
  ) {
  }

  @HostListener('click')
  onClick(): void {
    if (this.enabled) {
      this.contentScrollService.scrollToTarget(this.scrollToKey);
    }
  }

}
