import {Component, OnInit} from '@angular/core';
import {File} from '../../../../models/file';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Referral} from '../../../../models/referral.model';
import {ReferralService} from '../../../../services/referral.service';
import {EntityService} from '../../../../services/entity.service';
import {CorrespondenceType} from '../../../../models/correspondence-type.model';
import {Correspondence} from '../../../../models/correspondence.model';
import {merge, Observable} from 'rxjs';
import {FileService} from '../../../../services/file.service';
import {toArray} from 'rxjs/operators';
import {SessionService} from '../../../../services/session.service';
import {CorrespondenceStatus} from '../../../../models/correspondence-status.model';

@Component({
    selector: 'app-create-correspondence-modal',
    templateUrl: './create-correspondence-modal.component.html',
    styleUrls: ['./create-correspondence-modal.component.scss', '../../../stylesheet/stylesheet.component.scss']
})
export class CreateCorrespondenceModalComponent implements OnInit {
    files: File[] = [];
    referral: Referral;
    correspondenceTypes: CorrespondenceType[];
    selectedCorrespondenceType: CorrespondenceType = null;
    approve: boolean = false;
    loading = false;
    loadingText = $localize`Creating Correspondence`;

    constructor(private activeModal: NgbActiveModal,
                private entityService: EntityService,
                private fileService: FileService,
                private sessionService: SessionService,
                private referralService: ReferralService) {
    }

    ngOnInit() {
        this.entityService.correspondenceTypes$.subscribe(t => this.correspondenceTypes = t);
    }

    initWith(referral: Referral) {
        this.referral = referral;
    }

    closeModal() {
        this.activeModal.close();
    }

    generateCorrespondence(): Correspondence {
        const newCorrespondence = new Correspondence();
        newCorrespondence.referralId = this.referral.id;
        newCorrespondence.isSystemGenerated = false;
        newCorrespondence.correspondenceTypeId = this.selectedCorrespondenceType.id;
        newCorrespondence.referringCorrespondenceStatusTypeId = CorrespondenceStatus.PendingId;
        newCorrespondence.referredCorrespondenceStatusTypeId = this.approve ? CorrespondenceStatus.ApprovedId : CorrespondenceStatus.PendingId;
        newCorrespondence.signeeId = this.sessionService.sessionContainer.value?.employee.id;
        newCorrespondence.content = 'N/A';
        return newCorrespondence;
    }

    createCorrespondence() {
        this.loading = true;
        this.referralService.createReferralCorrespondence(this.generateCorrespondence()).subscribe(c => {
            const attachmentObs: Observable<any>[] = [];
            for (const f of this.files) {
                attachmentObs.push(this.fileService.uploadCorrespondenceAttachment(f, c));
            }
            merge(...attachmentObs).pipe(toArray()).subscribe( result => {
                this.loading = false;
                this.activeModal.close(true);
            }, error => {
                this.loading = false;
                console.log(error);
            });
        }, error => {
            this.loading = false;
            console.log(error);
        });
    }

    handleFileAdded(file: File) {
        console.log(file);
    }
}
