import {ElementRef, Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ContentScrollService {

    public scrollToTopSubject: Subject<void> = new Subject<void>();
    private _scrollToTargetMap = new BehaviorSubject(new Map<any, ElementRef>());

    constructor() {
    }

    scrollToTop() {
        this.scrollToTopSubject.next();
    }

    public addScrollTargetToMap(key: any, target: ElementRef): void {
        this._scrollToTargetMap.once((targetMap) => {
            const targetMapCopy = new Map(targetMap);
            targetMapCopy.set(key, target);
            this._scrollToTargetMap.next(targetMapCopy);
        });
    }

    public removeScrollTargetFromMap(key: any): void {
        this._scrollToTargetMap.once((targetMap) => {
            const targetMapCopy = new Map(targetMap);
            targetMapCopy.delete(key);
            this._scrollToTargetMap.next(targetMapCopy);
        });
    }

    public scrollToTarget(key: any): void {
        this._scrollToTargetMap.once((targetMap) => {
            const target = targetMap.get(key);
            target?.nativeElement?.scrollIntoView({ behavior: "smooth", block: 'start' });
        });
    }
}
