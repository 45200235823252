import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ApiService} from './api.service';
import {environment} from '../../environments/environment';
import {ReferralNotification} from '../models/referral-notification.model';
import {ReferralUpdatedNotification} from '../models/referral-updated-notification.model';
import {CompletedNotifications} from '../models/completed-notifications.model';
import {DeserializeHelper} from '../views/shared/utils/json-utils';
import {BehaviorSubject, forkJoin, Observable, Subject} from 'rxjs';
import {SessionService} from './session.service';
import {PermissionService, PermissionTypeIds} from './permission.service';
import {tap} from 'rxjs/operators';
import {WebSocketEvent} from '../models/webSocketEvent';
import {WebSocketService} from './web-socket.service';
import {
    LocationAllNotifications,
    LocationNotificationGroup,
    ReferralNotificationType
} from '../models/location-all-notifications';

@Injectable({
    providedIn: 'root'
})
export class NotificationService {
    locationNotifications = new Map<number, BehaviorSubject<LocationAllNotifications>>();
    eventSubject = new Subject<[number, WebSocketEvent]>();

    constructor(private http: HttpClient,
                private sessionService: SessionService,
                private permissionService: PermissionService,
                private webSocketService: WebSocketService,
                private api: ApiService) {
    }

    public getReferralCreatedNotifications(locationId: number, companyId: number, getCompleted: boolean): Observable<ReferralNotification[]> {
        const params = new Map<string, string>();
        const reqParams = Object.assign({}, params);
        reqParams['Completed'] = getCompleted.toString();
        return this.api.get<ReferralNotification[]>(environment.apiBaseUrl +
            `/Companies/${companyId}/locations/${locationId}/referralcreatednotifications`, reqParams)
            .map(r => {
                return r.map(d => DeserializeHelper.deserializeToInstance(ReferralNotification, d));
            });
    }

    public completeReferralCreatedNotifications(locationId: number, companyId: number, notificationIds: CompletedNotifications): Observable<ReferralNotification> {
        return this.api.put<ReferralNotification>(environment.apiBaseUrl +
            `/Companies/${companyId}/locations/${locationId}/referralcreatednotifications/complete`, notificationIds);
    }

    public getReferralMessageCreatedNotifications(incoming: boolean, locationId: number, companyId: number, getCompleted: boolean): Observable<ReferralNotification[]> {
        const params = new Map<string, string>();
        const reqParams = Object.assign({}, params);
        reqParams['Completed'] = getCompleted.toString();
        reqParams['incoming'] = incoming.toString();
        return this.api.get<ReferralNotification[]>(environment.apiBaseUrl +
            `/Companies/${companyId}/locations/ ${locationId}/referralmessagecreatednotifications`, reqParams)
            .map(r => {
                return r.map(d => DeserializeHelper.deserializeToInstance(ReferralNotification, d));
            });
    }

    public completeReferralMessageNotifications(incoming: boolean, locationId: number, companyId: number, notificationIds: CompletedNotifications): Observable<ReferralNotification> {
        return this.api.put<ReferralNotification>(environment.apiBaseUrl +
            `/Companies/${companyId}/locations/${locationId}/referralmessagecreatednotifications/complete`, notificationIds);
    }

    public getReferralUpdatedNotifications(locationId: number, companyId: number, getCompleted: boolean): Observable<ReferralUpdatedNotification[]> {
        const params = new Map<string, string>();
        const reqParams = Object.assign({}, params);
        reqParams['Completed'] = getCompleted.toString();
        return this.api.get<ReferralUpdatedNotification[]>(environment.apiBaseUrl +
            `/Companies/${companyId}/locations/${locationId}/referralupdatednotifications`, reqParams)
            .map(r => this.filterByPermission(r.map(d => DeserializeHelper.deserializeToInstance(ReferralUpdatedNotification, d))));
    }

    public completeReferralUpdatedNotifications(locationId: number, companyId: number, notificationIds: CompletedNotifications): Observable<ReferralNotification> {
        return this.api.put<ReferralNotification>(environment.apiBaseUrl +
            `/Companies/${companyId}/locations/${locationId}/referralupdatednotifications/complete`, notificationIds);
    }

    public getLabReferralCreatedNotifications(locationId: number, companyId: number, getCompleted: boolean): Observable<ReferralNotification[]> {
        const params = new Map<string, string>();
        const reqParams = Object.assign({}, params);
        reqParams['Completed'] = getCompleted.toString();
        return this.api.get<ReferralNotification[]>(environment.apiBaseUrl +
            `/Companies/${companyId}/dentallabslocations/${locationId}/referralcreatednotifications`, reqParams)
            .map(r => {
                return r.map(d => DeserializeHelper.deserializeToInstance(ReferralNotification, d));
            });
    }

    public getLabReferralMessageCreatedNotifications(incoming: boolean, locationId: number, companyId: number, getCompleted: boolean): Observable<ReferralNotification[]> {
        const params = new Map<string, string>();
        const reqParams = Object.assign({}, params);
        reqParams['Completed'] = getCompleted.toString();
        reqParams['incoming'] = incoming.toString();
        return this.api.get<ReferralNotification[]>(environment.apiBaseUrl +
            `/Companies/${companyId}/dentallabslocations/ ${locationId}/referralmessagecreatednotifications`, reqParams)
            .map(r => {
                return r.map(d => DeserializeHelper.deserializeToInstance(ReferralNotification, d));
            });
    }

    public getLabReferralUpdatedNotifications(locationId: number, companyId: number, getCompleted: boolean): Observable<ReferralUpdatedNotification[]> {
        const params = new Map<string, string>();
        const reqParams = Object.assign({}, params);
        reqParams['Completed'] = getCompleted.toString();
        return this.api.get<ReferralUpdatedNotification[]>(environment.apiBaseUrl +
            `/Companies/${companyId}/dentallabslocations/${locationId}/referralupdatednotifications`, reqParams)
            .map(r => this.filterByPermission(r.map(d => DeserializeHelper.deserializeToInstance(ReferralUpdatedNotification, d))));
    }

    public completeLabReferralCreatedNotifications(locationId: number, companyId: number, notificationIds: CompletedNotifications): Observable<ReferralNotification> {
        return this.api.put<ReferralNotification>(environment.apiBaseUrl +
            `/Companies/${companyId}/dentallabslocations/${locationId}/referralcreatednotifications/complete`, notificationIds);
    }

    public completeLabReferralMessageNotifications(incoming: boolean, locationId: number, companyId: number, notificationIds: CompletedNotifications): Observable<ReferralNotification> {
        return this.api.put<ReferralNotification>(environment.apiBaseUrl +
            `/Companies/${companyId}/dentallabslocations/${locationId}/referralmessagecreatednotifications/complete`, notificationIds);
    }

    filterByPermission(notifications: ReferralUpdatedNotification[]): ReferralUpdatedNotification[] {
        if (this.permissionService.permissionGranted(PermissionTypeIds.ManageReferralsViewAllReferrals)) {
            return notifications;
        }
        const currentUser = this.sessionService.sessionContainer.value.employee;
        return notifications.filter(n => n.referringDentist === `${currentUser.primaryName} ${currentUser.familyName}`);
    }

    getNotificationSubject(locationId: number, companyId: number): BehaviorSubject<LocationAllNotifications> {
        let cachedLocationNotificationsSubject = this.locationNotifications.get(locationId);

        if (!cachedLocationNotificationsSubject) {
            cachedLocationNotificationsSubject = new BehaviorSubject<LocationAllNotifications>(null);
            this.locationNotifications.set(locationId, cachedLocationNotificationsSubject);
            this.loadNotifications(locationId, companyId, cachedLocationNotificationsSubject, ReferralNotificationType.All);
            this.bindToWebSocketNotifications(locationId, companyId, cachedLocationNotificationsSubject);
        }

        return cachedLocationNotificationsSubject;
    }

    refreshNotifications(locationId: number, companyId: number) {
        const cachedLocationNotificationsSubject = this.locationNotifications.get(locationId);
        if (cachedLocationNotificationsSubject) {
            this.loadNotifications(locationId, companyId, cachedLocationNotificationsSubject, ReferralNotificationType.All);
        }
    }

    loadNotifications(locationId: number, companyId: number, notificationSubject: BehaviorSubject<LocationAllNotifications>, loadNotificationType: ReferralNotificationType) {

        const cachedLocationNotifications = notificationSubject.value;
        const updatedNotifications = new LocationAllNotifications();
        updatedNotifications.incoming = Object.assign(new LocationNotificationGroup(), cachedLocationNotifications?.incoming);
        updatedNotifications.outgoing = Object.assign(new LocationNotificationGroup(), cachedLocationNotifications?.outgoing);

        const obs: Observable<any>[] = [];

        // Incoming Referral Created Notifications
        if (loadNotificationType === ReferralNotificationType.All || loadNotificationType === ReferralNotificationType.ReferralCreated) {
            const referralCreatedUnread = this.getReferralCreatedNotifications(locationId, companyId, false);
            const labReferralCreatedUnread = this.getLabReferralCreatedNotifications(locationId, companyId, false);
            const referralCreatedCompleted = this.getReferralCreatedNotifications(locationId, companyId, true);
            const labReferralCreatedCompleted = this.getLabReferralCreatedNotifications(locationId, companyId, true);
            const createdUnread = forkJoin([referralCreatedUnread, labReferralCreatedUnread])
                .pipe(tap(n => {
                    updatedNotifications.incoming.referralsUnread = n.flat();
                }));
            const createdCompleted = forkJoin([referralCreatedCompleted, labReferralCreatedCompleted])
                .pipe(tap(n => {
                    updatedNotifications.incoming.referralsCompleted = n.flat();
                }));
            obs.push(createdUnread, createdCompleted);
        }

        // Outgoing Referral Updated Notifications
        if (loadNotificationType === ReferralNotificationType.All || loadNotificationType === ReferralNotificationType.ReferralUpdated) {
            const referralUpdated = this.getReferralUpdatedNotifications(locationId, companyId, false);
            const labReferralUpdated = this.getLabReferralUpdatedNotifications(locationId, companyId, false);
            const updatedObs = forkJoin([referralUpdated, labReferralUpdated])
                .pipe(tap(n => {
                    updatedNotifications.outgoing.referralsUnread = n.flat();
                }));
            obs.push(updatedObs);
        }

        // Incoming Message Notifications
        if (loadNotificationType === ReferralNotificationType.All || loadNotificationType === ReferralNotificationType.Message) {
            const incomingMessageCreatedUnread = this.getReferralMessageCreatedNotifications(true, locationId, companyId, false);
            const incomingLabMessageCreatedUnread = this.getLabReferralMessageCreatedNotifications(true, locationId, companyId, false);
            const incomingMessageCreatedCompleted = this.getReferralMessageCreatedNotifications(true, locationId, companyId, true);
            const incomingLabMessageCreatedCompleted = this.getLabReferralMessageCreatedNotifications(true, locationId, companyId, true);
            const incomingMessagesUnread = forkJoin([incomingMessageCreatedUnread, incomingLabMessageCreatedUnread])
                .pipe(tap(n => {
                    updatedNotifications.incoming.messagesUnread = n.flat();
                }));
            const incomingMessagesCompleted = forkJoin([incomingMessageCreatedCompleted, incomingLabMessageCreatedCompleted])
                .pipe(tap(n => {
                    updatedNotifications.incoming.messagesCompleted = n.flat();
                }));
            obs.push(incomingMessagesUnread, incomingMessagesCompleted);

            // Outgoing Message Notifications
            const outgoingMessageCreatedUnread = this.getReferralMessageCreatedNotifications(false, locationId, companyId, false);
            const outgoingLabMessageCreatedUnread = this.getLabReferralMessageCreatedNotifications(false, locationId, companyId, false);
            const outgoingMessageCreatedCompleted = this.getReferralMessageCreatedNotifications(false, locationId, companyId, true);
            const outgoingLabMessageCreatedCompleted = this.getLabReferralMessageCreatedNotifications(false, locationId, companyId, true);
            const outgoingMessagesUnread = forkJoin([outgoingMessageCreatedUnread, outgoingLabMessageCreatedUnread])
                .pipe(tap(n => {
                    updatedNotifications.outgoing.messagesUnread = n.flat();
                }));
            const outgoingMessagesCompleted = forkJoin([outgoingMessageCreatedCompleted, outgoingLabMessageCreatedCompleted])
                .pipe(tap(n => {
                    updatedNotifications.outgoing.messagesCompleted = n.flat();
                }));
            obs.push(outgoingMessagesUnread, outgoingMessagesCompleted);
        }

        forkJoin(obs).subscribe(() => {
            notificationSubject.next(updatedNotifications);
        });
    }

    bindToWebSocketNotifications(locationId: number, companyId: number, notificationSubject: BehaviorSubject<LocationAllNotifications>) {
        const connection = this.webSocketService.connect(companyId, locationId);
        const connectionSubscription = connection.subscribe(socketEvent => {
            let loadReferralTypes = ReferralNotificationType.All;
            switch (socketEvent) {
                case WebSocketEvent.ReferralCreated:
                case WebSocketEvent.DentalLabReferralCreated:
                    loadReferralTypes = ReferralNotificationType.ReferralCreated;
                    break;
                case WebSocketEvent.ReferralUpdated:
                case WebSocketEvent.DentalLabReferralUpdated:
                    loadReferralTypes = ReferralNotificationType.ReferralUpdated;
                    break;
                case WebSocketEvent.ReferralMessageCreated:
                case WebSocketEvent.DentalLabReferralMessageCreated:
                    loadReferralTypes = ReferralNotificationType.Message;
                    break;
            }
            this.eventSubject.next([locationId, socketEvent]);
            this.loadNotifications(locationId, companyId, notificationSubject, loadReferralTypes);
        });
        notificationSubject.subscribe(() => {}, () => {}, () => {
            connectionSubscription.unsubscribe();
        });
    }

    disposeBindings() {
        this.locationNotifications.forEach(subject => {
            subject.complete();
        });
        this.locationNotifications.clear();
        this.webSocketService.disconnectAll();
    }
}
