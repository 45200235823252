import {Deserializable, Logable} from '../views/shared/utils/json-utils';

export class IntegrationLog implements Deserializable, Logable {
    public id: string;
    public referralId: string;
    public message: string;
    public content: string;
    public createdDate: Date;
    public createdById: number;
    public createdBy: string;
    public isLogItem: boolean = true;

    onDeserialize() {
        this.content = this.message;
    }

}
