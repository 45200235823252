import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild} from '@angular/core';
import {DataTableExtraFilterOptions} from "../../../../../models/data-table-options";
import {BehaviorSubject, combineLatest, Observable, of} from "rxjs";
import {NgbDate, NgbDateParserFormatter, NgbDatepickerI18n, NgbInputDatepicker} from "@ng-bootstrap/ng-bootstrap";
import {CustomDatepickerTetherport, I18n} from "../../../components/date-picker/custom-date-picker-language";
import {map} from "rxjs/operators";
import {DatePipe} from "@angular/common";

@Component({
  selector: 'app-date-filter-options-form',
  templateUrl: './date-filter-options-form.component.html',
  styleUrls: ['../../../../stylesheet/stylesheet.component.scss', './date-filter-options-form.component.scss'],
  providers: [
    I18n,
    {provide: NgbDatepickerI18n, useClass: CustomDatepickerTetherport},
    DatePipe
  ]
})
export class DateFilterOptionsFormComponent implements OnChanges {

  constructor(public formatter: NgbDateParserFormatter, private datePipe: DatePipe) {
  }

  @Input() options: DataTableExtraFilterOptions;
  @Output() dateRangeSelected = new EventEmitter<NgbDate[]>();
  @Output() dateRangeCleared = new EventEmitter<void>();

  @ViewChild('dp') datePicker: NgbInputDatepicker;

  private _startDate = new BehaviorSubject<NgbDate>(null);
  public startDate$ = this._startDate as Observable<NgbDate>;

  private _endDate = new BehaviorSubject<NgbDate>(null);
  public endDate$ = this._endDate as Observable<NgbDate>;

  public hideClearButton$ = combineLatest([this.startDate$, this.endDate$]).pipe(
      map(([startDate, endDate]) => {
        return !startDate && !endDate;
      })
  );

  public inputPlaceHolder$ = of($localize`Select a date range`);

  public rangeString$ = combineLatest([this.startDate$, this.endDate$, this.inputPlaceHolder$]).pipe(
        map(([startDate, endDate, placeholder]) => {
          const dateFormat = 'MMM d, y';
          const formattedStartDate = startDate
              ? this.datePipe.transform(this.formatter.format(startDate), dateFormat)
              : null;
          const formattedEndDate = startDate
              ? this.datePipe.transform(this.formatter.format(endDate), dateFormat)
              : null;
          if (formattedStartDate && formattedEndDate) {
            return `${formattedStartDate} - ${formattedEndDate}`;
          }
          if (formattedStartDate) {
            return `${formattedStartDate} -`;
          }
          if (formattedEndDate) {
            return `- ${formattedEndDate}`;
          }
          return placeholder;
        })
  );

  public hoveredDate: NgbDate | null = null;

  public isCurrentDate(date: any): boolean {
    return date.year === new Date().getFullYear() && date.month === new Date().getMonth() + 1 && date.day === new Date().getDate();
  }

  public onDateSelection(date: NgbDate): void {
    combineLatest([this.startDate$, this.endDate$]).once(([startDate, endDate]) => {
      if (!startDate && !endDate) {
        this._startDate.next(date);
      } else if (startDate && !endDate && date.after(startDate)) {
        this._endDate.next(date);
        this.dateRangeSelected.emit([startDate, date]);
        this.datePicker.close();
      } else {
        this._endDate.next(null)
        this._startDate.next(date);
      }
    });
  }

  public isHovered(date: NgbDate): boolean {
    const startDate = this._startDate.getValue();
    const endDate = this._endDate.getValue();
    return (
        startDate && !endDate && this.hoveredDate && date.after(startDate) && date.before(this.hoveredDate)
    );
  }

  public isInside(date: NgbDate): boolean {
    const startDate = this._startDate.getValue();
    const endDate = this._endDate.getValue();
    return endDate && date.after(startDate) && date.before(endDate);
  }

  public isRange(date: NgbDate): boolean {
    const startDate = this._startDate.getValue();
    const endDate = this._endDate.getValue();
    return (
        date.equals(startDate) ||
        (endDate && date.equals(endDate)) ||
            this.isInside(date) ||
            this.isHovered(date)
    );
  }

  public resetDates(): void {
    this.dateRangeCleared.emit();
    this._startDate.next(null);
    this._endDate.next(null);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.options) {
      const selectedDates = this.options?.selectedDates;
      if (selectedDates?.length === 2) {
        this._startDate.next(selectedDates[0]);
        this._endDate.next(selectedDates[1]);
      } else {
        this._startDate.next(null);
        this._endDate.next(null);
      }
    }
  }

}
