export default class ModalUtils {
    static defaultStaticModalOptions(): any {
        return {size: 'md', backdrop: 'static', centered: true};
    }
    static defaultModalOptions(): any {
        return {size: 'md', centered: true};
    }

    static modalOptions(size: 'sm' | 'md' | 'lg' | 'xl', staticBackdrop: boolean, centered: boolean = true): any {
        const options: any =  {size: size, centered: centered};
        if (staticBackdrop) {
            options.backdrop = 'static';
        }
        return options;
    }
}
