export default class UserUtils {
    static prefixes = [
        'N/A',
        'Dr.',
        'Mrs.',
        'Ms.',
        'Mr.',
        'Mx.'
    ];

    static formatNameToLastNameFirstNameFormat(fullName: string): string {
        const names = fullName?.split(' ');
        if (!names) {
            return '';
        }
        const firstName = names.slice(0, -1).join(' ');
        const lastName = names.slice(-1).join(' ');
        return `${lastName}, ${firstName}`;
    }

    static generateNewPassword(): string {
        const alphaChars = 'abcdefghijklmnopqrstuvwxyz';
        const numericChars = '0123456789';
        const specialChars = '!@#$%^&*()';

        let alphaPortion = '';
        for (let i = 0, n = alphaChars.length; i < 4; i++) {
            alphaPortion += alphaChars.charAt(Math.floor(Math.random() * n));
        }
        alphaPortion = alphaPortion[0].toUpperCase() + alphaPortion.slice(1);

        let numericPortion = '';
        for (let i = 0, n = numericChars.length; i < 3; i++) {
            numericPortion += numericChars.charAt(Math.floor(Math.random() * n));
        }

        let specialPortion = '';
        for (let i = 0, n = specialChars.length; i < 1; i++) {
            specialPortion += specialChars.charAt(Math.floor(Math.random() * n));
        }

        return alphaPortion + numericPortion + specialPortion;
    }
}
