import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';

@Component({
  selector: 'app-removables-form',
  templateUrl: './removables-form.component.html',
  styleUrls: ['./removables-form.component.scss']
})
export class RemovablesFormComponent implements OnInit {

  constructor() { }

  @Input() parentForm: FormGroup;
  @Input() radiobuttons = [];

  ngOnInit(): void {
  }

  getLocalizedRestorationTypeTitle(): string {
    return $localize`Restoration Type`;
  }

  getLocalizedShadeTitle(): string {
    return $localize`Shade`;
  }

  getLocalizedPartialDentureTitle(): string {
    return $localize`Partial Denture`;
  }

  getLocalizedCompleteDentureTitle(): string {
    return $localize`Complete Dentures`;
  }

  getLocalizedMaterialTypeTitle(): string {
    return $localize`Material Type`;
  }

  getLocalizedMajorConnectorTitle(): string {
    return $localize`Major Connector`;
  }

  getLocalizedDeliveryTitle(): string {
    return $localize`Delivery`;
  }

  getLocalizedShadePlaceholder(): string {
    return $localize`Enter Shade...`;
  }

}
