import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {UrlBuilder} from './url-builder';
import {Observable, of} from 'rxjs';
import {FrequentlyAskedQuestion} from '../models/frequentlyAskedQuestion';
import {DeserializeHelper} from '../views/shared/utils/json-utils';
import {environment} from '../../environments/environment';
import {HelpVideo, LocalizedHelpVideo} from '../models/helpVideo';
import {ReleaseNote} from '../models/releaseNote.model';
import {Location} from '../models/location.model';

@Injectable({
    providedIn: 'root'
})
export class HelpService {

    constructor(private api: ApiService) {
    }

    public getFAQ(): Observable<FrequentlyAskedQuestion[]> {
        return this.api.get<FrequentlyAskedQuestion[]>(UrlBuilder.build('/frequentlyAskedQuestions'))
            .map(r => r.map(d => DeserializeHelper.deserializeToInstance(FrequentlyAskedQuestion, d)));
    }

    public createFAQ(faq: FrequentlyAskedQuestion): Observable<FrequentlyAskedQuestion> {
        return this.api.post<FrequentlyAskedQuestion>(UrlBuilder.build('/frequentlyAskedQuestions'), faq);
    }

    public updateFAQ(faq: FrequentlyAskedQuestion): Observable<FrequentlyAskedQuestion> {
        return this.api.put<FrequentlyAskedQuestion>(UrlBuilder.build(`/frequentlyAskedQuestions/${faq.id}`), faq);
    }

    public deleteFAQ(faq: FrequentlyAskedQuestion): Observable<string> {
        return this.api.delete(
            UrlBuilder.build(`/frequentlyAskedQuestions/${faq.id}`),
            null,
            null,
            'text');
    }

    public getHelpVideos(): Observable<HelpVideo[]> {
        return this.api.get<HelpVideo[]>(UrlBuilder.build('/helpVideos'))
            .map(r => r.map(d => DeserializeHelper.deserializeToInstance(HelpVideo, d)));
    }

    public createHelpVideo(helpVideo: HelpVideo): Observable<HelpVideo> {
        return this.api.post<HelpVideo>(UrlBuilder.build('/helpVideos'), helpVideo);
    }

    public deleteHelpVideo(helpVideo: HelpVideo): Observable<string> {
        return this.api.delete(
            UrlBuilder.build(`/helpVideos/${helpVideo.id}`),
            null,
            null,
            'text');
    }

    public createLocalizedHelpVideo(localizedHelpVideo: LocalizedHelpVideo): Observable<LocalizedHelpVideo> {
        return this.api.post<LocalizedHelpVideo>(
            UrlBuilder.build(`/helpVideos/${localizedHelpVideo.helpVideoId}/localizedhelpvideos`),
            localizedHelpVideo);
    }

    public deleteLocalizedHelpVideo(localizedHelpVideo: LocalizedHelpVideo): Observable<string> {
        return this.api.delete(
            UrlBuilder.build(`/helpVideos/${localizedHelpVideo.helpVideoId}/localizedhelpvideos/${localizedHelpVideo.id}`),
            null,
            null,
            'text');
    }

    public getReleaseNotes(): Observable<ReleaseNote[]> {
        return this.api.recursiveGet<ReleaseNote[]>('/releaseNotes/')
            .map(r => r.map(d => DeserializeHelper.deserializeToInstance(ReleaseNote, d)));
    }
}
